import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListActivityUserRequest extends IBaseRequest {
    activity: number;
}

export class ListActivityUserRequest extends BaseRequest implements IListActivityUserRequest {
    public activity: number;
    constructor(server: string, session: string, activity: number) {
        super(server, session);
        this.activity = activity;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        if (this.activity) parameters += `<activity>${this.activity}</activity>`;
        
        return super.wrapperXml('ListActivityUser', parameters);
    }
}
