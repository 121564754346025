export interface ProjectItem {
    amount?: string;
    amountWithVat?: string;
    approved?: string;
    approvedBy?: string;
    approvedByName?: string;
    billingDate?: string;
    nextBillingDate?: string;
    billingStatus?: string;
    totalQuantity?: string;
    convertToNextPeriod?: string;
    budgetId?: string;
    customer?: string;
    provider?: string;
    customerName?: string;
    providerName?: string;
    catalogPrice?: string;
    componentCategory?: string;
    created?: string;
    createdByName?: string;
    currency?: string;
    distGroup?: string;
    formId?: string;
    formValue?: string;
    freeValue?: string;
    freeQuantity?: string;
    id?: string;
    invoiceId?: string;
    item?: string;
    itemType?: string;
    itemSerial?: string;
    language?: string;
    manufacturer?: string;
    manufacturerName?: string;
    modified?: string;
    modifiedByName?: string;
    maxLen?: string;
    name?: string;
    note?: string;
    orderId?: string;
    parentId?: string;
    priceType?: string;
    priceTypeName?: string;
    priority?: string;
    project?: string;
    projectMasterProject?: string;
    projectName?: string;
    projectState?: string;
    projectCustomState?: string;
    projectStatusName?: string;
    purchasePrice?: string;
    purchasePriceDefault?: string;
    quantity?: string;
    recordId?: string;
    recurringId?: string;
    refId?: string;
    refIdBudget?: string;
    refIdInvoice?: string;
    refIdOrder?: string;
    serviceLevel?: string;
    serviceLevelName?: string;
    serviceMonths?: string;
    status?: string;
    statusName?: string;
    folder?: string;
    folderName?: string;
    folderStatusColor?: string;
    folderRole?: string;
    totalAmount?: string;
    totalAmountWithVat?: string;
    type?: string;
    typeName?: string;
    units?: string;
    unitsName?: string;
    vat?: string;
    vatValue?: string;
    ratio?: string;
    dimension1?: string;
    dimension1Name?: string;
    dimension2?: string;
    dimension2Name?: string;
    dimension3?: string;
    dimension3Name?: string;
    taxationDate?: string;
    fixAmount?: string;
    isModelItem?: string;
    splitInInvoice?: string;
    fixText?: string;
    isContainer?: string;
    estimateTransaction?: string;
    containderId?: string;
    availableFreeQuantity?: string;
    containderName?: string;
    recommended?: string;
    recommendedName?: string;
    validFrom?: string;
    validTo?: string;
    destinationProject?: string;
    activity?: string;
    typeOfActivity?: string;
    typeOfActivityName?: string;
    billingFrequency?: string;
    fromTimeSheet?: string;
    orderIndex?: string;
    purchasedQty?: string;
    soldQty?: string;
    attrib0?: string;
    attrib0_id?: string;
    attrib1?: string;
    attrib1_id?: string;
    attrib2?: string;
    attrib2_id?: string;
    attrib3?: string;
    attrib3_id?: string;
    attrib4?: string;
    attrib4_id?: string;
    attrib5?: string;
    attrib5_id?: string;
    attrib6?: string;
    attrib6_id?: string;
    attrib7?: string;
    attrib7_id?: string;
    attrib8?: string;
    attrib8_id?: string;
    attrib9?: string;
    attrib9_id?: string;
    currencyRate?: string;
    supplier?: string;
    purchaseCurrency?: string;
    purchaseCurrencyRate?: string;
    totalAmountBccy?: string;
    totalPurchase?: string;
    totalPurchaseBccy?: string;
    catalogPriceBccy?: string;
    supplierName?: string;
    purchasePriceBccy?: string;
  }

  export function toProjectItemXML(item:ProjectItem): string{
    var parameters: string = "";

    if(item.amount) parameters += "<amount>" + item.amount + "</amount>";
    if(item.amountWithVat) parameters += "<amountWithVat>" + item.amountWithVat + "</amountWithVat>";
    if(item.approved) parameters += "<approved>" + item.approved + "</approved>";
    if(item.approvedBy) parameters += "<approvedBy>" + item.approvedBy + "</approvedBy>";
    if(item.approvedByName) parameters += "<approvedByName>" + item.approvedByName + "</approvedByName>";
    if(item.billingDate) parameters += "<billingDate>" + item.billingDate + "</billingDate>";
    if(item.nextBillingDate) parameters += "<nextBillingDate>" + item.nextBillingDate + "</nextBillingDate>";
    if(item.billingStatus) parameters += "<billingStatus>" + item.billingStatus + "</billingStatus>";
    if(item.totalQuantity) parameters += "<totalQuantity>" + item.totalQuantity + "</totalQuantity>";
    if(item.convertToNextPeriod) parameters += "<convertToNextPeriod>" + item.convertToNextPeriod + "</convertToNextPeriod>";
    if(item.budgetId) parameters += "<budgetId>" + item.budgetId + "</budgetId>";
    if(item.customer) parameters += "<customer>" + item.customer + "</customer>";
    if(item.provider) parameters += "<provider>" + item.provider + "</provider>";
    if(item.customerName) parameters += "<customerName>" + item.customerName + "</customerName>";
    if(item.providerName) parameters += "<providerName>" + item.providerName + "</providerName>";
    if(item.catalogPrice) parameters += "<catalogPrice>" + item.catalogPrice + "</catalogPrice>";
    if(item.componentCategory) parameters += "<componentCategory>" + item.componentCategory + "</componentCategory>";
    if(item.created) parameters += "<created>" + item.created + "</created>";
    if(item.createdByName) parameters += "<createdByName>" + item.createdByName + "</createdByName>";
    if(item.currency) parameters += "<currency>" + item.currency + "</currency>";
    if(item.distGroup) parameters += "<distGroup>" + item.distGroup + "</distGroup>";
    if(item.formId) parameters += "<formId>" + item.formId + "</formId>";
    if(item.formValue) parameters += "<formValue>" + item.formValue + "</formValue>";
    if(item.freeValue) parameters += "<freeValue>" + item.freeValue + "</freeValue>";
    if(item.freeQuantity) parameters += "<freeQuantity>" + item.freeQuantity + "</freeQuantity>";
    if(item.id) parameters += "<id>" + item.id + "</id>";
    if(item.invoiceId) parameters += "<invoiceId>" + item.invoiceId + "</invoiceId>";
    if(item.item) parameters += "<item>" + item.item + "</item>";
    if(item.itemType) parameters += "<itemType>" + item.itemType + "</itemType>";
    if(item.itemSerial) parameters += "<itemSerial>" + item.itemSerial + "</itemSerial>";
    if(item.language) parameters += "<language>" + item.language + "</language>";
    if(item.manufacturer) parameters += "<manufacturer>" + item.manufacturer + "</manufacturer>";
    if(item.manufacturerName) parameters += "<manufacturerName>" + item.manufacturerName + "</manufacturerName>";
    if(item.modified) parameters += "<modified>" + item.modified + "</modified>";
    if(item.modifiedByName) parameters += "<modifiedByName>" + item.modifiedByName + "</modifiedByName>";
    if(item.maxLen) parameters += "<maxLen>" + item.maxLen + "</maxLen>";
    if(item.name) parameters += "<name>" + item.name + "</name>";
    if(item.note) parameters += "<note>" + item.note + "</note>";
    if(item.orderId) parameters += "<orderId>" + item.orderId + "</orderId>";
    if(item.parentId) parameters += "<parentId>" + item.parentId + "</parentId>";
    if(item.priceType) parameters += "<priceType>" + item.priceType + "</priceType>";
    if(item.priceTypeName) parameters += "<priceTypeName>" + item.priceTypeName + "</priceTypeName>";
    if(item.priority) parameters += "<priority>" + item.priority + "</priority>";
    if(item.project) parameters += "<project>" + item.project + "</project>";
    if(item.projectMasterProject) parameters += "<projectMasterProject>" + item.projectMasterProject + "</projectMasterProject>";
    if(item.projectName) parameters += "<projectName>" + item.projectName + "</projectName>";
    if(item.projectState) parameters += "<projectState>" + item.projectState + "</projectState>";
    if(item.projectCustomState) parameters += "<projectCustomState>" + item.projectCustomState + "</projectCustomState>";
    if(item.projectStatusName) parameters += "<projectStatusName>" + item.projectStatusName + "</projectStatusName>";
    if(item.purchasePrice) parameters += "<purchasePrice>" + item.purchasePrice + "</purchasePrice>";
    if(item.purchasePriceDefault) parameters += "<purchasePriceDefault>" + item.purchasePriceDefault + "</purchasePriceDefault>";
    if(item.quantity) parameters += "<quantity>" + item.quantity + "</quantity>";
    if(item.recordId) parameters += "<recordId>" + item.recordId + "</recordId>";
    if(item.recurringId) parameters += "<recurringId>" + item.recurringId + "</recurringId>";
    if(item.refId) parameters += "<refId>" + item.refId + "</refId>";
    if(item.refIdBudget) parameters += "<refIdBudget>" + item.refIdBudget + "</refIdBudget>";
    if(item.refIdInvoice) parameters += "<refIdInvoice>" + item.refIdInvoice + "</refIdInvoice>";
    if(item.refIdOrder) parameters += "<refIdOrder>" + item.refIdOrder + "</refIdOrder>";
    if(item.serviceLevel) parameters += "<serviceLevel>" + item.serviceLevel + "</serviceLevel>";
    if(item.serviceLevelName) parameters += "<serviceLevelName>" + item.serviceLevelName + "</serviceLevelName>";
    if(item.serviceMonths) parameters += "<serviceMonths>" + item.serviceMonths + "</serviceMonths>";
    if(item.status) parameters += "<status>" + item.status + "</status>";
    if(item.statusName) parameters += "<statusName>" + item.statusName + "</statusName>";
    if(item.folder) parameters += "<folder>" + item.folder + "</folder>";
    if(item.folderName) parameters += "<folderName>" + item.folderName + "</folderName>";
    if(item.folderStatusColor) parameters += "<folderStatusColor>" + item.folderStatusColor + "</folderStatusColor>";
    if(item.folderRole) parameters += "<folderRole>" + item.folderRole + "</folderRole>";
    if(item.totalAmount) parameters += "<totalAmount>" + item.totalAmount + "</totalAmount>";
    if(item.totalAmountWithVat) parameters += "<totalAmountWithVat>" + item.totalAmountWithVat + "</totalAmountWithVat>";
    if(item.type) parameters += "<type>" + item.type + "</type>";
    if(item.typeName) parameters += "<typeName>" + item.typeName + "</typeName>";
    if(item.units) parameters += "<units>" + item.units + "</units>";
    if(item.unitsName) parameters += "<unitsName>" + item.unitsName + "</unitsName>";
    if(item.vat) parameters += "<vat>" + item.vat + "</vat>";
    if(item.vatValue) parameters += "<vatValue>" + item.vatValue + "</vatValue>";
    if(item.ratio) parameters += "<ratio>" + item.ratio + "</ratio>";
    if(item.dimension1) parameters += "<dimension1>" + item.dimension1 + "</dimension1>";
    if(item.dimension1Name) parameters += "<dimension1Name>" + item.dimension1Name + "</dimension1Name>";
    if(item.dimension2) parameters += "<dimension2>" + item.dimension2 + "</dimension2>";
    if(item.dimension2Name) parameters += "<dimension2Name>" + item.dimension2Name + "</dimension2Name>";
    if(item.dimension3) parameters += "<dimension3>" + item.dimension3 + "</dimension3>";
    if(item.dimension3Name) parameters += "<dimension3Name>" + item.dimension3Name + "</dimension3Name>";
    if(item.taxationDate) parameters += "<taxationDate>" + item.taxationDate + "</taxationDate>";
    if(item.fixAmount) parameters += "<fixAmount>" + item.fixAmount + "</fixAmount>";
    if(item.isModelItem) parameters += "<isModelItem>" + item.isModelItem + "</isModelItem>";
    if(item.splitInInvoice) parameters += "<splitInInvoice>" + item.splitInInvoice + "</splitInInvoice>";
    if(item.fixText) parameters += "<fixText>" + item.fixText + "</fixText>";
    if(item.isContainer) parameters += "<isContainer>" + item.isContainer + "</isContainer>";
    if(item.estimateTransaction) parameters += "<estimateTransaction>" + item.estimateTransaction + "</estimateTransaction>";
    if(item.containderId) parameters += "<containderId>" + item.containderId + "</containderId>";
    if(item.availableFreeQuantity) parameters += "<availableFreeQuantity>" + item.availableFreeQuantity + "</availableFreeQuantity>";
    if(item.containderName) parameters += "<containderName>" + item.containderName + "</containderName>";
    if(item.recommended) parameters += "<recommended>" + item.recommended + "</recommended>";
    if(item.recommendedName) parameters += "<recommendedName>" + item.recommendedName + "</recommendedName>";
    if(item.validFrom) parameters += "<validFrom>" + item.validFrom + "</validFrom>";
    if(item.validTo) parameters += "<validTo>" + item.validTo + "</validTo>";
    if(item.destinationProject) parameters += "<destinationProject>" + item.destinationProject + "</destinationProject>";
    if(item.activity) parameters += "<activity>" + item.activity + "</activity>";
    if(item.typeOfActivity) parameters += "<typeOfActivity>" + item.typeOfActivity + "</typeOfActivity>";
    if(item.typeOfActivityName) parameters += "<typeOfActivityName>" + item.typeOfActivityName + "</typeOfActivityName>";
    if(item.billingFrequency) parameters += "<billingFrequency>" + item.billingFrequency + "</billingFrequency>";
    if(item.fromTimeSheet) parameters += "<fromTimeSheet>" + item.fromTimeSheet + "</fromTimeSheet>";
    if(item.orderIndex) parameters += "<orderIndex>" + item.orderIndex + "</orderIndex>";
    if(item.purchasedQty) parameters += "<purchasedQty>" + item.purchasedQty + "</purchasedQty>";
    if(item.soldQty) parameters += "<soldQty>" + item.soldQty + "</soldQty>";
    if(item.attrib0) parameters += "<attrib0>" + item.attrib0 + "</attrib0>";
    if(item.attrib0_id) parameters += "<attrib0_id>" + item.attrib0_id + "</attrib0_id>";
    if(item.attrib1) parameters += "<attrib1>" + item.attrib1 + "</attrib1>";
    if(item.attrib1_id) parameters += "<attrib1_id>" + item.attrib1_id + "</attrib1_id>";
    if(item.attrib2) parameters += "<attrib2>" + item.attrib2 + "</attrib2>";
    if(item.attrib2_id) parameters += "<attrib2_id>" + item.attrib2_id + "</attrib2_id>";
    if(item.attrib3) parameters += "<attrib3>" + item.attrib3 + "</attrib3>";
    if(item.attrib3_id) parameters += "<attrib3_id>" + item.attrib3_id + "</attrib3_id>";
    if(item.attrib4) parameters += "<attrib4>" + item.attrib4 + "</attrib4>";
    if(item.attrib4_id) parameters += "<attrib4_id>" + item.attrib4_id + "</attrib4_id>";
    if(item.attrib5) parameters += "<attrib5>" + item.attrib5 + "</attrib5>";
    if(item.attrib5_id) parameters += "<attrib5_id>" + item.attrib5_id + "</attrib5_id>";
    if(item.attrib6) parameters += "<attrib6>" + item.attrib6 + "</attrib6>";
    if(item.attrib6_id) parameters += "<attrib6_id>" + item.attrib6_id + "</attrib6_id>";
    if(item.attrib7) parameters += "<attrib7>" + item.attrib7 + "</attrib7>";
    if(item.attrib7_id) parameters += "<attrib7_id>" + item.attrib7_id + "</attrib7_id>";
    if(item.attrib8) parameters += "<attrib8>" + item.attrib8 + "</attrib8>";
    if(item.attrib8_id) parameters += "<attrib8_id>" + item.attrib8_id + "</attrib8_id>";
    if(item.attrib9) parameters += "<attrib9>" + item.attrib9 + "</attrib9>";
    if(item.attrib9_id) parameters += "<attrib9_id>" + item.attrib9_id + "</attrib9_id>";
    if(item.currencyRate) parameters += "<currencyRate>" + item.currencyRate + "</currencyRate>";
    if(item.supplier) parameters += "<supplier>" + item.supplier + "</supplier>";
    if(item.purchaseCurrency) parameters += "<purchaseCurrency>" + item.purchaseCurrency + "</purchaseCurrency>";
    if(item.purchaseCurrencyRate) parameters += "<purchaseCurrencyRate>" + item.purchaseCurrencyRate + "</purchaseCurrencyRate>";
    if(item.totalAmountBccy) parameters += "<totalAmountBccy>" + item.totalAmountBccy + "</totalAmountBccy>";
    if(item.totalPurchase) parameters += "<totalPurchase>" + item.totalPurchase + "</totalPurchase>";
    if(item.totalPurchaseBccy) parameters += "<totalPurchaseBccy>" + item.totalPurchaseBccy + "</totalPurchaseBccy>";
    if(item.catalogPriceBccy) parameters += "<catalogPriceBccy>" + item.catalogPriceBccy + "</catalogPriceBccy>";
    if(item.supplierName) parameters += "<supplierName>" + item.supplierName + "</supplierName>";
    if(item.purchasePriceBccy) parameters += "<purchasePriceBccy>" + item.purchasePriceBccy + "</purchasePriceBccy>";

    return parameters;
  }