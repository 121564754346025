import { BaseRequest, IBaseRequest } from '../../BaseRequest';
import { Person, toPersonXml } from '../../dataObjects/Person';

export interface ICreateContactRequest extends IBaseRequest {
    PERSON:       Person;
}

export class CreateContactRequest extends BaseRequest implements ICreateContactRequest {
    public PERSON: Person;
    constructor(server: string, session: string, person: Person) {
        super(server, session);
        this.PERSON = person;
    }

    public toXml(): string {
        var parameters: string = super.toXml();
        parameters += toPersonXml(this.PERSON);
        return super.wrapperXml('CreateContact', parameters);
    }
}
