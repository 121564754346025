import { ACCESS } from "../../../utils/ServiceUtils";
import { ServiceBase } from "../ServiceBase";
import { IGetUserRequest } from "./requests/GetUserRequest";
import { IListProfileRequest } from "./requests/ListProfileRequest";
import { IListRoleRequest } from "./requests/ListRoleRequest";
import { IListUserRequest } from "./requests/ListUserRequest";
import { GetUserResponse } from "./responses/GetUserResponse";
import { ListProfileResponse } from "./responses/ListProfileResponse";
import { ListRoleResponse } from "./responses/ListRoleResponse";
import { ListUserResponse } from "./responses/ListUserResponse";

export interface IAccessService {
    listUser(request: IListUserRequest): Promise<ListUserResponse>;
    getUser(request: IGetUserRequest): Promise<GetUserResponse>;
    listRole(request: IListRoleRequest): Promise<ListRoleResponse>;
    listProfile(request: IListProfileRequest): Promise<ListProfileResponse>;
}

export class AccessService extends ServiceBase implements IAccessService {
    _addRootElementForUserList(xml: string): string {
        xml = xml.replace("</count><USER>", "</count><USERS><USER>");
        xml = xml.replace("</USER></ns1:ListUserResponse>", "</USER></USERS></ns1:ListUserResponse>");
        return xml;
    }
    _addRootElementForRoleList(xml: string): string {
        xml = xml.replace("</result><ROLE>", "</result><ROWS><ROLE>");
        xml = xml.replace("</ROLE></ns1:ListRoleResponse>", "</ROLE></ROWS></ns1:ListRoleResponse>");
        return xml;
    }
    public async listUser(request: IListUserRequest): Promise<ListUserResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), ACCESS.listUser, request.cancelSource));
            response = this._addRootElementForUserList(tmp_response.data);
            await this.updateCache(ACCESS.listUser, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(ACCESS.listUser, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListUserResponse as ListUserResponse;
        if (!super._checkErrors(parsedResponse)) {

        };
        return parsedResponse;
    }
    public async getUser(request: IGetUserRequest): Promise<GetUserResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), ACCESS.getUser, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(ACCESS.getUser, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(ACCESS.getUser, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).GetUserResponse as GetUserResponse;
        if (!super._checkErrors(parsedResponse)) {

        };
        return parsedResponse;
    }

    public async listRole(request: IListRoleRequest): Promise<ListRoleResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), ACCESS.listRole, request.cancelSource));
            response = this._addRootElementForRoleList(tmp_response.data);
            await this.updateCache(ACCESS.listRole, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(ACCESS.listRole, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListRoleResponse as ListRoleResponse;
        if (!super._checkErrors(parsedResponse)) {

        };
        return parsedResponse;
    }

    public async listProfile(request: IListProfileRequest): Promise<ListProfileResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), ACCESS.listProfile, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(ACCESS.listProfile, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(ACCESS.listProfile, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListProfileResponse as ListProfileResponse;
        if (!super._checkErrors(parsedResponse)) {

        };
        return parsedResponse;
    }
}