// DataComponent.tsx
import { useState } from 'react';
import { useDataContext } from './Contexts/DataContext';
import { ComboBox, Item, Key } from '@adobe/react-spectrum';
import { DataComponentProps } from './DataComponentProps';

const DepartmentDropDown: React.FC<DataComponentProps> = ({ compKey, label, width, isRequired, onValueChange }) => {
  const [value, setValue] = useState<Key | null>('');
  const { getDepartmentData } = useDataContext();
  const { data, error, loading } = getDepartmentData();
  const onSelectionChange = (value: Key | null) => {
    if(onValueChange)
        onValueChange(value ? value.toString() : '');
    setValue(value)
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <ComboBox key={compKey} 
        label={label} 
        width={width} 
        isRequired={isRequired} 
        selectedKey={value}
        onSelectionChange={onSelectionChange} 
        >
      {data.map((item) => (
        <Item key={item.id}>
          {item.name}
        </Item>
      ))}
    </ComboBox>
  );
};

export default DepartmentDropDown;
