import React, { useState } from 'react';
import { View, Flex, TextArea } from '@adobe/react-spectrum';
import { RatingComponent } from '@syncfusion/ej2-react-inputs';
import { useTranslation } from 'react-i18next';
import ContextMenuButton from './ContextMenuButton';
import styles from '../ExternalContacts.module.css';

interface ContactCardProps {
  currentItem: any;
  contact: any;
  avatarSrc: string;
  selectedItem: any;
  FPADataTypes: any;
  onSaveDescription: (contactId: string, description: string) => void;
  onSaveRating: (contactId: string, rating: string) => void;
  updateItem: (request: any, message: string) => void;
  closeDialog: (reload: boolean) => void;
}

const ContactCard: React.FC<ContactCardProps> = ({ currentItem, contact, avatarSrc, selectedItem, FPADataTypes, onSaveDescription, onSaveRating, updateItem, closeDialog }) => {
  const [isTextAreaFocused, setIsTextAreaFocused] = useState<boolean>(false);
  const [description, setDescription] = useState<string>(contact?.description);
  const { t } = useTranslation();

  const topPosition = (() => {
    let position = 0;
    if (contact?.email) position += 80;
    if (contact?.phone) {
      if (position === 80) {
        position += 20;
      } else {
        position += 80;
      }
    }
    if (contact?.mobile) {
      if (position >= 80) {
        position += 20;
      } else {
        position += 80;
      }
    }
    return position === 0 ? 70 : Math.min(position, 125);
  })();

  const handleSaveDescription = () => {
    onSaveDescription(contact.id, description);
    setIsTextAreaFocused(false);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      onSaveDescription(contact.id, description);
      setIsTextAreaFocused(false);
    }
  };

  function emptyTemplate() {
    return <span className="custom-font sf-icon-empty-star"></span>;
  }
  function fullTemplate() {
    return <span className="custom-font sf-icon-fill-star"></span>;
  }

  const handleRatingChange = (e: any) => {
    onSaveRating(contact.id, String(e.value * 20));
  };

  return (
    <View key={contact.id} UNSAFE_className={styles.contact_parent}>
      <View UNSAFE_className={styles.contact_avatar_parent}>
        <img className={styles.contact_avatar} src={avatarSrc} alt="" />
      </View>
      <View UNSAFE_className={styles.contact_name}>
        {contact?.isPrimary === '1' && (
          <i className="bi bi-star-fill" style={{ color: '#F4CA16' }}>
            &nbsp;
          </i>
        )}
        {contact?.name3 + ' ' + contact?.name1}
      </View>
      <Flex UNSAFE_className={styles.description_parent} UNSAFE_style={{ top: `${topPosition}px` }}>
        <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} alignSelf={'stretch'} height={'24px'}>
          <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} height={'24px'}>
            <View position={'relative'}>{t('description', { ns: 'layout_components' })}</View>
          </Flex>
        </Flex>
        <div style={{ width: '100%' }} onKeyUp={handleKeyUp}>
          <TextArea
            aria-label="Description"
            key={`txt_${contact.id}`}
            defaultValue={contact?.note}
            value={description}
            onChange={e => setDescription(e)}
            onFocus={() => setIsTextAreaFocused(true)}
            width={'100%'}
          ></TextArea>
        </div>
        {isTextAreaFocused && (
          <Flex UNSAFE_className={styles.activity_btn_container}>
            <Flex UNSAFE_className={styles.activity_btn_master}>
              <Flex UNSAFE_className={styles.activity_btn_child}>
                <Flex UNSAFE_className={styles.btn_cancel_desc}>
                  <button
                    onClick={() => {
                      setIsTextAreaFocused(false);
                    }}
                    className={`${styles.icon_cancel} bi bi-x fs-3`}
                  ></button>
                </Flex>
                <Flex UNSAFE_className={styles.btn_save_desc}>
                  <button
                    onClick={() => {
                      handleSaveDescription();
                    }}
                    className={`${styles.icon_add} bi bi-check fs-3`}
                  ></button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
      <ContextMenuButton contactId={contact.id} currentItem={currentItem} updateItem={updateItem} closeDialog={closeDialog} selectedItem={selectedItem} />
      <Flex UNSAFE_className={styles.contact_details_parent}>
        {contact?.email && (
          <Flex UNSAFE_className={styles.icon_parent}>
            <i className={`${styles.icon_email} bi bi-envelope fs-7`}></i>
            <View position={'relative'}>{contact?.email}</View>
          </Flex>
        )}
        {contact?.phone && (
          <Flex UNSAFE_className={styles.icon_parent}>
            <i className={`${styles.icon_telephone} bi bi-telephone fs-7`}></i>
            <View position={'relative'}>{contact?.phone}</View>
          </Flex>
        )}
        {contact?.mobile && (
          <Flex UNSAFE_className={styles.icon_parent}>
            <i className={`${styles.icon_mobile} bi bi-phone fs-7`}></i>
            <View position={'relative'}>{contact?.mobile}</View>
          </Flex>
        )}
        {contact?.relationshipLevel && selectedItem.type === FPADataTypes.FOLDER && (
          <Flex UNSAFE_className={styles.icon_parent}>
            <RatingComponent emptyTemplate={emptyTemplate} fullTemplate={fullTemplate} value={contact?.relationshipLevel / 20} valueChanged={handleRatingChange}></RatingComponent>
          </Flex>
        )}
      </Flex>
    </View>
  );
};

export default ContactCard;
