import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListFolderTemplateRequest extends IBaseRequest {
    canBeRoot?:       boolean;
    contactType?:     string;
    folderType?:      string;
    treeHandle?:      string;
    fpaTypeCategory?: string;
}

export class ListFolderTemplateRequest extends BaseRequest implements IListFolderTemplateRequest {
    public canBeRoot?:       boolean;
    public contactType?:     string;
    public folderType?:      string;
    public treeHandle?:      string;
    public fpaTypeCategory?: string;

    constructor(server: string, session: string, otherOptions: any) {
        super(server, session);
        this.canBeRoot       = otherOptions.canBeRoot;
        this.contactType     = otherOptions.contactType;
        this.folderType      = otherOptions.folderType;
        this.treeHandle      = otherOptions.treeHandle;
        this.fpaTypeCategory = otherOptions.fpaTypeCategory;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        if(this.canBeRoot) parameters += `<canBeRoot>${this.canBeRoot}</canBeRoot>`;
        if(this.contactType) parameters += `<contactType>${this.contactType}</contactType>`;
        if(this.folderType) parameters += `<folderType>${this.folderType}</folderType>`;
        if(this.treeHandle) parameters += `<treeHandle>${this.treeHandle}</treeHandle>`;
        if(this.fpaTypeCategory) parameters += `<fpaTypeCategory>${this.fpaTypeCategory}</fpaTypeCategory>`;
        
        return super.wrapperXml('ListFolderTemplate', parameters);
    }
}
