import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IGetFinanceSettingsRequest extends IBaseRequest {
    linkTable? : string;
    linkName? : string;
}

export class GetFinanceSettingsRequest extends BaseRequest implements IGetFinanceSettingsRequest {
    public linkTable?: string;
    public linkName?: string;

    constructor(server: string, session: string, linkTable?: string, linkName?: string) {
        super(server, session);
        this.linkTable = linkTable;
        this.linkName = linkName;
    }

  public toXml(): string {
    var parameters: string = super.toXml();

    if (this.linkTable) {
      parameters += '<linkTable>' + this.linkTable + '</linkTable>';
    }
    if (this.linkName) {
      parameters += '<linkName>' + this.linkName + '</linkName>';
    }
    
    return super.wrapperXml('GetFinanceSettings', parameters);
  }
}
