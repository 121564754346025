import React, { useContext, useState } from 'react';
import { Application } from '../services/soap/features/responses/ListApplicationsResponse';
import AppsContext from './AppsContext';

type AppsProviderProps = {
    children: React.ReactNode;
}

export const AppsProvider: React.FC<AppsProviderProps> = ({ children }) => {
    const [apps, setApps] = useState<Application[]>([]);
    const [redirectUrl, setRedirectUrl] = useState('');
    return (<AppsContext.Provider value={{ 
        apps,
        setApps,
        redirectUrl,
        setRedirectUrl
    }}>{children}</AppsContext.Provider>);
};

export function useApps() {
    const context = useContext(AppsContext);
    if (context === undefined) {
        throw new Error('useApps must be used within a AppsProvider');
    }
    return context;
}
