import { ActionButton, Flex, View } from '@adobe/react-spectrum';
import styles from './ProjectCell.module.css';
import { IconStyle, ProjectIcon, SearchIcon } from '../../Icons/IconsLib';
import ChevronRight from '@spectrum-icons/workflow/ChevronRight';
import ChevronDown from '@spectrum-icons/workflow/ChevronDown';
import { useState } from 'react';
import { FPAData } from '../../../../infra/protected/FPA/FPAData';
import useDeviceType from '../../../../hooks/UseDeviceType';
import ConstantUtils from '../../../../utils/ConstantUtils';

export interface IProjectCellProps {
    item: FPAData;
    onSearch?: (id:number) => void;
    onClicked: (id:number) => void;
    onReset?:() => void;
}

export function ProjectCell ({
    item,
    onSearch,
    onReset,
    onClicked
}: IProjectCellProps) {
    const [isOpen, setIsOpen] = useState(item.isOpen);
    const { isTouchDevice, isSmallDevice } = useDeviceType();

    const handleToggle = (e:any) => {
        e.stopPropagation();
        setIsOpen(!isOpen);
        onClicked(item.id);
    }
    //if(id == 449420101) console.log(`ProjectCell - ${id} - ${title} - ${count} - ${isOpen}`);
    const handleSearch = (e:any) => { 
        e.stopPropagation(); 
        if(item.isFiltered) {
            if(onReset)
                onReset();
        }
        else { 
            if(onSearch) 
                onSearch(item.id);
        }
    }
    const handleClicked = (e:any) => {
        // e.stopPropagation();
        if(isTouchDevice) return;
        handleToggle(e);
    }

    var spacing = Math.max(0,30 + (item.node_level - 1) * 50);
    var display_title = item.title;
    var label_width = (isSmallDevice ? window.innerWidth - 205 : window.innerWidth / 4 - 205 )  - spacing;
    var line1_width = label_width;

  return (
    <>
        <Flex direction="row" UNSAFE_className={styles.projectWrapper}>
            <View width={spacing} />
            <View>
                <span onTouchEnd={handleToggle} onClick={handleClicked}>
                    <Flex direction="row" height={'100%'}>
                        <span style={{color: 'var(--neutral-40)'}}>{(item.count > 0) && (isOpen ? ( <ChevronDown size={'S'} />) : ( <ChevronRight size={'S'} />)) }</span>    
                        {!(item.count > 0) && (<span style={{width: '20px'}}></span>)}
                        <ProjectIcon size={24} />
                    </Flex>
                </span>
            </View>
            <Flex direction="column" marginStart={10} flex>
                <Flex direction="row" alignItems="center" gap={'size-100'}>
                    <View>
                        <span className={styles.title} style={{maxWidth: label_width}}>{display_title}</span> 
                    </View>
                    {item.count > 0 &&
                    <View>
                        <span className={styles.count}>{item.count}</span>
                    </View>}
                </Flex>                    
                <View UNSAFE_className={styles.line1} UNSAFE_style={{maxWidth: line1_width}}>
                    {item.line1}
                </View>
                <View UNSAFE_className={styles.line2}>
                    {item.line2}
                </View>
            </Flex>
            <Flex direction="row" alignItems="end" alignSelf={'center'} justifySelf={'end'} justifyContent={'end'} UNSAFE_className={styles.statusArea}>
                <View paddingEnd={10} UNSAFE_className={styles.search} UNSAFE_style={{ display:  (isOpen? 'flex': '')}}>
                    <ActionButton isQuiet 
                        onPress={handleSearch}
                        UNSAFE_style={{ height: '21px'}}
                        >
                        <span>
                            <SearchIcon size={18} stateClass={item.isFiltered ? IconStyle.filtered : IconStyle.normal}/>
                        </span>
                    </ActionButton>                    
                </View>
                <View UNSAFE_className={styles.status}>
                    <div className={styles.statusIndicator} style={{backgroundColor: item.statusColor}}></div>
                </View>
                <View UNSAFE_className={styles.statusText}>
                {item.statusName}
                </View>
            </Flex>
        </Flex>
    </>
  );
}
