import { BaseRequest, IBaseRequest } from "../../BaseRequest";

export interface FilterCol{
    name: string;
    type:string;
    value?: string;
    negation?: boolean;
    includeNull?: boolean;
}

function toFilterColXml(fc:FilterCol):string{
    var parameters:string = '';
    if(fc.value) parameters += `<value>${fc.value}</value>\n`;
    if(fc.negation) parameters += `<negation>${fc.negation}</negation>\n`;
    if(fc.includeNull) parameters += `<includeNull>${fc.includeNull}</includeNull>\n`;
    return `<COL>
    <name>${fc.name}</name>
    <type>${fc.type}</type>
    ${parameters}
    </COL>`;
}

export interface FilterFolder{
    COL: FilterCol[];
}

function toFilterFolderXml(ff:FilterFolder):string{
    let cols = '';
    ff.COL.forEach(col => {
        cols += toFilterColXml(col);
    });
    return `<FILTER_FOLDER>${cols}</FILTER_FOLDER>`;
}

export interface FilterProject{
    COL: FilterCol[];
}

function toFilterProjectXml(fp:FilterProject):string{
    let cols = '';
    fp.COL.forEach(col => {
        cols += toFilterColXml(col);
    });
    return `<FILTER_PROJECT>${cols}</FILTER_PROJECT>`;
}

export interface FilterActivity{
    COL: FilterCol[];
}
function toFilterActivityXml(fa:FilterActivity):string{
    let cols = '';
    fa.COL.forEach(col => {
        cols += toFilterColXml(col);
    });
    return `<FILTER_ACTIVITY>${cols}</FILTER_ACTIVITY>`;
}

export interface Col{
    name: string;
    desc?: boolean;
}

function toColXml(col:Col):string{
    return `<COL>
    <name>${col.name}</name>
    <desc>${col.desc}</desc>
    </COL>`;
}
export interface Order{
    COL: Col[];
}

function toOrderXml(o:Order):string{
    let cols = '';
    o.COL.forEach(col => {
        cols += toColXml(col);
    });
    return `<ORDER>${cols}</ORDER>`;
}

export interface Parent {
    id: number;
    type: string;
}
function toParentXml(p:Parent):string{
    return `<PARENT>
    <id>${p.id}</id>
    <type>${p.type}</type>
    </PARENT>`;
}

export interface IListFPARequest2 extends IBaseRequest{
    limit?: number;
    offset?: number;
    orderBy?: string;
    countOnly?: boolean;
    flatView?: boolean;
    includeSlave?: boolean;
    fulltext?: string;
    parent?: Parent;
    folderFilter?: FilterFolder;
    projectFilter?: FilterProject;
    activityFilter?: FilterActivity;
    ORDER?: Order;    
}

export class ListFPARequest2 extends BaseRequest implements IListFPARequest2 {
    limit?: number;
    offset?: number;
    orderBy?: string;
    countOnly?: boolean;
    flatView?: boolean;
    includeSlave?: boolean;
    fulltext?: string;
    parent?: Parent;
    folderFilter?: FilterFolder;
    projectFilter?: FilterProject;
    activityFilter?: FilterActivity;
    ORDER?: Order;    

    constructor(server: string, 
        session: string,
        otherOptions: any) {
            super(server, session);
            this.limit = otherOptions.limit;
            this.offset = otherOptions.offset;
            this.orderBy = otherOptions.orderBy;
            this.countOnly = otherOptions.countOnly;
            this.flatView = otherOptions.flatView;
            this.includeSlave = otherOptions.includeSlave;
            this.fulltext = otherOptions.fulltext;
            this.parent = otherOptions.parent;
            this.folderFilter = otherOptions.folderFilter;
            this.projectFilter = otherOptions.projectFilter;
            this.activityFilter = otherOptions.activityFilter;
            this.ORDER = otherOptions.ORDER;
    }

    toXml(): string {
        var parameters:string = super.toXml();
        if(this.limit) parameters += `<limit>${this.limit}</limit>\n`;
        if(this.offset !== undefined) parameters += `<offset>${this.offset}</offset>\n`;
        if(this.orderBy) parameters += `<orderBy>${this.orderBy}</orderBy>\n`;
        if(this.countOnly) parameters += `<countOnly>${this.countOnly}</countOnly>\n`;
        if(this.flatView) parameters += `<flatView>${this.flatView}</flatView>\n`;
        if(this.includeSlave) parameters += `<includeSlave>${this.includeSlave}</includeSlave>\n`;
        if(this.fulltext) parameters += `<fulltext>${this.fulltext}</fulltext>\n`;
        if(this.parent) parameters += toParentXml(this.parent);
        if(this.folderFilter) parameters += toFilterFolderXml(this.folderFilter);
        if(this.projectFilter) parameters += toFilterProjectXml(this.projectFilter);
        if(this.activityFilter) parameters += toFilterActivityXml(this.activityFilter);
        if(this.ORDER) parameters += toOrderXml(this.ORDER);

        return super.wrapperXml("ListFPA", parameters);
    }
}