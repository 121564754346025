import { Image } from '@react-spectrum/image';
import { Flex } from '@react-spectrum/layout';
import { Text } from '@react-spectrum/text';
import { View } from '@react-spectrum/view';
import { ReactComponent as OfflineIcon} from '../../../assets/icons/offline.svg';

import styles from './UserHeaderMobile.module.css';
import { useEffect, useState } from 'react';
import { useDeviceInfo } from '../../../hooks/UseDeviceInfo';

const Offline = () => {
    const iconStyle = {
        paddingLeft: '10px',
        paddingTop: '5px',
        color: 'red',
        fontWeight: 'bold'
    };
    return (
        <div style={iconStyle}>
            <OfflineIcon width={16} height={16} />
        </div>
    );
}

export interface IUserHeaderMobileProps {
}

export function UserHeaderMobile (props: IUserHeaderMobileProps) {
    const { isInternetConnected } = useDeviceInfo();
    const [isOnline, setIsOnline] = useState(isInternetConnected);

    useEffect(() => {
        setIsOnline(isInternetConnected);
    }, [isInternetConnected]);
  return (
    <>
        <Flex direction={"column"} justifyContent="space-between" alignItems="center">
            <View>
                <Image src="../assets/images/atollon-logo.svg" alt={"Atollon"} height={60} />
            </View>
            {!isOnline && <View UNSAFE_className={styles.offlineAlert}>
                <Flex direction='row' alignItems='center'>
                    <Offline />
                    <Text><span style={{ paddingLeft: '5px'}}>Offline mode</span></Text>
                </Flex>
            </View>}
        </Flex>
    </>
  );
}
