import { useEffect, useState } from "react";

const SCRIPT_REG_EX_MAIN = /^.*<script.*\/(main.*\.js).*$/gim;
type UsePollerProps = {
    deploymentUrl: string;
    comparisonInterval?: number;
};
export const UsePoller = ({ deploymentUrl, comparisonInterval }:UsePollerProps) => {
    const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);
    if(!comparisonInterval) comparisonInterval = 5000;

    useEffect(() => {
        const compareVersions = async () => {
            var append = (!deploymentUrl.endsWith("?") ? "?" : "&") + (new Date()).getTime();
            // request the index.html file from the deployment
            const fetchedPage = await fetch(deploymentUrl + append, { method: 'get', mode: 'cors' });
            
            // get the text from the response
            const loadedText = await fetchedPage.text();
            // get the main.js file to get hash
            const matchResponses = SCRIPT_REG_EX_MAIN.exec(loadedText);
            let remoteMainScript = matchResponses && matchResponses.length > 0 ? matchResponses[1] : undefined;
            if (remoteMainScript === undefined) {
                console.log("Could not find main script in index.html");
                setIsNewVersionAvailable(false);
                return;
            }
            
            // get the current version hash from current deployment
            let currentMainScript = undefined;
            
            // get text representation of document
            const scriptTags = document.head.getElementsByTagName('script');
            
            for (let i = 0; i < scriptTags.length; i++) {
                const scriptTag = scriptTags[i];
                const ifMatches = /^.*\/(main.*\.js).*$/gim.exec(scriptTag.src);

                if(ifMatches !== null) {
                    currentMainScript = ifMatches === null ? undefined : ifMatches[1];
                    break;
                }
            }
            
            // if the current main script or the remote main script is undefined, we can't compare
            // but if they are there, compare them
            setIsNewVersionAvailable(
                !!currentMainScript && !!remoteMainScript && currentMainScript !== remoteMainScript
            );
            console.log("Current main script: ", currentMainScript);
            console.log("Remote main script: ", remoteMainScript);
        }
        
        const createdInterval = setInterval(compareVersions, comparisonInterval);
        compareVersions();
        return () => {
            // clear the interval when the component unmounts
            clearInterval(createdInterval)
        };
    }, [deploymentUrl, comparisonInterval]);
    
    // return the state
    return { isNewVersionAvailable };
}