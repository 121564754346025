import { BaseRequest, IBaseRequest } from '../../BaseRequest';
import { SearchTimeSheet, toSearchTimeSheetXml } from './SearchTimesheet';

export interface IListTimesheetRequest extends IBaseRequest {
    options: SearchTimeSheet;
}


export class ListTimesheetRequest extends BaseRequest implements IListTimesheetRequest {
    public options: SearchTimeSheet;
    constructor(server: string, session: string, options: SearchTimeSheet) {
        super(server, session);
        this.options = options;
    }

    public toXml(): string {
        var parameters: string = super.toXml();
        parameters += toSearchTimeSheetXml(this.options);
        return super.wrapperXml('ListTimesheet', parameters);
    }
}
