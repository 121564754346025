import { GLOBAL } from '../../../utils/ServiceUtils';
import { ServiceBase } from '../ServiceBase';
import { IListCurrencyRequest } from './requests/ListCurrencyRequest';
import { IListVATRequest } from './requests/ListVATRequest';
import { ListCurrencyResponse } from './responses/ListCurrencyResponse';
import { ListVATResponse } from './responses/ListVATResponse';

export interface IGlobalService {
    listCurrency(request: IListCurrencyRequest): Promise<ListCurrencyResponse>;
    listVAT(request: IListVATRequest): Promise<ListVATResponse>;
}

export class GlobalService extends ServiceBase implements IGlobalService { 
    private _addRootelementForItems(xml:string, call:string):string{
        xml = xml.replace('</count><ITEM>', '</count><ROWS><ITEM>');
        xml = xml.replace(`</ITEM></ns1:${call}>`, `</ITEM></ROWS></ns1:${call}>`);
        return xml;
    }

    public async listCurrency(request: IListCurrencyRequest): Promise<ListCurrencyResponse>{
        let response:any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), GLOBAL.listCurrency, request.cancelSource)).data ;

            response = this._addRootelementForItems(tmp_response,'ListCurrencyResponse');
            await this.updateCache(GLOBAL.listCurrency, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(GLOBAL.listCurrency, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListCurrencyResponse as ListCurrencyResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async listVAT(request: IListVATRequest): Promise<ListVATResponse>{
        let response:any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), GLOBAL.listVAT, request.cancelSource)).data ;

            response = this._addRootelementForItems(tmp_response, 'ListVATResponse');
            await this.updateCache(GLOBAL.listVAT, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(GLOBAL.listVAT, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListVATResponse as ListVATResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }
}
