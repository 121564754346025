import { Button, ButtonGroup, Content, Dialog, Divider, Header, Heading, Flex, Footer, ActionButton } from '@adobe/react-spectrum';
import { MultipleOptionFilter } from './components/MultipleOptionFilter';
import { ProjectTypeFilter } from './components/ProjectTypeFilter';
import { ResponsibleUserFilter } from './components/ResponsibleUserFilter';
import { usePreloadAssets } from '../../../hooks/UsePreloadAssets';
import { useDependency } from '../../../contexts/DependencyProvider';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ListUserRequest } from '../../../services/soap/access/requests/ListUserRequest';

export interface IFPAFiltersDialogProps {
    onClose: () => void;
    onApply: (filter:any) => void;
    filter: any;
}

export function FPAFiltersDialog ({
  onClose,
  onApply,
  filter
}: IFPAFiltersDialogProps) {
  const { accessService, store } = useDependency();
  const { projectTypes } = usePreloadAssets();
  const project_types = Object.keys(projectTypes).map((key:any) => projectTypes[key]).sort((a:any, b:any) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);
  const [users, setUsers] = useState<any[]>([]);
  const filterOptions = useRef<any>(null);
  const [folderStateFilter, setFolderStateFilter] = useState<boolean[]>([]);
  const [projectStateFilter, setProjectStateFilter] = useState<boolean[]>([]);
  const [activityStateFilter, setActivityStateFilter] = useState<boolean[]>([]);
  const [projectTypeFilter, setProjectTypeFilter] = useState(null);
  const [responsibleUserFilter, setResponsibleUserFilter] = useState(null);
  
  const getUsersCallback = useCallback(async () => {
    const _users = await accessService.listUser(new ListUserRequest(store.Server, store.SessionId,true));
    const allUsers = _users.USERS.map((user:any) => ({id:user.USER.contactId, name:user.USER.contactName}));
    allUsers.unshift({ id: '0', name: '' });
    setUsers(allUsers);
  }, [store.Server, store.SessionId, accessService]);

  project_types.unshift({ id:'', name:'' });
  const handleApply = () => {
    onApply(filterOptions.current);
    onClose();
  };

  const handleFilterChange = (key:string, value:any) => {

    if(filterOptions.current === null) return;
    if(filterOptions.current.FinderSettings === undefined) filterOptions.current.FinderSettings = {};
    if(filterOptions.current.FinderSettings.finderViewSettings === undefined) filterOptions.current.FinderSettings.finderViewSettings = {};
    try{
      switch(key) {
        case 'folder':
          filterOptions.current.FinderSettings.finderViewSettings.folderFilter = value.join(',');
          break;
        case 'project':
          filterOptions.current.FinderSettings.finderViewSettings.projectFilter = value.join(',');
          break;
        case 'activity':
          filterOptions.current.FinderSettings.finderViewSettings.activityFilter = value.join(',');
          break;
        case 'projectType':
          if(value == "")
            value = null;
          filterOptions.current.FinderSettings.finderViewSettings.projectTypeFilter = value;
          break;
        case 'responsibleUser':
          if(value == "0")
            value = null;
          filterOptions.current.FinderSettings.finderViewSettings.responsibleUserFilter = value;
          break;
      }
    }catch(err){
      console.log(`Error setting filter [${key}, ${value}]: `,err);
    }
  }
  useEffect(() => {
    getUsersCallback();
  }, [getUsersCallback]);

  useEffect(() => {
    if(filter === undefined || filter === null) return;

    filterOptions.current = JSON.parse(JSON.stringify(filter)); 
    if(!filter.FinderSettings) return;
    if(!filter.FinderSettings.finderViewSettings) return;

    if(filter.FinderSettings.finderViewSettings.folderFilter)
      setFolderStateFilter(filter.FinderSettings.finderViewSettings.folderFilter.split(',').map((item:any) => (item === 'true')));

    if(filter.FinderSettings.finderViewSettings.projectFilter)
      setProjectStateFilter(filter.FinderSettings.finderViewSettings.projectFilter.split(',').map((item:any) => (item === 'true')));

    if(filter.FinderSettings.finderViewSettings.activityFilter)
      setActivityStateFilter(filter.FinderSettings.finderViewSettings.activityFilter.split(',').map((item:any) => (item === 'true')));

    if(filter.FinderSettings.finderViewSettings.projectTypeFilter)
      setProjectTypeFilter(filter.FinderSettings.finderViewSettings.projectTypeFilter);

    if(filter.FinderSettings.finderViewSettings.responsibleUserFilter)
      setResponsibleUserFilter(filter.FinderSettings.finderViewSettings.responsibleUserFilter);
  }, [filter]);
  return (
    <>
        <Dialog>
            <Heading>Quick Filter</Heading>
            <Header>Filters to apply on search</Header>
            <Divider />
            <Content>
                <Flex direction='column' gap={'size-100'}>
                  <Flex direction='row' gap={'size-200'}>
                    <MultipleOptionFilter typeKey='folder' 
                                          title='Folder filter' 
                                          onChange={handleFilterChange} 
                                          defaultOptions={folderStateFilter} 
                                          />
                    <ProjectTypeFilter  title='Project Type' 
                                        onChange={handleFilterChange}
                                        options={project_types} 
                                        selectedOption={projectTypeFilter}
                                        />
                  </Flex>
                  <Flex direction='row' gap={'size-200'}>
                    <MultipleOptionFilter typeKey='project' 
                                          title='Project filter' 
                                          onChange={handleFilterChange} 
                                          defaultOptions={projectStateFilter} 
                                          />
                    <ResponsibleUserFilter  title='Responsible User' 
                                            onChange={handleFilterChange}
                                            options={users}
                                            selectedOption={responsibleUserFilter}
                                            />
                  </Flex>
                  <Flex direction='row' gap={'size-200'}>
                    <MultipleOptionFilter typeKey='activity' 
                                          title='Activity filter' 
                                          onChange={handleFilterChange} 
                                          defaultOptions={activityStateFilter} 
                                          />
                  </Flex>
                </Flex>
            </Content>
            <Footer>
              <Button variant='secondary'>Clear</Button>
            </Footer>
            <ButtonGroup>
                <Button variant="secondary" onPress={onClose}>Close</Button>
                <Button variant="accent" onPress={handleApply} >Apply</Button>
            </ButtonGroup>
        </Dialog>
    </>
  );
}
