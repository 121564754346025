import { SearchField } from '@adobe/react-spectrum';
import styles from './GlobalSearch.module.css';
import GlobalSearchResults from './GlobalSearchResults';
import { useDependency } from '../../../contexts/DependencyProvider';
import { Col, ListFPARequest, Order } from '../../../services/soap/project/requests/ListFPARequest';
import { ListContextRequest } from '../../../services/soap/project/requests/ListContextRequest';
import { useCallback, useEffect, useRef, useState } from 'react';
import { GroupedSearchResultItemData, mergeGroupedSearchResults, processHistoryData, processSearchData } from '../../../utils/SearchUtils';
import ConstantUtils from '../../../utils/ConstantUtils';

interface IGlobalSearchProps {
  close: () => void;
  onSearchSelected: (selectedItem: any) => void;
  folderStatusMap: any;
  projectStatusMap: any;
  activityStatusMap: any;
}

function GlobalSearch ({ 
    close, 
    onSearchSelected, 
    folderStatusMap, 
    projectStatusMap, 
    activityStatusMap 
  }: IGlobalSearchProps) {
  const { projectService, store } = useDependency();
  const [searchTitle, setSearchTitle] = useState("History");
  const [searchResults, setSearchResults] = useState<GroupedSearchResultItemData[]>([]);
  const [selectedItem, setSelectedItem] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState(0);

  const resultOffsetRef = useRef<number>(0);
  const resultRef = useRef<GroupedSearchResultItemData[]>([]);
  const searchRef = useRef<string>('');
  const resultEndRef = useRef<boolean>(false);
  const cancelSource = useRef<any>(null);
  const totalCountRef = useRef<number>(0);

  const historyCallBack = useCallback(async () => {
    try {
      if(cancelSource.current){
        cancelSource.current.cancel();
        cancelSource.current = null;
      }
      if(resultEndRef.current) return;
      var request = new ListContextRequest(store.Server, store.SessionId, resultOffsetRef.current, ConstantUtils.LIST_CONTEXT_PAGE_SIZE);
      cancelSource.current = request.cancelSource;

      var result = await projectService.listContext(request);
      var processed_result = processHistoryData(result.CONTEXTS, folderStatusMap, projectStatusMap, activityStatusMap);
      var total_items = mergeGroupedSearchResults(resultRef.current, processed_result);

      totalCountRef.current += +result.count;
      setSearchTitle(`History (${totalCountRef.current})`);
      setSearchResults(total_items);
      resultRef.current = total_items;
      resultOffsetRef.current += ConstantUtils.LIST_CONTEXT_PAGE_SIZE;
      resultEndRef.current = result.CONTEXTS.length < ConstantUtils.LIST_CONTEXT_PAGE_SIZE;
    } catch (error) {
      console.log(error);
    }
  }, [projectService, store.Server, store.SessionId, folderStatusMap, projectStatusMap, activityStatusMap]);

  const searchCallBack = async (text:string, loadMore:boolean = false) => {
    try {
      if(text !== searchRef.current){
        resultOffsetRef.current = 0;
        resultRef.current = [];
        resultEndRef.current = false;
        totalCountRef.current = 0;
      } 
      
      if(resultEndRef.current){
        return;
      }

      searchRef.current = text;

      if(cancelSource.current){
        cancelSource.current.cancel();
        cancelSource.current = null;
      }

      if(text.length < 1) { historyCallBack(); return ; }

      var request = new ListFPARequest(
        store.Server, 
        store.SessionId, 
        text+"*", 
        true, 
        ConstantUtils.LIST_FPA_PAGE_SIZE, 
        resultOffsetRef.current,
        undefined,
        false,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        new Order([
          new Col('itemTypeName'), 
          new Col('name'), 
          new Col('created', true)
        ])
      );
      cancelSource.current = request.cancelSource;

      var result = await projectService.listFPA(request);
      var tmpSearchData = processSearchData(result.ITEMS, folderStatusMap, projectStatusMap, activityStatusMap);      
      var processed_result = mergeGroupedSearchResults(resultRef.current, tmpSearchData);

      totalCountRef.current += +result.count;
      setSearchTitle(`Results (${totalCountRef.current})`);
      setSearchResults(processed_result);
      if(!loadMore){
        setSelectedGroup(0);
        setSelectedItem(0);
      }

      resultRef.current = processed_result;
      resultOffsetRef.current += ConstantUtils.LIST_FPA_PAGE_SIZE;
      resultEndRef.current = +result.count < ConstantUtils.LIST_FPA_PAGE_SIZE;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    historyCallBack();
  }, [historyCallBack]);

  const handleClose = async (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      close();
      return;
    }

    var group_index = selectedGroup;
    var item_index = selectedItem;

    if(item_index === -1)
      item_index = 0;

    if (event.key === 'ArrowDown') {
      if(searchResults[group_index].items.length - 1 === item_index) {
          if(searchResults.length - 1 === group_index) {
              setSelectedGroup(0);
          } else {
              setSelectedGroup(group_index + 1);
          }
          setSelectedItem(0);
      }
      else if(searchResults[group_index].items.length - 1 > item_index) {      
        setSelectedItem(selectedItem + 1);
      }
      return;
    }  

    if(event.key === 'ArrowUp') {
  
      if(item_index === 0) {
          if(group_index === 0) {
              setSelectedGroup(searchResults.length - 1);
              group_index = searchResults.length - 1;
          } else {
              setSelectedGroup(group_index - 1);
              group_index --;
          }
          setSelectedItem(searchResults[group_index].items.length - 1);
      }
      else if(item_index > 0) {      
        setSelectedItem(selectedItem - 1);
      }

      return;
    }

    if(event.key === 'Enter') {
      var item = searchResults[selectedGroup].items[selectedItem];

      onSearchSelected({ link: item.link, contextId: item.itemId, contextType: item.itemType });
      return;
    }
    
  }

  var timeout:number | null = null;

  const handleSearchChange = async (text:string) => {
    if(timeout !== null)
      window.clearTimeout(timeout);

    timeout = window.setTimeout(() => {
      searchCallBack(text);
    }, 500) as number;
  }

  const handleContainerClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  }

  const handleOverlayClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    close();
  }

  const handleLoadMore = async () => {
    if (totalCountRef.current >= ConstantUtils.LIST_FPA_PAGE_SIZE )
    {
      // setSelectedItem(-1);
      if(searchTitle === "History") {
        historyCallBack();
      }else {
        searchCallBack(searchRef.current, true);
      }
    }
  }

  return (
    <>
        <div className={styles.overlay} onKeyUp={handleClose} onClick={handleOverlayClick}>
          <div className={styles.searchContainer} onClick={handleContainerClick}>
            <SearchField aria-label="Search" width={'100%'} onChange={handleSearchChange} autoFocus />
            <GlobalSearchResults 
                title={searchTitle} 
                groups={searchResults} 
                selectedGroup={selectedGroup}
                selectedItem={selectedItem}
                onSearchSelected={onSearchSelected} 
                onLoadMore={handleLoadMore}
                />
          </div>
        </div>
    </>
  );
}
export default GlobalSearch;
