import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListIndustryRequest extends IBaseRequest {
    offset?: number;
    limit?: number;
}

export class ListIndustryRequest extends BaseRequest implements IListIndustryRequest {
    public offset?: number;
    public limit?: number;
    constructor(server: string, session: string, offset?: number, limit?: number) {
        super(server, session);
        this.offset = offset;
        this.limit = limit;
    }

    public toXml(): string {
        var parameters: string = super.toXml();
        if (this.offset) parameters += `<offset>${this.offset}</offset>`;
        if (this.limit) parameters += `<limit>${this.limit}</limit>`;
        return super.wrapperXml('ListIndustry', parameters);
    }
}
