// useDataLoader.ts
import { useState, useEffect, useRef } from 'react';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { ListIndustryRequest } from '../../../../services/soap/main/requests/ListIndustryRequest';
import { Industry } from '../../../../services/soap/main/responses/ListIndustryResponse';
import { CountIndustryRequest } from '../../../../services/soap/main/requests/CountIndustryRequest';
import { ListCountryRequest } from '../../../../services/soap/translate/reqeusts/ListCountryRequest';
import { Country } from '../../../../services/soap/translate/responses/ListCountryResponse';
import { Language } from '../../../../services/soap/translate/responses/ListLanguagesResponse';
import { ListLanguagesRequest } from '../../../../services/soap/translate/reqeusts/ListLanguagesRequest';
import { Position } from '../../../../services/soap/main/responses/ListPositionResponse';
import { CountPositionRequest } from '../../../../services/soap/main/requests/CountPositionRequest';
import { ListPositionRequest } from '../../../../services/soap/main/requests/ListPositionRequest';
import { Department } from '../../../../services/soap/main/responses/ListDepartmentResponse';
import { CountDepartmentRequest } from '../../../../services/soap/main/requests/CountDepartmentRequest';
import { ListDepartmentRequest } from '../../../../services/soap/main/requests/ListDepartmentRequest';
import { Contact } from '../../../../services/soap/main/responses/GetContactListResponse';
import { GetContactListRequest } from '../../../../services/soap/main/requests/GetContactListRequest';

// Cache type
type CacheType = { [key: string]: any };
enum CacheKeys {
    INDUSTRY = 'industry',
    COUNTRY = 'country',
    LANGUAGE = 'language',
    POSITION = 'position',
    DEPARTMENT = 'department',
    CONTACT = 'contact',
}

// Global cache object
const cache: CacheType = {};

export const useIndustryDataLoader = () => {
    const { mainService, store } = useDependency();
    const [data, setData] = useState<Industry[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;

        const fetchData = async () => {
            if (cache[CacheKeys.INDUSTRY]) {
                setData(cache[CacheKeys.INDUSTRY]);
                return;
            }

            setLoading(true);
            try {
                const count_response = await mainService.countIndustry(new CountIndustryRequest(store.Server, store.SessionId));
                const response = await mainService.listIndustry(new ListIndustryRequest(
                    store.Server, 
                    store.SessionId,
                    0,
                    count_response.count
                ));

                const result = response.INDUSTRY;
                cache[CacheKeys.INDUSTRY] = result;

                if (isMounted.current) {
                    setData(result);
                }
            } catch (err) {
                if (isMounted.current) {
                setError('Failed to fetch data');
                }
            } finally {
                if (isMounted.current) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted.current = false;
        };
    }, [mainService, store.Server, store.SessionId]);

    return { data, error, loading };
}

export const useCountryDataLoader = () => {
    const { translateService, store } = useDependency();
    const [data, setData] = useState<Country[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;

        const fetchData = async () => {
            if (cache[CacheKeys.COUNTRY]) {
                setData(cache[CacheKeys.COUNTRY]);
                return;
            }

            setLoading(true);
            try {
                const response = await translateService.listCountry(new ListCountryRequest(store.Server, store.SessionId, 
                    {
                        limit: '1000',
                        offset: '0',
                    }));

                const result = response.ROWS.ROW;
                cache[CacheKeys.INDUSTRY] = result;

                if (isMounted.current) {
                    setData(result);
                }
            } catch (err) {
                if (isMounted.current) {
                setError('Failed to fetch data');
                }
            } finally {
                if (isMounted.current) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted.current = false;
        };
    }, [translateService, store.Server, store.SessionId]);

    return { data, error, loading };
}

export const useLanguageDataLoader = () => {
    const { translateService, store } = useDependency();
    const [data, setData] = useState<Language[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;

        const fetchData = async () => {
            if (cache[CacheKeys.LANGUAGE]) {
                setData(cache[CacheKeys.LANGUAGE]);
                return;
            }

            setLoading(true);
            try {
                const response = await translateService.listLanguages(new ListLanguagesRequest(store.Server, store.SessionId));

                const result = response.LANGUAGE;
                cache[CacheKeys.LANGUAGE] = result;

                if (isMounted.current) {
                    setData(result);
                }
            } catch (err) {
                if (isMounted.current) {
                setError('Failed to fetch data');
                }
            } finally {
                if (isMounted.current) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted.current = false;
        };
    }, [translateService, store.Server, store.SessionId]);

    return { data, error, loading };
}

export const usePositionDataLoader = () => {
    const { mainService, store } = useDependency();
    const [data, setData] = useState<Position[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;

        const fetchData = async () => {
            if (cache[CacheKeys.POSITION]) {
                setData(cache[CacheKeys.POSITION]);
                return;
            }

            setLoading(true);
            try {
                const count_response = await mainService.countPosition(new CountPositionRequest(store.Server, store.SessionId));
                const response = await mainService.listPosition(new ListPositionRequest(
                    store.Server, 
                    store.SessionId,
                    0,
                    count_response.count
                ));

                const result = response.POSITION;
                cache[CacheKeys.POSITION] = result;

                if (isMounted.current) {
                    setData(result);
                }
            } catch (err) {
                if (isMounted.current) {
                setError('Failed to fetch data');
                }
            } finally {
                if (isMounted.current) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted.current = false;
        };
    }, [mainService, store.Server, store.SessionId]);

    return { data, error, loading };
}

export const useDepartmentDataLoader = () => {
    const { mainService, store } = useDependency();
    const [data, setData] = useState<Department[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;

        const fetchData = async () => {
            if (cache[CacheKeys.DEPARTMENT]) {
                setData(cache[CacheKeys.DEPARTMENT]);
                return;
            }

            setLoading(true);
            try {
                const count_response = await mainService.countDepartment(new CountDepartmentRequest(store.Server, store.SessionId));
                const response = await mainService.listDepartment(new ListDepartmentRequest(
                    store.Server, 
                    store.SessionId,
                    0,
                    count_response.count
                ));

                const result = response.DEPARTMENT;
                cache[CacheKeys.DEPARTMENT] = result;

                if (isMounted.current) {
                    setData(result);
                }
            } catch (err) {
                if (isMounted.current) {
                setError('Failed to fetch data');
                }
            } finally {
                if (isMounted.current) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted.current = false;
        };
    }, [mainService, store.Server, store.SessionId]);

    return { data, error, loading };
}

export const useContactDataLoader = (additional_options: any) => {
    const { mainService, store } = useDependency();
    const [data, setData] = useState<Contact[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;

        const fetchData = async () => {
            if (cache[CacheKeys.CONTACT]) {
                setData(cache[CacheKeys.CONTACT]);
                return;
            }

            setLoading(true);
            try {
                const count_only_options = { ...additional_options, countOnly: true };
                const count_response = await mainService.getContactList(
                    new GetContactListRequest(
                        store.Server, store.SessionId,
                        count_only_options
                    )
                );

                const response = await mainService.getContactList(
                    new GetContactListRequest(
                        store.Server, store.SessionId,
                        { ...additional_options, offset: 0, limit: count_response.count }
                    )
                );

                const result = response.CONTACT;
                cache[CacheKeys.CONTACT] = result;

                if (isMounted.current) {
                    setData(result);
                }
            } catch (err) {
                if (isMounted.current) {
                setError('Failed to fetch data');
                }
            } finally {
                if (isMounted.current) {
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            isMounted.current = false;
        };
    }, [mainService, store.Server, store.SessionId, additional_options]);

    return { data, error, loading };
}