import { useCallback, useEffect, useState } from 'react';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { ListStatusRequest, ListStatusType } from '../../../../services/soap/project/requests/ListStatusRequest';
import { Flex, View } from '@adobe/react-spectrum';
import {
  AtollonIcon,
  PrintIcon,
  EditIcon,
  ToolIcon,
  TrashIcon,
  MassmailGreen,
  MassmailRed,
  TwitterIcon,
  FacebookIcon,
  LinkdinIcon,
  ClientIconBlue,
} from '../../../../components/protected/Icons/IconsLib';
import { ToastQueue } from '@react-spectrum/toast';
import { useTranslation } from 'react-i18next';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import ButtonsComponent from './ButtonsComponent';
import { GetContextPathRequest } from '../../../../services/soap/project/requests/GetContextPathRequest';
import { GetFolderRequest } from '../../../../services/soap/project/requests/GetFolderRequest';
import { UpdateFolderRequest } from '../../../../services/soap/project/requests/UpdateFolderRequest';
import { CheckWorkFlowQueueRequest } from '../../../../services/soap/features/requests/CheckWorkFlowQueueRequest';
import { FPAData } from '../../../../infra/protected/FPA/FPAData';
import styles from './HeaderToolbarComponent.module.css';

interface IFolderComponentProps {
  headerData: any;
  statusColor: (color: string) => string;
  onPrintClicked?: () => void;
  onEditClicked?: () => void;
  onDeleteClicked?: () => void;
  onToolClicked?: () => void;
  onAIClicked?: () => void;
  selectedItem?: FPAData;
}

const FolderComponent = ({ headerData, statusColor, onPrintClicked, onEditClicked, onDeleteClicked, onToolClicked, onAIClicked, selectedItem }: IFolderComponentProps) => {
  const { store, projectService, featureService } = useDependency();
  const [folderStatusList, setFolderStatusList] = useState<any>([]);
  const [fullPath, setFullPath] = useState<string>('');
  const [folderData, setFolderData] = useState<any>([]);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      headerData && (await getFullPath());
      await getFolderData();
      await getFolderStatusArray();
    })();
  }, []);

  const getFolderStatusArray = useCallback(async () => {
    const statusArray: any = [];
    let res: any = await projectService.listFolderStatus2(new ListStatusRequest(store.Server, store.SessionId, ListStatusType.Folder));

    if (res.result === 'OK' && res.STATUS.length > 0) {
      res?.STATUS.forEach((status: any) => {
        // Extract id and name from each STATUS object
        const { id, name, color, TYPE } = status;
        // Push an object containing id and name to the statusArray
        statusArray.push({ id, name, color, types: TYPE });
      });

      var filtered_status = statusArray.filter((status: any) => status.types.find((type: any) => type.id === headerData?.folderType));
      setFolderStatusList(filtered_status);
    }
    return statusArray;
  }, []);

  const getFullPath = async () => {
    const response = await projectService.getContextPath(new GetContextPathRequest(store.Server, store.SessionId, headerData?.id || 0));
    setFullPath(response.LIST.map(item => item.CONTEXT.name).join(' > '));
  };

  const getAddress = () => {
    return headerData?.CONTACT?.COMPANY?.street !== undefined && headerData?.CONTACT?.COMPANY?.street !== null
      ? headerData?.CONTACT?.COMPANY?.street + ' ' + headerData?.CONTACT?.COMPANY?.city + ' ' + headerData?.CONTACT?.COMPANY?.zip + ' ' + headerData?.CONTACT?.COMPANY?.country
      : '';
  };

  function itemTemplate(data: any): JSX.Element {
    return (
      <Flex UNSAFE_className={styles.dot_text_container}>
        <View UNSAFE_className={styles.dot} UNSAFE_style={{ backgroundColor: `${statusColor(data.color)}` }}></View>
        <View marginTop={5}>{data.name}</View>
      </Flex>
    );
  }

  function valueTemplate(data: any): JSX.Element {
    return (
      <Flex UNSAFE_className={styles.dot_text_container}>
        <View UNSAFE_className={styles.dot} UNSAFE_style={{ backgroundColor: `${statusColor(data.color)}` }}></View>
        <View marginTop={5} UNSAFE_style={{ marginLeft: '10px' }}>
          <span title={data?.name}>{data?.name && data?.name.length > 16 ? data?.name.substring(0, 16) + '...' : data?.name}</span>
        </View>
      </Flex>
    );
  }

  async function getFolderData() {
    if (selectedItem) {
      let { FOLDER } = await projectService.getFolder(new GetFolderRequest(store.Server, store.SessionId, selectedItem.id));
      setFolderData(FOLDER);
    }
  }

  async function updateFolderStatus(e: any) {
    let resFolder = await projectService.updateFolder(new UpdateFolderRequest(store.Server, store.SessionId, { ...folderData, customState: e.value }));
    if (resFolder.result == 'OK') {
      await featureService.checkWorkFlowQueue(new CheckWorkFlowQueueRequest(store.Server, store.SessionId));
      ToastQueue.positive(t('status_updated_successfully', { ns: 'layout_components' }));
    } else {
      ToastQueue.negative(resFolder.EXCEPTION?.message, { timeout: 1000 });
    }
  }

  return (
    <Flex width="100%">
      <View UNSAFE_className={styles.header_main}>
        <View UNSAFE_className={styles.header_child}>
          <View UNSAFE_className={styles.frame_parent}>
            <View UNSAFE_className={styles.icon_activity_parent}>
              <ClientIconBlue size={32} />
              <b title={headerData?.name} className={styles.activity_name}>
                {headerData?.name.length > 45 ? headerData?.name.substring(0, 45) + '...' : headerData?.name}
              </b>
            </View>
            <View UNSAFE_className={styles.folder_name_parent}>
              <View UNSAFE_className={styles.folder_name}>{fullPath}</View>
              {/* <View UNSAFE_className={styles.folder_name}>Folder Name</View>

              <RightArrowIcon size={32} />
              <View UNSAFE_className={styles.sub_folder_name}>SUB FOLDER NAME</View> */}
            </View>
          </View>
          <Flex direction={'row'} justifyContent={'space-between'} UNSAFE_className={styles.tag_parent} marginTop={20}>
            <View UNSAFE_style={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
              <View UNSAFE_className={styles.tag}>
                <View UNSAFE_className={styles.activity_type}>
                  <View UNSAFE_className={styles.activity_type_text}>{headerData?.folderTypeName}</View>

                  {headerData?.referenceId ? (
                    <>
                      <View UNSAFE_className={styles.divider}></View> <View UNSAFE_className={styles.ref_id}>{headerData?.referenceId}</View>
                    </>
                  ) : (
                    <View UNSAFE_className={styles.divider_transparent}></View>
                  )}
                </View>
              </View>

              <View UNSAFE_className={styles.io_parent}>
                {headerData?.CONTACT?.COMPANY?.COMPANYINFO.regNumber !== undefined ? (
                  <>
                    <View UNSAFE_className={styles.io_label}>IČO</View>
                    <View UNSAFE_className={styles.io_text}>{headerData?.CONTACT?.COMPANY?.COMPANYINFO.regNumber}</View>
                  </>
                ) : (
                  ''
                )}
              </View>

              <View UNSAFE_className={styles.di_parent}>
                {headerData?.CONTACT?.COMPANY?.COMPANYINFO.VATNumber !== undefined ? (
                  <>
                    <View UNSAFE_className={styles.di_label}>DIČ</View>
                    <View UNSAFE_className={styles.di_text}>{headerData?.CONTACT?.COMPANY?.COMPANYINFO.VATNumber}</View>
                  </>
                ) : (
                  ''
                )}
              </View>

              <View UNSAFE_className={styles.address_parent}>
                {getAddress() !== '' ? (
                  <>
                    <View UNSAFE_className={styles.address_child}>{t('address', { ns: 'layout_components' })}</View>
                    <View UNSAFE_className={styles.address}>{getAddress()}</View>
                  </>
                ) : (
                  ''
                )}
              </View>
            </View>

            <View UNSAFE_className={styles.socials_icons}>
              <View UNSAFE_className={styles.icon_twitter}>
                <TwitterIcon size={24} />
              </View>

              <View UNSAFE_className={styles.icon_facebook}>
                <FacebookIcon size={24} />
              </View>

              <View UNSAFE_className={styles.icon_linkedin}>
                <LinkdinIcon size={24} />
              </View>
            </View>
          </Flex>
        </View>
        <View UNSAFE_className={styles.icon_list_parent}>
          {headerData?.CONTACT?.COMPANY?.adsAllowed === 'N' ? (
            <View UNSAFE_className={styles.ad_not_allowed}>
              <MassmailRed size={20} />
            </View>
          ) : (
            <View UNSAFE_className={styles.ad_allowed}>
              <MassmailGreen size={20} />
            </View>
          )}
          {/* <ComboBox defaultItems={folderStatusList} selectedKey={folderStatus} onSelectionChange={() => setFolderStatus}>
            {(item: any) => <Item>{item.name}</Item>}
          </ComboBox> */}

          <DropDownListComponent
            width={'200px'}
            name="vacancyTypeReef"
            dataSource={folderStatusList}
            fields={{ text: 'name', value: 'id' }}
            showClearButton={true}
            itemTemplate={itemTemplate}
            valueTemplate={valueTemplate}
            value={headerData?.customState}
            onChange={(e: any) => updateFolderStatus(e)}
          />

          <ButtonsComponent
            styles={styles}
            AtollonIcon={AtollonIcon}
            PrintIcon={PrintIcon}
            EditIcon={EditIcon}
            ToolIcon={ToolIcon}
            TrashIcon={TrashIcon}
            onPrintClicked={onPrintClicked}
            onEditClicked={onEditClicked}
            onDeleteClicked={onDeleteClicked}
            onToolClicked={onToolClicked}
            onAIClicked={onAIClicked}
          />
        </View>
      </View>
    </Flex>
  );
};

export default FolderComponent;
