import { SessionInfo } from "../data/SessionInfo";
import { IStore } from "../services/LocalStorage/IStore";
import { IMainService } from "../services/soap/main/MainService";
import { LoginRequest } from "../services/soap/main/requests/LoginRequest";

type DoLoginResponse = {
    status: boolean;
    message: string;
    session: SessionInfo | undefined;
}

export async function doLogin(
    username: string, 
    password: string, 
    server:string, 
    mainService:IMainService,
    store: IStore
  ):Promise<DoLoginResponse> {
    const result = await mainService.login(
        new LoginRequest(username, password, server)
      );
      if (result.result === "OK") {
        store.SessionId = result.session;
        let session: SessionInfo = {
          session: result.session,
          server: server,
          userId: result.userId,
          organization: result.ORGANIZATION,
          sessionInfo: result.SESSIONINFO,
          userPreferences: result.USER_PREFERENCES,
        };
        
        return {
            status: true,
            message: "",
            session: session
        }
      } else {
        return {
            status: false,
            message: result.EXCEPTION.message + " " + result.EXCEPTION.reason,
            session: undefined
        }
      }        
}
