import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IGetFormPanelRequest extends IBaseRequest {
    itemType: string;
    onlyId?: boolean;
}

export class GetFormPanelRequest extends BaseRequest implements IGetFormPanelRequest {

    public itemType: string;
    public onlyId?: boolean;

  constructor(server: string, session: string, itemType: string, onlyId?: boolean) {
    super(server, session);
    this.itemType = itemType;
    this.onlyId = onlyId;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    parameters += '<itemType>' + this.itemType + '</itemType>';
    if (this.onlyId) parameters += '<onlyId>' + this.onlyId + '</onlyId>';
    
    return super.wrapperXml('GetFormPanel', parameters);
  }
}
