import { Filter } from './ListProjectItemRequest';

export class ListProjectItemRequestOptions_BillingItem {
  public orderBy: string = 'orderindex';
  public activity: string;
  public FILTER: Filter;
  constructor(activity: string) {
    this.activity = activity;
    this.FILTER = {
      COL: [{ name: 'type', type: 'in', value: '1' }],
    };
  }
}

export class ListProjectItemRequestOptions_ProjectItem {
  public orderBy: string = 'orderindex';

  public folder: string;
  public project: string;
  public activity: string;
  public project_type: string;
  public FILTER: Filter;
  constructor(folder: string, project: string, activity: string, project_type: string) {
    this.folder = folder;
    this.project = project;
    this.activity = activity;
    this.project_type = project_type;
    this.FILTER = {
      COL: [{ name: 'type', type: 'in', value: project_type }],
    };
  }
}
