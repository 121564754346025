import { useEffect, useState } from "react";

type DeviceInfo = {
    isInternetConnected: boolean;
}
export function useDeviceInfo(): DeviceInfo {
    const [isInternetConnected, setIsInternetConnected] = useState(navigator.onLine);
    
    const handleOnline = () => setIsInternetConnected(true);
    const handleOffline = () => setIsInternetConnected(false);

    useEffect(() => {
        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);
    
        return () => {
          window.removeEventListener("online", handleOnline);
          window.removeEventListener("offline", handleOffline);
        };
      }, []);
    
    return {
        isInternetConnected,
    }
}