import { ActionButton, Flex, View } from '@adobe/react-spectrum';
import { IconStyle, SearchIcon } from '../../Icons/IconsLib';
import styles from './FolderTypeCell.module.css';
import ChevronRight from '@spectrum-icons/workflow/ChevronRight';
import ChevronDown from '@spectrum-icons/workflow/ChevronDown';

import { useState } from 'react';
import { FPAData } from '../../../../infra/protected/FPA/FPAData';
import useDeviceType from '../../../../hooks/UseDeviceType';

export interface IFolderTypeCellProps {
    item: FPAData;
    onSearch?: (id:number) => void;
    onClicked: (id:number) => void;
    onReset?:() => void;
}

export function FolderTypeCell ({
    item,
    onSearch,
    onClicked,
    onReset
}: IFolderTypeCellProps) {
    const [isOpen, setIsOpen] = useState(item.isOpen || false);
    const { isTouchDevice } = useDeviceType();

    const handleToggle = () => {
        setIsOpen(!isOpen);
        onClicked(item.id);
    }

    const handleSearch = (e:any) => { 
        // e.stopPropagation(); 
        if(item.isFiltered) {
            if(onReset)
                onReset();
        }
        else { 
            if(onSearch) 
                onSearch(item.id);
        }
    }

    const handleTouchEnd = (e:any) => {
        e.stopPropagation();
        handleToggle();
    }

    const handleClick = (e:any) => {
        e.stopPropagation();
        if(isTouchDevice) return;
        handleToggle();
    }
    return (
    <>
        <span onTouchEnd={handleTouchEnd} onClick={handleClick}>
            <Flex direction="row" UNSAFE_className={`${styles.folderTypeWrapper} ${item.isSelected ? styles.selected : ''}`}>
                <View>
                    <Flex direction="row" alignItems="center">
                        <span style={{color: 'var(--neutral-40)'}}>{isOpen ? ( <ChevronDown size={'S'} />) : ( <ChevronRight size={'S'} />)}</span>
                    </Flex>
                </View>
                <Flex direction="column" marginStart={2} flex>
                    <View UNSAFE_className={styles.title}>
                        {item.title}
                    </View>
                </Flex>
                <Flex direction="row" alignItems="end" alignSelf={'center'} justifySelf={'end'}>
                    <View paddingEnd={10} UNSAFE_className={styles.search} UNSAFE_style={{ display:  (isOpen? 'flex': '')}}>
                        <ActionButton isQuiet 
                          onPress={handleSearch}
                          UNSAFE_style={{ height: '21px'}}
                          >
                            <span>
                              <SearchIcon size={18} stateClass={item.isFiltered ? IconStyle.filtered : IconStyle.normal}/>
                            </span>
                        </ActionButton>
                    </View>
                </Flex>
            </Flex>
        </span>
    </>
  );
}
