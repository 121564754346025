import { BaseRequest, IBaseRequest } from "../../BaseRequest";

export class FilterCol{
    name: string;
    type:string;
    value?: string;
    negation?: boolean;
    includeNull?: boolean;
    constructor(_name:string, _type:string, _value?:string, _negation?:boolean, _includeNull?:boolean){
        this.name = _name;
        this.type = _type;
        this.value = _value;
        this.negation = _negation;
        this.includeNull = _includeNull;
    }
    toXml():string{
        var parameters:string = '';
        if(this.value) parameters += `<value>${this.value}</value>\n`;
        if(this.negation) parameters += `<negation>${this.negation}</negation>\n`;
        if(this.includeNull) parameters += `<includeNull>${this.includeNull}</includeNull>\n`;
        return `<COL>
        <name>${this.name}</name>
        <type>${this.type}</type>
        ${parameters}
        </COL>`;
    }
}

export class FilterFolder{
    COL: FilterCol[];
    constructor(_COL:FilterCol[] = []){
        this.COL = _COL;
    }
    toXml():string{
        let cols = '';
        this.COL.forEach(col => {
            cols += col.toXml();
        });
        return `<FILTER_FOLDER>${cols}</FILTER_FOLDER>`;
    }
}

export class FilterProject{
    COL: FilterCol[];
    constructor(_COL:FilterCol[] = []){
        this.COL = _COL;
    }
    toXml():string{
        let cols = '';
        this.COL.forEach(col => {
            cols += col.toXml();
        });
        return `<FILTER_PROJECT>${cols}</FILTER_PROJECT>`;
    }
}

export class FilterActivity{
    COL: FilterCol[];
    constructor(_COL:FilterCol[] = []){
        this.COL = _COL;
    }
    toXml():string{
        let cols = '';
        this.COL.forEach(col => {
            cols += col.toXml();
        });
        return `<FILTER_ACTIVITY>${cols}</FILTER_ACTIVITY>`;
    }

}
export class Col{
    name: string;
    desc?: boolean;
    constructor(_name:string, _desc:boolean = false){
        this.name = _name;
        this.desc = _desc;
    }
    toXml():string{
        return `<COL>
        <name>${this.name}</name>
        <desc>${this.desc}</desc>
        </COL>`;
    }
}
export class Order{
    COL: Col[];
    constructor(_COL:Col[] = []){
        this.COL = _COL;
    }
    toXml():string{
        let cols = '';
        this.COL.forEach(col => {
            cols += col.toXml();
        });
        return `<ORDER>${cols}</ORDER>`;
    }
}
export class Parent {
    id: number;
    type: string;
    constructor(_id:number, _type:string){
        this.id = _id;
        this.type = _type;
    }
    toXml():string{
        return `<PARENT>
        <id>${this.id}</id>
        <type>${this.type}</type>
        </PARENT>`;
    }
}
export interface IListFPARequest extends IBaseRequest{
    limit?: number;
    offset?: number;
    orderBy?: string;
    countOnly?: boolean;
    flatView?: boolean;
    includeSlave?: boolean;
    fulltext?: string;
    parent?: Parent;
    folderFilter?: FilterFolder;
    projectFilter?: FilterProject;
    activityFilter?: FilterActivity;
    ORDER?: Order;    
}

export class ListFPARequest extends BaseRequest implements IListFPARequest {
    limit?: number;
    offset?: number;
    orderBy?: string;
    countOnly?: boolean;
    flatView?: boolean;
    includeSlave?: boolean;
    fulltext?: string;
    parent?: Parent;
    folderFilter?: FilterFolder;
    projectFilter?: FilterProject;
    activityFilter?: FilterActivity;
    ORDER?: Order;    

    constructor(server: string, 
        session: string,
        fulltext?: string,
        flatView?: boolean,
        limit?: number,
        offset?: number,
        orderBy?: string,
        countOnly?: boolean,
        includeSlave?: boolean,
        parent?: Parent,
        folderFilter?: FilterFolder,
        projectFilter?: FilterProject,
        activityFilter?: FilterActivity,
        ORDER?: Order) {
            super(server, session);
            this.limit = limit;
            this.offset = offset;
            this.orderBy = orderBy;
            this.countOnly = countOnly;
            this.flatView = flatView;
            this.includeSlave = includeSlave;
            this.fulltext = fulltext;
            this.parent = parent;
            this.folderFilter = folderFilter;
            this.projectFilter = projectFilter;
            this.activityFilter = activityFilter;
            this.ORDER = ORDER;
    }

    toXml(): string {
        var parameters:string = super.toXml();
        if(this.limit) parameters += `<limit>${this.limit}</limit>\n`;
        if(this.offset !== undefined) parameters += `<offset>${this.offset}</offset>\n`;
        if(this.orderBy) parameters += `<orderBy>${this.orderBy}</orderBy>\n`;
        if(this.countOnly) parameters += `<countOnly>${this.countOnly}</countOnly>\n`;
        if(this.flatView) parameters += `<flatView>${this.flatView}</flatView>\n`;
        if(this.includeSlave) parameters += `<includeSlave>${this.includeSlave}</includeSlave>\n`;
        if(this.fulltext) parameters += `<fulltext>${this.fulltext}</fulltext>\n`;
        if(this.parent) parameters += this.parent.toXml();
        if(this.folderFilter) parameters += this.folderFilter.toXml();
        if(this.projectFilter) parameters += this.projectFilter.toXml();
        if(this.activityFilter) parameters += this.activityFilter.toXml();
        if(this.ORDER) parameters += this.ORDER.toXml();

        return super.wrapperXml("ListFPA", parameters);
    }
}