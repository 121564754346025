import { BaseRequest, IBaseRequest } from "../../BaseRequest";

export interface IGetUserRequest extends IBaseRequest{
    userId: string;
}

export class GetUserRequest extends BaseRequest implements IGetUserRequest{
    public userId: string;

    constructor (_server: string, _session: string, _userId: string){
        super(_server, _session);
        this.userId = _userId;
    }

    public toXml(): string {
        let xml: string = super.toXml();
        xml += "<userId>" + this.userId + "</userId>";
        return super.wrapperXml("GetUser", xml);
    }
}