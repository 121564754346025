import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IGetFolderTemplateRequest extends IBaseRequest {
    id: number;
}

export class GetFolderTemplateRequest extends BaseRequest implements IGetFolderTemplateRequest {
    public id: number;
    constructor(server: string, session: string, id: number) {
        super(server, session);
        this.id = id;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        parameters += '<id>' + this.id + '</id>';
        
        return super.wrapperXml('GetFolderTemplate', parameters);
    }
}
