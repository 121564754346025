import { Flex, ProgressBar, SearchField, View } from '@adobe/react-spectrum';
import styles from './InPlaceSearch.module.css';
import { useRef, useState } from 'react';
import { InPlaceSearchResults } from './InPlaceSearchResults';
import { FPAData } from '../../../../../infra/protected/FPA/FPAData';
import { TextFieldRef } from '@react-types/textfield';
import { useSelector } from 'react-redux';

export interface IInPlaceSearchProps {
    closeSearch: (isCancel:boolean) => void;
    doSearch: (search:string, is_page:boolean) => void;
    onSearchSelected: (item:any) => void;
    items: FPAData[];
}

export function InPlaceSearch ({
    closeSearch,
    doSearch,
    onSearchSelected,
    items,
}: IInPlaceSearchProps) {
    const isSearching = useSelector((state:any) => state.finder.isSearching);
    const txtSearch = useRef<TextFieldRef>(null);
    const timeoutRef = useRef<number | null>(null);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        switch(e.key){
            case 'Escape':
                console.log('closing the search');
                e.stopPropagation();
                closeSearch(true);
                break;
            case 'Enter':
                if(selectedIndex < items.length && selectedIndex >= 0){
                    onSearchSelected({ contextType: items[selectedIndex].type, contextId: items[selectedIndex].id, item: items[selectedIndex] });
                    return;
                }
                closeSearch(false);
                break;
            case 'ArrowDown':
                if(selectedIndex < items.length - 1){
                    setSelectedIndex(selectedIndex + 1);
                    txtSearch.current?.focus();
                }
                break;
            case 'ArrowUp':
                if(selectedIndex > 0){
                    setSelectedIndex(selectedIndex - 1);
                    txtSearch.current?.focus();
                }
                break;
            default:
                break;
        }
    }

    const handleSearchChange = async (text:string) => {
        // setSearchText(text);
        if(text === ""){ return; }
        if(timeoutRef.current !== null)
          window.clearTimeout(timeoutRef.current);
    
          timeoutRef.current = window.setTimeout(() => {
            onSearch(text);
        }, 500) as number;
    }

    const onSearch = (text:string, is_page:boolean = false) => {
        doSearch(text, is_page);
    }

    const handleClear = () => {
        handleSearchChange("");
        closeSearch(true);
    }
    const handleOnScroll = (e: React.UIEvent<HTMLSpanElement>) => {
        if(e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight){
            if(txtSearch.current && txtSearch.current.getInputElement())
                onSearch(txtSearch.current.getInputElement()!.value, true);
            
        }
    }
    return (
        <>
            <Flex direction="column" justifyContent="center" UNSAFE_className={styles.inPlaceSearchContainer}>
                <Flex direction={'row'}>
                    <SearchField autoFocus 
                        ref={txtSearch} 
                        width={'100%'}
                        onChange={handleSearchChange}
                        onKeyUp={handleKeyUp}
                        onClear={handleClear}
                        label="Search"
                        />
                </Flex>
                {(items && items.length > 0) && (
                    <View>
                        <div className={styles.inPlaceSearchResultsContainer} onScroll={handleOnScroll}>
                            <InPlaceSearchResults items={items} onSearchSelected={onSearchSelected} selectedIndex={selectedIndex} />
                        </div>
                    </View>
                )}
                {isSearching && (<View backgroundColor="static-white" padding="size-100" borderRadius={'small'}>
                    <ProgressBar label={'Loading...'} labelPosition='side' isIndeterminate />
                </View>)}
            </Flex>
        </>
    );
}

