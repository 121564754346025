import { useProvider } from "@adobe/react-spectrum";
import { useEffect, useState } from "react";

type HostInfo = {
    isMobile: boolean;
    isDesktop: boolean;
    isStandAlone: boolean;
    OS: string;
};
export function useViewInfo(): HostInfo {
    const provider = useProvider();
    const [isMobile, setIsMobile] = useState(provider.breakpoints.M! > window.innerWidth);
    const [isDesktop, setIsDesktop] = useState(provider.breakpoints.M! <= window.innerWidth);
    const [OS, setOS] = useState('');
    const isStandAlone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone || document.referrer.includes('android-app://');

    useEffect(() => {
        setIsMobile(provider.breakpoints.M! > window.innerWidth);
        setIsDesktop(provider.breakpoints.M! <= window.innerWidth);
    }, [provider.breakpoints.M, window.innerWidth]);

    useEffect(() => { 
        if (window.navigator.userAgent.includes('Android')) {
            setOS('Android');
        } else if (window.navigator.userAgent.includes('iOS')) {
            setOS('iOS');
        } else if (window.navigator.userAgent.includes('Windows')) {
            setOS('Windows');
        } else if (window.navigator.userAgent.includes('Mac')) {
            setOS('Mac');
        } else if (window.navigator.userAgent.includes('Linux')) {
            setOS('Linux');
        } else {
            setOS('Unknown');
        }
    }, []);
    return {
        isMobile,
        isDesktop,
        isStandAlone,
        OS
    };
}