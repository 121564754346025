import React, { useEffect, useState } from 'react';
import { Checkbox, ComboBox, Content, Dialog, Flex, Footer, Header, Item, Link, TextArea, View } from '@adobe/react-spectrum';
import { useTranslation } from 'react-i18next';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import { useViewInfo } from '../../../../../hooks/UseViewInfo';
import { ListUserRequest } from '../../../../../services/soap/access/requests/ListUserRequest';
import { NewActivityUserRequest } from '../../../../../services/soap/team/requests/NewActivityUserRequest';
import { NewProjectUserRequest } from '../../../../../services/soap/team/requests/NewProjectUserRequest';
import { NewFolderUserRequest } from '../../../../../services/soap/team/requests/NewFolderUserRequest';
import styles from './ResponsibleUsersSearch.module.css';

interface ResponsibleUsersSearchProps {
  selectedItem: any;
  currentItem: any;
  responsibleUsers: any;
  FPADataTypes: any;
  updateItem: (request: any, message: string) => void;
  closeDialog: (reload: boolean) => void;
}

type formDataType = {
  userId: string;
  isPrimary: boolean;
  note: string;
};

const ResponsibleUsersSearch: React.FC<ResponsibleUsersSearchProps> = ({ selectedItem, currentItem, responsibleUsers, FPADataTypes, updateItem, closeDialog }) => {
  const { isMobile } = useViewInfo();
  const { t } = useTranslation();
  const { teamService, accessService, store } = useDependency();
  const [formValue, setFormValue] = useState<formDataType>({
    userId: '',
    isPrimary: false,
    note: '',
  });

  const [userData, setUserData] = useState<any>([]);

  useEffect(() => {
    (async () => {
      getUserCount().then((totalRecords: any) => getUserData(totalRecords));
    })();
  }, []);

  const getUserCount = async () => {
    let userList = await accessService.listUser(new ListUserRequest(store.Server, store.SessionId, true, undefined, undefined, '1'));
    if (userList.result === 'OK') {
      return userList.count;
    }
  };

  const getUserData = async (totalRecords: number) => {
    let userResp = await accessService.listUser(new ListUserRequest(store.Server, store.SessionId, true, totalRecords));

    if (userResp.result === 'OK') {
      const formattedArray = userResp.USERS.map(({ USER }) => ({
        id: USER.id,
        name: `${USER.name1} ${USER.name3}`,
      }));
      const userIds = responsibleUsers?.map((user: any) => user.userId) || [];
      const filteredUser = formattedArray.filter((item: any) => !userIds.includes(item.id));
      setUserData(filteredUser);
    }
  };

  const ContactActions = () => (
    <Flex UNSAFE_className={styles.button_container_new_contact}>
      <Flex UNSAFE_className={styles.new_contact_cancel}>
        <Link isQuiet UNSAFE_className={styles.new_contact_cancel_text} onPress={() => closeDialog(false)}>
          {t('cancel', { ns: 'layout_components' })}
        </Link>
      </Flex>
      {formValue.userId && (
        <Flex UNSAFE_className={styles.new_contact_add}>
          <Link isQuiet UNSAFE_className={styles.new_contact_add_text} onPress={validateAndSubmit}>
            {t('add_user', { ns: 'layout_components' })}
          </Link>
        </Flex>
      )}
    </Flex>
  );

  const ContactButtons = () => (
    <Flex direction={isMobile ? 'column' : { base: 'column', L: 'row' }} justifyContent={'end'} gap={'size-100'}>
      <ContactActions />
    </Flex>
  );

  const validateAndSubmit = async () => {
    const updateFunctions = {
      [FPADataTypes.ACTIVITY]: () =>
        teamService.newActivityUser(
          new NewActivityUserRequest(store.Server, store.SessionId, Number(formValue.userId), selectedItem.id, Number(formValue.isPrimary), store.UserId, { note: formValue.note })
        ),
      [FPADataTypes.PROJECT]: () =>
        teamService.newProjectUser(
          new NewProjectUserRequest(store.Server, store.SessionId, {
            userId: formValue.userId,
            project: selectedItem.id,
            isPrimary: formValue.isPrimary,
            contact: store.UserId,
            note: formValue.note,
          })
        ),
      [FPADataTypes.FOLDER]: () =>
        teamService.newFolderUser(
          new NewFolderUserRequest(store.Server, store.SessionId, {
            userId: formValue.userId,
            folder: selectedItem.id,
            isPrimary: formValue.isPrimary,
            contact: store.UserId,
            note: formValue.note,
          })
        ),
    };

    updateItem(updateFunctions[selectedItem.type], t('user_added_successfully', { ns: 'layout_components' }));
    closeDialog(true);
  };

  return (
    <Dialog>
      <Header UNSAFE_className={styles.new_contact_heading}>{t('add_responsible_user', { ns: 'layout_components' })}</Header>
      <Content height={isMobile ? '400' : '220px'}>
        <Flex direction="column" width={'100%'} justifyContent={'center'} alignContent={'center'}>
          <View UNSAFE_className={styles.add_contact_parent}>
            <Flex UNSAFE_className={styles.form_parent}>
              <Flex UNSAFE_className={styles.form_group}>
                <Flex UNSAFE_className={styles.form_fields}>
                  <Flex UNSAFE_className={styles.form_input}>
                    <Flex UNSAFE_className={styles.form_label_container}>
                      <Flex UNSAFE_className={styles.form_label}>
                        <View UNSAFE_className={styles.form_label_text}>{t('user', { ns: 'layout_components' })}</View>
                      </Flex>
                    </Flex>

                    <ComboBox
                      autoFocus
                      width={'100%'}
                      isRequired
                      label=""
                      defaultItems={userData}
                      onSelectionChange={value => {
                        setFormValue((prevValue: any) => ({ ...prevValue, userId: value }));
                      }}
                    >
                      {(item: any) => <Item key={item.id}>{item.name}</Item>}
                    </ComboBox>
                  </Flex>
                </Flex>
                <Flex UNSAFE_className={styles.form_fields}>
                  <Flex UNSAFE_className={styles.form_input}>
                    <Flex UNSAFE_className={styles.form_label_container}>
                      <Flex UNSAFE_className={styles.form_label}>
                        <View UNSAFE_className={styles.form_label_text}>{t('is_primary', { ns: 'layout_components' })}</View>
                      </Flex>
                    </Flex>
                    <Checkbox
                      onChange={e => {
                        setFormValue((prevValue: any) => ({ ...prevValue, isPrimary: e }));
                      }}
                    ></Checkbox>
                  </Flex>
                </Flex>
              </Flex>
              <Flex UNSAFE_className={styles.form_input_large}>
                <Flex UNSAFE_className={styles.form_label_container}>
                  <Flex UNSAFE_className={styles.form_label}>
                    <View UNSAFE_className={styles.form_label_text}>{t('note', { ns: 'layout_components' })}</View>
                  </Flex>
                </Flex>
                <TextArea
                  width={'100%'}
                  onChange={e => {
                    setFormValue((prevValue: any) => ({ ...prevValue, note: e }));
                  }}
                ></TextArea>
              </Flex>
            </Flex>
          </View>
        </Flex>
      </Content>
      <Footer>
        <View UNSAFE_className={styles.footer}>
          <ContactButtons />
        </View>
      </Footer>
    </Dialog>
  );
};

export default ResponsibleUsersSearch;
