import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IDeleteFolderUserRequest extends IBaseRequest {
    roleName?:   string;
    role?:       string;
    note?:       string;
    id?:         string;
    userId?:     string;
    folderName?: string;
    acl?:        string;
    folder?:     string;
    isPrimary?:  string;
    contact?:    string;
    userName?:   string;
}

export class DeleteFolderUserRequest extends BaseRequest implements IDeleteFolderUserRequest {

    public roleName?:   string;
    public role?:       string;
    public note?:       string;
    public id?:         string;
    public userId?:     string;
    public folderName?: string;
    public acl?:        string;
    public folder?:     string;
    public isPrimary?:  string;
    public contact?:    string;
    public userName?:   string;

  constructor(server: string, session: string, otherOptions: any) {
    super(server, session);
    this.roleName = otherOptions.roleName;
    this.role = otherOptions.role;
    this.note = otherOptions.note;
    this.id = otherOptions.id;
    this.userId = otherOptions.userId;
    this.folderName = otherOptions.folderName;
    this.acl = otherOptions.acl;
    this.folder = otherOptions.folder;
    this.isPrimary = otherOptions.isPrimary;
    this.contact = otherOptions.contact;
    this.userName = otherOptions.userName
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    if(this.roleName) parameters += '<roleName>' + this.roleName + '</roleName>';
    if(this.role) parameters += '<role>' + this.role + '</role>';
    if(this.note) parameters += '<note>' + this.note + '</note>';
    if(this.id) parameters += '<id>' + this.id + '</id>';
    if(this.userId) parameters += '<userId>' + this.userId + '</userId>';
    if(this.folderName) parameters += '<folderName>' + this.folderName + '</folderName>';
    if(this.acl) parameters += '<acl>' + this.acl + '</acl>';
    if(this.folder) parameters += '<folder>' + this.folder + '</folder>';
    if(this.isPrimary) parameters += '<isPrimary>' + this.isPrimary + '</isPrimary>';
    if(this.contact) parameters += '<contact>' + this.contact + '</contact>';
    if(this.userName) parameters += '<userName>' + this.userName + '</userName>';
    
    return super.wrapperXml('DeleteFolderUser', parameters);
  }
}
