// DataContext.tsx
import React, { createContext, useContext } from 'react';
import { useContactDataLoader, useCountryDataLoader, useDepartmentDataLoader, useIndustryDataLoader, useLanguageDataLoader, usePositionDataLoader } from './DataLoader';
import { Industry } from '../../../../services/soap/main/responses/ListIndustryResponse';
import { Country } from '../../../../services/soap/translate/responses/ListCountryResponse';
import { Language } from '../../../../services/soap/translate/responses/ListLanguagesResponse';
import { Position } from '../../../../services/soap/main/responses/ListPositionResponse';
import { Department } from '../../../../services/soap/main/responses/ListDepartmentResponse';
import { Contact } from '../../../../services/soap/main/responses/GetContactListResponse';


interface DataContextProps {
    getIndustryData: () => { data: Industry[]; error: string | null; loading: boolean };
    getCountryData: () => { data: Country[]; error: string | null; loading: boolean };
    getLanguageData: () => { data: Language[]; error: string | null; loading: boolean };
    getPositionData: () => { data: Position[]; error: string | null; loading: boolean };
    getDepartmentData: () => { data: Department[]; error: string | null; loading: boolean };
    getContactData: (apiOptions:any) => { data: Contact[]; error: string | null; loading: boolean };
}

const DataContext = createContext<DataContextProps | null>(null);

export const DataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const useIndustryData = () => { return useIndustryDataLoader(); };
    const useCountryData = () => { return useCountryDataLoader(); };
    const useLanguageData = () => { return useLanguageDataLoader(); };
    const usePositionData = () => { return usePositionDataLoader(); };
    const useDepartmentData = () => { return useDepartmentDataLoader(); };
    const useContactData = (apiOptions:any) => { return useContactDataLoader(apiOptions); };

    return (
        <DataContext.Provider value={{ 
            getIndustryData: useIndustryData, 
            getCountryData: useCountryData,
            getLanguageData: useLanguageData,
            getPositionData: usePositionData,
            getDepartmentData: useDepartmentData,
            getContactData: useContactData
        }}>
            {children}
        </DataContext.Provider>
    );
};

// Hook to use DataContext
export const useDataContext = () => {
    const context = useContext(DataContext);
    if (!context) {
        throw new Error('useDataContext must be used within a DataProvider');
    }
    return context;
};
