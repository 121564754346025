export interface TaskItem {
    aclh?:                    string;
    activityId?:              string;
    activityName?:            string;
    activityRefId?:           string;
    actualHours?:             string;
    addDuration?:             string;
    chargeable?:              string;
    color?:                   string;
    comments?:                string;
    commonId?:                string;
    completed?:               string;
    compold?:                 string;
    contactsChanged?:         string;
    cost?:                    string;
    created?:                 string;
    createdBy?:               string;
    createdByName?:           string;
    customId?:                string;
    dateFrom?:                string;
    dateTo?:                  string;
    description?:             string;
    developmentStatus?:       string;
    dimension1?:              string;
    dimension1Name?:          string;
    dimension2?:              string;
    dimension2Name?:          string;
    dimension3?:              string;
    dimension3Name?:          string;
    distributionHandle?:      string;
    enteredId?:               string;
    entryType?:               string;
    entryTypeName?:           string;
    estimationHours?:         string;
    folderId?:                string;
    folderName?:              string;
    folderRefId?:             string;
    forUser?:                 string;
    headId?:                  string;
    headUserId?:              string;
    htmlText?:                string;
    ident?:                   string;
    isIssue?:                 string;
    isMultiUser?:             string;
    isPrivate?:               string;
    isTask?:                  string;
    iversion?:                string;
    location?:                string;
    masterMultiEventTask?:    string;
    milestones?:              string;
    modified?:                string;
    modifiedByName?:          string;
    multiChange?:             string;
    multiEventTask?:          string;
    nodeId?:                  string;
    notify?:                  string;
    plannedTaskCurrent?:      string;
    postChecklistForm?:       string;
    postChecklistFormName?:   string;
    postChecklistFormValues?: string;
    preChecklistForm?:        string;
    preChecklistFormName?:    string;
    preChecklistFormValues?:  string;
    priority?:                string;
    projectId?:               string;
    refId?:                   string;
    remainingHours?:          string;
    reminder?:                string;
    reminderTime?:            string;
    remoteHeadId?:            string;
    remoteId?:                string;
    resourceId?:              string;
    revenue?:                 string;
    severity?:                string;
    solutionStatus?:          string;
    status?:                  string;
    taskTemplateId?:          string;
    timeTracker?:             string;
    title?:                   string;
    userId?:                  string;
    userName?:                string;
    workRecord?:              string;
    workRequest?:             string;
    
    CONTACT?:                 Contact;
    DEPEND?:                  Depend;
    DOCUMENT?:                Document;
    GARANT?:                  Garant;
    REPEAT?:                  Repeat;
    RESOURCE?:                Resource;
    USER?:                    Contact;
}

export interface Contact {
    id?: string;
}

export interface Depend {
    ident?: string;
    title?: string;
    type?:  string;
}

export interface Document {
    documentId?: string;
    name?:       string;
}

export interface Garant {
    userId?: string;
}

export interface Repeat {
    repeatMode?:        string;
    validFrom?:         string;
    validTo?:           string;
    repeatEvery?:       string;
    repeatDay?:         string;
    repeatMonth?:       string;
    repeatWeekDay?:     string;
    repeatWeekCount?:   string;
    repeatWeekDayList?: string;
    timeFrom?:          string;
    timeTo?:            string;
    useOffset?:         string;
    offset?:            string;
    limit?:             string;
    user?:              string;
}

export interface Resource {
    id?:           string;
    name?:         string;
    description?:  string;
    categoryId?:   string;
    needApproval?: string;
}

export function toTaskItemXML(item:TaskItem): string{
    var parameters = '';

    if(item.aclh) parameters += `<aclh>${item.aclh}</aclh>`;
    if(item.activityId) parameters += `<activityId>${item.activityId}</activityId>`;
    if(item.activityName) parameters += `<activityName>${item.activityName}</activityName>`;
    if(item.activityRefId) parameters += `<activityRefId>${item.activityRefId}</activityRefId>`;
    if(item.actualHours) parameters += `<actualHours>${item.actualHours}</actualHours>`;
    if(item.addDuration) parameters += `<addDuration>${item.addDuration}</addDuration>`;
    if(item.chargeable) parameters += `<chargeable>${item.chargeable}</chargeable>`;
    if(item.color) parameters += `<color>${item.color}</color>`;
    if(item.comments) parameters += `<comments>${item.comments}</comments>`;
    if(item.commonId) parameters += `<commonId>${item.commonId}</commonId>`;
    if(item.completed) parameters += `<completed>${item.completed}</completed>`;
    if(item.compold) parameters += `<compold>${item.compold}</compold>`;
    if(item.contactsChanged) parameters += `<contactsChanged>${item.contactsChanged}</contactsChanged>`;
    if(item.cost) parameters += `<cost>${item.cost}</cost>`;
    if(item.created) parameters += `<created>${item.created}</created>`;
    if(item.createdBy) parameters += `<createdBy>${item.createdBy}</createdBy>`;
    if(item.createdByName) parameters += `<createdByName>${item.createdByName}</createdByName>`;
    if(item.customId) parameters += `<customId>${item.customId}</customId>`;
    if(item.dateFrom) parameters += `<dateFrom>${item.dateFrom}</dateFrom>`;
    if(item.dateTo) parameters += `<dateTo>${item.dateTo}</dateTo>`;
    if(item.description) parameters += `<description>${item.description}</description>`;
    if(item.developmentStatus) parameters += `<developmentStatus>${item.developmentStatus}</developmentStatus>`;
    if(item.dimension1) parameters += `<dimension1>${item.dimension1}</dimension1>`;
    if(item.dimension1Name) parameters += `<dimension1Name>${item.dimension1Name}</dimension1Name>`;
    if(item.dimension2) parameters += `<dimension2>${item.dimension2}</dimension2>`;
    if(item.dimension2Name) parameters += `<dimension2Name>${item.dimension2Name}</dimension2Name>`;
    if(item.dimension3) parameters += `<dimension3>${item.dimension3}</dimension3>`;
    if(item.dimension3Name) parameters += `<dimension3Name>${item.dimension3Name}</dimension3Name>`;
    if(item.distributionHandle) parameters += `<distributionHandle>${item.distributionHandle}</distributionHandle>`;
    if(item.enteredId) parameters += `<enteredId>${item.enteredId}</enteredId>`;
    if(item.entryType) parameters += `<entryType>${item.entryType}</entryType>`;
    if(item.entryTypeName) parameters += `<entryTypeName>${item.entryTypeName}</entryTypeName>`;
    if(item.estimationHours) parameters += `<estimationHours>${item.estimationHours}</estimationHours>`;
    if(item.folderId) parameters += `<folderId>${item.folderId}</folderId>`;
    if(item.folderName) parameters += `<folderName>${item.folderName}</folderName>`;
    if(item.folderRefId) parameters += `<folderRefId>${item.folderRefId}</folderRefId>`;
    if(item.forUser) parameters += `<forUser>${item.forUser}</forUser>`;
    if(item.headId) parameters += `<headId>${item.headId}</headId>`;
    if(item.headUserId) parameters += `<headUserId>${item.headUserId}</headUserId>`;
    if(item.htmlText) parameters += `<htmlText>${item.htmlText}</htmlText>`;
    if(item.ident) parameters += `<ident>${item.ident}</ident>`;
    if(item.isIssue) parameters += `<isIssue>${item.isIssue}</isIssue>`;
    if(item.isMultiUser) parameters += `<isMultiUser>${item.isMultiUser}</isMultiUser>`;
    if(item.isPrivate) parameters += `<isPrivate>${item.isPrivate}</isPrivate>`;
    if(item.iversion) parameters += `<iversion>${item.iversion}</iversion>`;
    if(item.location) parameters += `<location>${item.location}</location>`;
    if(item.masterMultiEventTask) parameters += `<masterMultiEventTask>${item.masterMultiEventTask}</masterMultiEventTask>`;
    if(item.milestones) parameters += `<milestones>${item.milestones}</milestones>`;
    if(item.modified) parameters += `<modified>${item.modified}</modified>`;
    if(item.modifiedByName) parameters += `<modifiedByName>${item.modifiedByName}</modifiedByName>`;
    if(item.multiChange) parameters += `<multiChange>${item.multiChange}</multiChange>`;
    if(item.multiEventTask) parameters += `<multiEventTask>${item.multiEventTask}</multiEventTask>`;
    if(item.nodeId) parameters += `<nodeId>${item.nodeId}</nodeId>`;
    if(item.notify) parameters += `<notify>${item.notify}</notify>`;
    if(item.plannedTaskCurrent) parameters += `<plannedTaskCurrent>${item.plannedTaskCurrent}</plannedTaskCurrent>`;
    if(item.postChecklistForm) parameters += `<postChecklistForm>${item.postChecklistForm}</postChecklistForm>`;
    if(item.postChecklistFormName) parameters += `<postChecklistFormName>${item.postChecklistFormName}</postChecklistFormName>`;
    if(item.postChecklistFormValues) parameters += `<postChecklistFormValues>${item.postChecklistFormValues}</postChecklistFormValues>`;
    if(item.preChecklistForm) parameters += `<preChecklistForm>${item.preChecklistForm}</preChecklistForm>`;
    if(item.preChecklistFormName) parameters += `<preChecklistFormName>${item.preChecklistFormName}</preChecklistFormName>`;
    if(item.preChecklistFormValues) parameters += `<preChecklistFormValues>${item.preChecklistFormValues}</preChecklistFormValues>`;
    if(item.priority) parameters += `<priority>${item.priority}</priority>`;
    if(item.projectId) parameters += `<projectId>${item.projectId}</projectId>`;
    if(item.refId) parameters += `<refId>${item.refId}</refId>`;
    if(item.remainingHours) parameters += `<remainingHours>${item.remainingHours}</remainingHours>`;
    if(item.reminder) parameters += `<reminder>${item.reminder}</reminder>`;
    if(item.reminderTime) parameters += `<reminderTime>${item.reminderTime}</reminderTime>`;
    if(item.remoteHeadId) parameters += `<remoteHeadId>${item.remoteHeadId}</remoteHeadId>`;
    if(item.remoteId) parameters += `<remoteId>${item.remoteId}</remoteId>`;
    if(item.resourceId) parameters += `<resourceId>${item.resourceId}</resourceId>`;
    if(item.severity) parameters += `<severity>${item.severity}</severity>`;
    if(item.solutionStatus) parameters += `<solutionStatus>${item.solutionStatus}</solutionStatus>`;
    if(item.status) parameters += `<status>${item.status}</status>`;
    if(item.taskTemplateId) parameters += `<taskTemplateId>${item.taskTemplateId}</taskTemplateId>`;
    if(item.timeTracker) parameters += `<timeTracker>${item.timeTracker}</timeTracker>`;
    if(item.title) parameters += `<title>${item.title}</title>`;
    if(item.userId) parameters += `<userId>${item.userId}</userId>`;
    if(item.userName) parameters += `<userName>${item.userName}</userName>`;
    if(item.workRecord) parameters += `<workRecord>${item.workRecord}</workRecord>`;
    if(item.workRequest) parameters += `<workRequest>${item.workRequest}</workRequest>`;
    
    if(item.CONTACT) parameters += `<CONTACT><id>${item.CONTACT.id}</id></CONTACT>`;
    if(item.DOCUMENT) parameters += `<DOCUMENT><documentId>${item.DOCUMENT.documentId}</documentId><name>${item.DOCUMENT.name}</name></DOCUMENT>`;
    if(item.DEPEND) parameters += `<DEPEND><ident>${item.DEPEND.ident}</ident><title>${item.DEPEND.title}</title><type>${item.DEPEND.type}</type></DEPEND>`;
    if(item.GARANT && item.GARANT.userId) parameters += `<GARANT><userId>${item.GARANT.userId}</userId></GARANT>`;
    if(item.REPEAT) parameters += `<REPEAT><repeatMode>${item.REPEAT.repeatMode}</repeatMode><validFrom>${item.REPEAT.validFrom}</validFrom><validTo>${item.REPEAT.validTo}</validTo><repeatEvery>${item.REPEAT.repeatEvery}</repeatEvery><repeatDay>${item.REPEAT.repeatDay}</repeatDay><repeatMonth>${item.REPEAT.repeatMonth}</repeatMonth><repeatWeekDay>${item.REPEAT.repeatWeekDay}</repeatWeekDay><repeatWeekCount>${item.REPEAT.repeatWeekCount}</repeatWeekCount><repeatWeekDayList>${item.REPEAT.repeatWeekDayList}</repeatWeekDayList><timeFrom>${item.REPEAT.timeFrom}</timeFrom><timeTo>${item.REPEAT.timeTo}</timeTo><useOffset>${item.REPEAT.useOffset}</useOffset><offset>${item.REPEAT.offset}</offset><limit>${item.REPEAT.limit}</limit><user>${item.REPEAT.user}</user></REPEAT>`;
    if(item.RESOURCE) parameters += `<RESOURCE><id>${item.RESOURCE.id}</id><name>${item.RESOURCE.name}</name><description>${item.RESOURCE.description}</description><categoryId>${item.RESOURCE.categoryId}</categoryId><needApproval>${item.RESOURCE.needApproval}</needApproval></RESOURCE>`;
    if(item.USER) parameters += `<USER><id>${item.USER.id}</id></USER>`;

    return parameters;
}
