import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IGetFormSettingsRequest extends IBaseRequest {
    settingsId: string;
}

export class GetFormSettingsRequest extends BaseRequest implements IGetFormSettingsRequest {

    public settingsId: string;
    constructor(server: string, session: string, settingsId: string) {
        super(server, session);
        this.settingsId = settingsId;
    }

    public toXml(): string {
        var parameters: string = super.toXml();
        parameters += '<settingsId>' + this.settingsId + '</settingsId>';
        return super.wrapperXml('GetFormSettings', parameters);
    }
}
