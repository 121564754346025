import * as React from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { LayoutDefinition } from '../../../../infra/protected/Layout/LayoutDefinitions';
import { Flex } from '@adobe/react-spectrum';
import { ComponentFactory } from '../ComponentFactory';
import { FPAData } from '../../../../infra/protected/FPA/FPAData';

export interface IHorizontalLayoutProps {
  children?: LayoutDefinition[];
  selectedItem?: FPAData;
}

function HorizontalLayout({ children, selectedItem }: IHorizontalLayoutProps) {
  if (!children) return <>No children</>;
  return (
    <Flex direction={{ base: 'column', M: 'row' }} gap="size-100">
      {children.map((child, index) => (
        <ComponentFactory key={index} selectedItem={selectedItem!} definition={child} systemAttributes={{}} />
      ))}
    </Flex>
  );
}

export const HorizontalLayoutComponent = LayoutComponent(HorizontalLayout);
