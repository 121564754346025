import { ComboBox, Flex, Item } from '@adobe/react-spectrum';
import * as React from 'react';
import { useEffect, useState } from 'react';

export interface IProjectTypeFilterProps {
  title:string;
  options:any[];
  onChange?: (key:string, value: any) => void;
  selectedOption?: any;
}

export function ProjectTypeFilter({
  title,
  options,
  onChange,
  selectedOption,
}:IProjectTypeFilterProps) {
    const [selectedFilter, setSelectedFilter] = useState(selectedOption);
    const handleOnChange = (value:any) => {
      setSelectedFilter(value);
      if(onChange === undefined) return;
      onChange('projectType', value);
    }
    useEffect(() => {
      setSelectedFilter(selectedOption);
    }, [selectedOption])
    return (
      <>
        <Flex direction='column' gap={'size-10'}>
          <ComboBox selectedKey={selectedFilter} onSelectionChange={handleOnChange} label={title} width={'90%'}>
            {options.map((item) => (
              <Item textValue={item.name} key={item.id}>
                {item.name}
              </Item>
            ))}
          </ComboBox>
        </Flex>
      </>
    );
}
