import { IStore } from "./IStore";

export class BrowserStorage implements IStore{
    private static keyPrefix: string = "atollon_";
    private static USERNAME: string = BrowserStorage.keyPrefix + "username";
    private static USER_ID: string = BrowserStorage.keyPrefix + "userId";
    private static PASSWORD: string = BrowserStorage.keyPrefix + "password";
    private static SERVER: string = BrowserStorage.keyPrefix + "server";
    private static SESSION_ID: string = BrowserStorage.keyPrefix + "sessionId";
    private static SESSION: string = BrowserStorage.keyPrefix + "session";
    private static CUSTOM_APPS: string = BrowserStorage.keyPrefix + "customApps";

    public get(key: string): any {
        return localStorage.getItem(key);
    }

    public set(key: string, value: any): void {
        localStorage.setItem(key, value);
    }

    public remove(key: string): void {
        localStorage.removeItem(key);
    }

    public clear(): void {
        localStorage.clear();
    }

    public get UserId(): number {
        return parseInt(this.get(BrowserStorage.USER_ID));
    }
    public set UserId(value: number) {
        this.set(BrowserStorage.USER_ID, value);
    }

    public get Username(): string {
        return this.get(BrowserStorage.USERNAME);
    }
    public set Username(value: string) {
        this.set(BrowserStorage.USERNAME, value);
    }

    public get Password(): string {
        return this.get(BrowserStorage.PASSWORD);
    }
    public set Password(value: string) {
        this.set(BrowserStorage.PASSWORD, value);
    }

    public get Server(): string {
        return this.get(BrowserStorage.SERVER);
    }
    public set Server(value: string) {
        this.set(BrowserStorage.SERVER, value);
    }

    public get SessionId(): string {
        return this.get(BrowserStorage.SESSION_ID);
    }
    public set SessionId(value: string) {
        this.set(BrowserStorage.SESSION_ID, value);
    }

    public get customApps(): string[] {
        return JSON.parse(this.get(BrowserStorage.CUSTOM_APPS));
    }
    public set customApps(value: string[]) {
        this.set(BrowserStorage.CUSTOM_APPS, JSON.stringify(value));
    }

    public get session(): any {
        return JSON.parse(this.get(BrowserStorage.SESSION));
    }
    public set session(value: any) {
        this.set(BrowserStorage.SESSION, JSON.stringify(value));
    }
}