import { Flex, Text } from '@adobe/react-spectrum';
import { AppsIcon, HomeIcon, MessageIcon, MobileSearchIcon, ProfileIcon } from '../Icons/IconsLib';
import { AppMenuOptions } from '../../../infra/protected/Search/enum/AppMenuOptions';
import { useState } from 'react';
import styles from './MobileMenu.module.css';

export interface IMobileMenuProps {
    setIsMenuOpen: (isOpen:boolean) => void;
    setIsShowSearch: (isShowSearch:boolean) => void;
    setIsProfileOpen: (isProfileOpen:boolean) => void;
}

export function MobileMenu ({
    setIsMenuOpen,
    setIsShowSearch,
    setIsProfileOpen
}: IMobileMenuProps) {
    const [currentMenuOption, setCurrentMenuOption] = useState<AppMenuOptions>(AppMenuOptions.None);

    const handleMobileOptionChange = (option:AppMenuOptions) => {
        setCurrentMenuOption(option);
        switch(option) {
          case AppMenuOptions.Apps:
            setIsMenuOpen(true);
            setIsShowSearch(false);
            setIsProfileOpen(false);
            break;
          case AppMenuOptions.Profile:
            setIsMenuOpen(false);
            setIsShowSearch(false);
            setIsProfileOpen(true);
            break;
          case AppMenuOptions.Search:
            setIsMenuOpen(false);
            setIsShowSearch(true);
            setIsProfileOpen(false);
            break;
          default:
            setIsMenuOpen(false);
            setIsProfileOpen(false);
            setIsShowSearch(false);
            break;
        }
      } 
  return (
    <>
          <Flex 
            direction={"row"} 
            alignItems={'center'} 
            justifyContent={"space-evenly"} 
            isHidden={{base: false, M: true}} 
            marginTop={10} 
            marginBottom={20}>
              <span onClick={()=>handleMobileOptionChange(AppMenuOptions.Apps)}>
                <Flex direction={"column"} alignItems={'center'} justifyContent={"center"} 
                      UNSAFE_className={currentMenuOption === AppMenuOptions.Apps ? styles.selectedOption : styles.option}>
                    <AppsIcon size={24} />
                    <Text>Apps</Text>
                </Flex>
              </span>
              <span onClick={()=>handleMobileOptionChange(AppMenuOptions.Messages)}>
                <Flex direction={"column"} alignItems={'center'} justifyContent={"center"} 
                      UNSAFE_className={currentMenuOption === AppMenuOptions.Messages ? styles.selectedOption : styles.option}>
                    <MessageIcon size={24} />
                    <Text>Messages</Text>
                </Flex>
              </span>
              <span onClick={()=>handleMobileOptionChange(AppMenuOptions.Home)}>
                <Flex direction={"column"} alignItems={'center'} justifyContent={"center"} 
                      UNSAFE_className={currentMenuOption === AppMenuOptions.Home ? styles.selectedOption : styles.option}>
                    <HomeIcon size={24} />
                    <Text>Home</Text>
                </Flex>
              </span>
              <span onClick={()=>handleMobileOptionChange(AppMenuOptions.Search)}>
                <Flex direction={"column"} alignItems={'center'} justifyContent={"center"} 
                    UNSAFE_className={currentMenuOption === AppMenuOptions.Search ? styles.selectedOption : styles.option}>
                    <MobileSearchIcon size={24} />
                    <Text>Search</Text>
                </Flex>
              </span>
              <span onClick={()=>handleMobileOptionChange(AppMenuOptions.Profile)}>
                <Flex direction={"column"} alignItems={'center'} justifyContent={"center"} 
                    UNSAFE_className={currentMenuOption === AppMenuOptions.Profile ? styles.selectedOption : styles.option}>
                    <ProfileIcon size={24} />
                    <Text>Profile</Text>
                </Flex>
              </span>
          </Flex>      
    </>
  );
}
