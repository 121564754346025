import { TREE } from "../../../utils/ServiceUtils";
import { ServiceBase } from "../ServiceBase";
import { NodeToPathRequest } from "./reqeusts/NodeToPathRequest";
import { NodeToPathResponse } from "./responses/NodeToPathResponse";

export interface ITreeService {
    nodeToPath(request: NodeToPathRequest): Promise<NodeToPathResponse>;
}

export class TreeService extends ServiceBase implements ITreeService{
    public async nodeToPath(request: NodeToPathRequest): Promise<NodeToPathResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), TREE.nodeToPath, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(TREE.nodeToPath, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(TREE.nodeToPath, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).NodeToPathResponse as NodeToPathResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }
}