import { ActionButton, Flex, View } from '@adobe/react-spectrum';
import { IconStyle, SearchIcon } from '../../Icons/IconsLib';
import styles from './ItemTypeCell.module.css';
import ChevronRight from '@spectrum-icons/workflow/ChevronRight';
import ChevronDown from '@spectrum-icons/workflow/ChevronDown';

import { useState } from 'react';
import { FPAData } from '../../../../infra/protected/FPA/FPAData';
import useDeviceType from '../../../../hooks/UseDeviceType';

export interface IItemTypeCellProps {
    item: FPAData;
}

export function ItemTypeCell ({
    item
}: IItemTypeCellProps) {
    return (
    <>
        <span>
            <Flex direction="row" UNSAFE_className={styles.itemTypeWrapper}>
                <View UNSAFE_className={styles.title}>
                    {item.title}
                </View>
            </Flex>
        </span>
    </>
  );
}
