import { Flex, ProgressBar, View } from '@adobe/react-spectrum';
import { ItemCell } from './components/ItemCell';
import styles from './FPATree.module.css';
import { FPAData, FPADataTypes } from '../../../infra/protected/FPA/FPAData';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';

export interface IFPATreeProps {
  treeData: FPAData[];
  onItemOpened?: (item: FPAData) => void;
  onItemClosed?: (item: FPAData) => void;
  onItemSelected?: (item: FPAData, show_context:boolean) => void;
  onItemSearching?: (search:string, parent_id?:number, parent_type?:FPADataTypes, is_page?:boolean) => void;
  onItemSearchOpened?: (item: FPAData) => void;
  onItemSearchClosed?: (isCancel:boolean) => void;
  onItemSearchReset?: (item: FPAData) => void;
  onSearchedItemSelected?: (item: FPAData) => void;
  onItemLoadMore?: (item: FPAData) => void;
}


export function FPATree ({
  treeData,
  onItemOpened,
  onItemClosed,
  onItemSelected,
  onItemSearching,
  onItemSearchOpened,
  onItemSearchClosed,
  onItemSearchReset,
  onSearchedItemSelected,
  onItemLoadMore
}: IFPATreeProps) {

  const searchParent = useSelector((state: RootState) => state.finder.searchParent);
  const lastOpenedItem = useSelector((state: RootState) => state.finder.lastOpenedItem);
  const isLoading = useSelector((state: RootState) => state.finder.isLoading);
  const loadingIndex = useSelector((state: RootState) => state.finder.loadingIndex);

  const handleItemClicked = (item: FPAData, show_context: boolean) => {
    if(onItemSelected) onItemSelected(item, show_context);
  }

  const handleSelectedSearchItem = (item: FPAData, search_item:any) => {
    if(onSearchedItemSelected) onSearchedItemSelected(search_item.item);
  }

  const searchCallback = (search:string, parent_id?:number, parent_type?:FPADataTypes):void => {
    if(onItemSearching) onItemSearching(search, parent_id, parent_type);
  };

  const handleShowOverlay = (status:boolean, item: FPAData, isCancel:boolean) => {
    if(status){
      if(onItemSearchOpened) onItemSearchOpened(item);
    }else{
      if(onItemSearchClosed) onItemSearchClosed(isCancel);
    }
  }

  const handleResetSearch = (item:FPAData) => {
    // console.log('FPATree.handleResetSearch');
    onItemSearchReset && onItemSearchReset(item);
  }

  const loadChildData = (item:FPAData) => {
    if(item.isOpen){
      if(onItemClosed) onItemClosed(item);
    } else {
      if(onItemOpened) onItemOpened(item);
    }
  }

  const handleOnScroll = (e: React.UIEvent<HTMLSpanElement>) => {
    if(e.currentTarget.scrollHeight - e.currentTarget.scrollTop === e.currentTarget.clientHeight){
      if(lastOpenedItem && lastOpenedItem.isOpen && !lastOpenedItem.isFiltered && lastOpenedItem.page_offset < lastOpenedItem.count){
        onItemLoadMore && onItemLoadMore(lastOpenedItem);
      } 
    }
  }
  return (
    <>
      <span className={styles.container} onScroll={handleOnScroll}>
        <Flex direction="column" gap="size-100" UNSAFE_className={styles.treeHolder}>
          <View>
            {treeData.map((item, index) =>{ 
                var loading_bar = (<View key={`loading_${index}`} backgroundColor="static-white" padding="size-100" borderRadius={'small'}>
                                      <ProgressBar label={'Loading...'} labelPosition='side' isIndeterminate />
                                  </View>);
                var item_cell = (
                  <ItemCell key={`${item.id}_${item.isOpen}_${item.isFiltered}_${item.isSelected}_${item.isSearching}`} 
                    item={item} 
                    loadChildData={loadChildData}
                    onResetSearch={handleResetSearch}
                    isSearched={searchParent === item}
                    onItemClicked={handleItemClicked}
                    onSelectSearchItem={handleSelectedSearchItem}
                    searchCallback={searchCallback}
                    showOverlay={handleShowOverlay}
                  />
                );
                if(index === loadingIndex && isLoading){
                  return [item_cell,loading_bar];
                }else{
                  return item_cell;
                }
              }
            )
            }
          </View>
        </Flex>
      </span>
    </>
  );
}
