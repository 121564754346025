import { useState, useEffect } from 'react';
import useDebounce from './UseDebounce';

const useResize = (): number[] => {
  const [size, setSize] = useState<[number, number]>([window.innerWidth, window.innerHeight]);
  const debouncedValue = useDebounce(() => {
    setSize([window.innerWidth, window.innerHeight]);
    if (window.innerWidth < 1000 || window.innerWidth > 1200) {
      window.location.reload();
    }
  }, 300);

  useEffect(() => {
    //console.log('debouncedValue', size);
    window.addEventListener('resize', debouncedValue);

    return () => {
      window.removeEventListener('resize', debouncedValue);
    };
  }, [size]);

  return size;
};

export default useResize;
