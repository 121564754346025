import { ProjectCell } from './ProjectCell';
import { ActivityCell } from './ActivityCell';
import styles from './ItemCell.module.css';
import { ContextHeader } from './ContextHeader';
import { View } from '@react-spectrum/view';
import { useEffect, useRef, useState } from 'react';
import { FolderTypeCell } from './FolderTypeCell';
import { FolderCell } from './FolderCell';
import { InPlaceSearch } from './InPlaceSearch/InPlaceSearch';
import { FPAData, FPADataTypes } from '../../../../infra/protected/FPA/FPAData';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { ItemTypeCell } from './ItemTypeCell';

export interface IItemCellProps {
    item: FPAData;
    loadChildData?: (item:FPAData) => void;
    onResetSearch: (item:FPAData) => void;
    isSearched:boolean;
    onItemClicked: (item:FPAData, showContext:boolean) => void;
    onSelectSearchItem: (item:FPAData, search_item:any, results:FPAData[]) => void;
    searchCallback?: (search:string, parent_id:number, parent_type:FPADataTypes, is_page:boolean) => void;
    showOverlay?: (status: boolean, item:FPAData, isCancel:boolean) => void;
}

export function ItemCell ({
    item,
    loadChildData,
    onResetSearch,
    isSearched,
    onItemClicked,
    onSelectSearchItem,
    searchCallback,
    showOverlay
}: IItemCellProps) {
    const [showSearch, setShowSearch] = useState(false);
    const searchResults = useSelector((state: RootState) => state.finder.searchData);
    const isMoveRef = useRef<boolean>(false);
    const itemRef = useRef<any>(null);

    // const [results, setResults] = useState<FPAData[]>([]);
    const handleClicked = (item:FPAData) => {
        loadChildData && loadChildData(item);
    }

    const handleSearch = () => {
        setShowSearch(true);
        if(showOverlay){
            showOverlay(true, item, false);
        }
    }

    const handleSearchSelected = (search_item:any) => {
        setShowSearch(false);
        if(showOverlay){
            showOverlay(false, item, false);
        }
        onSelectSearchItem(item, search_item, searchResults);
    }

    const handleReset = () => {
        onResetSearch(item);
    }

    useEffect(() => { if(isSearched !== undefined) setShowSearch(isSearched)}, [isSearched]);

    useEffect(() => {
        if(item.isSelected && itemRef.current){
            itemRef.current.UNSAFE_getDOMNode().scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
        }
    }, [item.isSelected])

    const onSearch = (text:string, is_page:boolean = false) => {
        if(searchCallback){
            searchCallback(text, item.id, item.type, is_page);
        }
    }

    if(showSearch){
        const handleCloseSearch = (isCancel: boolean) => {
            setShowSearch(false);
            if(showOverlay) showOverlay(false, item, isCancel);
        };
        return (
            <View padding={'size-100'}>
                <span className={styles.itemWrapper}>
                    <InPlaceSearch 
                        closeSearch={handleCloseSearch} 
                        doSearch={onSearch} 
                        onSearchSelected={handleSearchSelected} 
                        items={searchResults}
                        />
                </span>
            </View>
        );
    }

    if(item.type === FPADataTypes.GROUP){
        return (
            <span className={styles.separatorWrapper}>
                <ContextHeader item={item}
                    onSearch={handleSearch} 
                    />
            </span>
        );
    }
    if(item.type === FPADataTypes.FOLDER_TYPE){
        return (
            <span className={`${styles.folderTypeWrapper}`}>
                <FolderTypeCell item={item} 
                    onSearch={handleSearch} 
                    onClicked={() => {handleClicked(item);}}
                    onReset={handleReset}
                    />
            </span>
        );
    }
    
    if(item.type === FPADataTypes.ITEM_TYPE){
        return (
            <span className={`${styles.itemTypeWrapper} ${item.isSelected ? styles.selected : ''}`}>
                <ItemTypeCell item={item} 
                    />
            </span>
        );
    }
    const handleTouchMove = (e:React.TouchEvent) => {
        isMoveRef.current = true;
    }
    const handleTouchEnd = (e:React.TouchEvent) => {
        if(!isMoveRef.current){
            onItemClicked(item, true);
        }
        isMoveRef.current = false;
    }
    return (
    <>
        <View 
            ref={itemRef}
            paddingEnd={'size-100'} 
            paddingStart={'5px'} 
            UNSAFE_className={`${styles.itemContainer} ${item.isSelected ? styles.selected : ''}`}
            >
            <span   className={styles.itemWrapper} 
                    onTouchEnd={handleTouchEnd} 
                    onTouchMove={handleTouchMove} 
                    onDoubleClick={() => onItemClicked(item, true)}
                    onClick={() => onItemClicked(item, false)}
                    >
                {item.type === FPADataTypes.FOLDER && (
                    <span>
                        <FolderCell 
                            item={item}
                            onSearch={handleSearch}
                            onClicked={() => { if(item.count > 0) handleClicked(item);}}
                            onReset={handleReset}
                        />
                    </span>
                    )}
                
                {item.type === FPADataTypes.PROJECT && (
                    <span>
                        <ProjectCell
                            item={item}
                            onSearch={handleSearch}
                            onClicked={() => { if(item.count > 0) handleClicked(item);}}
                            onReset={handleReset}
                        />
                    </span>)}
                
                {item.type === FPADataTypes.ACTIVITY && (
                    <span onClick={() => {if(item.count > 0) handleClicked(item)}}>
                        <ActivityCell 
                            item={item}
                            onSearch={handleSearch}
                        />
                    </span>)}
            </span>
        </View>
    </>
  );
}
