import { SettingItem } from '../../../services/soap/features/responses/GetFormSettingsResponse';
import { Divider, Flex } from '@adobe/react-spectrum';
import { CreateBillingItemsFields, CreateCustomFormFields, CreatePersonFields, CreateProjectFields, CreateSalesItemsFields, CreateTechnologyItemsFields, CreateTrackingCodeFields } from './ContextFields';
import { FPATemplateItem } from '../../../infra/protected/FPA/FPATemplateItem';

export interface IProjectContextFieldsProps {
    settings: SettingItem[];
    selectedItem?: FPATemplateItem;
}

export function ProjectContextFields ({
    settings,
    selectedItem
}: IProjectContextFieldsProps) {
  return (
    <Flex direction={'column'} gap={'size-100'}>
        <CreateProjectFields settings={settings} selectedItem={selectedItem} />
        <CreateTrackingCodeFields settings={settings} selectedItem={selectedItem} />
        <Divider size='M' />
        <CreateCustomFormFields settings={settings} selectedItem={selectedItem} />
        <CreatePersonFields settings={settings} selectedItem={selectedItem} />
        <CreateSalesItemsFields settings={settings} selectedItem={selectedItem} />
        <CreateBillingItemsFields settings={settings} selectedItem={selectedItem} />
        <CreateTechnologyItemsFields settings={settings} selectedItem={selectedItem} />
    </Flex>    
  );
}
