export default {
  lowercase,
  uppercase,
  datetime,
  number,
  currency,
} as const;

export function lowercase(value: string) {
  return value.toLowerCase();
}

export function uppercase(value: string) {
  return value.toUpperCase();
}

/**
 * Returns the default qualified locale code
 * (language-REGION) for the given locale.
 *
 * @param lng The locale code.
 * @returns The qualified locale code, including region.
 */
function qualifiedLngFor(lng: string): string {
  switch (lng) {
    case 'ar':
      return 'ar-EG';
    case 'en':
      return 'en-US';
    case 'cz':
      return 'cs-CZ';
    case 'fr':
      return 'fr-FR';
    case 'de':
      return 'de-DE';
    case 'es':
      return 'es-ES';
    case 'it':
      return 'it-IT';
    case 'ja':
      return 'ja-JP';
    case 'ko':
      return 'ko-KR';
    case 'nl':
      return 'nl-NL';
    case 'pl':
      return 'pl-PL';
    case 'pt':
      return 'pt-PT';
    case 'ru':
      return 'ru-RU';
    case 'tr':
      return 'tr-TR';
    case 'zh':
      return 'zh-CN';
    default:
      return lng;
  }
}

/**
 * Formats a datetime.
 *
 * @param value - The datetime to format.
 * @param lng - The language to format the number in.
 * @param options - passed to Intl.DateTimeFormat.
 * @returns The formatted datetime.
 */
export function datetime(value: Date | number, lng: string | undefined, options?: Intl.DateTimeFormatOptions): string {
  if (!options) {
    options = { month: '2-digit', day: '2-digit', year: 'numeric' };
  }
  return new Intl.DateTimeFormat(qualifiedLngFor(lng!), options).format(value);
}

/**
 * Formats a number.
 *
 * @param value - The number to format.
 * @param lng - The language to format the number in.
 * @param options - passed to Intl.NumberFormat.
 * @returns The formatted number.
 */
export function number(value: number, lng: string | undefined, options?: Intl.NumberFormatOptions): string {
  return new Intl.NumberFormat(qualifiedLngFor(lng!), options).format(value);
}

/**
 * Formats a number as currency.
 *
 * @param value - The number to format.
 * @param lng - The language to format the number in.
 * @param options - passed to Intl.NumberFormat.
 * @returns The formatted currency string.
 */
export function currency(value: number, lng: string | undefined, options?: Intl.NumberFormatOptions): string {
  return number(value, lng, {
    style: 'currency',
    ...options,
  });
}
