import axios from "axios";
export interface IRestClient {
  makeRestCall(request: any, url: string, action: string, cancelSource: any, responseType: any): Promise<any>;
}

export class RestClient implements IRestClient {
  public async makeRestCall(
    request: any,
    url: string,
    action: string,
    cancelSource: any,
    responseType: any = "json"
  ): Promise<any> {
    return axios.post(url + action, request, {
      responseType: responseType,
      cancelToken: cancelSource.token,
      headers: {
        "Content-Type": "text/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  }
}
