//import jsonConfig from "../config/lagoon-config.json";
import { useCallback, useEffect, useState } from "react";
import { OneTimeStorage } from "../services/LocalStorage/OneTimeStorage";
import { saveToCache } from "./UsePreloadAssets";
import { json } from "body-parser";


const CACHE_KEYS = {
    reef_config: 'reef_config',
};

interface LoginInfo{
    showServer: boolean;
    serverName: string;
    instanceName: string;
}
interface SOAP{
    uri: string;
}
interface REST{
    uri: string;
}
interface ReefConfig {
    baseUrl: string;
    loginInfo: LoginInfo;
    soap: SOAP;
    recruitment_dashboard: REST;
    install_path: string;
};

export function useReefConfig(): ReefConfig {
    const [config, setConfig] = useState<ReefConfig>({
        baseUrl: "",
        loginInfo: {
            showServer: true,
            serverName: "",
            instanceName: ""
        },
        soap: {
            uri: ""
        },
        recruitment_dashboard: {
            uri: ""
        },
        install_path: ""
    });

    const loadCallback = useCallback(async () => {
        const reef_config = await OneTimeStorage.getInstance().get(CACHE_KEYS.reef_config);
        var jsonConfig = null;
        
        if(reef_config === null){
            console.log('fetching reef-config.json...');
            var response = await fetch('/reef-config.json');
            jsonConfig = await response.json();
            await saveToCache(CACHE_KEYS.reef_config, jsonConfig);
        }else{
            jsonConfig = JSON.parse(reef_config);
        }

        if(jsonConfig)
            jsonConfig = jsonConfig.configuration;

        var server:string = "";
        var instance:string = "";
        var showServer:boolean = jsonConfig.loginInfo === undefined
                                || jsonConfig.loginInfo.serverName === undefined 
                                || jsonConfig.loginInfo.serverName === null 
                                || jsonConfig.loginInfo.serverName === "" ? true : false;
    
        if (!showServer) {
            server = jsonConfig.loginInfo.serverName;
            instance = jsonConfig.loginInfo.instanceName;
        }
        setConfig({
            baseUrl: jsonConfig.baseUrl,
            loginInfo: {serverName: server, instanceName: instance, showServer: showServer},
            soap: { uri: jsonConfig.baseUrl + jsonConfig.soap.uri},
            recruitment_dashboard: { uri: jsonConfig.baseUrl + jsonConfig['recruitment-dashboard'].uri},
            install_path: jsonConfig.baseUrl + jsonConfig.install_path
        });
        }, []);
    
    useEffect(() => {
        loadCallback();
    }, [loadCallback]);

    return config;
}