import { FORM } from "../../../utils/ServiceUtils";
import { ServiceBase } from "../ServiceBase";
import { ICreateFormValueRequest } from "./requests/CreateFormValueRequest";
import { IGetFormRequest } from "./requests/GetFormRequest";
import { IListFormsRequest } from "./requests/ListFormsRequest";
import { IListItemValueRequest } from "./requests/ListItemValueRequest";
import { IUpdateFormItemValuesRequest } from "./requests/UpdateFormItemValuesRequest";
import { IUpdateItemValueRequest } from "./requests/UpdateItemValueRequest";
import { CreateFormValueResponse } from "./responses/CreateFormValueResponse";
import { GetFormResponse } from "./responses/GetFormResponse";
import { ListFormsResponse } from "./responses/ListFormsResponse";
import { ListItemValueResponse } from "./responses/ListItemValueResponse";
import { UpdateFormItemValuesResponse } from "./responses/UpdateFormItemValuesResponse";
import { UpdateItemValueResponse } from "./responses/UpdateItemValueResponse";

export interface IFormService {
    listItemValue(requst: IListItemValueRequest): Promise<ListItemValueResponse>;
    updateFormItemValues(request: IUpdateFormItemValuesRequest): Promise<UpdateFormItemValuesResponse>;
    updateItemValue(request: IUpdateItemValueRequest): Promise<UpdateItemValueResponse>;
    createFormValue(request: ICreateFormValueRequest): Promise<CreateFormValueResponse>;
    listForms(request: IListFormsRequest): Promise<ListFormsResponse>;
    getFrom(request: IGetFormRequest): Promise<GetFormResponse>;
}

export class FormService extends ServiceBase implements IFormService {
    private _addRootElementForListItems(xml: string): string {
        xml = xml.replaceAll('</count><ITEMVALUE>', '</count><ROWS><ITEMVALUE>');
        xml = xml.replaceAll('</ITEMVALUE></ns1:ListItemValueResponse>', '</ITEMVALUE></ROWS></ns1:ListItemValueResponse>');
        return xml;
    }

    public async listItemValue(request: IListItemValueRequest): Promise<ListItemValueResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), FORM.listItemValue, request.cancelSource));
            response = this._addRootElementForListItems(tmp_response.data);
            await this.updateCache(FORM.listItemValue, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(FORM.listItemValue, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListItemValueResponse as ListItemValueResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async updateFormItemValues(request: IUpdateFormItemValuesRequest): Promise<UpdateFormItemValuesResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), FORM.updateFormItemValues, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(FORM.updateFormItemValues, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(FORM.updateFormItemValues, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).UpdateFormItemValuesResponse as UpdateFormItemValuesResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async updateItemValue(request: IUpdateItemValueRequest): Promise<UpdateItemValueResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), FORM.updateItemValue, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(FORM.updateItemValue, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(FORM.updateItemValue, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).UpdateItemValueResponse as UpdateItemValueResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async createFormValue(request: ICreateFormValueRequest): Promise<CreateFormValueResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), FORM.createFormValue, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(FORM.createFormValue, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(FORM.createFormValue, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).CreateFormValueResponse as CreateFormValueResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async listForms(request: IListFormsRequest): Promise<ListFormsResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), FORM.listForms, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(FORM.listForms, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(FORM.listForms, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListFormsResponse as ListFormsResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async getFrom(request: IGetFormRequest): Promise<GetFormResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), FORM.getForm, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(FORM.getForm, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(FORM.getForm, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['FORMITEM']).GetFormResponse as GetFormResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }
}