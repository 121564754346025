import { Flex, TextField, View } from "@adobe/react-spectrum";
import { SettingItem } from "../../../services/soap/features/responses/GetFormSettingsResponse";
import { ContextFieldsMapping } from "./ContextFieldsMapping";
import { FPATemplateItem } from "../../../infra/protected/FPA/FPATemplateItem";
import { FPADataTypes, FPASystemTypes } from "../../../infra/protected/FPA/FPAData";
import IndustryDropDown from "../data-components/IndustryDropDown";
import CountryDropDown from "../data-components/CountryDropDown";
import LanguageDropDown from "../data-components/LanguageDropDown";
import PositionDropDown from "../data-components/PositionDropDown";
import DepartmentDropDown from "../data-components/DepartmentDropDown";
import ContactDropDown from "../data-components/ContactDropDown";

export interface SectionFieldsProps {
    settings: SettingItem[];
    selectedItem?: FPATemplateItem;
    setFormData?: (key: string, value: any) => void;
}
function generateMap(keys: string[], values: SettingItem[]) {
    let map = new Map<string, SettingItem>();
    values.filter(setting => setting.visibility == 1 && keys.includes(setting.itemname)).forEach(setting => {
        map.set(setting.itemname, setting);
    });
    return map;
}

function CreateTextField( label: string, key: string, width: string, isRequired: boolean, dataKey: string, setValue?: (key: string, value: any) => void) {
    const onChange = (value: string) => { if(setValue) setValue(dataKey, value); }
    return (<View flex>
      <TextField label={label} key={key} width={width} isRequired={isRequired} onChange={onChange} />
    </View>);
}

function CreateIndustryComponent( label: string, key: string, width: string, isRequired: boolean, dataKey: string, setValue?: (key: string, value: any) => void) {
    const onChange = (value: string) => { if(setValue) setValue(dataKey, value); }
    return (<View flex>
      <IndustryDropDown compKey={key} label={label} width={width} isRequired={isRequired} onValueChange={onChange} />
    </View>);
}

function CreateCountryComponent( label: string, key: string, width: string, isRequired: boolean, dataKey: string, setValue?: (key: string, value: any) => void) {
    const onChange = (value: string) => { if(setValue) setValue(dataKey, value); }
    return (<View flex>
      <CountryDropDown compKey={key} label={label} width={width} isRequired={isRequired} onValueChange={onChange} />
    </View>);
}

function CreateLanguageComponent( label: string, key: string, width: string, isRequired: boolean, dataKey: string, setValue?: (key: string, value: any) => void) {
    const onChange = (value: string) => { if(setValue) setValue(dataKey, value); }
    return (<View flex>
      <LanguageDropDown compKey={key} label={label} width={width} isRequired={isRequired} onValueChange={onChange} />
    </View>);
}

function CreatePositionComponent( label: string, key: string, width: string, isRequired: boolean, dataKey: string, setValue?: (key: string, value: any) => void) {
    const onChange = (value: string) => { if(setValue) setValue(dataKey, value); }
    return (<View flex>
      <PositionDropDown compKey={key} label={label} width={width} isRequired={isRequired} onValueChange={onChange} />
    </View>);
}

function CreateDepartmentComponent( label: string, key: string, width: string, isRequired: boolean, dataKey: string, setValue?: (key: string, value: any) => void) {
    const onChange = (value: string) => { if(setValue) setValue(dataKey, value); }
    return (<View flex>
      <DepartmentDropDown compKey={key} label={label} width={width} isRequired={isRequired} onValueChange={onChange} />
    </View>);
}

function CreateCompanyComponent( label: string, key: string, width: string, isRequired: boolean, dataKey: string, setValue?: (key: string, value: any) => void) {
    const onChange = (value: string) => { if(setValue) setValue(dataKey, value); }
    return (<View flex>
      <ContactDropDown compKey={key} label={label} width={width} isRequired={isRequired} onValueChange={onChange} 
        apiOptions={{
          type: 2,
          onlyIds: false,
          organizationId: 130946101,
          hasFolder: 'all',
          showOnlyUc: false,
          conjuctionDistrGroups: 0
        }} 
        />
    </View>);
}
export function CreateContextFields({
  setting,
  setValue
}:{
  setting?: SettingItem,
  setValue?: (key: string, value: any) => void
}) {
    if(!setting) return null;
    switch(setting.itemname) {
        case ContextFieldsMapping.COMPANY.INDUSTRY:
            return CreateIndustryComponent(setting.caption ? setting.caption : setting.name, ''+setting.id, '100%', setting.mandatory == 1 ? true : false, setting.itemname, setValue); 

        case ContextFieldsMapping.COMPANY.COUNTRY:
        case ContextFieldsMapping.PERSON.COUNTRY:
            return CreateCountryComponent(setting.caption ? setting.caption : setting.name, ''+setting.id, '100%', setting.mandatory == 1 ? true : false, setting.itemname, setValue);

        case ContextFieldsMapping.PERSON.PREFERRED_LANGUAGE:
            return CreateLanguageComponent(setting.caption ? setting.caption : setting.name, ''+setting.id, '100%', setting.mandatory == 1 ? true : false, setting.itemname, setValue);
            
        case ContextFieldsMapping.EMPLOYEE.POSITION:
            return CreatePositionComponent(setting.caption ? setting.caption : setting.name, ''+setting.id, '100%', setting.mandatory == 1 ? true : false, setting.itemname, setValue);

        case ContextFieldsMapping.EMPLOYEE.DEPARTMENT:
            return CreateDepartmentComponent(setting.caption ? setting.caption : setting.name, ''+setting.id, '100%', setting.mandatory == 1 ? true : false, setting.itemname, setValue);
            
        case ContextFieldsMapping.COMPANY.NAME:
            return CreateCompanyComponent(setting.caption ? setting.caption : setting.name, ''+setting.id, '100%', setting.mandatory == 1 ? true : false, setting.itemname, setValue);
            
        default:
            return CreateTextField(setting.caption ? setting.caption : setting.name, ''+setting.id, '100%', setting.mandatory == 1 ? true : false, setting.itemname, setValue);
    }
}

export function CreateCompanyFields({ settings, selectedItem, setFormData }:SectionFieldsProps) {
    
    let keys = [
      ContextFieldsMapping.COMPANY.NAME, 
      ContextFieldsMapping.COMPANY.VAT_NO, 
      ContextFieldsMapping.COMPANY.COUNTRY, 
      ContextFieldsMapping.COMPANY.INDUSTRY,
      ContextFieldsMapping.COMPANY.TRACKING_CODE,
      ContextFieldsMapping.COMPANY.REF_NO
    ];

    let map = generateMap(keys, settings);

    if(!selectedItem || (selectedItem.systemType != FPASystemTypes.FOLDER_CONTACT)) {
      return null;
    }

  return (
    <Flex direction="column" gap="size-100" width={'100%'}>
      <Flex direction="row" gap="size-100" width={'100%'}>
        <CreateContextFields setting={map.get(ContextFieldsMapping.COMPANY.NAME)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.COMPANY.INDUSTRY)} setValue={setFormData} />
      </Flex>
      <Flex direction="row" gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.COMPANY.VAT_NO)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.COMPANY.COUNTRY)} setValue={setFormData} />
      </Flex>
      <Flex direction="row" gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.COMPANY.REF_NO)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.COMPANY.TRACKING_CODE)} setValue={setFormData} />
      </Flex>
    </Flex>);
}

export function CreatePersonFields({ settings, selectedItem, setFormData }:SectionFieldsProps) {
      
      let keys = [
        ContextFieldsMapping.PERSON.PREFIX, 
        ContextFieldsMapping.PERSON.FIRST_NAME, 
        ContextFieldsMapping.PERSON.SURNAME, 
        ContextFieldsMapping.PERSON.SUFFIX,
        ContextFieldsMapping.PERSON.EMAIL,
        ContextFieldsMapping.PERSON.MOBILE,
        ContextFieldsMapping.PERSON.PHONE,
        ContextFieldsMapping.PERSON.SALUTATION,
        ContextFieldsMapping.PERSON.COUNTRY,
        ContextFieldsMapping.PERSON.PREFERRED_LANGUAGE
      ];
  
      let map = generateMap(keys, settings);
  
      if(!selectedItem ) return null;
      if( selectedItem.type == FPADataTypes.FOLDER &&
        (selectedItem.systemType != FPASystemTypes.FOLDER_CONTACT && selectedItem.systemType != FPASystemTypes.FOLDER_USER)
      ) return null;
      return (
      <Flex direction="column" gap="size-100" width={'100%'}>
        <Flex direction="row" gap="size-100" width={'100%'}>
          <CreateContextFields setting={map.get(ContextFieldsMapping.PERSON.PREFIX)} setValue={setFormData} />
          <CreateContextFields setting={map.get(ContextFieldsMapping.PERSON.SUFFIX)} setValue={setFormData} />
        </Flex>
        <Flex direction="row" gap="size-100">
          <CreateContextFields setting={map.get(ContextFieldsMapping.PERSON.FIRST_NAME)} setValue={setFormData} />
          <CreateContextFields setting={map.get(ContextFieldsMapping.PERSON.SURNAME)} setValue={setFormData} />
        </Flex>
        <Flex direction="row" gap="size-100">
          <CreateContextFields setting={map.get(ContextFieldsMapping.PERSON.EMAIL)} setValue={setFormData} />
          <CreateContextFields setting={map.get(ContextFieldsMapping.PERSON.MOBILE)} setValue={setFormData} />
        </Flex>
        <Flex direction="row" gap="size-100">
          <CreateContextFields setting={map.get(ContextFieldsMapping.PERSON.PHONE)} setValue={setFormData} />
          <CreateContextFields setting={map.get(ContextFieldsMapping.PERSON.SALUTATION)} setValue={setFormData} />
        </Flex>
        <Flex direction="row" gap="size-100">
          <CreateContextFields setting={map.get(ContextFieldsMapping.PERSON.COUNTRY)} setValue={setFormData} />
          <CreateContextFields setting={map.get(ContextFieldsMapping.PERSON.PREFERRED_LANGUAGE)} setValue={setFormData} />
        </Flex>
      </Flex>);
}

export function CreateEmployeeFields({ settings, setFormData }:SectionFieldsProps) {
  
    let keys = [
      ContextFieldsMapping.EMPLOYEE.PANEL, 
      ContextFieldsMapping.EMPLOYEE.POSITION, 
      ContextFieldsMapping.EMPLOYEE.POSITION_NAME, 
      ContextFieldsMapping.EMPLOYEE.DEPARTMENT
    ];

    let map = generateMap(keys, settings);

    return (
    <Flex direction="column" gap="size-100" width={'100%'}>
      <Flex direction="row" gap="size-100" width={'100%'}>
        <CreateContextFields setting={map.get(ContextFieldsMapping.EMPLOYEE.PANEL)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.EMPLOYEE.POSITION)} setValue={setFormData} />
      </Flex>
      <Flex direction="row" gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.EMPLOYEE.POSITION_NAME)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.EMPLOYEE.DEPARTMENT)} setValue={setFormData} />
      </Flex>
    </Flex>);
}

export function CreateSimpleFolderFields({ settings, setFormData }:SectionFieldsProps) {
  
    let keys = [
      ContextFieldsMapping.SIMPLE_FOLDER.REF_ID, 
      ContextFieldsMapping.SIMPLE_FOLDER.TRACKING_CATEGORY_ONE, 
      ContextFieldsMapping.SIMPLE_FOLDER.TRACKING_CATEGORY_TWO, 
      ContextFieldsMapping.SIMPLE_FOLDER.TRACKING_CATEGORY_THREE,
      ContextFieldsMapping.SIMPLE_FOLDER.DESCRIPTION,
    ];

    let map = generateMap(keys, settings);

    return (
    <Flex direction="column" gap="size-100" width={'100%'}>
      <Flex direction="row" gap="size-100" width={'100%'}>
        <CreateContextFields setting={map.get(ContextFieldsMapping.SIMPLE_FOLDER.REF_ID)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.SIMPLE_FOLDER.TRACKING_CATEGORY_ONE)} setValue={setFormData} />
      </Flex>
      <Flex direction="row" gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.SIMPLE_FOLDER.TRACKING_CATEGORY_TWO)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.SIMPLE_FOLDER.TRACKING_CATEGORY_THREE)} setValue={setFormData} />
      </Flex>
      <Flex direction="row" gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.SIMPLE_FOLDER.DESCRIPTION)} setValue={setFormData} />
      </Flex>
    </Flex>);
}

export function CreateProjectFields({ settings, setFormData }:SectionFieldsProps) {
  
    let keys = [
      ContextFieldsMapping.PROJECT.DESCRIPTION, 
      ContextFieldsMapping.PROJECT.FOLDER_TYPE_NAME, 
      ContextFieldsMapping.PROJECT.SUB_FOLDER_TYPE_NAME, 
      ContextFieldsMapping.PROJECT.TRACKING_CATEGORY_ONE,
      ContextFieldsMapping.PROJECT.TRACKING_CATEGORY_TWO,
      ContextFieldsMapping.PROJECT.TRACKING_CATEGORY_THREE,
      ContextFieldsMapping.PROJECT.NAME,
      ContextFieldsMapping.PROJECT.REF_ID,
      ContextFieldsMapping.PROJECT.CONTACT,
    ];

    let map = generateMap(keys, settings);

    var project_name = {
        id:          0,
        colname:     'name1',
        visibility:  1,
        settingsId:  4,
        mandatory:   1,
        defaultval:  '',
        coltype:     '',
        otherparams: '',
        caption:     'Name',
        itemid:      0,
        name:        'project.name1',
        itemname:    'project.name1',
    };

    map.set(ContextFieldsMapping.PROJECT.NAME, project_name);

    return (
    <Flex direction="column" gap="size-100" width={'100%'}>
      <Flex direction="row" gap="size-100" width={'100%'}>
        <CreateContextFields setting={map.get(ContextFieldsMapping.PROJECT.NAME)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.PROJECT.DESCRIPTION)} setValue={setFormData} />
      </Flex>
      <Flex direction="row" gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.PROJECT.FOLDER_TYPE_NAME)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.PROJECT.SUB_FOLDER_TYPE_NAME)} setValue={setFormData} />
      </Flex>
      <Flex direction="row" gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.PROJECT.TRACKING_CATEGORY_ONE)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.PROJECT.TRACKING_CATEGORY_TWO)} setValue={setFormData} />
      </Flex>
      <Flex direction="row" gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.PROJECT.TRACKING_CATEGORY_THREE)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.PROJECT.REF_ID)} setValue={setFormData} />
      </Flex>
      <Flex direction="row" gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.PROJECT.CONTACT)} setValue={setFormData} />
      </Flex>  
    </Flex>);
}

export function CreateCustomFormFields({ settings }:SectionFieldsProps) {
  
    let keys = [
      ContextFieldsMapping.CUSTOM_FORM
    ];

    let map = generateMap(keys, settings);
    if(map.size == 0) return null;

    return (
    <View UNSAFE_style={{color:'red'}}>Custom Form Goes here</View>);
}

export function CreateTrackingCodeFields({ settings }:SectionFieldsProps) {
  
    let keys = [
      ContextFieldsMapping.TRACKING_CODE
    ];

    let map = generateMap(keys, settings);
    if(map.size == 0) return null;

    return (
    <View UNSAFE_style={{color:'red'}}>Tracking Code Goes here</View>);
}

export function CreateResponsibleUserFields({ settings }:SectionFieldsProps) {
  
    let keys = [
      ContextFieldsMapping.RESPONSIBLE_USER
    ];

    let map = generateMap(keys, settings);
    if(map.size == 0) return null;

    return (
    <View UNSAFE_style={{color:'red'}}>Responsible User Goes here</View>);
}

export function CreateSalesItemsFields({ settings }:SectionFieldsProps) {
  
    let keys = [
      ContextFieldsMapping.SALES_ITEMS
    ];

    let map = generateMap(keys, settings);
    if(map.size == 0) return null;

    return (
    <View UNSAFE_style={{color:'red'}}>Sales Items Goes here</View>);
}

export function CreateBillingItemsFields({ settings }:SectionFieldsProps) {
  
    let keys = [
      ContextFieldsMapping.BILLING_ITEMS
    ];

    let map = generateMap(keys, settings);
    if(map.size == 0) return null;

    return (
    <View UNSAFE_style={{color:'red'}}>Billing Items Goes here</View>);
}

export function CreateTechnologyItemsFields({ settings }:SectionFieldsProps) {
  
    let keys = [
      ContextFieldsMapping.TECHNOLOGY_ITEMS
    ];

    let map = generateMap(keys, settings);
    if(map.size == 0) return null;

    return (
    <View UNSAFE_style={{color:'red'}}>Technology Items Goes here</View>);
}

export function CreateActivityFields({ settings, setFormData }:SectionFieldsProps) {
    let keys = [
      ContextFieldsMapping.ACTIVITY.DESCRIPTION, 
      ContextFieldsMapping.ACTIVITY.FOLDER_TYPE_NAME, 
      ContextFieldsMapping.ACTIVITY.SUB_FOLDER_TYPE_NAME, 
      ContextFieldsMapping.ACTIVITY.TRACKING_CATEGORY_ONE,
      ContextFieldsMapping.ACTIVITY.TRACKING_CATEGORY_TWO,
      ContextFieldsMapping.ACTIVITY.TRACKING_CATEGORY_THREE,
      ContextFieldsMapping.ACTIVITY.NAME,
      ContextFieldsMapping.ACTIVITY.REF_ID,
      ContextFieldsMapping.ACTIVITY.CONTACT,
    ];

    let map = generateMap(keys, settings);
    if(map.size == 0) return null;

    var activity_name = {
        id:          0,
        colname:     'name1',
        visibility:  1,
        settingsId:  4,
        mandatory:   1,
        defaultval:  '',
        coltype:     '',
        otherparams: '',
        caption:     'Name',
        itemid:      0,
        name:        'activity.name1',
        itemname:    'activity.name1',
    };

    map.set(ContextFieldsMapping.ACTIVITY.NAME, activity_name);

    return (
    <Flex direction="column" gap="size-100" width={'100%'}>
      <Flex direction="row" gap="size-100" width={'100%'}>
        <CreateContextFields setting={map.get(ContextFieldsMapping.ACTIVITY.NAME)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.ACTIVITY.DESCRIPTION)} setValue={setFormData} />
      </Flex>
      <Flex direction="row" gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.ACTIVITY.FOLDER_TYPE_NAME)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.ACTIVITY.SUB_FOLDER_TYPE_NAME)} setValue={setFormData} />
      </Flex>
      <Flex direction="row" gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.ACTIVITY.TRACKING_CATEGORY_ONE)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.ACTIVITY.TRACKING_CATEGORY_TWO)} setValue={setFormData} />
      </Flex>
      <Flex direction="row" gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.ACTIVITY.TRACKING_CATEGORY_THREE)} setValue={setFormData} />
        <CreateContextFields setting={map.get(ContextFieldsMapping.ACTIVITY.REF_ID)} setValue={setFormData} />
      </Flex>
      <Flex direction="row" gap="size-100">
        <CreateContextFields setting={map.get(ContextFieldsMapping.ACTIVITY.CONTACT)} setValue={setFormData} />
      </Flex>
    </Flex>);
}