import { InPlaceSearchResultItem } from './InPlaceSearchResultItem';
import { FPAData } from '../../../../../infra/protected/FPA/FPAData';

export interface IInPlaceSearchResultsProps {
    items: FPAData[];
    onSearchSelected: (item:any) => void;
    selectedIndex: number;
}

export function InPlaceSearchResults ({
    items,
    onSearchSelected,
    selectedIndex
}: IInPlaceSearchResultsProps) {
  const handleSearchSelected = (item:any) => {
    onSearchSelected(item);
  }
  return (
    <>
      {items.map((item, index) =>(
        <InPlaceSearchResultItem 
            key={index}
            image={item.image} 
            title={item.title} 
            subtitle={item.line1} 
            description={item.line2}
            contextType={item.type}
            contextId={item.id}
            stateName={item.statusName}
            stateColor={item.statusColor}
            onSearchSelected={handleSearchSelected}
            isSelected={selectedIndex === index}
            item={item}
            />
      ))}
    </>
  );
}
