import axios from "axios";

export interface IBaseRestRequest {
    server: string;
    session: string;
    cancelSource: any;
}
export class BaseRestRequest implements IBaseRestRequest {
    public server: string;
    public session: string;
    public cancelSource: any;

    constructor(_server: string, _session: string) {
        this.server = _server;
        this.session = _session;
        this.cancelSource = axios.CancelToken.source();
    }
}