import { SettingItem } from '../../../services/soap/features/responses/GetFormSettingsResponse';
import { Divider, Flex, TextField, View } from '@adobe/react-spectrum';
import { CreateActivityFields, CreateBillingItemsFields, CreateContextFields, CreatePersonFields, CreateResponsibleUserFields, CreateTrackingCodeFields } from './ContextFields';
import { FPATemplateItem } from '../../../infra/protected/FPA/FPATemplateItem';

export interface IActivityContextFieldsProps {
    settings: SettingItem[];
    selectedItem?: FPATemplateItem;
}

export function ActivityContextFields ({
    settings,
    selectedItem
}: IActivityContextFieldsProps) {
  return (
    <Flex direction={'column'} gap={'size-100'}>
        <CreateActivityFields settings={settings} selectedItem={selectedItem} />
        <Divider size='M' />
        <CreateTrackingCodeFields settings={settings} selectedItem={selectedItem} />
        <Divider size='M' />
        <CreatePersonFields settings={settings} selectedItem={selectedItem} />
        <Divider size='M' />
        <CreateResponsibleUserFields settings={settings} selectedItem={selectedItem} />
        <Divider size='M' />
        <CreateBillingItemsFields settings={settings} selectedItem={selectedItem} />
    </Flex>    
  );
}
