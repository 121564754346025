import { SearchResultItemData } from "../infra/protected/Search/SearchResultData";

export interface GroupedSearchResultItemData {
  title: string;
  items: SearchResultItemData[];
}

export function processHistoryData(
  data: any[],
  folderStatusMap: any,
  projectStatusMap: any,
  activityStatusMap: any
): any[] {
  const items: SearchResultItemData[] = [];
  var color = '';
  var padding = '';

  data.forEach((item: any, index: number) => {
    var itemData: SearchResultItemData = {
      html_id: `item_${index}`,
      image: "/assets/icons/group-402.svg",
      title: "",
      subtitle: "",
      link: "",
      contextTypeId: 0,
      contextTypeName: "",
      itemType: item.CONTEXT.activityName
        ? "ACTIVITY"
        : item.CONTEXT.projectName
        ? "PROJECT"
        : item.CONTEXT.folderName
        ? "FOLDER"
        : "",
      itemId: item.CONTEXT.activityName
        ? item.CONTEXT.activityId
        : item.CONTEXT.projectName
        ? item.CONTEXT.projectId
        : item.CONTEXT.folderName
        ? item.CONTEXT.folderId
        : 0,
      isFavorite: false,
      stateColor: "#4F4F4F",
      breadcrumbs: [],
      count: 0
    };
    if (item.CONTEXT.activityName) {
      itemData.title = item.CONTEXT.activityName;
      itemData.subtitle = item.CONTEXT.activityRefId;
      itemData.link = "";
      itemData.image = "/assets/icons/group-4022.svg";
      itemData.isFavorite = item.CONTEXT.isFavourite === "1";
      itemData.breadcrumbs = [
        item.CONTEXT.folderName,
        item.CONTEXT.projectName,
      ];
      if (item.CONTEXT.activityCustomState in activityStatusMap) {
          itemData.stateName = activityStatusMap[item.CONTEXT.activityCustomState].name;
          color = (+activityStatusMap[item.CONTEXT.activityCustomState].color).toString(16).split('').reverse().join('');
          padding = color.length < 6 ? Array(6 - color.length).fill('0').join('') : '';
          itemData.stateColor = `#${color}${padding}`;
      } else {
          itemData.stateName = item.CONTEXT.activityCustomState;
      }
    } else if (item.CONTEXT.projectName) {
      itemData.title = item.CONTEXT.projectName;
      itemData.subtitle = item.CONTEXT.projectRefId;
      itemData.link = "";
      itemData.image = "/assets/icons/group-403.svg";
      itemData.isFavorite = item.CONTEXT.isFavourite === "1";
      itemData.breadcrumbs = [item.CONTEXT.folderName];
      if (item.CONTEXT.projectCustomState in projectStatusMap) {
        itemData.stateName =
          projectStatusMap[item.CONTEXT.projectCustomState].name;

          color = (+projectStatusMap[item.CONTEXT.projectCustomState].color).toString(16).split('').reverse().join('');
          padding = color.length < 6 ? Array(6 - color.length).fill('0').join('') : '';
          itemData.stateColor = `#${color}${padding}`;
      } else {
        itemData.stateName = item.CONTEXT.projectCustomState;
      }
    } else if (item.CONTEXT.folderName) {
      itemData.title = item.CONTEXT.folderName;
      itemData.subtitle = item.CONTEXT.folderRefId;
      itemData.link = "";
      itemData.image = "/assets/icons/group-402.svg";
      itemData.isFavorite = item.CONTEXT.isFavourite === "1";
      itemData.breadcrumbs = [];
      itemData.stateName = item.CONTEXT.folderCustomState;
      if (item.CONTEXT.folderCustomState in folderStatusMap) {
        itemData.stateName = folderStatusMap[item.CONTEXT.folderCustomState].name;
        color = (+folderStatusMap[item.CONTEXT.folderCustomState].color).toString(16).split('').reverse().join('');
        padding = color.length < 6 ? Array(6 - color.length).fill('0').join('') : '';
        itemData.stateColor = `#${color}${padding}`;
      } else {
        itemData.stateName = item.CONTEXT.folderCustomState;
      }
    }

    if (itemData.stateColor?.toUpperCase() === "#FFFFFF") {
      itemData.stateColor = "#EFEFEF";
    }
    items.push(itemData);
  });

  var groups: any[] = [
    {
      title: "",
      items: items,
    },
  ];

  return groups;
}

export function convertToSearchResultItemData(
  html_id: string,
  item: any,
  folderStatusMapping: any,
  projectStatusMapping: any,
  activityStatusMap: any
): SearchResultItemData {
  var itemData: SearchResultItemData = {
    html_id: html_id,
    image: "/assets/icons/group-402.svg",
    title: "",
    subtitle: "",
    link: "",
    contextTypeId: 0,
    contextTypeName: "",
    itemType: item.ITEM.FOLDER
      ? "FOLDER"
      : item.ITEM.PROJECT
      ? "PROJECT"
      : item.ITEM.ACTIVITY
      ? "ACTIVITY"
      : "",
    itemId: item.ITEM.FOLDER
      ? item.ITEM.FOLDER.id
      : item.ITEM.PROJECT
      ? item.ITEM.PROJECT.id
      : item.ITEM.ACTIVITY
      ? item.ITEM.ACTIVITY.id
      : 0,
    isFavorite: false,
    stateColor: "#4F4F4F",
    breadcrumbs: [],
    count: item.subitems
  };

  var color = '';
  var padding = '';

  if (item.ITEM.FOLDER) {
    itemData.title = item.ITEM.FOLDER.name;
    itemData.subtitle = item.ITEM.FOLDER.referenceId;
    itemData.link = "";
    itemData.contextTypeName = item.ITEM.FOLDER.folderTypeName;
    itemData.image = "/assets/icons/group-402.svg";
    itemData.isFavorite = item.ITEM.FOLDER.state === "1";
    itemData.stateName = item.ITEM.FOLDER.customStateName;
    itemData.breadcrumbs = [item.ITEM.FOLDER.contextParentName];

    if (folderStatusMapping[item.ITEM.FOLDER.customState]) {
      color = (+folderStatusMapping[item.ITEM.FOLDER.customState].color).toString(16).split('').reverse().join('');
      padding = color.length < 6 ? Array(6 - color.length).fill('0').join('') : '';
      itemData.stateColor = `#${color}${padding}`;
    }
  }
  if (item.ITEM.PROJECT) {
    itemData.title = item.ITEM.PROJECT.name;
    itemData.subtitle = item.ITEM.PROJECT.refId;
    itemData.link = "";
    itemData.contextTypeName = item.ITEM.PROJECT.projectTypeName;
    itemData.image = "/assets/icons/group-403.svg";
    itemData.isFavorite = item.ITEM.PROJECT.state === "1";
    itemData.stateName = item.ITEM.PROJECT.customStateName;
    itemData.breadcrumbs = [item.ITEM.PROJECT.contextParentName];

    if (projectStatusMapping[item.ITEM.PROJECT.customState]) {
      color = (+projectStatusMapping[item.ITEM.PROJECT.customState].color).toString(16).split('').reverse().join('');
      padding = color.length < 6 ? Array(6 - color.length).fill('0').join('') : '';
      itemData.stateColor = `#${color}${padding}`;
    }
  }
  if (item.ITEM.ACTIVITY) {
    itemData.title = item.ITEM.ACTIVITY.name;
    itemData.subtitle = item.ITEM.ACTIVITY.refId;
    itemData.link = "";
    itemData.contextTypeName = item.ITEM.ACTIVITY.activityTypeName;
    itemData.image = "/assets/icons/group-4022.svg";
    itemData.isFavorite = item.ITEM.ACTIVITY.state === "1";
    itemData.stateName = item.ITEM.ACTIVITY.customStateName;
    itemData.breadcrumbs = [item.ITEM.ACTIVITY.contextParentName];

    if (activityStatusMap[item.ITEM.ACTIVITY.customState]) {
      color = (+activityStatusMap[item.ITEM.ACTIVITY.customState].color).toString(16).split('').reverse().join('');
      padding = color.length < 6 ? Array(6 - color.length).fill('0').join('') : '';
      itemData.stateColor = `#${color}${padding}`;
    }
  }

  if (itemData.stateColor?.toUpperCase() === "#FFFFFF") {
    itemData.stateColor = "#EFEFEF";
  }
  if (itemData.stateColor === "#000000" || itemData.stateColor === "#000") {
    itemData.stateColor = "#4F4F4F";
  }
  return itemData;
}

export function processSearchData(
  data: any[] | any,
  folderStatusMap: any,
  projectStatusMap: any,
  activityStatusMap: any,
  makeGroups: boolean = true
): GroupedSearchResultItemData[] {
  const items: SearchResultItemData[] = [];

  if (!data) return [];
  if (!Array.isArray(data)) {
    items.push(
      convertToSearchResultItemData(
        "item_00",
        data,
        folderStatusMap,
        projectStatusMap,
        activityStatusMap
      )
    );
  } else
    data.forEach((item: any, index: number) => {
      items.push(
        convertToSearchResultItemData(
          `item_${index}`,
          item,
          folderStatusMap,
          projectStatusMap,
          activityStatusMap
        )
      );
    });

  
  if(!makeGroups) return [{ title: '', items }];
  
  var build_groups: any = items.reduce((r: any, a: any) => {
    r[a.contextTypeName] = [...(r[a.contextTypeName] || []), a];
    return r;
  }, {});

  var groups: any[] = Object.keys(build_groups).map((key: string) => ({
    title: key,
    items: build_groups[key],
  }));

  return groups;
}

export function mergeGroupedSearchResults(
  groupOne: GroupedSearchResultItemData[],
  groupTwo: GroupedSearchResultItemData[]
): GroupedSearchResultItemData[] {
  var groups: GroupedSearchResultItemData[] = groupOne.concat(groupTwo);

  var build_groups: any = groups.reduce((r: any, a: any) => {
    r[a.title] = [...(r[a.title] || []), ...a.items];
    return r;
  }, {});

  var result: GroupedSearchResultItemData[] = Object.keys(build_groups).map((key: string) => ({
    title: key,
    items: build_groups[key],
  }));

  result.sort((a, b) => a.title.localeCompare(b.title));
  return result;
}