import Dexie, {Table} from 'dexie';

export interface ApiData {
    id?: number;
    type: string;
    url: string;
    request?: string;
    response?: string;
}

export class ApiDataDB extends Dexie {
    public apiData!: Table<ApiData, number>;

    public constructor() {
        super('ApiDataDB');
        this.version(1).stores({
            apiData: '++id,type,url,request,response',
        });
    }
}

export const apiDataDB = new ApiDataDB();