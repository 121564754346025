import { ServiceActivities } from '../pages/protected/service-activities/ServiceActivities';

export const ListRegisterUtils = {
  Modules: {
    Finder: 'com.atollon.core.finder',
    RctDashboard: 'com.atollon.core.project',
    Project: 'com.atollon.core.project',
  },
  Keys: {
    Finder: {
      FinderSettings: 'FinderSettings',
    },
    RctDashboard: {
      FilterSettings: 'Reef.FilterSettings',
    },
    Project: {
      ProjectSettings: 'ProjectSettings',
      ServiceActivities: 'userServiceActivityColumns',
    },
  },
  Levels: {
    RctDashboard: {
      Default: 40,
    },
    Project: {
      Default: 40,
    },
  },
};
