import { SearchResultItemData } from '../../../../infra/protected/Search/SearchResultData';
import styles from './MobileSearchResultGroup.module.css';
import { MobileSearchResultItem } from './MobileSearchResultItem';

export interface IMobileSearchResultGroupProps {
    groupTitle: string;
    items: SearchResultItemData[];
    onSearchSelected: (item:any) => void;
}

export function MobileSearchResultGroup ({
    groupTitle,
    items,
    onSearchSelected,
}: IMobileSearchResultGroupProps) {
  return (
   <>
<div className={styles.groupTitle}>{groupTitle}</div>
        <div className={styles.groupContainer}>
            {items.map((item, index) => 
                <MobileSearchResultItem
                  html_id={item.html_id}
                  key={index}
                  image={item.image} 
                  title={item.title} 
                  subtitle={item.subtitle} 
                  link={item.link} 
                  breadcrumbs={item.breadcrumbs} 
                  onSearchSelected={onSearchSelected}
                  contextType={item.itemType}
                  contextId={item.itemId}
                  stateName={item.stateName}
                  stateColor={item.stateColor}
                  />
                                            )}
        </div>  
   </>
  );
}
