import styles from './Profile.module.css';
import { Button, Flex } from '@adobe/react-spectrum';

export interface IProfileProps {
    onLogout: () => void;
}

export function Profile ({
    onLogout
}: IProfileProps) {
  return (
    <>
        <Flex direction={'column'} justifyContent={'center'} alignContent={'center'}>
            <Button variant={'negative'} UNSAFE_className={styles.logoutButton} onPress={onLogout}>Logout</Button>
        </Flex>
    </>
  );
}
