import { ProjectItem } from './ProjectItem';

export function generateBillingItem(
  project: string,
  activity: string,
  part_number: string,
  product_name: string,
  quantity: number,
  unit_price: number,
  total_price: number,
  totalAmountWithVat: number,
  currency: string,
  vatValue: string,
  purchasePrice: string,
  u_item: ProjectItem = {}
): ProjectItem {
  let item: ProjectItem = {
    ...u_item,
    project: '' + project,
    activity: '' + activity,
    item: part_number,
    amount: '' + unit_price,
    catalogPrice: '' + unit_price,
    totalQuantity: '' + quantity,
    quantity: '' + quantity,
    currency: currency,
    purchaseCurrency: currency,
    totalAmount: '' + total_price,
    totalAmountWithVat: '' + totalAmountWithVat,
    name: product_name,
    vatValue: '' + vatValue,
    purchasePrice: purchasePrice,
    billingStatus: '1',
  };
  return item;
}

export function generateProjectProductItem(
  folder: string,
  project: string,
  activity: string,
  part_number: string,
  product_name: string,
  quantity: number,
  unit_price: number,
  total_price: number,
  totalAmountWithVat: number,
  currency: string,
  vatValue: string,
  purchasePrice: string,
  attrib0: string,
  attrib1: string,
  attrib2: string,
  supplier: string,
  refId: string,
  billingDate: string,
  u_item: ProjectItem = {}
): ProjectItem {
  let item: ProjectItem = {
    ...u_item,
    folder: '' + folder,
    project: '' + project,
    activity: '' + activity,
    item: part_number,
    amount: '' + unit_price,
    catalogPrice: '' + unit_price,
    totalQuantity: '' + quantity,
    quantity: '' + quantity,
    currency: currency,
    purchaseCurrency: currency,
    totalAmount: '' + total_price,
    totalAmountWithVat: '' + totalAmountWithVat,
    name: product_name,
    vatValue: '' + vatValue,
    purchasePrice: purchasePrice,
    billingStatus: '1',
    attrib0,
    attrib1,
    attrib2,
    supplier,
    refId,
    billingDate,
  };
  return item;
}
