import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListFolderTypeRequest extends IBaseRequest {
  canBeRoot: boolean;
}

export class ListFolderTypeRequest extends BaseRequest implements IListFolderTypeRequest {
  public canBeRoot: boolean;
  constructor(server: string, session: string, canBeRoot: boolean = false) {
    super(server, session);
    this.canBeRoot = canBeRoot;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    if (this.canBeRoot) parameters += `<canBeRoot>${this.canBeRoot}</canBeRoot>\n`;
    return super.wrapperXml('ListFolderType', parameters);
  }
}
