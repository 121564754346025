import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListFormSettingsRequest extends IBaseRequest {

}

export class ListFormSettingsRequest extends BaseRequest implements IListFormSettingsRequest {
  public toXml(): string {
    var parameters: string = super.toXml();

    return super.wrapperXml('ListFormSettings', parameters);
  }
}
