import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListProjectUserRequest extends IBaseRequest {
    project: number;
}

export class ListProjectUserRequest extends BaseRequest implements IListProjectUserRequest {
    public project: number;
  constructor(server: string, session: string, project: number) {
    super(server, session);
    this.project = project;
  }

  public toXml(): string {
    var parameters: string = super.toXml();
    parameters += `<project>${this.project}</project>`;
    return super.wrapperXml('ListProjectUser', parameters);
  }
}
