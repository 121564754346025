import { useCallback, useEffect, useState } from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { Checkbox, Content, Flex, Form, Link, ProgressCircle, TextField, View, DatePicker } from '@adobe/react-spectrum';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { ToastQueue } from '@react-spectrum/toast';
import async from 'async';
import moment from 'moment';
import { Provider } from '@adobe/react-spectrum';
import { parseDate } from '@internationalized/date';
import { useTranslation } from 'react-i18next';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { Row } from '../../../../services/soap/recruitment/responses/ListCandidateVacancyResponse';
import { FPAData } from '../../../../infra/protected/FPA/FPAData';
import { ListCandidateVacancyRequest } from '../../../../services/soap/recruitment/requests/ListCandidateVacancyRequest';
import { UpdateCandidateVacancyRequest } from '../../../../services/soap/recruitment/requests/UpdateCandidateVacancyRequest';
import { ListPlacementConfigurationRequest } from '../../../../services/soap/recruitment/requests/ListPlacementConfigurationRequest';
import { ListStatusRequest } from '../../../../services/soap/project/requests/ListStatusRequest';
import styles from './SelectionProcedureDetail.module.css';

export interface ISelectionProcedureDetailProps {
  selectedItem?: FPAData;
}

function SelectionProcedure({ selectedItem }: ISelectionProcedureDetailProps) {
  const { isMobile } = useViewInfo();
  const { t } = useTranslation();
  const { recruitmentService, projectService, store } = useDependency();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [candidateVacancy, setCandidateVacancy] = useState<any>({});
  const [vacancyFormLabels, setVacancyFormLabels] = useState<any>({});

  useEffect(() => {
    (async () => {
      setShowLoader(true);
      await getStatusArray();
      await handleCandidateVacancy(selectedItem);
      setShowLoader(false);
    })();
  }, [selectedItem]);

  const getStatusArray = async () => {
    let res: any = await projectService.listProjectStatus(new ListStatusRequest(store.Server, store.SessionId, 1, 'com.atollon.project.fpatypecategory.recruitment.placement'));
    if (res.result === 'OK' && res.STATUS_LIST.length > 0) {
      const projectStatusColor: any = res.STATUS_LIST.reduce((acc: any, val: any) => ({ ...acc, [val.STATUS.id]: { color: val.STATUS.color, name: val.STATUS.name } }), {});
      getFormLabelText(projectStatusColor);

      //console.log('projectStatusPriorty', projectStatusPriorty);
    }
  };

  //Get Vacancy Form Label
  const getFormLabelText = (projectStatusMapping: any) => {
    recruitmentService.listPlacementConfiguration(new ListPlacementConfigurationRequest(store.Server, store.SessionId)).then(async (res: any) => {
      if (res.result === 'OK') {
        //console.log('listPlacementConfiguration', res.ROWS[0].ROW);
        let placementConfigration = res.ROWS[0].ROW;

        const updatedPlacementConfigration: { [key: string]: any } = {};
        //console.log(placementConfigration);
        Object.keys(projectStatusMapping).length &&
          async.forEachOf(placementConfigration, (value: any, key: any) => {
            if (projectStatusMapping.hasOwnProperty(value)) {
              updatedPlacementConfigration[key] = projectStatusMapping[value].name;
            }
            //console.log(updatedPlacementConfigration);
            Object.keys(updatedPlacementConfigration).length && setVacancyFormLabels(updatedPlacementConfigration);
          });
      }
    });
  };

  const handleCandidateVacancy = useCallback(
    async (item: any) => {
      let res: any = await recruitmentService.listCandidateVacancy(new ListCandidateVacancyRequest(store.Server, store.SessionId, selectedItem!.id));
      if (res.result === 'OK') {
        let vacancyData = res.ROWS[0]?.ROW; // Access the first element of ROWS array and then access the ROW property
        //console.log('vacancyData', vacancyData);
        setCandidateVacancy(vacancyData);
      } else {
        //updateVacancyDialogState(false, false);
        ToastQueue.negative(res.EXCEPTION.reason, { timeout: 3000 });
      }
    },
    [selectedItem]
  );

  const handleChange = (name: string, value: string, dateFieldName: string) => {
    console.log(name, value, dateFieldName);
    //EMPTY THE DATE FILED IF UNCHCKED ElSE PUT CURRENT DATE
    if (dateFieldName.length && value) {
      setCandidateVacancy({
        ...candidateVacancy,
        [name]: value,
        [dateFieldName]: moment().format('YYYY-MM-DD'),
      });
    } else if (dateFieldName.length && !value) {
      setCandidateVacancy({
        ...candidateVacancy,
        [name]: value,
        [dateFieldName]: undefined,
      });
    } else {
      setCandidateVacancy({
        ...candidateVacancy,
        [name]: value,
      });
    }

    //console.log(candidateVacancy);
  };

  const handleDateChange = (name: any, value: any) => {
    //console.log(name, value);
    setCandidateVacancy({
      ...candidateVacancy,
      [name]: value ? value : '',
    });
    //console.log(candidateVacancy);
  };

  const handleSubmit = async () => {
    //console.log('Form submitted with data:', candidateVacancy);
    //REPLACE EMPTY FILED WITH UNDEFINED

    Object.keys(candidateVacancy).forEach(key => {
      if (candidateVacancy[key] === null || candidateVacancy[key] === '') {
        candidateVacancy[key] = undefined;
      }
    });

    //console.log('candidateVacancy', candidateVacancy);
    let candidateVacancyRow = candidateVacancy as Row;
    let result = await recruitmentService.updateCandidateVacancy(
      new UpdateCandidateVacancyRequest(
        store.Server,
        store.SessionId,
        candidateVacancyRow.id,
        new Date().getTime(),
        candidateVacancyRow.acl,
        candidateVacancyRow.candidate,
        candidateVacancyRow.client,
        candidateVacancyRow.priority,
        candidateVacancyRow.contacted,
        candidateVacancyRow.contactedDate,
        candidateVacancyRow.contactedNote,
        candidateVacancyRow.interested,
        candidateVacancyRow.interestedDate,
        candidateVacancyRow.interestedNote,
        candidateVacancyRow.CVProvidedToClient,
        candidateVacancyRow.CVProvidedToClientDate,
        candidateVacancyRow.CVProvidedToClientNote,
        candidateVacancyRow.interviewed,
        candidateVacancyRow.interviewedDate,
        candidateVacancyRow.interviewedNote,
        candidateVacancyRow.offered,
        candidateVacancyRow.offeredDate,
        candidateVacancyRow.offeredNote,
        candidateVacancyRow.accepted,
        candidateVacancyRow.acceptedDate,
        candidateVacancyRow.acceptedNote,
        candidateVacancyRow.rejected,
        candidateVacancyRow.rejectedDate,
        candidateVacancyRow.rejectedNote,
        candidateVacancyRow.name,
        candidateVacancyRow.visit1,
        candidateVacancyRow.visit1Date,
        candidateVacancyRow.visit1Note,
        candidateVacancyRow.visit2,
        candidateVacancyRow.visit2Date,
        candidateVacancyRow.visit2Note
      )
    );

    if (result.result === 'OK') {
      ToastQueue.positive(t('candidate_vacancy_updated_successfully', { ns: 'layout_components' }), { timeout: 1000 });
    }
  };

  const ContactActions = () => (
    <Flex UNSAFE_className={styles.button_container_parent}>
      {/* <Flex UNSAFE_className={styles.btn_cancel}>
        <Link isQuiet UNSAFE_className={styles.btn_cancel_text} onPress={() => console.log('cancel')}>
          {t('cancel', { ns: 'layout_components' })}
        </Link>
      </Flex> */}

      <Flex UNSAFE_className={styles.btn_add}>
        <Link isQuiet UNSAFE_className={styles.btn_add_text} onPress={handleSubmit}>
          {t('apply', { ns: 'layout_components' })}
        </Link>
      </Flex>
    </Flex>
  );

  const ContactButtons = () => (
    <Flex direction={'row'} justifyContent={'end'} gap={'size-100'}>
      <ContactActions />
    </Flex>
  );

  if (showLoader) {
    return (
      <Flex width="100%" minHeight={'50px'} justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} gap={'size-150'} position={'relative'} width={'100%'}>
        <Flex direction={'row'} alignItems={'start'} justifyContent={'start'}>
          <Content position={'relative'} UNSAFE_className={styles.heading_text}>
            {t('selection_procedure_details', { ns: 'layout_components' })}
          </Content>
          <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'size-100'} UNSAFE_className={styles.icon_add_parent}>
            <Content>&nbsp;</Content>
          </Flex>
        </Flex>
        <Flex direction={'column'}>
          {/* <Flex maxHeight={{ L: '450px', M: '450px' }} width="100%" direction="column" UNSAFE_style={{ overflowX: 'auto' }}> */}
          <Flex width={'100%'} direction={'column'} alignItems={'start'} justifyContent={'start'} gap={'16px'} position={'relative'} marginBottom={'20px'} UNSAFE_className={styles.signature_parent}>
            <Form onSubmit={handleSubmit} width={'100%'}>
              <Flex gap="size-100" direction={{ base: 'column', L: 'row' }}>
                <View width={{ L: '25%', M: '100%' }}>
                  <b>{vacancyFormLabels.placementPhase1}</b>
                </View>
                <View width={{ L: '5%', M: '100%' }}>
                  <Checkbox name="contacted" isSelected={candidateVacancy?.contacted == 1} onChange={(value: any) => handleChange('contacted', value, 'contactedDate')} />
                </View>
                <View width={{ L: '25%', M: '100%' }}>
                  <Provider locale="en-US">
                    <DatePicker
                      name="contactedDate"
                      isRequired
                      width={'100%'}
                      value={candidateVacancy?.contactedDate ? parseDate(moment(candidateVacancy?.contactedDate, 'YYYY-MM-DD').format('YYYY-MM-DD')) : undefined}
                      onChange={(value: any) => handleDateChange('contactedDate', value)}
                      UNSAFE_style={{ padding: '0' }}
                    />
                  </Provider>
                </View>
                <View width={{ L: '45%', M: '100%' }}>
                  <TextField name="contactedNote" width="100%" value={candidateVacancy?.contactedNote} onChange={(value: any) => handleChange('contactedNote', value, '')} />
                </View>
              </Flex>
              <Flex gap="size-100" direction={{ base: 'column', L: 'row' }}>
                <View width={{ L: '25%', M: '100%' }}>
                  <b>{vacancyFormLabels.placementPhase2}</b>
                </View>
                <View width={{ L: '5%', M: '100%' }}>
                  <Checkbox name="interested" isSelected={candidateVacancy?.interested == 1} onChange={(value: any) => handleChange('interested', value, 'interestedDate')} />
                </View>
                <View width={{ L: '25%', M: '100%' }}>
                  <Provider locale="en-US">
                    <DatePicker
                      name="interestedDate"
                      isRequired
                      granularity="day"
                      width={'100%'}
                      value={candidateVacancy?.interestedDate ? parseDate(moment(candidateVacancy?.interestedDate, 'YYYY-MM-DD').format('YYYY-MM-DD')) : undefined}
                      onChange={(value: any) => handleDateChange('interestedDate', value)}
                      UNSAFE_style={{ padding: '0' }}
                    />
                  </Provider>
                </View>
                <View width={{ L: '45%', M: '100%' }}>
                  <TextField name="interestedNote" width="100%" value={candidateVacancy?.interestedNote} onChange={(value: any) => handleChange('interestedNote', value, '')} />
                </View>
              </Flex>
              <Flex gap="size-100" direction={{ base: 'column', L: 'row' }}>
                <View width={{ L: '25%', M: '100%' }}>
                  <b>{vacancyFormLabels.placementPhase3}</b>
                </View>
                <View width={{ L: '5%', M: '100%' }}>
                  <Checkbox
                    name="CVProvidedToClient"
                    isSelected={candidateVacancy?.CVProvidedToClient == 1}
                    onChange={(value: any) => handleChange('CVProvidedToClient', value, 'CVProvidedToClientDate')}
                  />
                </View>
                <View width={{ L: '25%', M: '100%' }}>
                  <Provider locale="en-US">
                    <DatePicker
                      name="CVProvidedToClientDate"
                      isRequired
                      granularity="day"
                      width={'100%'}
                      value={candidateVacancy?.CVProvidedToClientDate ? parseDate(moment(candidateVacancy?.CVProvidedToClientDate, 'YYYY-MM-DD').format('YYYY-MM-DD')) : undefined}
                      onChange={(value: any) => handleDateChange('CVProvidedToClientDate', value)}
                      UNSAFE_style={{ padding: '0' }}
                    />
                  </Provider>
                </View>
                <View width={{ L: '45%', M: '100%' }}>
                  <TextField
                    name="CVProvidedToClientNote"
                    width="100%"
                    value={candidateVacancy?.CVProvidedToClientNote}
                    onChange={(value: string) => handleChange('CVProvidedToClientNote', value, '')}
                  />
                </View>
              </Flex>
              <Flex gap="size-100" direction={{ base: 'column', L: 'row' }}>
                <View width={{ L: '25%', M: '100%' }}>
                  <b>{vacancyFormLabels.placementPhase4}</b>
                </View>
                <View width={{ L: '5%', M: '100%' }}>
                  <Checkbox name="interviewed" isSelected={candidateVacancy?.interviewed == 1} onChange={(value: any) => handleChange('interviewed', value, 'interviewedDate')} />
                </View>
                <View width={{ L: '25%', M: '100%' }}>
                  <Provider locale="en-US">
                    <DatePicker
                      name="interviewedDate"
                      isRequired
                      granularity="day"
                      width={'100%'}
                      value={candidateVacancy?.interviewedDate ? parseDate(moment(candidateVacancy?.interviewedDate, 'YYYY-MM-DD').format('YYYY-MM-DD')) : undefined}
                      onChange={(value: any) => handleDateChange('interviewedDate', value)}
                      UNSAFE_style={{ padding: '0' }}
                    />
                  </Provider>
                </View>
                <View width={{ L: '45%', M: '100%' }}>
                  <TextField name="interviewedNote" width="100%" value={candidateVacancy?.interviewedNote} onChange={(value: any) => handleChange('interviewedNote', value, '')} />
                </View>
              </Flex>
              <Flex gap="size-100" direction={{ base: 'column', L: 'row' }}>
                <View width={{ L: '25%', M: '100%' }}>
                  <b>{vacancyFormLabels.placementPhase5}</b>
                </View>
                <View width={{ L: '5%', M: '100%' }}>
                  <Checkbox name="offered" isSelected={candidateVacancy?.offered == 1} onChange={(value: any) => handleChange('offered', value, 'offeredDate')} />
                </View>
                <View width={{ L: '25%', M: '100%' }}>
                  <Provider locale="en-US">
                    <DatePicker
                      name="offeredDate"
                      isRequired
                      granularity="day"
                      width={'100%'}
                      value={candidateVacancy?.offeredDate ? parseDate(moment(candidateVacancy?.offeredDate, 'YYYY-MM-DD').format('YYYY-MM-DD')) : undefined}
                      onChange={(value: any) => handleDateChange('offeredDate', value)}
                      UNSAFE_style={{ padding: '0' }}
                    />
                  </Provider>
                </View>
                <View width={{ L: '45%', M: '100%' }}>
                  <TextField name="offeredNote" width="100%" value={candidateVacancy?.offeredNote} onChange={(value: any) => handleChange('offeredNote', value, '')} />
                </View>
              </Flex>
              <Flex gap="size-100" direction={{ base: 'column', L: 'row' }}>
                <View width={{ L: '25%', M: '100%' }}>
                  <b>{vacancyFormLabels.placementPhase6}</b>
                </View>
                <View width={{ L: '5%', M: '100%' }}>
                  <Checkbox name="visit1" isSelected={candidateVacancy?.visit1 == 1} onChange={(value: any) => handleChange('visit1', value, 'visit1Date')} />
                </View>
                <View width={{ L: '25%', M: '100%' }}>
                  <Provider locale="en-US">
                    <DatePicker
                      name="visit1Date"
                      isRequired
                      granularity="day"
                      width={'100%'}
                      value={candidateVacancy?.visit1Date ? parseDate(moment(candidateVacancy?.visit1Date, 'YYYY-MM-DD').format('YYYY-MM-DD')) : undefined}
                      onChange={(value: any) => handleDateChange('visit1Date', value)}
                      UNSAFE_style={{ padding: '0' }}
                    />
                  </Provider>
                </View>
                <View width={{ L: '45%', M: '100%' }}>
                  <TextField name="visit1Note" width="100%" value={candidateVacancy?.visit1Note} onChange={(value: any) => handleChange('visit1Note', value, '')} />
                </View>
              </Flex>
              <Flex gap="size-100" direction={{ base: 'column', L: 'row' }}>
                <View width={{ L: '25%', M: '100%' }}>
                  <b>{vacancyFormLabels.placementPhase7}</b>
                </View>
                <View width={{ L: '5%', M: '100%' }}>
                  <Checkbox name="visit2" isSelected={candidateVacancy?.visit2 == 1} onChange={(value: any) => handleChange('visit2', value, 'visit2Date')} />
                </View>
                <View width={{ L: '25%', M: '100%' }}>
                  <Provider locale="en-US">
                    <DatePicker
                      name="visit2Date"
                      isRequired
                      granularity="day"
                      width={'100%'}
                      value={candidateVacancy?.visit2Date ? parseDate(moment(candidateVacancy?.visit2Date, 'YYYY-MM-DD').format('YYYY-MM-DD')) : undefined}
                      onChange={(value: any) => handleDateChange('visit2Date', value)}
                      UNSAFE_style={{ padding: '0' }}
                    />
                  </Provider>
                </View>
                <View width={{ L: '45%', M: '100%' }}>
                  <TextField name="visit2Note" width="100%" value={candidateVacancy?.visit2Note} onChange={(value: any) => handleChange('visit2Note', value, '')} />
                </View>
              </Flex>
              <Flex gap="size-100" direction={{ base: 'column', L: 'row' }}>
                <View width={{ L: '25%', M: '100%' }}>
                  <b>{vacancyFormLabels.placementPhaseAccepted}</b>
                </View>
                <View width={{ L: '5%', M: '100%' }}>
                  <Checkbox name="accepted" isSelected={candidateVacancy?.accepted == 1} onChange={(value: any) => handleChange('accepted', value, 'acceptedDate')} />
                </View>
                <View width={{ L: '25%', M: '100%' }}>
                  <Provider locale="en-US">
                    <DatePicker
                      name="acceptedDate"
                      isRequired
                      granularity="day"
                      width={'100%'}
                      value={candidateVacancy?.acceptedDate ? parseDate(moment(candidateVacancy?.acceptedDate, 'YYYY-MM-DD').format('YYYY-MM-DD')) : undefined}
                      onChange={(value: any) => handleDateChange('acceptedDate', value)}
                      UNSAFE_style={{ padding: '0' }}
                    />
                  </Provider>
                </View>
                <View width={{ L: '45%', M: '100%' }}>
                  <TextField name="acceptedNote" width="100%" value={candidateVacancy?.acceptedNote} onChange={(value: any) => handleChange('acceptedNote', value, '')} />
                </View>
              </Flex>
              <Flex gap="size-100" direction={{ base: 'column', L: 'row' }}>
                <View width={{ L: '25%', M: '100%' }}>
                  <b>{vacancyFormLabels.placementPhaseRejected}</b>
                </View>
                <View width={{ L: '5%', M: '100%' }}>
                  <Checkbox name="rejected" isSelected={candidateVacancy?.rejected == 1} onChange={(value: any) => handleChange('rejected', value, 'rejectedDate')} />
                </View>
                <View width={{ L: '25%', M: '100%' }}>
                  <Provider locale="en-US">
                    <DatePicker
                      name="rejectedDate"
                      isRequired
                      granularity="day"
                      width={'100%'}
                      value={candidateVacancy?.rejectedDate ? parseDate(moment(candidateVacancy?.rejectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD')) : undefined}
                      onChange={(value: any) => handleDateChange('rejectedDate', value)}
                      UNSAFE_style={{ padding: '0' }}
                    />
                  </Provider>
                </View>
                <View width={{ L: '45%', M: '100%' }}>
                  <TextField name="rejectedNote" width="100%" value={candidateVacancy?.rejectedNote} onChange={(value: any) => handleChange('rejectedNote', value, '')} />
                </View>
              </Flex>
              <Flex gap="size-100" direction={{ base: 'column', L: 'row' }}>
                <View width="100%" marginBottom={isMobile ? 'size-1000' : '0'}>
                  <ContactButtons />
                </View>
              </Flex>
            </Form>
          </Flex>
          {/* </Flex> */}
        </Flex>
      </Flex>
    );
  }
}

export const SelectionProcedureDetail = LayoutComponent(SelectionProcedure);
