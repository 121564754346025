import { BaseRequest } from '../../BaseRequest';

export class UpdateCandidateVacancyRequest extends BaseRequest {
  id: number;
  timestamp?: number;
  acl?: string;
  candidate?: number;
  client?: number;
  priority?: number;
  contacted?: number;
  contactedDate?: Date;
  contactedNote?: string;
  interested?: number;
  interestedDate?: Date;
  interestedNote?: string;
  CVProvidedToClient?: number;
  CVProvidedToClientDate?: Date;
  CVProvidedToClientNote?: string;
  interviewed?: number;
  interviewedDate?: Date;
  interviewedNote?: string;
  offered?: number;
  offeredDate?: Date;
  offeredNote?: string;
  accepted?: number;
  acceptedDate?: Date;
  acceptedNote?: string;
  rejected?: number;
  rejectedDate?: Date;
  rejectedNote?: string;
  name?: string;
  visit1?: number;
  visit1Date?: Date;
  visit1Note?: string;
  visit2?: number;
  visit2Date?: Date;
  visit2Note?: string;

  constructor(
    server: string,
    sessionId: string,
    id: number,
    timestamp?: number,
    acl?: string,
    candidate?: number,
    client?: number,
    priority?: number,
    contacted?: number,
    contactedDate?: Date,
    contactedNote?: string,
    interested?: number,
    interestedDate?: Date,
    interestedNote?: string,
    CVProvidedToClient?: number,
    CVProvidedToClientDate?: Date,
    CVProvidedToClientNote?: string,
    interviewed?: number,
    interviewedDate?: Date,
    interviewedNote?: string,
    offered?: number,
    offeredDate?: Date,
    offeredNote?: string,
    accepted?: number,
    acceptedDate?: Date,
    acceptedNote?: string,
    rejected?: number,
    rejectedDate?: Date,
    rejectedNote?: string,
    name?: string,
    visit1?: number,
    visit1Date?: Date,
    visit1Note?: string,
    visit2?: number,
    visit2Date?: Date,
    visit2Note?: string
  ) {
    super(server, sessionId);
    this.id = id;
    this.timestamp = timestamp;
    this.acl = acl;
    this.candidate = candidate;
    this.client = client;
    this.priority = priority;
    this.contacted = contacted;
    this.contactedDate = contactedDate;
    this.contactedNote = contactedNote;
    this.interested = interested;
    this.interestedDate = interestedDate;
    this.interestedNote = interestedNote;
    this.CVProvidedToClient = CVProvidedToClient;
    this.CVProvidedToClientDate = CVProvidedToClientDate;
    this.CVProvidedToClientNote = CVProvidedToClientNote;
    this.interviewed = interviewed;
    this.interviewedDate = interviewedDate;
    this.interviewedNote = interviewedNote;
    this.offered = offered;
    this.offeredDate = offeredDate;
    this.offeredNote = offeredNote;
    this.accepted = accepted;
    this.acceptedDate = acceptedDate;
    this.acceptedNote = acceptedNote;
    this.rejected = rejected;
    this.rejectedDate = rejectedDate;
    this.rejectedNote = rejectedNote;
    this.name = name;
    this.visit1 = visit1;
    this.visit1Date = visit1Date;
    this.visit1Note = visit1Note;
    this.visit2 = visit2;
    this.visit2Date = visit2Date;
    this.visit2Note = visit2Note;
  }

  override toXml(): string {
    var parameters = super.toXml() + `<id>${this.id}</id>`;
    if (this.timestamp) parameters += `<timestamp>${this.timestamp}</timestamp>`;
    if (this.acl) parameters += `<acl>${this.acl}</acl>`;
    if (this.candidate) parameters += `<candidate>${this.candidate}</candidate>`;
    if (this.client) parameters += `<client>${this.client}</client>`;
    if (this.priority) parameters += `<priority>${this.priority}</priority>`;
    if (this.contacted) parameters += `<contacted>${this.contacted}</contacted>`;
    if (this.contactedDate) parameters += `<contactedDate>${this.contactedDate}</contactedDate>`;
    if (this.contactedNote) parameters += `<contactedNote>${this.contactedNote}</contactedNote>`;
    if (this.interested) parameters += `<interested>${this.interested}</interested>`;
    if (this.interestedDate) parameters += `<interestedDate>${this.interestedDate}</interestedDate>`;
    if (this.interestedNote) parameters += `<interestedNote>${this.interestedNote}</interestedNote>`;
    if (this.CVProvidedToClient) parameters += `<CVProvidedToClient>${this.CVProvidedToClient}</CVProvidedToClient>`;
    if (this.CVProvidedToClientDate) parameters += `<CVProvidedToClientDate>${this.CVProvidedToClientDate}</CVProvidedToClientDate>`;
    if (this.CVProvidedToClientNote) parameters += `<CVProvidedToClientNote>${this.CVProvidedToClientNote}</CVProvidedToClientNote>`;
    if (this.interviewed) parameters += `<interviewed>${this.interviewed}</interviewed>`;
    if (this.interviewedDate) parameters += `<interviewedDate>${this.interviewedDate}</interviewedDate>`;
    if (this.interviewedNote) parameters += `<interviewedNote>${this.interviewedNote}</interviewedNote>`;
    if (this.offered) parameters += `<offered>${this.offered}</offered>`;
    if (this.offeredDate) parameters += `<offeredDate>${this.offeredDate}</offeredDate>`;
    if (this.offeredNote) parameters += `<offeredNote>${this.offeredNote}</offeredNote>`;
    if (this.accepted) parameters += `<accepted>${this.accepted}</accepted>`;
    if (this.acceptedDate) parameters += `<acceptedDate>${this.acceptedDate}</acceptedDate>`;
    if (this.acceptedNote) parameters += `<acceptedNote>${this.acceptedNote}</acceptedNote>`;
    if (this.rejected) parameters += `<rejected>${this.rejected}</rejected>`;
    if (this.rejectedDate) parameters += `<rejectedDate>${this.rejectedDate}</rejectedDate>`;
    if (this.rejectedNote) parameters += `<rejectedNote>${this.rejectedNote}</rejectedNote>`;
    if (this.name) parameters += `<name>${this.name}</name>`;
    if (this.visit1) parameters += `<visit1>${this.visit1}</visit1>`;
    if (this.visit1Date) parameters += `<visit1Date>${this.visit1Date}</visit1Date>`;
    if (this.visit1Note) parameters += `<visit1Note>${this.visit1Note}</visit1Note>`;
    if (this.visit2) parameters += `<visit2>${this.visit2}</visit2>`;
    if (this.visit2Date) parameters += `<visit2Date>${this.visit2Date}</visit2Date>`;
    if (this.visit2Note) parameters += `<visit2Note>${this.visit2Note}</visit2Note>`;

    return super.wrapperXml('UpdateCandidateVacancy', parameters);
  }
}
