import { useDependency } from "../../../../contexts/DependencyProvider";
import { GetContextPathRequest } from "../../../../services/soap/project/requests/GetContextPathRequest";
import { ListFPARequest2 } from "../../../../services/soap/project/requests/ListFPARequest2";


export interface ListItem{
    id: number;
    name: string;
    type: string;
}

export function useServiceActivityHeaderComponent(){
    const { store, projectService } = useDependency();

    const getSuppliersList = async (searchText: string):Promise<ListItem[]> => {
        var list = await projectService.listFPA2(new ListFPARequest2(
            store.Server, 
            store.SessionId,
            {
                limit: 50,
                offset: 0,
                flatView: true,
                fulltext: searchText+'*',
                folderFilter: {
                    COL: [{
                        name: 'fpaTypeCategories',
                        type: 'any',
                        value: 'com.atollon.project.fpatypecategory.organization'
                    }]
                },
                ORDER: {
                    COL:[
                    {
                        name: 'name',
                        desc: false
                    },
                    {
                        name: 'created',
                        desc: true
                    }]
                }                
            }
        ));

        if(list.count == 0) return [];
        
        return list.ITEMS.map((item: any) => {
            var type: string = item.ITEM.FOLDER ? 'FOLDER' : item.ITEM.PROJECT ? 'PROJECT' : 'ACTIVITY';
            var inner_item = item.ITEM.FOLDER || item.ITEM.PROJECT || item.ITEM.ACTIVITY;
            return {
                id: inner_item.id,
                name: inner_item.name,
                type: type
            }
        });
    }

    const getInvoiceToList = async (searchText: string):Promise<ListItem[]> => {
        var list = await projectService.listFPA2(new ListFPARequest2(
            store.Server, 
            store.SessionId, 
            {
                limit: 50,
                offset: 0,
                flatView: true,
                fulltext: searchText+'*',
                folderFilter: {
                    COL:[
                    {
                        name: 'fpaTypeCategories',
                        type: 'any',
                        value: 'com.atollon.project.fpatypecategory.client'
                    }
                ]},
                ORDER: {
                    COL:[
                    {
                        name: 'name',
                        desc: false
                    },
                    {
                        name: 'created',
                        desc: true
                    }]
                }                
            }
        ));

        if(list.count == 0) return [];
        
        return list.ITEMS.map((item: any) => {
            var type: string = item.ITEM.FOLDER ? 'FOLDER' : item.ITEM.PROJECT ? 'PROJECT' : 'ACTIVITY';
            var inner_item = item.ITEM.FOLDER || item.ITEM.PROJECT || item.ITEM.ACTIVITY;
            return {
                id: inner_item.id,
                name: inner_item.name,
                type: type
            }
       });
    }

    const getContext = async (id: number) => {
        var data = await projectService.getContextPath(new GetContextPathRequest(store.Server, store.SessionId, id));
        return data.LIST[0].CONTEXT;
    }
    return { getSuppliersList, getInvoiceToList, getContext };
}