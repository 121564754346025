import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export class ItemValue{
    public id? : number;
    public acl? : string;
    public formInfo?: number;
    public formItem?: number;
    public formItemName?: string;
    public value?: string;
    public context?: string;
    public typ?: string;

    constructor(id?: number, acl?: string, formInfo?: number, formItem?: number, formItemName?: string, value?: string, typ?: string, context?: string){
        this.id = id;
        this.acl = acl;
        this.formInfo = formInfo;
        this.formItem = formItem;
        this.formItemName = formItemName;
        this.value = value;
        this.context = context;
        this.typ = typ;
    }

    public toXml(): string {
        var xml: string = '';
        if(this.id) xml += '<id>' + this.id + '</id>';
        if(this.acl) xml += '<acl>' + this.acl + '</acl>';
        if(this.formInfo) xml += '<formInfo>' + this.formInfo + '</formInfo>';
        if(this.formItem) xml += '<formItem>' + this.formItem + '</formItem>';
        if(this.formItemName) xml += '<formItemName>' + this.formItemName + '</formItemName>';
        if(this.value) xml += '<value>' + this.value + '</value>';
        if(this.context) xml += '<context>' + this.context + '</context>';
        if(this.typ) xml += '<typ>' + this.typ + '</typ>';
        return `<ITEMVALUE>${xml}</ITEMVALUE>`;
    }
}

export interface IUpdateItemValueRequest extends IBaseRequest {
    ITEMVALUE? : ItemValue[];
}

export class UpdateItemValueRequest extends BaseRequest implements IUpdateItemValueRequest {

    public ITEMVALUE?: ItemValue[] = [];
    constructor(server: string, session: string, itemValue?: ItemValue[]) {
        super(server, session);
        this.ITEMVALUE = itemValue;
    }

  public toXml(): string {
    var parameters: string = super.toXml();
    if (this.ITEMVALUE) {
      this.ITEMVALUE.forEach((itemValue: ItemValue) => {
        parameters += itemValue.toXml();
      });
    }
    return super.wrapperXml('UpdateItemValue', parameters);
  }
}
