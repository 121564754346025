import { useCallback, useEffect, useState } from "react";
import { useDependency } from "../contexts/DependencyProvider";
import { OneTimeStorage } from "../services/LocalStorage/OneTimeStorage";
import { ListStatusRequest, ListStatusType } from "../services/soap/project/requests/ListStatusRequest";
import { ListFolderTypeRequest } from "../services/soap/project/requests/ListFolderTypeRequest";
import { ListProjectTypeRequest } from "../services/soap/project/requests/ListProjectTypeRequest";
import { ListRegisterRequest } from "../services/soap/features/requests/ListRegisterRequest";
import { ListRegisterUtils } from "../utils/ListRegisterUtils";
import { ListFormSettingsRequest } from "../services/soap/features/requests/ListFormSettingsRequest";

export const CACHE_KEYS = {
    folder_status: 'folder_status', 
    project_status: 'project_status', 
    activity_status: 'activity_status', 
    folder_types: 'folder_types',
    project_types: 'project_types',
    form_settings: 'form_settings',
    registers: 'registers',
};

export async function saveToCache(key:string, value:any){
    await OneTimeStorage.getInstance().set(key, JSON.stringify(value));
}
export function usePreloadAssets(){
    const [projectStatusMapping, setProjectStatusMapping] = useState({});
    const [folderStatusMapping, setFolderStatusMapping] = useState({});
    const [activityStatusMapping, setActivityStatusMapping] = useState({});
    const [folderTypes, setFolderTypes] = useState<any[]>([]);
    const [projectTypes, setProjectTypes] = useState<any[]>([]);
    const [formSettings, setFormSettings] = useState<any>({});
    const [registers, setRegisters] = useState<{[key:string]:any}>({});

    const { projectService, featureService, store } = useDependency();

    const loadStatusesCallback = useCallback( async () => {
        const register_calls = [
            { moduleId: ListRegisterUtils.Modules.Finder, key: ListRegisterUtils.Keys.Finder.FinderSettings, level: 40 },
        ];

        var folder_status = await OneTimeStorage.getInstance().get(CACHE_KEYS.folder_status);
        var project_status = await OneTimeStorage.getInstance().get(CACHE_KEYS.project_status);
        var activity_status = await OneTimeStorage.getInstance().get(CACHE_KEYS.activity_status);
        var folder_types = await OneTimeStorage.getInstance().get(CACHE_KEYS.folder_types);
        var project_types = await OneTimeStorage.getInstance().get(CACHE_KEYS.project_types);
        var form_settings = await OneTimeStorage.getInstance().get(CACHE_KEYS.form_settings);
        var registers = await OneTimeStorage.getInstance().get(CACHE_KEYS.registers);

        var folder_status_temp = null;
        var project_status_temp = null;
        var activity_status_temp = null;
        var folder_types_temp = null;
        var project_types_temp = null;
        var form_settings_temp = null;
        var registers_temp = null;

        var structured_status = null;

        if(folder_status === null){
            folder_status_temp = await projectService.listFolderStatus(new ListStatusRequest(store.Server, store.SessionId, ListStatusType.Folder));
            if(!folder_status_temp.STATUS_LIST){
                structured_status = { 0: { color: '#000000', name: 'Unknown' } };
            } else {
                structured_status = folder_status_temp.STATUS_LIST.reduce((acc, val) => ({...acc, [val.STATUS.id]: { color: val.STATUS.color, name: val.STATUS.name}}), {});
            }
            await saveToCache(CACHE_KEYS.folder_status, structured_status);
            folder_status_temp = structured_status;
        } else {
            folder_status_temp = JSON.parse(folder_status);
        }

        if(project_status === null){
            project_status_temp = await projectService.listProjectStatus(new ListStatusRequest(store.Server, store.SessionId, ListStatusType.Project));
            if(!project_status_temp.STATUS_LIST){
                structured_status = { 0: { color: '#000000', name: 'Unknown' } };
            } else {
                structured_status = project_status_temp.STATUS_LIST.reduce((acc, val) => ({...acc, [val.STATUS.id]: { color: val.STATUS.color, name: val.STATUS.name}}), {});
            }
            await saveToCache(CACHE_KEYS.project_status, structured_status);
            project_status_temp = structured_status;
        } else {
            project_status_temp = JSON.parse(project_status);
        }

        if(activity_status === null){
            activity_status_temp = await projectService.listActivityStatus(new ListStatusRequest(store.Server, store.SessionId, ListStatusType.Activity));
            if(!activity_status_temp.STATUS_LIST){
                structured_status = { 0: { color: '#000000', name: 'Unknown' } };
            } else {
                structured_status = activity_status_temp.STATUS_LIST.reduce((acc, val) => ({...acc, [val.STATUS.id]: { color: val.STATUS.color, name: val.STATUS.name}}), {});
            }
            await saveToCache(CACHE_KEYS.activity_status, structured_status);
            activity_status_temp = structured_status;
        } else {
            activity_status_temp = JSON.parse(activity_status);
        }

        if(folder_types === null){
            folder_types_temp = await projectService.listFolderType(new ListFolderTypeRequest(store.Server, store.SessionId, true));
            if(!folder_types_temp.TYPE_LIST){
                structured_status = { 0: { id: 0, name: 'Unknown' } };
            } else {
                structured_status = folder_types_temp.TYPE_LIST.reduce((acc, val) => ({...acc, [val.TYPE.id]: val.TYPE}), {});
            }
            await saveToCache(CACHE_KEYS.folder_types, structured_status);
            folder_types_temp = structured_status;
        } else {
            folder_types_temp = JSON.parse(folder_types);
        }

        if(form_settings === null){
            form_settings_temp = await featureService.listFormSettings(new ListFormSettingsRequest(store.Server, store.SessionId));
            if(form_settings_temp.count == 0){
                structured_status = { 0: { id: 0, name: 'Unknown' } };
            } else {
                if(form_settings_temp.SETTING)
                    structured_status = form_settings_temp.SETTING.reduce((acc, val) => ({...acc, [val.formId]: val}), {});
            }
            await saveToCache(CACHE_KEYS.form_settings, structured_status);
            form_settings_temp = structured_status;
        } else {
            form_settings_temp = JSON.parse(form_settings);
        }

        if(project_types === null){
            project_types_temp = await projectService.listProjectType2(new ListProjectTypeRequest(store.Server, store.SessionId));
            if(project_types_temp.count == 0){
                structured_status = { 0: { id: 0, name: 'Unknown' } };
            } else {
                structured_status = project_types_temp.TYPE.reduce((acc, val) => ({...acc, [val.id]: val}), {});
            }
            await saveToCache(CACHE_KEYS.project_types, structured_status);
            project_types_temp = structured_status;
        } else {
            project_types_temp = JSON.parse(project_types);
        }

        if(registers === null){
            var combined = {};
            for(var call of register_calls){
                var s_response = await featureService.listRegister(new ListRegisterRequest(store.Server, store.SessionId, call.moduleId, call.key, 1, call.level));
                if(s_response.count > 0)
                    combined = { ...combined, [`${call.moduleId}_${call.key}`]: s_response.ROWS[0].ROW };
            }
            await saveToCache(CACHE_KEYS.registers, combined);
            registers_temp = combined;
        }else{
            registers_temp = JSON.parse(registers);
        }

        setFolderStatusMapping(folder_status_temp);
        setProjectStatusMapping(project_status_temp);
        setActivityStatusMapping(activity_status_temp);
        setFolderTypes(folder_types_temp);
        setProjectTypes(project_types_temp);
        setFormSettings(form_settings_temp);
        setRegisters(registers_temp);
    }, [projectService, featureService, store.Server, store.SessionId]);

    useEffect(() => {
        loadStatusesCallback();
    }, [loadStatusesCallback]);

    return { 
        projectStatusMapping, 
        folderStatusMapping, 
        activityStatusMapping, 
        folderTypes,
        projectTypes,
        formSettings,
        registers,
    };
}