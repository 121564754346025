import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListFolderUserRequest extends IBaseRequest {
    folder: number;
}

export class ListFolderUserRequest extends BaseRequest implements IListFolderUserRequest {
    public folder: number;

  constructor(server: string, session: string, folder: number) {
    super(server, session);
    this.folder = folder;
  }

  public toXml(): string {
    var parameters: string = super.toXml();
    parameters += '<folder>' + this.folder + '</folder>';
    return super.wrapperXml('ListFolderUser', parameters);
  }
}
