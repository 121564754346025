import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IDeleteTaskRequest extends IBaseRequest {
    ident?:          string;
    multiChange?:    string;
    multiEventTask?: false;
    headId?:         string;
}

export class DeleteTaskRequest extends BaseRequest implements IDeleteTaskRequest {

    public ident?:          string;
    public multiChange?:    string;
    public multiEventTask?: false;
    public headId?:         string;

  constructor(server: string, session: string, ident: string, multiEventTask?: false, headId?: string, multiChange?: string) {
    super(server, session);
    this.ident = ident;
    this.multiChange = multiChange;
    this.multiEventTask = multiEventTask;
    this.headId = headId;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    if(this.ident) parameters += `<ident>${this.ident}</ident>`;
    if(this.multiChange) parameters += `<multiChange>${this.multiChange}</multiChange>`;
    if(this.multiEventTask) parameters += `<multiEventTask>${this.multiEventTask}</multiEventTask>`;
    if(this.headId) parameters += `<headId>${this.headId}</headId>`;

    return super.wrapperXml('DeleteTask', parameters);
  }
}
