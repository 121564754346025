import { Flex, View } from '@adobe/react-spectrum';
import styles from './AppTabContainerItem.module.css';
import { CloseIcon } from '../Icons/IconsLib';
import { MouseEvent } from 'react';
import ConstantUtils from '../../../utils/ConstantUtils';

export type AppTabContainerItemType = {
    /** Props */
    appId: string;
    itemKey: string;
    label: string;
    ref: any;
    icon: string;
    altName?: string;
};


export interface IAppTabContainerItemProps {
    item: AppTabContainerItemType;
    closeApp: (item: string) => void;
    is_selected: boolean;
    onSelectedAppChanged: (itemKey: AppTabContainerItemType) => void;
}

export function AppTabContainerItem ({
    item,
    closeApp,
    is_selected = false,
    onSelectedAppChanged
}: IAppTabContainerItemProps) {
    // const [addInfo, setAddInfo] = useState('');

    const handleCloseClicked = (e:MouseEvent) => {
        e.stopPropagation();
        closeApp(item.itemKey);
    }

    var display_title = item.altName ? item.altName : item.label;

    if(display_title.length > ConstantUtils.FINDER_TITLE_MAX_LENGTH){
        display_title = display_title.substring(0, ConstantUtils.FINDER_TITLE_MAX_LENGTH) + '...';
    }
  return (
    <>
        <span onClick={() => onSelectedAppChanged(item)}>
            <Flex direction="row" wrap="nowrap" gap="size-100" UNSAFE_className={is_selected ? styles.container_active : styles.container}>
                <View paddingStart={10}>
                    <img className={styles.appIcon} alt="" src={item.icon} />
                </View>
                <View>
                    {display_title}
                </View>
                <View paddingStart={5} paddingTop={2}>
                    <span onClick={handleCloseClicked}>
                        <CloseIcon size={15} />
                    </span>
                </View>
                <View height={34} />
            </Flex>
        </span>
    </>
  );
}
