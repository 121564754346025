import { SettingItem } from '../../../services/soap/features/responses/GetFormSettingsResponse';
import { Divider, Flex } from '@adobe/react-spectrum';
import { CreateCompanyFields, CreateCustomFormFields, CreateEmployeeFields, CreatePersonFields, CreateSimpleFolderFields, CreateTrackingCodeFields } from './ContextFields';
import { FPATemplateItem } from '../../../infra/protected/FPA/FPATemplateItem';

export interface IFolderContextFieldsProps {
    settings: SettingItem[];
    selectedItem?: FPATemplateItem;
    setFormData?: (key: string, value: any) => void;
}

export function FolderContextFields ({
    settings,
    selectedItem,
    setFormData
}: IFolderContextFieldsProps) {
  return (
    <Flex direction={'column'} gap={'size-100'}>
        <CreateCompanyFields settings={settings} selectedItem={selectedItem} setFormData={setFormData} />
        <CreateTrackingCodeFields settings={settings} selectedItem={selectedItem} setFormData={setFormData} />
        <Divider size='M' />
        <CreatePersonFields settings={settings} selectedItem={selectedItem} setFormData={setFormData} />
        <CreateEmployeeFields settings={settings} selectedItem={selectedItem} setFormData={setFormData} />
        <CreateSimpleFolderFields settings={settings} selectedItem={selectedItem} setFormData={setFormData} />
        <CreateCustomFormFields settings={settings} selectedItem={selectedItem} setFormData={setFormData} />
    </Flex>    
  );
}
