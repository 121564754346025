import { useCallback, useEffect, useState } from 'react';
import { TextField } from '@adobe/react-spectrum';
import { useDebounce } from '@uidotdev/usehooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';

interface IFilterSearchTextBoxProps {
  onSearchChange: (params: {}) => void;
  filterParams: any;
  serachedText?: string;
  fpaTypeCategory: string;
}

const FilterSearchTextBox: React.FC<IFilterSearchTextBoxProps> = ({ onSearchChange, filterParams, serachedText, fpaTypeCategory }) => {
  const selectedApp = useSelector((state: RootState) => state.userWebLayout.selectedAppId);
  const [filterTerm, setFilterTerm] = useState<string>(serachedText ?? '');
  const debouncedFilterTerm = useDebounce(filterTerm, 1000);

  useEffect(() => {
    (async () => {
      searchServiceActivities();
    })();
  }, [debouncedFilterTerm]);

  useEffect(() => {
    setFilterTerm('');
  }, [selectedApp]);

  const handleFiltering = async (e: any) => {
    setFilterTerm(e);
  };

  const searchServiceActivities = useCallback(async () => {
    // console.log('debouncedFilterTerm', debouncedFilterTerm);
    // console.log('filterParams', filterParams);

    if (!filterParams.FILTER.COL.some((obj: any) => obj.name === 'nameSearch') && debouncedFilterTerm === '') {
      return;
    }

    if (!filterParams) {
      filterParams = {
        FILTER: {
          COL: [
            {
              name: 'fpaTypeCategory',
              type: 'any',
              value: fpaTypeCategory,
            },
          ],
        },
      };
    } else {
      const fpaTypeCategoryExists = filterParams.FILTER.COL.some((col: any) => col.name === 'fpaTypeCategory');
      if (!fpaTypeCategoryExists) {
        filterParams.FILTER.COL.push({
          name: 'fpaTypeCategory',
          type: 'any',
          value: fpaTypeCategory,
        });
      }
    }

    const updatedArray = filterParams.FILTER.COL.map((obj: any) => (obj.name === 'nameSearch' ? { ...obj, value: debouncedFilterTerm } : obj));

    const hasNameSearch = filterParams.FILTER.COL.some((obj: any) => obj.name === 'nameSearch');

    if (!hasNameSearch && debouncedFilterTerm) {
      updatedArray.push({
        name: 'nameSearch',
        type: 'contains',
        value: debouncedFilterTerm,
      });
    }

    filterParams.FILTER.COL = updatedArray;
    filterParams.FILTER.COL = filterParams.FILTER.COL.filter((col: any) => col.value !== '');
    onSearchChange(filterParams);
  }, [debouncedFilterTerm]);

  return <TextField value={filterTerm} width={'100%'} onChange={handleFiltering} aria-label="none" />;
};

export default FilterSearchTextBox;
