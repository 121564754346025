import React from 'react';
import { useParams } from 'react-router-dom';

function Placeholder() {
    const { app } = useParams();
    return (
        <>
            <h1>Place holder for {app} application</h1>
        </>
    );
}

export default Placeholder;