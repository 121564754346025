import { useState, useEffect } from 'react';

const useBrowserName = () => {
  const [browserName, setBrowserName] = useState('');

  useEffect(() => {
    const getBrowserName = () => {
      const userAgent = navigator.userAgent;
      if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) {
        return 'Chrome';
      } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
        return 'Safari';
      } else if (userAgent.includes('Firefox')) {
        return 'Firefox';
      } else if (userAgent.includes('Edg')) {
        return 'Edge';
      } else {
        return 'Other';
      }
    };

    setBrowserName(getBrowserName());
  }, []);

  return browserName;
};

export default useBrowserName;
