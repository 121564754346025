import { ServiceBase } from "../ServiceBase";
import { MESSAGE } from "../../../utils/ServiceUtils";
import { INewMessageRequest } from "./requests/NewMessageRequest";
import { NewMessageResponse } from "./responses/NewMessageResponse";

export interface IMessageService {
    newMessage(request:INewMessageRequest): Promise<NewMessageResponse>;
}

export class MessageService extends ServiceBase implements IMessageService {
    public async newMessage(request:INewMessageRequest): Promise<NewMessageResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), MESSAGE.newMessage, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(MESSAGE.newMessage, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(MESSAGE.newMessage, request.toXml());
        }

        var parsedResponse = super.sendResponse2(response, ['TO', 'CC', 'BCC', 'USER', 'ITEM', 'CONTACT_RELATION']).NewMessageResponse as NewMessageResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }
}