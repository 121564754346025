import { MobileSearchResultGroup } from './MobileSearchResultGroup';
import styles from './MobileSearchResults.module.css';

export interface IMobileSearchResultsProps {
    mainTitle: string;
    groups: any[];
    onSearchSelected: (item:any) => void;
    onLoadMore?: () => void;
}

export function MobileSearchResults ({
    mainTitle,
    groups,
    onSearchSelected,
    onLoadMore
}: IMobileSearchResultsProps) {

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const element = event.target as HTMLDivElement;
    if(element.scrollTop + element.clientHeight >= element.scrollHeight) {
      console.log('Load more');
      onLoadMore && onLoadMore();
    }
  }

  return (
    <>
      <div className={styles.mainTitle}>{mainTitle}</div>
      <div className={styles.mainSearchResultContainer}
            onScroll={handleScroll}>
      {groups.map((group, index) => <MobileSearchResultGroup key={index} 
                                                      groupTitle={group.title} 
                                                      items={group.items} 
                                                      onSearchSelected={onSearchSelected} 
                                                      />)}
      </div>
    </>
  );
}
