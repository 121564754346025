import { Provider, defaultTheme } from '@adobe/react-spectrum';
import { ToastContainer, ToastQueue } from '@react-spectrum/toast';
import PublicRoutes from './routes/PublicRoutes';
import ProtectedRoutes from './routes/ProtectedRoutes';
import { useLocation } from 'react-router-dom';
import { DependencyProvider } from './contexts/DependencyProvider';
import { UsePoller } from './hooks/UsePoller';
import { useEffect } from 'react';
import './App.css';
import { useReefConfig } from './hooks/UseReefConfig';
import { AppsProvider } from './contexts/AppsProvider';

function App() {
  const { state } = useLocation();
  const { baseUrl } = useReefConfig();
  const { isNewVersionAvailable } = UsePoller({ deploymentUrl: `${baseUrl}/index.html`, comparisonInterval: 1000 * 60 });
  const default_breakpoints = {
    base: 0,
    S: 640,
    M: 800,
    L: 1024,
    XL: 1280,
    XXL: 1536,
  };
  useEffect(() => {
    if (isNewVersionAvailable) {
      var message: string = 'A new version of the application is available. Please restart the application to complete the upgrade.';
      ToastQueue.info(message, { timeout: 10000 });
    }
  }, [isNewVersionAvailable]);

  return (
    <AppsProvider>
      <DependencyProvider>
        <Provider theme={defaultTheme} colorScheme="light" breakpoints={default_breakpoints}>
          <ToastContainer />
          {(!state || !state.session) && <PublicRoutes />}
          {state && state.session && (<><ProtectedRoutes /></>)}
        </Provider>
      </DependencyProvider>
    </AppsProvider>
  );
}

export default App;
