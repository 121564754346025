import React from 'react';
import { DialogComponent } from '@syncfusion/ej2-react-popups';

interface DialogProps {
  width: string;
  isModal: boolean;
  buttons: any[];
  visible: boolean;
  header: () => JSX.Element;
  dialogContent: () => JSX.Element;
  closeDialog: () => void;
}

const CustomDialogComponent: React.FC<DialogProps> = ({ width, isModal, buttons, visible, header, dialogContent, closeDialog }: DialogProps) => {
  return (
    <div className="App" id="dialog-target">
      <DialogComponent
        cssClass="custom-dialog"
        id="dialog"
        width={width}
        target="#dialog-target"
        header={header}
        isModal={isModal}
        closeOnEscape={false}
        showCloseIcon={false}
        buttons={buttons}
        visible={visible}
        overlayClick={closeDialog}
      >
        <div>{dialogContent()}</div>
      </DialogComponent>
    </div>
  );
};

export default CustomDialogComponent;
