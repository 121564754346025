import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListPlacementConfigurationRequest extends IBaseRequest {
    projectType?: string;
}

export class ListPlacementConfigurationRequest extends BaseRequest implements IListPlacementConfigurationRequest {
    public projectType?: string;
    constructor(server: string, session: string, projectType?: string) {
        super(server, session);
        this.projectType = projectType;
    }

    public toXml(): string {
        var parameters: string = super.toXml();
        if(this.projectType) parameters += '<projectType>' + this.projectType + '</projectType>';
        return super.wrapperXml('ListPlacementConfiguration', parameters);
    }
}
