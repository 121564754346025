export interface ExternalContact {
  id: number;
  contactId: number;
  contextId: number;
  note: string;
  isPrimary: number;
  relationshipLevel: number;
  name1: string;
  name2: string;
  name3: string;
  type: number;
  email: string;
  phone: string;
  mobile: string;
}

export interface Rows {
  EXTERNAL_CONTACT: ExternalContact[];
}

export interface ExternalContacts {
  count: string;
  ROWS: any[];
}

function toExternalContactsXml(ecs: ExternalContacts) {
  var result = '<EXTERNAL_CONTACTS>';
  if (ecs.ROWS) {
    result += '<count>' + ecs.count + '</count>';
    if (+ecs.count > 0 && ecs.ROWS) {
      ecs.ROWS.forEach(ec => {
        result += '<EXTERNAL_CONTACT>';
        if (ec.EXTERNAL_CONTACT.id) result += '<id>' + ec.EXTERNAL_CONTACT.id + '</id>';
        if (ec.EXTERNAL_CONTACT.contactId) result += '<contactId>' + ec.EXTERNAL_CONTACT.contactId + '</contactId>';
        if (ec.EXTERNAL_CONTACT.contextId) result += '<contextId>' + ec.EXTERNAL_CONTACT.contextId + '</contextId>';
        if (ec.EXTERNAL_CONTACT.note) result += '<note>' + ec.EXTERNAL_CONTACT.note + '</note>';
        if (ec.EXTERNAL_CONTACT.isPrimary) result += '<isPrimary>' + ec.EXTERNAL_CONTACT.isPrimary + '</isPrimary>';
        if (ec.EXTERNAL_CONTACT.relationshipLevel) result += '<relationshipLevel>' + ec.EXTERNAL_CONTACT.relationshipLevel + '</relationshipLevel>';
        if (ec.EXTERNAL_CONTACT.name1) result += '<name1>' + ec.EXTERNAL_CONTACT.name1 + '</name1>';
        if (ec.EXTERNAL_CONTACT.name2) result += '<name2>' + ec.EXTERNAL_CONTACT.name2 + '</name2>';
        if (ec.EXTERNAL_CONTACT.name3) result += '<name3>' + ec.EXTERNAL_CONTACT.name3 + '</name3>';
        if (ec.EXTERNAL_CONTACT.type) result += '<type>' + ec.EXTERNAL_CONTACT.type + '</type>';
        if (ec.EXTERNAL_CONTACT.email) result += '<email>' + ec.EXTERNAL_CONTACT.email + '</email>';
        if (ec.EXTERNAL_CONTACT.phone) result += '<phone>' + ec.EXTERNAL_CONTACT.phone + '</phone>';
        if (ec.EXTERNAL_CONTACT.mobile) result += '<mobile>' + ec.EXTERNAL_CONTACT.mobile + '</mobile>';
        if (ec.EXTERNAL_CONTACT.deleteContact) result += '<deleteContact>' + ec.EXTERNAL_CONTACT.deleteContact + '</deleteContact>';
        result += '</EXTERNAL_CONTACT>';
      });
    }
  }
  result += '</EXTERNAL_CONTACTS>';
  return result;
}

export interface Activity {
  acl?: string;
  aclh?: string;
  activity?: string;
  activityName?: string;
  activityType?: string;
  activityTypeName?: string;
  comment?: string;
  created?: Date;
  createdByName?: string;
  customState?: string;
  defaultMessageContainer?: string;
  defaultMessageContainerPath?: string;
  defaultMessageGroup?: string;
  folder?: string;
  folderName?: string;
  folderTypeName?: string;
  formId?: string;
  formName?: string;
  formValues?: string;
  guiAction?: string;
  id: string;
  modified?: Date;
  modifiedByName?: string;
  name?: string;
  pricing?: string;
  pricingName?: string;
  project?: string;
  projectName?: string;
  refId?: string;
  state?: string;
  typeFormId?: string;
  requestHash?: string;
  dimension1?: string;
  dimension2?: string;
  dimension3?: string;
  provider?: string;
  customer?: string;
  startDate?: Date;
  finalDate?: Date;
  masterActivity?: string;
  EXTERNAL_CONTACTS?: ExternalContacts;
}

export function toActivityXml(activity: Activity): string {
  var response = '<ACTIVITY>';

  if (activity.acl) response += '<acl>' + activity.acl + '</acl>';
  if (activity.aclh) response += '<aclh>' + activity.aclh + '</aclh>';
  if (activity.activity) response += '<activity>' + activity.activity + '</activity>';
  if (activity.activityName) response += '<activityName>' + activity.activityName + '</activityName>';
  if (activity.activityType) response += '<activityType>' + activity.activityType + '</activityType>';
  if (activity.activityTypeName) response += '<activityTypeName>' + activity.activityTypeName + '</activityTypeName>';
  if (activity.comment) response += '<comment>' + activity.comment + '</comment>';
  if (activity.created) response += '<created>' + activity.created + '</created>';
  if (activity.createdByName) response += '<createdByName>' + activity.createdByName + '</createdByName>';
  if (activity.customState) response += '<customState>' + activity.customState + '</customState>';
  if (activity.defaultMessageContainer) response += '<defaultMessageContainer>' + activity.defaultMessageContainer + '</defaultMessageContainer>';

  if (activity.defaultMessageContainerPath) response += '<defaultMessageContainerPath>' + activity.defaultMessageContainerPath + '</defaultMessageContainerPath>';
  if (activity.defaultMessageGroup) response += '<defaultMessageGroup>' + activity.defaultMessageGroup + '</defaultMessageGroup>';
  if (activity.folder) response += '<folder>' + activity.folder + '</folder>';
  if (activity.folderName) response += '<folderName>' + activity.folderName + '</folderName>';
  if (activity.folderTypeName) response += '<folderTypeName>' + activity.folderTypeName + '</folderTypeName>';
  if (activity.formId) response += '<formId>' + activity.formId + '</formId>';
  if (activity.formName) response += '<formName>' + activity.formName + '</formName>';
  if (activity.formValues) response += '<formValues>' + activity.formValues + '</formValues>';
  if (activity.guiAction) response += '<guiAction>' + activity.guiAction + '</guiAction>';
  if (activity.id) response += '<id>' + activity.id + '</id>';
  if (activity.modified) response += '<modified>' + activity.modified + '</modified>';
  if (activity.modifiedByName) response += '<modifiedByName>' + activity.modifiedByName + '</modifiedByName>';
  if (activity.name) response += '<name>' + activity.name + '</name>';
  if (activity.pricing) response += '<pricing>' + activity.pricing + '</pricing>';
  if (activity.pricingName) response += '<pricingName>' + activity.pricingName + '</pricingName>';
  if (activity.project) response += '<project>' + activity.project + '</project>';
  if (activity.projectName) response += '<projectName>' + activity.projectName + '</projectName>';
  if (activity.refId) response += '<refId>' + activity.refId + '</refId>';
  if (activity.state) response += '<state>' + activity.state + '</state>';
  if (activity.typeFormId) response += '<typeFormId>' + activity.typeFormId + '</typeFormId>';
  if (activity.requestHash) response += '<requestHash>' + activity.requestHash + '</requestHash>';
  if (activity.dimension1) response += '<dimension1>' + activity.dimension1 + '</dimension1>';
  if (activity.dimension2) response += '<dimension2>' + activity.dimension2 + '</dimension2>';
  if (activity.dimension3) response += '<dimension3>' + activity.dimension3 + '</dimension3>';
  if (activity.provider) response += '<provider>' + activity.provider + '</provider>';
  if (activity.customer) response += '<customer>' + activity.customer + '</customer>';
  if (activity.startDate) response += '<startDate>' + activity.startDate + '</startDate>';
  if (activity.finalDate) response += '<finalDate>' + activity.finalDate + '</finalDate>';
  if (activity.masterActivity) response += '<masterActivity>' + activity.masterActivity + '</masterActivity>';
  if (activity.EXTERNAL_CONTACTS) response += toExternalContactsXml(activity.EXTERNAL_CONTACTS);

  response += '</ACTIVITY>';
  return response;
}
