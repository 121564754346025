import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IDeleteTimesheetRequest extends IBaseRequest {
    recordId: string;
}

export class DeleteTimesheetRequest extends BaseRequest implements IDeleteTimesheetRequest {
    public recordId: string;
    constructor(server: string, session: string, recordId: string) {
        super(server, session);
        this.recordId = recordId;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        parameters += '<recordId>' + this.recordId + '</recordId>';
        
        return super.wrapperXml('DeleteTimesheet', parameters);
    }
}
