import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface INewMessageRequest extends IBaseRequest {
  numberOfAttachments?: number;
  identity?: string;
  priority?: number;
  customType?: number;
  type?: number;
  mailFrom?: string;
  mailId?: string;
  templateAclh?: string;
  treeHandle?: number;
  group?: string;
  parent?: string;
  mobile?: string;
  status?: string;
  doNotMoveThread?: string;
  isSpam?: string;
  star?: string;
  threadId?: string;
  contextId?: string;
  remoteId?: string;
  schedulerId?: number;
  invoiceId?: string;
  INCLUDEMSG?: Includemsg;
  SUBJECT?: Comment;
  RECIPIENT?: Recipient;
  COMMENT?: Comment;
  HTML?: Comment;
  DOCUMENT?: Document;
  CONTACT_RELATIONS?: ContactRelations;
}
export interface Comment {
  string?: string;
}
function toCommentXml(c: Comment, proprtyName: string): string {
  if (c.string) return `<${proprtyName}><string>${c.string}</string></${proprtyName}>`;
  return ``;
}

export interface ContactRelations {
  CONTACT_RELATION?: ContactRelation[];
}
function toContactRelationsXml(c: ContactRelations): string {
  return `<CONTACT_RELATIONS>
    ${c.CONTACT_RELATION ? c.CONTACT_RELATION.map(toContactRelationXml).join('\n') : ''}
    </CONTACT_RELATIONS>`;
}

export interface ContactRelation {
  type?: string;
  identifier?: string;
  contact?: string;
}
function toContactRelationXml(c: ContactRelation): string {
  var info = '';

  if (c.type) info += `<type>${c.type}</type>`;
  if (c.identifier) info += `<identifier>${c.identifier}</identifier>`;
  if (c.contact) info += `<contact>${c.contact}</contact>`;

  return `<CONTACT_RELATION>${info}</CONTACT_RELATION>`;
}

export interface Document {
  ITEM?: Item[];
}
function toDocumentXml(d: Document): string {
  return `<DOCUMENT>
    ${d.ITEM ? d.ITEM.map(toItemXml).join('\n') : ''}
    </DOCUMENT>`;
}

export interface Item {
  createdByName?: string;
  id?: string;
  lastChangeDate?: string;
  name?: string;
}
function toItemXml(i: Item): string {
  var info = '';

  if (i.createdByName) info += `<createdByName>${i.createdByName}</createdByName>`;
  if (i.id) info += `<id>${i.id}</id>`;
  if (i.lastChangeDate) info += `<lastChangeDate>${i.lastChangeDate}</lastChangeDate>`;
  if (i.name) info += `<name>${i.name}</name>`;

  return `<ITEM>${info}</ITEM>`;
}

export interface Includemsg {
  includeMessage?: string;
  includeMsgText?: string;
}
function toIncludemsgXml(i: Includemsg): string {
  var info = '';

  if (i.includeMessage) info += `<includeMessage>${i.includeMessage}</includeMessage>`;
  if (i.includeMsgText) info += `<includeMsgText>${i.includeMsgText}</includeMsgText>`;

  return `<INCLUDEMSG>${info}</INCLUDEMSG>`;
}

export interface Recipient {
  TO?: Bcc[];
  CC?: Bcc[];
  BCC?: Bcc[];
  USER?: Bcc[];
}
function toRecipientXml(r: Recipient): string {
  var info = '';

  if (r.TO) info += `<TO>${r.TO.map(toBccXml).join('\n')}</TO>`;
  if (r.CC) info += `<CC>${r.CC.map(toBccXml).join('\n')}</CC>`;
  if (r.BCC) info += `<BCC>${r.BCC.map(toBccXml).join('\n')}</BCC>`;
  if (r.USER) info += `<USER>${r.USER.map(toBccXml).join('\n')}</USER>`;

  return `<RECIPIENT>${info}</RECIPIENT>`;
}

export interface Bcc {
  CONTENT?: string;
  salutation?: string;
  contact?: string;
  id?: string;
}
function toBccXml(b: Bcc): string {
  var info = '';

  if (b.CONTENT) info += `<CONTENT>${b.CONTENT}</CONTENT>`;
  if (b.salutation) info += `<salutation>${b.salutation}</salutation>`;
  if (b.contact) info += `<contact>${b.contact}</contact>`;
  if (b.id) info += `<id>${b.id}</id>`;

  return `<BCC>${info}</BCC>`;
}

export class NewMessageRequest extends BaseRequest implements INewMessageRequest {
  numberOfAttachments?: number;
  identity?: string;
  priority?: number;
  customType?: number;
  type?: number;
  mailFrom?: string;
  mailId?: string;
  templateAclh?: string;
  treeHandle?: number;
  group?: string;
  parent?: string;
  mobile?: string;
  status?: string;
  doNotMoveThread?: string;
  isSpam?: string;
  star?: string;
  threadId?: string;
  contextId?: string;
  remoteId?: string;
  schedulerId?: number;
  invoiceId?: string;
  INCLUDEMSG?: Includemsg;
  SUBJECT?: Comment;
  RECIPIENT?: Recipient;
  COMMENT?: Comment;
  HTML?: Comment;
  DOCUMENT?: Document;
  CONTACT_RELATIONS?: ContactRelations;

  constructor(server: string, session: string, otherOptions: any) {
    super(server, session);
    this.numberOfAttachments = otherOptions.numberOfAttachments;
    this.identity = otherOptions.identity;
    this.priority = otherOptions.priority;
    this.customType = otherOptions.customType;
    this.type = otherOptions.type;
    this.mailFrom = otherOptions.mailFrom;
    this.mailId = otherOptions.mailId;
    this.templateAclh = otherOptions.templateAclh;
    this.treeHandle = otherOptions.treeHandle;
    this.group = otherOptions.group;
    this.parent = otherOptions.parent;
    this.mobile = otherOptions.mobile;
    this.status = otherOptions.status;
    this.doNotMoveThread = otherOptions.doNotMoveThread;
    this.isSpam = otherOptions.isSpam;
    this.star = otherOptions.star;
    this.threadId = otherOptions.threadId;
    this.contextId = otherOptions.contextId;
    this.remoteId = otherOptions.remoteId;
    this.schedulerId = otherOptions.schedulerId;
    this.invoiceId = otherOptions.invoiceId;
    this.INCLUDEMSG = otherOptions.INCLUDEMSG;
    this.SUBJECT = otherOptions.SUBJECT;
    this.RECIPIENT = otherOptions.RECIPIENT;
    this.COMMENT = otherOptions.COMMENT;
    this.HTML = otherOptions.HTML;
    this.DOCUMENT = otherOptions.DOCUMENT;
    this.CONTACT_RELATIONS = otherOptions.CONTACT_RELATIONS;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    if (this.numberOfAttachments) parameters += `<numberOfAttachments>${this.numberOfAttachments}</numberOfAttachments>\n`;
    if (this.identity) parameters += `<identity>${this.identity}</identity>\n`;
    if (this.priority) parameters += `<priority>${this.priority}</priority>\n`;
    if (this.customType) parameters += `<customType>${this.customType}</customType>\n`;
    if (this.type) parameters += `<type>${this.type}</type>\n`;
    if (this.mailFrom) parameters += `<mailFrom>${this.mailFrom}</mailFrom>\n`;
    if (this.mailId) parameters += `<mailId>${this.mailId}</mailId>\n`;
    if (this.templateAclh) parameters += `<templateAclh>${this.templateAclh}</templateAclh>\n`;
    if (this.treeHandle) parameters += `<treeHandle>${this.treeHandle}</treeHandle>\n`;
    if (this.group) parameters += `<group>${this.group}</group>\n`;
    if (this.parent) parameters += `<parent>${this.parent}</parent>\n`;
    if (this.mobile) parameters += `<mobile>${this.mobile}</mobile>\n`;
    if (this.status) parameters += `<status>${this.status}</status>\n`;
    if (this.doNotMoveThread) parameters += `<doNotMoveThread>${this.doNotMoveThread}</doNotMoveThread>\n`;
    if (this.isSpam) parameters += `<isSpam>${this.isSpam}</isSpam>\n`;
    if (this.star) parameters += `<star>${this.star}</star>\n`;
    if (this.threadId) parameters += `<threadId>${this.threadId}</threadId>\n`;
    if (this.contextId) parameters += `<contextId>${this.contextId}</contextId>\n`;
    if (this.remoteId) parameters += `<remoteId>${this.remoteId}</remoteId>\n`;
    if (this.schedulerId) parameters += `<schedulerId>${this.schedulerId}</schedulerId>\n`;
    if (this.invoiceId) parameters += `<invoiceId>${this.invoiceId}</invoiceId>\n`;
    if (this.INCLUDEMSG) parameters += toIncludemsgXml(this.INCLUDEMSG);
    if (this.SUBJECT) parameters += toCommentXml(this.SUBJECT, 'SUBJECT');
    if (this.RECIPIENT) parameters += toRecipientXml(this.RECIPIENT);
    if (this.COMMENT) parameters += toCommentXml(this.COMMENT, 'COMMENT');
    if (this.HTML) parameters += toCommentXml(this.HTML, 'HTML');
    if (this.DOCUMENT) parameters += toDocumentXml(this.DOCUMENT);
    if (this.CONTACT_RELATIONS) parameters += toContactRelationsXml(this.CONTACT_RELATIONS);

    return super.wrapperXml('NewMessage', parameters);
  }
}
