import { BaseRequest, IBaseRequest } from "../../BaseRequest";

export interface ICreateRegisterRequest extends IBaseRequest {
    module?:string;
    moduleId:string;
    application?:string;
    key:string;
    level:number;
    value:string;
    assignTo?:number;
}

export class CreateRegisterRequest extends BaseRequest implements ICreateRegisterRequest {
    public module?:string;
    public moduleId:string;
    public application?:string;
    public key:string;
    public level:number;
    public value:string;
    public assignTo?:number;

    constructor(server: string, session: string, moduleId:string, key:string, level:number, value:string, application?:string, module?:string, assignTo?:number) {
        super(server, session);
        this.module = module;
        this.moduleId = moduleId;
        this.application = application;
        this.key = key;
        this.level = level;
        this.value = value;
        this.assignTo = assignTo;
    }

    public override toXml(): string {
        var parameters: string = super.toXml();
        if(this.module) parameters += '<module>' + this.module + '</module>';
        parameters += '<moduleId>' + this.moduleId + '</moduleId>';
        if(this.application) parameters += '<application>' + this.application + '</application>';
        parameters += '<key>' + this.key + '</key>';
        parameters += '<level>' + this.level + '</level>';
        parameters += '<value>' + this.value + '</value>';
        if(this.assignTo) parameters += '<assignTo>' + this.assignTo + '</assignTo>';
        return super.wrapperXml('CreateRegister', parameters);
    }
    
}