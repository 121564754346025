import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListRegisterRequest extends IBaseRequest {
    moduleId?:string;
    key?:string;
    currentUser?:number;
    level?:number;
    application?:number;
}

export class ListRegisterRequest extends BaseRequest implements IListRegisterRequest {
    public moduleId?:string;
    public key?:string;
    public currentUser?:number;
    public level?:number;
    public application?:number;

    constructor(server: string, session: string, moduleId?:string, key?:string, currentUser?:number, level?:number, application?:number) {
        super(server, session);
        this.moduleId = moduleId;
        this.key = key;
        this.currentUser = currentUser;
        this.level = level;
        this.application = application;
    }

    public toXml(): string {
        var parameters: string = super.toXml();
        if(this.moduleId) {
            parameters += '<moduleId>' + this.moduleId + '</moduleId>';
        }
        if(this.key) {
            parameters += '<key>' + this.key + '</key>';
        }
        if (this.currentUser) {
            parameters += '<currentUser>' + this.currentUser + '</currentUser>';
        }
        if (this.level) {
            parameters += '<level>' + this.level + '</level>';
        }
        if (this.application) {
            parameters += '<application>' + this.application + '</application>';
        }
        return super.wrapperXml('ListRegister', parameters);
    }
}
