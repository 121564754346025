import * as React from 'react';
import { LayoutComponent } from '../LayoutComponent';

export interface IFallbackProps {
    
}

function Fallback (props: IFallbackProps) {
  return (
    <div>
      Fallback component 2
    </div>
  );
}

export const FallbackComponent = LayoutComponent(Fallback);
