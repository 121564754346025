import { Route, Routes } from 'react-router-dom';
import UserWebLayout from '../layouts/protected/UserWebLayout';
import { Application } from '../services/soap/features/responses/ListApplicationsResponse';

import React, { useEffect, useState } from 'react';
import Dashboard from '../pages/protected/Dashboard';
import Placeholder from '../pages/protected/Placeholder';
import Empty from '../pages/protected/not-found/Empty';
import { useApps } from '../contexts/AppsProvider';
import { routesMap } from '../config/app-routes';
import { Redirect } from '../pages/public/Redirect';

const ProtectedRoutes = () => {
  const { apps } = useApps();
  const [routes, setRoutes] = useState<React.ReactNode[]>([]);
  // const routes:React.ReactNode[] = [];
  useEffect(() => {

    var tmp_routes:React.ReactNode[] = [];
    apps.forEach((app: Application) => {
      switch(app.accessType){
        case 'system':
          var system_app = routesMap.get(app.appIdent);
          if(system_app){
            if(app.primaryPath){
              tmp_routes.push(<Route key={app.id} path={app.primaryPath} element={<UserWebLayout />}>
                <Route path="" element={React.createElement(system_app)} />
              </Route>);
            }
            if(app.ALL_PATHS){
              app.ALL_PATHS.path.forEach((path: string) => {
                tmp_routes.push(<Route key={app.id} path={path} element={<UserWebLayout />}>
                  <Route path="" element={React.createElement(system_app)} />
                </Route>);
              });
            }
          }
          break;
        case 'custom':
          var data = JSON.parse(app.data);
          if(data.handler){
            var custom_app = routesMap.get(data.handler);
            if(custom_app){
              if(app.primaryPath){
                tmp_routes.push(<Route key={app.id} path={app.primaryPath} element={<UserWebLayout />}>
                  <Route path="" element={React.createElement(custom_app)} />
                </Route>);
              }
              if(app.ALL_PATHS){
                app.ALL_PATHS.path.forEach((path: string) => {
                  tmp_routes.push(<Route key={app.id} path={path} element={<UserWebLayout />}>
                    <Route path="" element={React.createElement(custom_app)} />
                  </Route>);
                });
              }
            }
          }
          break;
        default:
          break;
      }
    });
  
    setRoutes(tmp_routes);
  }, [apps]);
  
  return (
      <>
        <Routes>
          <Route path="/user" element={<UserWebLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="app/:app" element={<Placeholder />} />
            <Route path="*" element={<Empty />} />
          </Route>
          {routes.map((route: React.ReactNode) => route)}
          <Route path="*" element={<Redirect />} />
        </Routes>
      </>
    );
};

export default ProtectedRoutes;
