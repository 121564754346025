import React from 'react';
import { Text, Item, Link, Menu, MenuTrigger, View } from '@adobe/react-spectrum';
import { useDependency } from '../../../../../contexts/DependencyProvider';
import { useTranslation } from 'react-i18next';
import { UpdateActivityRequest } from '../../../../../services/soap/project/requests/UpdateActivityRequest';
import { UpdateProjectRequest } from '../../../../../services/soap/project/requests/UpdateProjectRequest';
import { UpdateFolderRequest } from '../../../../../services/soap/project/requests/UpdateFolderRequest';
import { FPAData, FPADataTypes } from '../../../../../infra/protected/FPA/FPAData';
import styles from '../ExternalContacts.module.css';

interface ContextMenuButtonProps {
  contactId: string;
  currentItem: any;
  updateItem: (request: any, message: string) => void;
  closeDialog: (reload: boolean) => void;
  selectedItem?: FPAData;
}

const ContextMenuButton: React.FC<ContextMenuButtonProps> = ({ contactId, currentItem, updateItem, closeDialog, selectedItem }) => {
  const { projectService, store } = useDependency();
  const { t } = useTranslation();
  let iconStyle = { paddingTop: '5px', paddingLeft: '5px', color: '#B0BEC5', WebkitTextStroke: '.5px' };

  const deleteContact = async (contactId: string) => {
    if (!selectedItem) return;

    currentItem.current.EXTERNAL_CONTACTS.ROWS.forEach((contact: any) => {
      if (contact.EXTERNAL_CONTACT.id === contactId) {
        contact.EXTERNAL_CONTACT.deleteContact = '1';
      } else {
        contact.EXTERNAL_CONTACT.deleteContact = '0';
      }
    });

    const requestData = {
      server: store.Server,
      sessionId: store.SessionId,
      data: {
        ...currentItem.current,
        EXTERNAL_CONTACTS: currentItem.current.EXTERNAL_CONTACTS,
      },
    };

    let successMessage = t('contact_deleted_successfully', { ns: 'layout_components' });

    switch (selectedItem.type) {
      case FPADataTypes.ACTIVITY:
        await updateItem(() => projectService.updateActivity(new UpdateActivityRequest(requestData.server, requestData.sessionId, requestData.data)), successMessage);
        break;
      case FPADataTypes.PROJECT:
        await updateItem(() => projectService.updateProject(new UpdateProjectRequest(requestData.server, requestData.sessionId, requestData.data)), successMessage);
        break;
      case FPADataTypes.FOLDER:
        await updateItem(() => projectService.updateFolder(new UpdateFolderRequest(requestData.server, requestData.sessionId, requestData.data)), successMessage);
        break;
    }
    closeDialog(true);
  };

  const menuAction = (key: string) => {
    switch (key) {
      case 'email':
        console.log('email');
        break;
      case 'sms':
        console.log('sms');
        break;
      case 'call':
        console.log('call');
        break;
      case 'delete':
        deleteContact(contactId);
        break;
      default:
        break;
    }
  };
  return (
    <View key={`view_${contactId}`} UNSAFE_className={styles.icon_more}>
      <MenuTrigger>
        <Link>
          <span key={`span_${contactId}`} aria-label="Open context menu" className={`context-target_${contactId}`} role="button" tabIndex={0}>
            <i className="bi bi-three-dots fs-7"></i>
          </span>
        </Link>
        <Menu width={'100%'} onAction={(key: any) => menuAction(key)}>
          <Item key="email" textValue="email">
            <i className="bi bi-copy" style={iconStyle}></i>&nbsp;&nbsp;
            <Text UNSAFE_style={{ color: '#36474F', paddingLeft: '5px' }}>{t('copy_email', { ns: 'layout_components' })}</Text>
          </Item>
          <Item key="sms" textValue="sms">
            <i className="bi bi-phone" style={iconStyle}></i>&nbsp;&nbsp;
            <Text UNSAFE_style={{ color: '#36474F', paddingLeft: '5px' }}>{t('sms', { ns: 'layout_components' })}</Text>
          </Item>
          <Item key="call" textValue="call">
            <i className="bi bi-telephone" style={iconStyle}></i>&nbsp;&nbsp;
            <Text UNSAFE_style={{ color: '#36474F', paddingLeft: '5px' }}>{t('call', { ns: 'layout_components' })}</Text>
          </Item>
          <Item key="delete" textValue="delete">
            <i className="bi bi-trash" style={iconStyle}></i>&nbsp;&nbsp;
            <Text UNSAFE_style={{ color: '#36474F', paddingLeft: '5px' }}>{t('remove', { ns: 'layout_components' })}</Text>
          </Item>
        </Menu>
      </MenuTrigger>
    </View>
  );
};

export default ContextMenuButton;
