import React, { useEffect, useState } from 'react';
import { ActionButton, Content, DateField, DatePicker, Dialog, DialogTrigger, Flex, Item, Link, NumberField, Picker, TextField, View } from '@adobe/react-spectrum';
import { TrashIcon, AlertIconFill } from '../../Icons/IconsLib';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { RowData } from './ExpenseLineComponent';
import { useTranslation } from 'react-i18next';
import { parseDate } from '@internationalized/date';
import moment from 'moment';
import { Provider } from '@adobe/react-spectrum';
interface TimesheetLineAddEditProps {
  id?: string;
  defaultRowData: {};
  selectedRowData: RowData;
  typeofWorkList: any[];
  handleRowClose: () => void;
  addRecord: (record: any) => void;
  updateRecord: (record: any) => void;
  deleteRecord: (record: any) => void;
  closeDialog: (isOpen: boolean) => void;
  styles: any;
}

const TimesheetLineAddEdit: React.FC<TimesheetLineAddEditProps> = ({
  id,
  defaultRowData,
  selectedRowData,
  typeofWorkList,
  handleRowClose,
  addRecord,
  updateRecord,
  deleteRecord,
  closeDialog,
  styles,
}) => {
  const { isMobile } = useViewInfo();
  const [selectedRow, setSelectedRow] = useState<RowData>(selectedRowData || defaultRowData);

  const [validationMessages, setValidationMessages] = useState<any>({ typeOfWork: '', entryDate: '', qty: '', description: '' });
  const { t } = useTranslation();

  useEffect(() => {
    //console.log('selectedRowData', selectedRowData);
    //console.log('id', id);
    setValidationMessages(selectedRow.ident !== 0 ? { typeOfWork: '', entryDate: '', qty: '', description: '' } : { typeOfWork: 'required', entryDate: '', qty: '', description: '' });
    setSelectedRow(selectedRowData || defaultRowData);
  }, [id, selectedRowData]);

  const validateFields = () => !['typeOfWork', 'entryDate', 'qty'].some(field => validationMessages[field] !== '');

  const validateAndSubmit = () => {
    if (!validateFields()) return;
    const action = selectedRowData && Number(selectedRowData.ident) !== 0 ? updateRecord : addRecord;
    //console.log('selectedRow', selectedRow); // Optional: Only if needed for both cases
    closeDialog(false);
    action(selectedRow);
  };

  return (
    <>
      {isMobile ? (
        <Flex direction="column" marginTop={'size-200'}>
          <View>
            <Picker
              label={t('type_of_expense', { ns: 'layout_components' })}
              isRequired
              width={'100%'}
              items={typeofWorkList}
              onSelectionChange={value => {
                setValidationMessages((prev: any) => ({ ...prev, typeOfWork: '' }));
                const typeOfWorkName = typeofWorkList.find(item => item.ITEM.ident === value)?.title;
                setSelectedRow((prev: any) => ({ ...prev, typeOfWorkId: value, typeOfWorkName }));
              }}
              selectedKey={selectedRow.typeOfWorkId}
              validationState={validationMessages.typeOfWork !== '' ? 'invalid' : 'valid'}
            >
              {(item: any) => <Item key={item.ITEM.ident}>{item.ITEM.title}</Item>}
            </Picker>
          </View>
          <View>
            <Provider locale="en-US">
              <DatePicker
                label={t('date', { ns: 'layout_components' })}
                isRequired
                width={'100%'}
                value={parseDate(moment(selectedRow.entryDate, 'YYYY-MM-DD').format('YYYY-MM-DD'))}
                onChange={(value: any) => {
                  setSelectedRow(prev => ({ ...prev, entryDate: value }));
                }}
              />
            </Provider>
          </View>
          <View>
            <NumberField
              width={'100%'}
              value={Number(selectedRow.qty)}
              isRequired
              minValue={1}
              label={t('quantity', { ns: 'layout_components' })}
              onChange={value => {
                validationMessages.qty = isNaN(value) || value <= 0 ? 'required' : '';
                setValidationMessages((prev: any) => ({ ...prev, validationMessages }));
                setSelectedRow((prev: any) => ({ ...prev, qty: String(value) }));
              }}
              validationState={validationMessages.qty !== '' ? 'invalid' : 'valid'}
            />
          </View>
          <View>
            <TextField
              label={t('description', { ns: 'layout_components' })}
              width={'100%'}
              value={selectedRow.description}
              onChange={value => setSelectedRow(prev => ({ ...prev, description: value }))}
            />
          </View>
        </Flex>
      ) : (
        <table className="spectrum-Table" style={{ marginTop: '20px' }} cellSpacing={0}>
          <thead className="spectrum-Table-head">
            <tr>
              <th style={{ width: '25%' }} className="spectrum-Table-headCell">
                <span className="spectrum-Table-columnTitle">
                  <Picker
                    label=""
                    width={'100%'}
                    items={typeofWorkList}
                    onSelectionChange={value => {
                      setValidationMessages((prev: any) => ({ ...prev, typeOfWork: '' }));
                      const workerName = typeofWorkList.find(item => item.ITEM.ident === value)?.title;
                      setSelectedRow((prev: any) => ({ ...prev, typeOfWorkId: value, workerName }));
                    }}
                    selectedKey={selectedRow.typeOfWorkId}
                    validationState={validationMessages.typeOfWork !== '' ? 'invalid' : 'valid'}
                  >
                    {(item: any) => <Item key={item.ITEM.ident}>{item.ITEM.title}</Item>}
                  </Picker>
                </span>
              </th>
              <th style={{ width: '25%' }} className="spectrum-Table-headCell date_bg_fix">
                <Provider locale="en-US">
                  <DatePicker
                    UNSAFE_style={{ fontWeight: '400' }}
                    isRequired
                    width={'100%'}
                    value={parseDate(moment(selectedRow.entryDate, 'YYYY-MM-DD').format('YYYY-MM-DD'))}
                    onChange={(value: any) => {
                      setSelectedRow(prev => ({ ...prev, entryDate: value }));
                    }}
                  />
                </Provider>
              </th>
              <th style={{ width: '25%' }} className="spectrum-Table-headCell">
                <span className="spectrum-Table-columnTitle">
                  <NumberField
                    width={'100%'}
                    value={Number(selectedRow.qty)}
                    hideStepper
                    isRequired
                    minValue={1}
                    label=""
                    onChange={value => {
                      validationMessages.qty = isNaN(value) || value <= 0 ? 'Qty must be greater than 0' : '';
                      setValidationMessages((prev: any) => ({ ...prev, validationMessages }));
                      setSelectedRow(prev => ({ ...prev, qty: String(value) }));
                    }}
                    validationState={validationMessages.qty !== '' ? 'invalid' : 'valid'}
                  />
                </span>
              </th>
              <th style={{ width: '25%' }} className="spectrum-Table-headCell">
                <span className="spectrum-Table-columnTitle">
                  <TextField width={'100%'} value={selectedRow.description} onChange={value => setSelectedRow(prev => ({ ...prev, description: value }))} />
                </span>
              </th>
            </tr>
          </thead>
        </table>
      )}

      <Flex direction={{ base: 'column', L: 'row' }} justifyContent={'end'} gap={'size-175'} marginTop={'size-200'}>
        <Flex>
          {Number(selectedRowData.ident) !== 0 && (
            <Flex>
              <DialogTrigger isDismissable type="modal">
                <ActionButton UNSAFE_className={styles.btn_delete}>{t('remove_expense', { ns: 'layout_components' })}</ActionButton>
                {close => (
                  <Dialog size="S">
                    <Flex UNSAFE_className={styles.delete_pop_up_parent}>
                      <Content UNSAFE_className={styles.delete_pop_up_header}>{t('remove_expense', { ns: 'layout_components' })} </Content>
                      <View UNSAFE_className={styles.delete_pop_up_line}></View>
                      <View UNSAFE_className={styles.delete_pop_up_warning_text}>{t('are_you_sure_you_want_to_remove_expense', { ns: 'layout_components' })}</View>
                      <View UNSAFE_className={styles.buttons_parent}>
                        <View UNSAFE_className={styles.button_cancel}>
                          <Link UNSAFE_className={styles.delete_pop_up_cancel_text} isQuiet onPress={close}>
                            {t('no_keep_it', { ns: 'layout_components' })}
                          </Link>
                        </View>
                        <View UNSAFE_className={styles.button_confirm}>
                          <div className={styles.trash_icon}>
                            <TrashIcon size={32} />
                          </div>
                          <Link UNSAFE_className={styles.delete_pop_up_confirm_text} isQuiet onPress={() => deleteRecord(selectedRow)}>
                            {t('yes_remove', { ns: 'layout_components' })}
                          </Link>
                        </View>
                      </View>
                      <View UNSAFE_className={styles.ellipse_div}>
                        <View UNSAFE_className={styles.delete_alert_icon}>
                          <AlertIconFill size={72} />
                        </View>
                      </View>
                    </Flex>
                  </Dialog>
                )}
              </DialogTrigger>
            </Flex>
          )}
        </Flex>
        <Flex direction={{ base: 'row', L: 'row' }} gap={'size-200'}>
          <Flex>
            <Link UNSAFE_className={styles.btn_cancel} isQuiet onPress={handleRowClose}>
              {t('cancel', { ns: 'layout_components' })}
            </Link>
          </Flex>
          <Flex>
            <Link UNSAFE_className={styles.btn_confirm} isQuiet onPress={() => validateAndSubmit()}>
              {t('confirm_expense', { ns: 'layout_components' })}
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default TimesheetLineAddEdit;
