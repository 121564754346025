import { BaseRequest } from "../../BaseRequest";

export class UpdateFavouriteContextRequest extends BaseRequest{
    private folderId?:number;
    private projectId?:number;
    private activityId?:number;

    constructor(server: string, session: string, folderId?:number, projectId?:number, activityId?:number){
        super(server, session);
        this.folderId = folderId;
        this.projectId = projectId;
        this.activityId = activityId;
    }

    public toXml():string{
        var parameters = super.toXml();
        if(this.folderId){
            parameters += `<folderId>${this.folderId}</folderId>`;
        }
        if(this.projectId){
            parameters += `<projectId>${this.projectId}</projectId>`;
        }
        if(this.activityId){
            parameters += `<activityId>${this.activityId}</activityId>`;
        }

        return super.wrapperXml("UpdateFavouriteContext", parameters);
    }
}