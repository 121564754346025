import React, { useContext } from 'react';
import { IMainService, MainService } from '../services/soap/main/MainService';
import { IStore } from '../services/LocalStorage/IStore';
import { BrowserStorage } from '../services/LocalStorage/BrowserStorage';
import DependencyContext from './DependencyContext';
import { FeatureService, IFeatureService } from '../services/soap/features/FeatureService';
import { IProjectService, ProjectService } from '../services/soap/project/ProjectService';
import { IRecruitmentService, RecruitmentService } from '../services/soap/recruitment/RecruitmentService';
import { IRecruitmentRestService, RecruitmentRestService } from '../services/rest/recruitment/RecruitmentRestService';
import { AccessService, IAccessService } from '../services/soap/access/AccessService';
import { IItemService, ItemService } from '../services/soap/item/ItemService';
import { FormService, IFormService } from '../services/soap/form/FormService';
import { ITreeService, TreeService } from '../services/soap/tree/TreeService';
import { ITranslateService, TranslateService } from '../services/soap/translate/TranslateService';
import { useReefConfig } from '../hooks/UseReefConfig';
import { FinanceService, IFinanceService } from '../services/soap/finance/FinanceService';
import { GlobalService, IGlobalService } from '../services/soap/global/GlobalService';
import { ISchedulerService, SchedulerService } from '../services/soap/scheduler/SchedulerService';
import { IPrintRestService, PrintRestService } from '../services/rest/print/PrintRestService';
import { DocumentLibraryService, IDocumentLibraryService } from '../services/soap/document_library/DocumentLibraryService';
import { ITeamService, TeamService } from '../services/soap/team/TeamService';
import { IMessageService, MessageService } from '../services/soap/message/MessageService';

type DependencyProviderProps = {
    children: React.ReactNode;
}

export const DependencyProvider: React.FC<DependencyProviderProps> = ({ children }) => {
    const { soap, recruitment_dashboard, baseUrl } = useReefConfig();
    if(soap.uri === "" || recruitment_dashboard.uri === ""){
        return null;
    }
    const accessService: IAccessService = new AccessService(soap.uri);
    const mainService: IMainService = new MainService(soap.uri);
    const featureService: IFeatureService = new FeatureService(soap.uri);
    const projectService: IProjectService = new ProjectService(soap.uri);
    const recruitmentService: IRecruitmentService = new RecruitmentService(soap.uri);
    const itemService: IItemService = new ItemService(soap.uri);
    const formService: IFormService = new FormService(soap.uri);
    const treeService: ITreeService = new TreeService(soap.uri);
    const translateService: ITranslateService = new TranslateService(soap.uri);
    const financeService: IFinanceService = new FinanceService(soap.uri);
    const globalService: IGlobalService = new GlobalService(soap.uri);
    const schedulerService: ISchedulerService = new SchedulerService(soap.uri);
    const documentLibraryService: IDocumentLibraryService = new DocumentLibraryService(soap.uri);
    const teamService: ITeamService = new TeamService(soap.uri);
    const messageService: IMessageService = new MessageService(soap.uri);
    const recruitmentRestService: IRecruitmentRestService = new RecruitmentRestService(recruitment_dashboard.uri);
    const printRestService: IPrintRestService = new PrintRestService(baseUrl);

    const store: IStore = new BrowserStorage();
    return (<DependencyContext.Provider value={{ 
        accessService,
        mainService, 
        featureService, 
        projectService, 
        recruitmentService,
        itemService,
        formService,
        treeService,
        translateService,
        financeService,
        globalService,
        schedulerService,
        recruitmentRestService,
        documentLibraryService,
        teamService,
        messageService,
        printRestService,
        store,
    }}>{children}</DependencyContext.Provider>);
};

export function useDependency() {
    const context = useContext(DependencyContext);
    if (context === undefined) {
        throw new Error('useDependency must be used within a DependencyProvider');
    }
    return context;
}
