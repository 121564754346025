import { Badge, Button, ButtonGroup, Content, Dialog, Divider, Flex, Heading, View } from '@adobe/react-spectrum';
import * as React from 'react';
import { useViewInfo } from '../../../hooks/UseViewInfo';

export interface IHelpDialogProps {
    close: () => void;
}

export function HelpDialog ({
    close
}: IHelpDialogProps) {
    const { OS } = useViewInfo();
    var cmdCtrl = OS === 'Mac' ? 'Cmd' : 'Ctrl';
  return (
    <>
        <Dialog>
            <Heading>Help</Heading>
            <Divider />
            <Content>
                <Flex direction={'column'} gap={20} flex>
                    <Flex direction={'row'} justifyContent={'space-between'}>
                        <View>Open Global search</View>
                        <View>
                            <Badge variant='purple'>{cmdCtrl}</Badge> + <Badge variant='neutral'>G</Badge>
                        </View>
                    </Flex>
                    <Flex direction={'row'} justifyContent={'space-between'}>
                        <View>Open app launcher</View>
                        <View>
                            <Badge variant='purple'>{cmdCtrl}</Badge> + <Badge variant='yellow'>Shift</Badge> + <Badge variant='neutral'>A</Badge>
                        </View>
                    </Flex>
                    <Flex direction={'row'} justifyContent={'space-between'}>
                        <View>Close active app</View>
                        <View>
                            <Badge variant='purple'>{cmdCtrl}</Badge> + <Badge variant='yellow'>Shift</Badge> + <Badge variant='neutral'>Z</Badge>
                        </View>
                    </Flex>
                    <Flex direction={'row'} justifyContent={'space-between'}>
                        <View>Open Mail app</View>
                        <View>
                            <Badge variant='purple'>{cmdCtrl}</Badge> + <Badge variant='yellow'>Shift</Badge> + <Badge variant='neutral'>C</Badge>
                        </View>
                    </Flex>
                    <Flex direction={'row'} justifyContent={'space-between'}>
                        <View>Open Contacts app</View>
                        <View>
                            <Badge variant='purple'>{cmdCtrl}</Badge> + <Badge variant='yellow'>Shift</Badge> + <Badge variant='neutral'>O</Badge>
                        </View>
                    </Flex>
                    <Flex direction={'row'} justifyContent={'space-between'}>
                        <View>Open Calendar app</View>
                        <View>
                            <Badge variant='purple'>{cmdCtrl}</Badge> + <Badge variant='yellow'>Shift</Badge> + <Badge variant='neutral'>S</Badge>
                        </View>
                    </Flex>
                    <Flex direction={'row'} justifyContent={'space-between'}>
                        <View>Open Tasks app</View>
                        <View>
                            <Badge variant='purple'>{cmdCtrl}</Badge> + <Badge variant='yellow'>Shift</Badge> + <Badge variant='neutral'>U</Badge>
                        </View>
                    </Flex>
                    <Flex direction={'row'} justifyContent={'space-between'}>
                        <View>Open Finder app</View>
                        <View>
                            <Badge variant='purple'>{cmdCtrl}</Badge> + <Badge variant='yellow'>Shift</Badge> + <Badge variant='neutral'>F</Badge>
                        </View>
                    </Flex>
                    <Flex direction={'row'} justifyContent={'space-between'}>
                        <View>Open Documents app</View>
                        <View>
                            <Badge variant='purple'>{cmdCtrl}</Badge> + <Badge variant='yellow'>Shift</Badge> + <Badge variant='neutral'>D</Badge>
                        </View>
                    </Flex>
                </Flex>
            </Content>
            <ButtonGroup>
                <Button variant="secondary" onPress={close}>OK</Button>
            </ButtonGroup>
        </Dialog>
    </>
  );
}
