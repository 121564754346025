import { configureStore } from '@reduxjs/toolkit';
import userWebLayoutReducer from './userWebLayout/userWebLayoutSlice';
import finderReducer from './Finder/finderSlice';
//import { logger } from './middleware/logger';

export const store = configureStore({
    reducer: {
        userWebLayout: userWebLayoutReducer,
        finder: finderReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
    //.concat(logger)    
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;