import { Flex } from '@react-spectrum/layout';
import { View } from '@react-spectrum/view';
import styles from './ContextHeader.module.css';
import { SearchIcon } from '../../Icons/IconsLib';
import { FPAData } from '../../../../infra/protected/FPA/FPAData';

export interface IContextHeaderProps {
    item: FPAData;
    onSearch?: (id:number) => void;
}

export function ContextHeader ({
    item,
    onSearch
}: IContextHeaderProps) {
    const handleSearch = () => { if(onSearch) onSearch(item.id);}
  return (
    <>
        <Flex direction="row" UNSAFE_className={styles.container} justifyContent={'space-between'}>
            <View>{item.title}</View>
            <Flex direction="row" justifySelf={'end'} alignItems="end">
                <View paddingStart={10} UNSAFE_className={styles.search}>
                    <span onClick={handleSearch}>
                        <SearchIcon size={17} />
                    </span>
                </View>
            </Flex>
        </Flex>
    </>
  );
}
