const useDebounce = (func: Function, milliseconds: number) => {
  const time: number = milliseconds || 400;
  let timer: any;

  return (event: Event) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(func, time, event);
  };
};

export default useDebounce;
