import * as React from 'react';

export interface ILayoutComponentProps {
  percentWidth?: number;
  percentHeight?: number;
  width?: number;
  height?: number;
}

export function LayoutComponent<P>(WrappedComponent: React.FC) {
  const withLayoutPropsComponent:React.FC<P & ILayoutComponentProps> = ({
    percentHeight, 
    percentWidth, 
    width, 
    height, 
    ...props
  }) => {
    const getComputedStyle = () => {
      let style: any = {};
      if (percentWidth) {
        style.width = `${percentWidth}%`;
      }
      if (percentHeight) {
        style.height = `${percentHeight}%`;
      }
      if (width) {
        style.width = `${width}px`;
      }
      if (height) {
        style.height = `${height}px`;
      }
      // style.border = "solid 3px red";

      return style;
    }
    return (
      <div style={getComputedStyle()}>
        <WrappedComponent {...props} />
      </div>
    );
  }

  return withLayoutPropsComponent;
}
