import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import formatters from './formatters';

export const supportedLangs = [
  { code: 'en', lang: 'English' },
  { code: 'cz', lang: 'Czech' },
  { code: 'fr', lang: 'French' },
  { code: 'ar', lang: 'Arabic' },
];

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: ['common', 'recruitment', 'finder', 'layout-components', 'layout_components'],
    defaultNS: 'common',
    fallbackLng: 'en',
    preload: ['en', 'cz'],
    debug: false,
    returnObjects: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

// load additional namespaces after initialization
//i18next.loadNamespaces('anotherNamespace', (err, t) => { /* ... */ });

Object.entries(formatters).forEach(([key, resolver]) => {
  i18next.services.formatter?.add(key, resolver);
});

export default i18next;
