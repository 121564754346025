import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface ICheckWorkFlowQueueRequest extends IBaseRequest {
    limit?:     string;
    offset?:    string;
}

export class CheckWorkFlowQueueRequest extends BaseRequest implements ICheckWorkFlowQueueRequest {
    public limit?: string;
    public offset?: string;
    constructor(server: string, session: string, limit?: string, offset?: string) {
        super(server, session);
        this.limit = limit;
        this.offset = offset;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        if (this.limit) parameters += `<limit>${this.limit}</limit>`;
        if (this.offset) parameters += `<offset>${this.offset}</offset>`;
        
        return super.wrapperXml('CheckWorkFlowQueue', parameters);
    }
}
