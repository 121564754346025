import { SearchResultItemData } from '../../../../infra/protected/Search/SearchResultData';
import styles from './SearchResultGroup.module.css';
import SearchResultItem from './SearchResultItem';

export interface ISearchResultGroupProps {
    groupTitle: string;
    items: SearchResultItemData[];
    onSearchSelected: (item:any) => void;
    selectedItemIndex: number;
    isSelected: boolean;
}

export default function SearchResultGroup ({
    groupTitle,
    items,
    onSearchSelected,
    selectedItemIndex,
    isSelected
}: ISearchResultGroupProps) {
  return (
    <>
        <div className={styles.groupTitle}>{groupTitle}</div>
        <div className={styles.groupContainer}>
            {items.map((item, index) => 
                <SearchResultItem 
                  html_id={item.html_id}
                  key={index}
                  image={item.image} 
                  title={item.title} 
                  subtitle={item.subtitle} 
                  link={item.link} 
                  isFavorite={item.isFavorite}
                  breadcrumbs={item.breadcrumbs} 
                  onSearchSelected={onSearchSelected}
                  isSelected={selectedItemIndex === index && isSelected}
                  contextType={item.itemType}
                  contextId={item.itemId}
                  stateName={item.stateName}
                  stateColor={item.stateColor}
                  />
                                            )}
        </div>
        <div className={styles.groupSeparator}></div>
    </>
  );
}
