import { BaseRequest, IBaseRequest } from "../../BaseRequest";

export interface ILoginRequest extends IBaseRequest{
    userName: string;
    password: string;
    toXml():string;
}
export class LoginRequest extends BaseRequest implements ILoginRequest{
    userName: string;
    password: string;
    constructor(_username:string , _password:string, _server:string){
        super(_server, '');
        this.userName = _username;
        this.password = _password;
        this.server = _server;
    }
    toXml(): string {

        var parameters = `<server>${this.server}</server>
                <password>${this.password}</password>
                <userName>${this.userName}</userName>`;
                
        return super.wrapperXml("Login", parameters);
    }
}