import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListItemRequest extends IBaseRequest {
    limit:               number;
    offset:              number;
    orderBy?:             string;
    countOnly?:           boolean;
    orderByType?:         string;
    relatedItem?:         string;
    treeHandle?:          string;
    type?:                string;
    priceList?:           string;
    searchName?:          string;
    status?:              string;
    statusShow?:          string;
    refId?:               string;
    field0?:              string;
    field0_id?:           string;
    field1?:              string;
    field1_id?:           string;
    field2?:              string;
    field2_id?:           string;
    field3?:              string;
    field3_id?:           string;
    field4?:              string;
    field4_id?:           string;
    field5?:              string;
    field5_id?:           string;
    field6?:              string;
    field6_id?:           string;
    field7?:              string;
    field7_id?:           string;
    field8?:              string;
    field8_id?:           string;
    field9?:              string;
    field9_id?:           string;
    manufacturer?:        string;
    refIdExact?:          string;
    closingBalanceQty?:   string;
    availableBalanceQty?: string;
    externalSystem?:      string;
    externalId?:          string;
}

export class ListItemRequest extends BaseRequest implements IListItemRequest {
    public limit:               number;
    public offset:              number;
    public orderBy?:             string;
    public countOnly?:           boolean;
    public orderByType?:         string;
    public relatedItem?:         string;
    public treeHandle?:          string;
    public type?:                string;
    public priceList?:           string;
    public searchName?:          string;
    public status?:              string;
    public statusShow?:          string;
    public refId?:               string;
    public field0?:              string;
    public field0_id?:           string;
    public field1?:              string;
    public field1_id?:           string;
    public field2?:              string;
    public field2_id?:           string;
    public field3?:              string;
    public field3_id?:           string;
    public field4?:              string;
    public field4_id?:           string;
    public field5?:              string;
    public field5_id?:           string;
    public field6?:              string;
    public field6_id?:           string;
    public field7?:              string;
    public field7_id?:           string;
    public field8?:              string;
    public field8_id?:           string;
    public field9?:              string;
    public field9_id?:           string;
    public manufacturer?:        string;
    public refIdExact?:          string;
    public closingBalanceQty?:   string;
    public availableBalanceQty?: string;
    public externalSystem?:      string;
    public externalId?:          string;

    constructor(server: string, session: string, limit: number, offset: number, otherOptions: any) {
        super(server, session);
        this.limit = limit;
        this.offset = offset;
        this.orderBy = otherOptions.orderBy;
        this.countOnly = otherOptions.countOnly;
        this.orderByType = otherOptions.orderByType;
        this.relatedItem = otherOptions.relatedItem;
        this.treeHandle = otherOptions.treeHandle;
        this.type = otherOptions.type;
        this.priceList = otherOptions.priceList;
        this.searchName = otherOptions.searchName;
        this.status = otherOptions.status;
        this.statusShow = otherOptions.statusShow;
        this.refId = otherOptions.refId;
        this.field0 = otherOptions.field0;
        this.field0_id = otherOptions.field0_id;
        this.field1 = otherOptions.field1;
        this.field1_id = otherOptions.field1_id;
        this.field2 = otherOptions.field2;
        this.field2_id = otherOptions.field2_id;
        this.field3 = otherOptions.field3;
        this.field3_id = otherOptions.field3_id;
        this.field4 = otherOptions.field4;
        this.field4_id = otherOptions.field4_id;
        this.field5 = otherOptions.field5;
        this.field5_id = otherOptions.field5_id;
        this.field6 = otherOptions.field6;
        this.field6_id = otherOptions.field6_id;
        this.field7 = otherOptions.field7;
        this.field7_id = otherOptions.field7_id;
        this.field8 = otherOptions.field8;
        this.field8_id = otherOptions.field8_id;
        this.field9 = otherOptions.field9;
        this.field9_id = otherOptions.field9_id;
        this.manufacturer = otherOptions.manufacturer;
        this.refIdExact = otherOptions.refIdExact;
        this.closingBalanceQty = otherOptions.closingBalanceQty;
        this.availableBalanceQty = otherOptions.availableBalanceQty;
        this.externalSystem = otherOptions.externalSystem;
        this.externalId = otherOptions.externalId;
    }

  public toXml(): string {
    var parameters: string = super.toXml();

    parameters += '<limit>' + this.limit + '</limit>';
    parameters += '<offset>' + this.offset + '</offset>';

    if(this.orderBy) parameters += '<orderBy>' + this.orderBy + '</orderBy>';
    if(this.countOnly) parameters += '<countOnly>' + this.countOnly + '</countOnly>';
    if(this.orderByType) parameters += '<orderByType>' + this.orderByType + '</orderByType>';
    if(this.relatedItem) parameters += '<relatedItem>' + this.relatedItem + '</relatedItem>';
    if(this.treeHandle) parameters += '<treeHandle>' + this.treeHandle + '</treeHandle>';
    if(this.type) parameters += '<type>' + this.type + '</type>';
    if(this.priceList) parameters += '<priceList>' + this.priceList + '</priceList>';
    if(this.searchName) parameters += '<searchName>' + this.searchName + '</searchName>';
    if(this.status) parameters += '<status>' + this.status + '</status>';
    if(this.statusShow) parameters += '<statusShow>' + this.statusShow + '</statusShow>';
    if(this.refId) parameters += '<refId>' + this.refId + '</refId>';
    if(this.field0) parameters += '<field0>' + this.field0 + '</field0>';
    if(this.field0_id) parameters += '<field0_id>' + this.field0_id + '</field0_id>';
    if(this.field1) parameters += '<field1>' + this.field1 + '</field1>';
    if(this.field1_id) parameters += '<field1_id>' + this.field1_id + '</field1_id>';
    if(this.field2) parameters += '<field2>' + this.field2 + '</field2>';
    if(this.field2_id) parameters += '<field2_id>' + this.field2_id + '</field2_id>';
    if(this.field3) parameters += '<field3>' + this.field3 + '</field3>';
    if(this.field3_id) parameters += '<field3_id>' + this.field3_id + '</field3_id>';
    if(this.field4) parameters += '<field4>' + this.field4 + '</field4>';
    if(this.field4_id) parameters += '<field4_id>' + this.field4_id + '</field4_id>';
    if(this.field5) parameters += '<field5>' + this.field5 + '</field5>';
    if(this.field5_id) parameters += '<field5_id>' + this.field5_id + '</field5_id>';
    if(this.field6) parameters += '<field6>' + this.field6 + '</field6>';
    if(this.field6_id) parameters += '<field6_id>' + this.field6_id + '</field6_id>';
    if(this.field7) parameters += '<field7>' + this.field7 + '</field7>';
    if(this.field7_id) parameters += '<field7_id>' + this.field7_id + '</field7_id>';
    if(this.field8) parameters += '<field8>' + this.field8 + '</field8>';
    if(this.field8_id) parameters += '<field8_id>' + this.field8_id + '</field8_id>';
    if(this.field9) parameters += '<field9>' + this.field9 + '</field9>';
    if(this.field9_id) parameters += '<field9_id>' + this.field9_id + '</field9_id>';
    if(this.manufacturer) parameters += '<manufacturer>' + this.manufacturer + '</manufacturer>';
    if(this.refIdExact) parameters += '<refIdExact>' + this.refIdExact + '</refIdExact>';
    if(this.closingBalanceQty) parameters += '<closingBalanceQty>' + this.closingBalanceQty + '</closingBalanceQty>';
    if(this.availableBalanceQty) parameters += '<availableBalanceQty>' + this.availableBalanceQty + '</availableBalanceQty>';
    if(this.externalSystem) parameters += '<externalSystem>' + this.externalSystem + '</externalSystem>';
    if(this.externalId) parameters += '<externalId>' + this.externalId + '</externalId>';

    return super.wrapperXml('ListItem', parameters);
  }
}
