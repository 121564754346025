import { BaseRequest, IBaseRequest } from "../../BaseRequest";

export interface IGetContactRequest extends IBaseRequest {
    id?:number;
    organizationId?:number;
    contactId?:number;
    folder?:number;
    quickView?:boolean;
    oldId?:string;
    sysUser?:number;
    externalSystem?:string;
    externalId?:string;
}

export class GetContactRequest extends BaseRequest implements IGetContactRequest {
    public id?:number;
    public organizationId?:number;
    public contactId?:number;
    public folder?:number;
    public quickView?:boolean;
    public oldId?:string;
    public sysUser?:number;
    public externalSystem?:string;
    public externalId?:string;

    constructor(_server:string, _sessionId:string,
        _id?:number,
        _organizationId?:number,
        _contactId?:number,
        _folder?:number,
        _quickView?:boolean,
        _oldId?:string,
        _sysUser?:number,
        _externalSystem?:string,
        _externalId?:string,
        ) {

        super(_server, _sessionId);
        this.id = _id;
        this.organizationId = _organizationId;
        this.contactId = _contactId;
        this.folder = _folder;
        this.quickView = _quickView;
        this.oldId = _oldId;
        this.sysUser = _sysUser;
        this.externalSystem = _externalSystem;
        this.externalId = _externalId;
    }

    toXml(): string {
        var parameters:string = super.toXml();
        if(this.id) parameters += `<id>${this.id}</id>\n`;
        if(this.organizationId) parameters += `<organizationId>${this.organizationId}</organizationId>\n`;
        if(this.contactId) parameters += `<contactId>${this.contactId}</contactId>\n`;
        if(this.folder) parameters += `<folder>${this.folder}</folder>\n`;
        if(this.quickView !== undefined) parameters += `<quickView>${this.quickView}</quickView>\n`;
        if(this.oldId) parameters += `<oldId>${this.oldId}</oldId>\n`;
        if(this.sysUser) parameters += `<sysUser>${this.sysUser}</sysUser>\n`;
        if(this.externalSystem) parameters += `<externalSystem>${this.externalSystem}</externalSystem>\n`;
        if(this.externalId) parameters += `<externalId>${this.externalId}</externalId>\n`;

        return super.wrapperXml("GetContact", parameters);
    }
}