import { BaseRequest, IBaseRequest } from "../../BaseRequest";

export interface ICountDepartmentRequest extends IBaseRequest {
    server: string;
    session: string;
    timestamp?: string;
    toXml(): string;
}

export class CountDepartmentRequest extends BaseRequest implements ICountDepartmentRequest {
    timestamp?: string;

    constructor(
        server: string,
        session: string,
        timestamp?: string
    ) {
        super(server, session);
        this.timestamp = timestamp;
    }

    toXml(): string {
        var parameters = super.toXml();
        if (this.timestamp) parameters += `<timestamp>${this.timestamp}</timestamp>`;
        return super.wrapperXml("CountDepartment", parameters);
    }
}
