import { TreeView, TreeViewItem } from '@react-spectrum/tree';
import { FPAItem } from '../../../../infra/protected/FPA/FPAItem';
import { Text } from '@adobe/react-spectrum';

export interface IFinderContextTreeProps {
    items:FPAItem[];
    onSelection?: (key: string) => void;
}

export function FinderContextTree ({ items, onSelection }: IFinderContextTreeProps) {
    const handleSelectionChange = (key: any) => { 
        if (onSelection) {
            onSelection(key.currentKey);
        }
    };
    // const handleExpandedChange = (key: Set<Key>) => { console.log(`expanded changed: ${key}`); };
    // const handleAction = (key: Key) => { console.log(`action: ${key}`); };

    return (
        <TreeView
            aria-label='Finder Context Tree'
            items={items}
            selectionMode="single"
            onSelectionChange={handleSelectionChange}
            // onExpandedChange={handleExpandedChange}
            // onAction={handleAction}
            height={'size-6000'}
            selectionStyle='highlight'
        >
            {(item) => (
                <TreeViewItem key={item.id} textValue={item.title} childItems={item.childItems}>
                    <Text>{item.title}</Text>
                </TreeViewItem>
            )}
        </TreeView>
    );
}
