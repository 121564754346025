import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListCurrencyRequest extends IBaseRequest {
    limit:     number;
    offset:    number;
    orderBy?:   string;
    name?:      string;
    id?:        string;
    systemId?:  string;
}

export class ListCurrencyRequest extends BaseRequest implements IListCurrencyRequest {
    public limit:     number;
    public offset:    number;
    public orderBy?:   string;
    public name?:      string;
    public id?:        string;
    public systemId?:  string;

  constructor(server: string, session: string, limit: number, offset: number, orderBy?: string, name?: string, id?: string, systemId?: string) {
    super(server, session);
    this.limit = limit;
    this.offset = offset;
    this.orderBy = orderBy;
    this.name = name;
    this.id = id;
    this.systemId = systemId;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    parameters += '<limit>' + this.limit + '</limit>';
    parameters += '<offset>' + this.offset + '</offset>';
    if (this.orderBy) {
      parameters += '<orderBy>' + this.orderBy + '</orderBy>';
    }
    if (this.name) {
      parameters += '<name>' + this.name + '</name>';
    }
    if (this.id) {
      parameters += '<id>' + this.id + '</id>';
    }
    if (this.systemId) {
      parameters += '<systemId>' + this.systemId + '</systemId>';
    }

    return super.wrapperXml('ListCurrency', parameters);
  }
}
