import { BaseRequest } from "../../../soap/BaseRequest";


export class PrintContextRequest extends BaseRequest {
    public print_type: string;
    public object_id: string;
    public output_format: string;
    public signatureName:string;
    public signaturePicture: string;

    constructor(
        server: string, session: string, 
        print_type: string, object_id: string, output_format: string, 
        signatureName:string, signaturePicture: string) {
        super(server, session);

        this.print_type = print_type;
        this.object_id = object_id;
        this.output_format = output_format;
        this.signatureName = signatureName;
        this.signaturePicture = signaturePicture;
    }
}
