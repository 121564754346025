import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IGetSchedulerSettingsRequest extends IBaseRequest {
    listType:   string;
    itemType:   SettingsItemType;
    limit:      number;
    offset:     number;
    countOnly?:  boolean;
}

export enum SettingsItemType {
    EVENT_ITEM_TYPE = 1 << 0,
    TASK_ITEM_TYPE = 1 << 1,
    TIME_SHEET_ITEM_TYPE = 1 << 2,
    EXPENSE_ITEM_TYPE = 1 << 3,
    ISSUE_ITEM_TYPE = 1 << 4,
    ALL_ITEM_TYPES = EVENT_ITEM_TYPE | TASK_ITEM_TYPE | TIME_SHEET_ITEM_TYPE | EXPENSE_ITEM_TYPE | ISSUE_ITEM_TYPE
}
export class GetSchedulerSettingsRequest extends BaseRequest implements IGetSchedulerSettingsRequest {
    public listType: string;
    public countOnly?: boolean;
    public itemType: SettingsItemType;
    public limit: number;
    public offset: number;

    constructor(server: string, session: string, listType: string, itemType: SettingsItemType, limit: number, offset: number, countOnly?: boolean) {
        super(server, session);
        this.listType = listType;
        this.countOnly = countOnly;
        this.itemType = itemType;
        this.limit = limit;
        this.offset = offset;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        parameters += '<listType>' + this.listType + '</listType>';
        parameters += '<limit>' + this.limit + '</limit>';
        parameters += '<offset>' + this.offset + '</offset>';

        if (this.countOnly) {
            parameters += '<countOnly>' + this.countOnly + '</countOnly>';
        }
        
        return super.wrapperXml('GetSchedulerSettings', parameters);
    }
}
