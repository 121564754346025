import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IGetProjectRequest extends IBaseRequest {
    id: number;
    externalSystem?: string;
    externalId?: string;
}

export class GetProjectRequest extends BaseRequest implements IGetProjectRequest {
    public id: number;
    public externalSystem?: string;
    public externalId?: string;

  constructor(server: string, session: string, id: number, externalSystem?: string, externalId?: string) {
    super(server, session);
    this.id = id;
    this.externalSystem = externalSystem;
    this.externalId = externalId;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    parameters += '<id>' + this.id + '</id>';
    if(this.externalSystem) parameters += '<externalSystem>' + this.externalSystem + '</externalSystem>';
    if(this.externalId) parameters += '<externalId>' + this.externalId + '</externalId>';
    
    return super.wrapperXml('GetProject', parameters);
  }
}
