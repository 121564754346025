import Dexie, {Table} from 'dexie';

export interface CacheData {
    id?: number;
    type: string;
    data: string;
    timestamp: Date;
}

export class CacheDataDB extends Dexie {
    public cacheData!: Table<CacheData, number>;

    public constructor() {
        super('CacheDataDB');
        this.version(1).stores({
            cacheData: '++id,type,data,timestamp',
        });
    }
}

export const cacheDataDB = new CacheDataDB();