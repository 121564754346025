import { ActionButton, Flex, View } from '@adobe/react-spectrum';
import * as React from 'react';
import styles from './ActivityCell.module.css';
import { ActivityIcon, IconStyle, SearchIcon } from '../../Icons/IconsLib';
import { FPAData } from '../../../../infra/protected/FPA/FPAData';
import ConstantUtils from '../../../../utils/ConstantUtils';
import useDeviceType from '../../../../hooks/UseDeviceType';

export interface IActivityCellProps {
    item: FPAData;
    onSearch?: (id:number) => void;
    onClicked?: (id:number) => void;
}

export function ActivityCell ({
    item,
    onSearch,
    onClicked
}: IActivityCellProps) {
    const { isSmallDevice } = useDeviceType();
    const handleSearch = () => { if(onSearch) onSearch(0);}

    var spacing = Math.max(0,30 + (item.node_level - 1) * 50);
    var display_title = item.title;
    var label_width = (isSmallDevice ? window.innerWidth - 205 : window.innerWidth / 4 - 205 )  - spacing;
    var line1_width = label_width;

  return (
    <>
        <Flex direction="row" UNSAFE_className={styles.activityWrapper}>
            <View width={spacing} />
            <View>
                <ActivityIcon size={24} />
            </View>
            <Flex direction="column" marginStart={10} flex>
                <Flex direction="row" alignItems="center" gap={'size-100'}>
                    <View>
                        <span className={styles.title} style={{maxWidth: label_width}}>{display_title}</span> 
                    </View>
                    {item.count > 0 &&
                    <View>
                        <span className={styles.count}>{item.count}</span>
                    </View>}
                </Flex>                    
                <View UNSAFE_className={styles.line1} UNSAFE_style={{maxWidth: line1_width}}>
                    {item.line1}
                </View>
                <View UNSAFE_className={styles.line2}>
                    {item.line2}
                </View>
            </Flex>
            <Flex direction="row" alignItems="end" alignSelf={'center'} justifySelf={'end'} justifyContent={'end'} UNSAFE_className={styles.statusArea}>
                <View paddingEnd={10} UNSAFE_className={styles.search}>
                    <ActionButton isQuiet 
                        onPress={handleSearch}
                        UNSAFE_style={ { height: '21px' }}
                        >
                        <span>
                            <SearchIcon size={18} stateClass={item.isFiltered ? IconStyle.filtered : IconStyle.normal}/>
                        </span>
                    </ActionButton>                    
                </View>
                <View UNSAFE_className={styles.status}>
                    <div className={styles.statusIndicator} style={{backgroundColor: item.statusColor}}></div>
                </View>
                <View UNSAFE_className={styles.statusText}>
                {item.statusName}
                </View>
            </Flex>
        </Flex>
    </>
  );
}
