import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IDeleteProjectItemRequest extends IBaseRequest {
    id: string;
}

export class DeleteProjectItemRequest extends BaseRequest implements IDeleteProjectItemRequest {
    public id: string;
  constructor(server: string, session: string, id: string) {
    super(server, session);
    this.id = id;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    parameters += '<ITEM>';
    parameters += '<id>' + this.id + '</id>';
    parameters += '</ITEM>';

    return super.wrapperXml('DeleteProjectItem', parameters);
  }
}
