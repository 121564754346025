import { Flex } from '@adobe/react-spectrum';
import { useSearchParams } from 'react-router-dom';
import { EmptyHolder } from '../../../components/protected/EmptyHolder/EmptyHolder';
import { getAtollonUrl } from '../../../utils/NavigationUtils';
export interface IEmptyProps {
}

export default function Empty ({

}: IEmptyProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  console.log('Empty', searchParams.get('service'));
  return (
    <>
      <Flex direction="column" gap="size-100" justifyContent={'center'} alignItems={'center'} height={'70vh'}>
        <EmptyHolder url={getAtollonUrl(searchParams.get('service'))}/>
      </Flex>
    </>
  );
}
