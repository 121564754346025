import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListLanguagesRequest extends IBaseRequest {
    languageLong?: string;
}

export class ListLanguagesRequest extends BaseRequest implements IListLanguagesRequest {

    public languageLong?: string;
  constructor(server: string, session: string, languageLong?: string) {
    super(server, session);
    this.languageLong = languageLong;
  }

  public toXml(): string {
    var parameters: string = super.toXml();
    if(this.languageLong) parameters += '<languageLong>' + this.languageLong + '</languageLong>';
    return super.wrapperXml('ListLanguages', parameters);
  }
}
