import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IGetFolderRequest extends IBaseRequest {
    id: number;
    noContactOrItemDetails?: number;
}

export class GetFolderRequest extends BaseRequest implements IGetFolderRequest {

    public id: number;
    public noContactOrItemDetails?: number;

    constructor(server: string, session: string, id: number, noContactOrItemDetails?: number) {
        super(server, session);
        this.id = id;
        this.noContactOrItemDetails = noContactOrItemDetails;
    }

    public toXml(): string {
        var parameters: string = super.toXml();
        parameters += '<id>' + this.id + '</id>';
        if (this.noContactOrItemDetails) {
            parameters += '<noContactOrItemDetails>' + this.noContactOrItemDetails + '</noContactOrItemDetails>';
        }
        return super.wrapperXml('GetFolder', parameters);
    }
}
