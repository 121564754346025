import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface ListFilter{
    rangeStart:                     Date;
    rangeEnd:                       Date;
    userId?:                         string;
    anyTime?:                        string;
    standardStatus?:                 string;
    entryType?:                      string;
    activityId?:                     string;
    projectId?:                      string;
    folderId?:                       string;
    solutionStatus?:                 string;
    includePast?:                    string;
    userFilter?:                     string;
    dependend?:                      string;
    recordId?:                       string;
    referenceId?:                    string;
    approvalStatus?:                 string;
    limit?:                          number;
    offset?:                         number;
    countOnly?:                      string;
    orderBy1Desc?:                   string;
    orderBy1Field?:                  string;
    orderBy2Desc?:                   string;
    orderBy2Field?:                  string;
    orderBy3Desc?:                   string;
    orderBy3Field?:                  string;
    orderBy4Desc?:                   string;
    orderBy4Field?:                  string;
    dateOfInterest?:                 string;
    groupUsers?:                     string;
    multiUser?:                      string;
    dimension1?:                     string;
    dimension2?:                     string;
    dimension3?:                     string;
    dimension1Name?:                 string;
    dimension2Name?:                 string;
    dimension3Name?:                 string;
    garant?:                         string;
    plannedTaskCurrent?:             string;
    masterProjectResponsibleUserId?: string;
    masterProjectId?:                string;
    USERFILTER?:                     Userfilter;
    IDFILTER?:                       Idfilter;
    MASSCHANGE?:                     Masschange;
}

export interface Idfilter {
    id?: string;
}

export interface Masschange {
    changeChargeability?: string;
    chargeability?:       string;
    changeTypeOfWork?:    string;
    typeOfWorkId?:        string;
    changeContext?:       string;
    folderId?:            string;
    projectId?:           string;
    activityId?:          string;
    changeTitle?:         string;
    newTitle?:            string;
    changeDescription?:   string;
    newDescription?:      string;
    changeComment?:       string;
    newComment?:          string;
    addUser?:             string;
    addedUserId?:         string;
    removeUser?:          string;
    removedUserId?:       string;
    changeReminder?:      string;
    reminderTime?:        string;
    addNotify?:           string;
    addedNotifyId?:       string;
    removeNotify?:        string;
    removedNotifyId?:     string;
}

export interface Userfilter {
    id?:        string;
    asGarant?:  string;
    asCreator?: string;
}

export interface IListTaskRequest extends IBaseRequest {
    filter: ListFilter;
}

export class ListTaskRequest extends BaseRequest implements IListTaskRequest {
    public filter: ListFilter;
  constructor(server: string, session: string, filter: ListFilter) {
    super(server, session);
    this.filter = filter;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    parameters += `<rangeStart>${this.filter.rangeStart.toISOString()}</rangeStart>`;
    parameters += `<rangeEnd>${this.filter.rangeEnd.toISOString()}</rangeEnd>`;
    if(this.filter.userId) parameters += `<userId>${this.filter.userId}</userId>`;
    if(this.filter.anyTime) parameters += `<anyTime>${this.filter.anyTime}</anyTime>`;
    if(this.filter.standardStatus) parameters += `<standardStatus>${this.filter.standardStatus}</standardStatus>`;
    if(this.filter.entryType) parameters += `<entryType>${this.filter.entryType}</entryType>`;
    if(this.filter.activityId) parameters += `<activityId>${this.filter.activityId}</activityId>`;
    if(this.filter.projectId) parameters += `<projectId>${this.filter.projectId}</projectId>`;
    if(this.filter.folderId) parameters += `<folderId>${this.filter.folderId}</folderId>`;
    if(this.filter.solutionStatus) parameters += `<solutionStatus>${this.filter.solutionStatus}</solutionStatus>`;
    if(this.filter.includePast) parameters += `<includePast>${this.filter.includePast}</includePast>`;
    if(this.filter.userFilter) parameters += `<userFilter>${this.filter.userFilter}</userFilter>`;
    if(this.filter.dependend) parameters += `<dependend>${this.filter.dependend}</dependend>`;
    if(this.filter.recordId) parameters += `<recordId>${this.filter.recordId}</recordId>`;
    if(this.filter.referenceId) parameters += `<referenceId>${this.filter.referenceId}</referenceId>`;
    if(this.filter.approvalStatus) parameters += `<approvalStatus>${this.filter.approvalStatus}</approvalStatus>`;
    if(this.filter.limit) parameters += `<limit>${this.filter.limit}</limit>`;
    if(this.filter.offset) parameters += `<offset>${this.filter.offset}</offset>`;
    if(this.filter.countOnly) parameters += `<countOnly>${this.filter.countOnly}</countOnly>`;
    if(this.filter.orderBy1Desc) parameters += `<orderBy1Desc>${this.filter.orderBy1Desc}</orderBy1Desc>`;
    if(this.filter.orderBy1Field) parameters += `<orderBy1Field>${this.filter.orderBy1Field}</orderBy1Field>`;
    if(this.filter.orderBy2Desc) parameters += `<orderBy2Desc>${this.filter.orderBy2Desc}</orderBy2Desc>`;
    if(this.filter.orderBy2Field) parameters += `<orderBy2Field>${this.filter.orderBy2Field}</orderBy2Field>`;
    if(this.filter.orderBy3Desc) parameters += `<orderBy3Desc>${this.filter.orderBy3Desc}</orderBy3Desc>`;
    if(this.filter.orderBy3Field) parameters += `<orderBy3Field>${this.filter.orderBy3Field}</orderBy3Field>`;
    if(this.filter.orderBy4Desc) parameters += `<orderBy4Desc>${this.filter.orderBy4Desc}</orderBy4Desc>`;
    if(this.filter.orderBy4Field) parameters += `<orderBy4Field>${this.filter.orderBy4Field}</orderBy4Field>`;
    if(this.filter.dateOfInterest) parameters += `<dateOfInterest>${this.filter.dateOfInterest}</dateOfInterest>`;
    if(this.filter.groupUsers) parameters += `<groupUsers>${this.filter.groupUsers}</groupUsers>`;
    if(this.filter.multiUser) parameters += `<multiUser>${this.filter.multiUser}</multiUser>`;
    if(this.filter.dimension1) parameters += `<dimension1>${this.filter.dimension1}</dimension1>`;
    if(this.filter.dimension2) parameters += `<dimension2>${this.filter.dimension2}</dimension2>`;
    if(this.filter.dimension3) parameters += `<dimension3>${this.filter.dimension3}</dimension3>`;
    if(this.filter.dimension1Name) parameters += `<dimension1Name>${this.filter.dimension1Name}</dimension1Name>`;
    if(this.filter.dimension2Name) parameters += `<dimension2Name>${this.filter.dimension2Name}</dimension2Name>`;
    if(this.filter.dimension3Name) parameters += `<dimension3Name>${this.filter.dimension3Name}</dimension3Name>`;
    if(this.filter.garant) parameters += `<garant>${this.filter.garant}</garant>`;
    if(this.filter.plannedTaskCurrent) parameters += `<plannedTaskCurrent>${this.filter.plannedTaskCurrent}</plannedTaskCurrent>`;
    if(this.filter.masterProjectResponsibleUserId) parameters += `<masterProjectResponsibleUserId>${this.filter.masterProjectResponsibleUserId}</masterProjectResponsibleUserId>`;
    if(this.filter.masterProjectId) parameters += `<masterProjectId>${this.filter.masterProjectId}</masterProjectId>`;

    if(this.filter.USERFILTER){
        parameters += `<USERFILTER>`;

        if(this.filter.USERFILTER.id) parameters += `<id>${this.filter.USERFILTER.id}</id>`;
        if(this.filter.USERFILTER.asGarant) parameters += `<asGarant>${this.filter.USERFILTER.asGarant}</asGarant>`;
        if(this.filter.USERFILTER.asCreator) parameters += `<asCreator>${this.filter.USERFILTER.asCreator}</asCreator>`;

        parameters += `</USERFILTER>`;
    }

    if(this.filter.IDFILTER){
        parameters += `<IDFILTER>`;

        if(this.filter.IDFILTER.id) parameters += `<id>${this.filter.IDFILTER.id}</id>`;

        parameters += `</IDFILTER>`;
    }

    if(this.filter.MASSCHANGE){
        parameters += `<MASSCHANGE>`;

        if(this.filter.MASSCHANGE.changeChargeability) parameters += `<changeChargeability>${this.filter.MASSCHANGE.changeChargeability}</changeChargeability>`;
        if(this.filter.MASSCHANGE.chargeability) parameters += `<chargeability>${this.filter.MASSCHANGE.chargeability}</chargeability>`;
        if(this.filter.MASSCHANGE.changeTypeOfWork) parameters += `<changeTypeOfWork>${this.filter.MASSCHANGE.changeTypeOfWork}</changeTypeOfWork>`;
        if(this.filter.MASSCHANGE.typeOfWorkId) parameters += `<typeOfWorkId>${this.filter.MASSCHANGE.typeOfWorkId}</typeOfWorkId>`;
        if(this.filter.MASSCHANGE.changeContext) parameters += `<changeContext>${this.filter.MASSCHANGE.changeContext}</changeContext>`;
        if(this.filter.MASSCHANGE.folderId) parameters += `<folderId>${this.filter.MASSCHANGE.folderId}</folderId>`;
        if(this.filter.MASSCHANGE.projectId) parameters += `<projectId>${this.filter.MASSCHANGE.projectId}</projectId>`;
        if(this.filter.MASSCHANGE.activityId) parameters += `<activityId>${this.filter.MASSCHANGE.activityId}</activityId>`;
        if(this.filter.MASSCHANGE.changeTitle) parameters += `<changeTitle>${this.filter.MASSCHANGE.changeTitle}</changeTitle>`;
        if(this.filter.MASSCHANGE.newTitle) parameters += `<newTitle>${this.filter.MASSCHANGE.newTitle}</newTitle>`;
        if(this.filter.MASSCHANGE.changeDescription) parameters += `<changeDescription>${this.filter.MASSCHANGE.changeDescription}</changeDescription>`;
        if(this.filter.MASSCHANGE.newDescription) parameters += `<newDescription>${this.filter.MASSCHANGE.newDescription}</newDescription>`;
        if(this.filter.MASSCHANGE.changeComment) parameters += `<changeComment>${this.filter.MASSCHANGE.changeComment}</changeComment>`;
        if(this.filter.MASSCHANGE.newComment) parameters += `<newComment>${this.filter.MASSCHANGE.newComment}</newComment>`;
        if(this.filter.MASSCHANGE.addUser) parameters += `<addUser>${this.filter.MASSCHANGE.addUser}</addUser>`;
        if(this.filter.MASSCHANGE.addedUserId) parameters += `<addedUserId>${this.filter.MASSCHANGE.addedUserId}</addedUserId>`;
        if(this.filter.MASSCHANGE.removeUser) parameters += `<removeUser>${this.filter.MASSCHANGE.removeUser}</removeUser>`;
        if(this.filter.MASSCHANGE.removedUserId) parameters += `<removedUserId>${this.filter.MASSCHANGE.removedUserId}</removedUserId>`;
        if(this.filter.MASSCHANGE.changeReminder) parameters += `<changeReminder>${this.filter.MASSCHANGE.changeReminder}</changeReminder>`;
        if(this.filter.MASSCHANGE.reminderTime) parameters += `<reminderTime>${this.filter.MASSCHANGE.reminderTime}</reminderTime>`;
        if(this.filter.MASSCHANGE.addNotify) parameters += `<addNotify>${this.filter.MASSCHANGE.addNotify}</addNotify>`;
        if(this.filter.MASSCHANGE.addedNotifyId) parameters += `<addedNotifyId>${this.filter.MASSCHANGE.addedNotifyId}</addedNotifyId>`;
        if(this.filter.MASSCHANGE.removeNotify) parameters += `<removeNotify>${this.filter.MASSCHANGE.removeNotify}</removeNotify>`;
        if(this.filter.MASSCHANGE.removedNotifyId) parameters += `<removedNotifyId>${this.filter.MASSCHANGE.removedNotifyId}</removedNotifyId>`;

        parameters += `</MASSCHANGE>`;
    }

    return super.wrapperXml('ListTask', parameters);
  }
}
