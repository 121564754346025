import { View } from '@adobe/react-spectrum';
import { LayoutComponent } from '../LayoutComponent';
import { EmptyComponent } from '../../EmptyHolder/EmptyComponent';
import { HeaderToolbarComponent } from '../HeaderToolbar/HeaderToolbarComponent';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { BillingLineComponent } from '../BillingLine/BillingLineComponent';
import { TimesheetLineComponent } from '../TimesheetLine/TimesheetLineComponent';
import { SystemAttributes } from '../../../../infra/protected/Layout/SystemAttributes';
import { ContextCommentComponent } from '../ContextComment/ContextCommentComponent';
import { ExpenseLineComponent } from '../ExpenseLine/ExpenseLineComponent';
import { SignComponent } from '../SignComponent/SignComponent';
import { TaskListComponent } from '../TaskList/TaskListComponent';
import { FormViewComponent } from '../FormView/FormViewComponent';
import { TitleComponent } from '../Title/TitleComponent';
import { DocumentComponent } from '../DocumentComponent/DocumentComponent';
import { GalleryComponent } from '../GalleryComponent/GalleryComponent';
import { ExternalContactsComponent } from '../ExternalContacts/ExternalContactsComponent';
import { RefreshButtonComponent } from '../RefreshButton/RefreshButtonComponent';
import { ResponsibleUsersComponent } from '../ResponsibleUsers/ResponsibleUsersComponent';
import { SelectionProcedureDetail } from '../SelectionProcedureDetail/SelectionProcedureDetail';
import { ServiceActivityHeaderComponent } from '../ServiceActivityHeader/ServiceActivityHeaderComponent';
import { TimeLineComponent } from '../TimeLineComponent/TimeLineComponent';
import { FPAData } from '../../../../infra/protected/FPA/FPAData';
import { ProjectProductComponent } from '../ProjectProduct/ProjectProductComponent';

export interface IBasicLayoutProps {
  viewComponent?: string;
  componentAttributes?: any;
  systemAttributes?: SystemAttributes<any>;
  selectedItem?: FPAData;
}

function BasicLayout({ viewComponent, componentAttributes, systemAttributes, selectedItem }: IBasicLayoutProps) {
  // const selectedItem = useSelector((state: RootState) => state.finder.selectedItem);

  if (!viewComponent || !selectedItem) return <>No viewComponent</>;

  var url = `atollon://finder/openDetail/?id=${selectedItem.id}&type=${selectedItem.type}`;

  switch (viewComponent) {
    case 'com.atollon.finder.headerToolbarFull':
      return (
        <View>
          <HeaderToolbarComponent selectedItem={selectedItem} {...componentAttributes} {...systemAttributes} />
        </View>
      );
    case 'com.atollon.item.BillingLineComponent':
      return (
        <View>
          <BillingLineComponent selectedItem={selectedItem} {...componentAttributes} {...systemAttributes} />
        </View>
      );
    case 'com.atollon.scheduler.timesheetLine2Component':
      return (
        <View>
          <TimesheetLineComponent selectedItem={selectedItem} {...componentAttributes} {...systemAttributes} />
        </View>
      );
    case 'com.atollon.scheduler.expensetLineComponent':
      return (
        <View>
          <ExpenseLineComponent selectedItem={selectedItem} {...componentAttributes} {...systemAttributes} />
        </View>
      );
    case 'com.atollon.finder.contextCommentComponent':
      return (
        <View>
          <ContextCommentComponent selectedItem={selectedItem} {...componentAttributes} {...systemAttributes} />
        </View>
      );
    case 'com.atollon.finder.signatureComponent':
      return (
        <View>
          <SignComponent selectedItem={selectedItem} {...componentAttributes} {...systemAttributes} />
        </View>
      );
    case 'com.atollon.TaskList':
      return (
        <View>
          <TaskListComponent selectedItem={selectedItem} {...componentAttributes} {...systemAttributes} />
        </View>
      );
    case 'com.atollon.FormView':
      return (
        <View>
          <FormViewComponent formIdType="self" selectedItem={selectedItem} {...componentAttributes} {...systemAttributes} />
        </View>
      );
    case 'com.atollon.ProjectFormView':
      return (
        <View>
          <FormViewComponent formIdType="project" selectedItem={selectedItem} {...componentAttributes} {...systemAttributes} />
        </View>
      );
    case 'com.atollon.FolderFormView':
      return (
        <View>
          <FormViewComponent formIdType="folder" selectedItem={selectedItem} {...componentAttributes} {...systemAttributes} />
        </View>
      );
    case 'com.atollon.finder.titleComponent':
      return (
        <View>
          <TitleComponent selectedItem={selectedItem} {...componentAttributes} {...systemAttributes} />
        </View>
      );
    case 'com.atollon.DocumentManager':
      return (
        <View>
          <DocumentComponent selectedItem={selectedItem} {...componentAttributes} {...systemAttributes} />
        </View>
      );
    case 'com.atollon.core.document.GalleryView':
      return (
        <View>
          <GalleryComponent selectedItem={selectedItem} {...componentAttributes} {...systemAttributes} />
        </View>
      );
    case 'com.atollon.finder.externalContactsView':
      return (
        <View>
          <ExternalContactsComponent selectedItem={selectedItem} {...componentAttributes} {...systemAttributes} />
        </View>
      );
    case 'com.atollon.finder.refreshContentButton':
      return (
        <View>
          <RefreshButtonComponent selectedItem={selectedItem} {...componentAttributes} {...systemAttributes} />
        </View>
      );
    case 'com.atollon.finder.responsibleUsersView':
      return (
        <View>
          <ResponsibleUsersComponent selectedItem={selectedItem} {...componentAttributes} {...systemAttributes} />
        </View>
      );
    case 'com.atollon.core.project.ServiceActivityHeader':
      return (
        <View>
          <ServiceActivityHeaderComponent selectedItem={selectedItem} {...componentAttributes} {...systemAttributes} />
        </View>
      );
    case 'com.atollon.recruitment.selectionProcedure':
      return (
        <View>
          <SelectionProcedureDetail selectedItem={selectedItem} {...componentAttributes} {...systemAttributes} />
        </View>
      );
    case 'com.atollon.finder.contextTimeline':
      return (
        <View>
          <TimeLineComponent selectedItem={selectedItem} {...componentAttributes} {...systemAttributes} />
        </View>
      );
    // case 'com.atollon.item.InstalledTechnologyLineComponent':
    //   return (
    //     <View>
    //       <ProjectProductComponent selectedItem={selectedItem} {...componentAttributes} {...systemAttributes} />
    //     </View>
    //   );
    default:
      return (
        <View>
          <EmptyComponent title={viewComponent} url={url} />
        </View>
      );
  }
}

export const BasicLayoutComponent = LayoutComponent(BasicLayout);
