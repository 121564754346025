import { useEffect, useRef, useState } from 'react';
import { LayoutComponent } from '../LayoutComponent';
import { Flex, Content, View, Link, ProgressCircle, DialogContainer } from '@adobe/react-spectrum';
import ResponsibleUsersSearch from './component/ResponsibleUsersSearch';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../state/store';
import { ToastQueue } from '@react-spectrum/toast';
import useComonentReload from '../../../../hooks/UseComponentReload';
import ContactCard from './component/ContactCard';
import { FPAData, FPADataTypes } from '../../../../infra/protected/FPA/FPAData';
import { ListActivityUserRequest } from '../../../../services/soap/team/requests/ListActivityUserRequest';
import { UpdateActivityUserRequest } from '../../../../services/soap/team/requests/UpdateActivityUserRequest';
import { UpdateProjectUserRequest } from '../../../../services/soap/team/requests/UpdateProjectUserRequest';
import { UpdateFolderUserRequest } from '../../../../services/soap/team/requests/UpdateFolderUserRequest';
import { ListProjectUserRequest } from '../../../../services/soap/team/requests/ListProjectUserRequest';
import { ListFolderUserRequest } from '../../../../services/soap/team/requests/ListFolderUserRequest';
import { t } from 'i18next';
import styles from './ResponsibleUsers.module.css';

interface ResponsibleUsersProps {
  selectedItem?: FPAData;
}

type UpdateRequestFunction = () => Promise<{ result: string }>;

function ResponsibleUsers({ selectedItem }: ResponsibleUsersProps) {
  const [reloadComponent] = useComonentReload();
  const { teamService, store } = useDependency();
  const [responsibleUsers, setResponsibleUsers] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [isOpenSearchContact, setIsOpenSearchContact] = useState<boolean>(false);

  const current_Item = useRef<any>(null);

  useEffect(() => {
    (async () => {
      await loadResponsibleUsers();
    })();
  }, [selectedItem, reloadComponent]);

  const loadResponsibleUsers = async () => {
    if (!selectedItem) return;
    setShowLoader(true);

    switch (selectedItem.type) {
      case FPADataTypes.ACTIVITY:
        let listActivityUser = await teamService.listActivityUser(new ListActivityUserRequest(store.Server, store.SessionId, Number(selectedItem.id)));
        setResponsibleUsers(listActivityUser?.ROW);
        current_Item.current = listActivityUser;
        break;
      case FPADataTypes.PROJECT:
        let listProjectUser = await teamService.listProjectUser(new ListProjectUserRequest(store.Server, store.SessionId, Number(selectedItem.id)));
        setResponsibleUsers(listProjectUser?.ROWS?.ROW);
        current_Item.current = listProjectUser;
        break;
      case FPADataTypes.FOLDER:
        let listFolderUser = await teamService.listFolderUser(new ListFolderUserRequest(store.Server, store.SessionId, Number(selectedItem.id)));
        setResponsibleUsers(listFolderUser?.ROWS?.ROW);
        current_Item.current = listFolderUser;
        break;
    }

    setShowLoader(false);
  };

  const updateItem = async (updateRequest: UpdateRequestFunction, successMessage: string) => {
    const updatedItem = await updateRequest();
    if (updatedItem.result === 'OK') {
      ToastQueue.positive(successMessage, { timeout: 3000 });
    }
  };

  const updateContactNote = (contactId: string, description: string) => {
    let contactRec: any = {};

    switch (selectedItem?.type) {
      case FPADataTypes.ACTIVITY:
        contactRec = current_Item.current?.ROW?.find((contact: any) => contact.id == contactId);
        contactRec.note = description;
        //console.log('contactRec', contactRec);
        break;
      case FPADataTypes.PROJECT:
      case FPADataTypes.FOLDER:
        contactRec = current_Item.current?.ROWS.ROW?.find((contact: any) => contact.id == contactId);
        contactRec.note = description;
        //console.log('contactRec', contactRec);
        break;
    }
    //console.log('current_Item.current', current_Item.current);
    return contactRec || {};
  };

  const onSaveDescription = async (contactId: string, description: string) => {
    if (!selectedItem) return;

    let updateContact = updateContactNote(contactId, description);

    const requestData = {
      server: store.Server,
      sessionId: store.SessionId,
      data: {
        ...updateContact,
      },
    };

    let successMessage = t('note_saved_successfully', { ns: 'layout_components' });

    switch (selectedItem.type) {
      case FPADataTypes.ACTIVITY:
        await updateItem(() => teamService.updateActivityUser(new UpdateActivityUserRequest(requestData.server, requestData.sessionId, requestData.data)), successMessage);
        break;
      case FPADataTypes.PROJECT:
        await updateItem(() => teamService.updateProjectUser(new UpdateProjectUserRequest(requestData.server, requestData.sessionId, requestData.data)), successMessage);
        break;
      case FPADataTypes.FOLDER:
        await updateItem(() => teamService.updateFolderUser(new UpdateFolderUserRequest(requestData.server, requestData.sessionId, requestData.data)), successMessage);
        break;
    }
  };

  const closeSearchDialog = async (reload: boolean) => {
    setIsOpenSearchContact(false);
    reload && (await loadResponsibleUsers());
  };

  const closeNewContactDialog = async (reload: boolean) => {
    reload && (await loadResponsibleUsers());
  };

  if (showLoader) {
    return (
      <Flex width="100%" justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} gap={'size-150'} position={'relative'} width={'100%'}>
        <Flex direction={'row'} alignItems={'start'} justifyContent={'start'}>
          <Content position={'relative'} UNSAFE_className={styles.heading_text}>
            {t('responsible_users', { ns: 'layout_components' })}
          </Content>
          <Flex direction={'row'} alignItems={'center'} justifyContent={'center'} gap={'size-100'} UNSAFE_className={styles.icon_add_parent}>
            <Content>
              <Link
                isQuiet
                onPress={e => {
                  setIsOpenSearchContact(true);
                }}
              >
                <i className="bi bi-plus fs-5">
                  <View UNSAFE_className={styles.icon_add_text}>{t('add', { ns: 'layout_components' })}</View>
                </i>
              </Link>
            </Content>
          </Flex>
        </Flex>
        <Flex direction={'column'}>
          <Flex maxHeight={{ base: '1000px', L: '450px', M: '450px' }} width="100%" direction="column" UNSAFE_style={{ overflowX: 'auto' }}>
            {responsibleUsers?.map((user: any, index: number) => (
              <ContactCard
                key={user.id}
                contact={user}
                avatarSrc="../../../../../../assets/images/avatar--desktop--light@2x.png"
                selectedItem={selectedItem}
                FPADataTypes={FPADataTypes}
                onSaveDescription={onSaveDescription}
                currentItem={current_Item}
                updateItem={updateItem}
                closeDialog={closeNewContactDialog}
              />
            ))}
          </Flex>

          <DialogContainer
            isDismissable
            onDismiss={() => {
              setIsOpenSearchContact(false);
            }}
          >
            {isOpenSearchContact && (
              <ResponsibleUsersSearch
                key={selectedItem?.id}
                selectedItem={selectedItem}
                currentItem={current_Item}
                responsibleUsers={responsibleUsers}
                FPADataTypes={FPADataTypes}
                updateItem={updateItem}
                closeDialog={closeSearchDialog}
              />
            )}
          </DialogContainer>
        </Flex>
      </Flex>
    );
  }
}

export const ResponsibleUsersComponent = LayoutComponent(ResponsibleUsers);
