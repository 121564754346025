import { LayoutComponent } from '../LayoutComponent';
import { Flex, Link, ProgressCircle, TextField, View } from '@adobe/react-spectrum';
import { useEffect, useRef, useState } from 'react';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { TrashIcon } from '../../Icons/IconsLib';
import { ToastQueue } from '@react-spectrum/toast';
import SignatureCanvas from 'react-signature-canvas';
import { useTranslation } from 'react-i18next';
import { FPAData } from '../../../../infra/protected/FPA/FPAData';
import { useReefConfig } from '../../../../hooks/UseReefConfig';
import { useViewInfo } from '../../../../hooks/UseViewInfo';
import { PrintContextRequest } from '../../../../services/rest/print/requests/PrintContextRequest';
import useComponentReload from '../../../../hooks/UseComponentReload';
import { UpdateActivityRequest } from '../../../../services/soap/project/requests/UpdateActivityRequest';
import { GetActivityRequest } from '../../../../services/soap/project/requests/GetActivityRequest';
import { CheckWorkFlowQueueRequest } from '../../../../services/soap/features/requests/CheckWorkFlowQueueRequest';
import styles from './SignComponent.module.css';

export interface ISignComponentProps {
  // signatureBase64?: string;
  // isSignatureRequired?: boolean;
  // minHeight?: number;
  // percentWidth?: number;
  readonly signatureColor?: number;
  readonly signatureSize?: number;
  readonly signedStatusId?: string;
  selectedItem?: FPAData;
}

function Signature({ signatureColor, signatureSize, signedStatusId, selectedItem }: ISignComponentProps) {
  //console.log('signedStatusId', signedStatusId);
  const { baseUrl, loginInfo } = useReefConfig();
  const { isMobile } = useViewInfo();
  const { t } = useTranslation();
  const [reloadComponent] = useComponentReload();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [signBase64, setSignBase64] = useState<any>('');
  const [fullName, setFullName] = useState<string>('');
  const [signatureKey, setSignatureKey] = useState(Date.now());
  const { featureService, printRestService, projectService, store } = useDependency();
  const sigCanvas = useRef<SignatureCanvas>(null);
  useEffect(() => {
    //console.log('loginInfo', loginInfo);
    reloadSignatureComponent();
  }, [selectedItem, reloadComponent]);

  function statusColor(color: string) {
    if (!color) return '#000000';
    return '#' + ('00000' + parseInt(color).toString(16)).slice(-6);
  }

  const reloadSignatureComponent = () => {
    setSignatureKey(Date.now());
  };

  const onChange = () => {
    let signatureData = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    signatureData = signatureData?.replace(/^data:image\/png;base64,/, '');
    setSignBase64(signatureData);
  };

  const handleConfirm = () => {
    setShowLoader(true);

    let windowReference: any = window.open('', '_blank', 'height=800,width=1200,scrollbars=yes,status=yes,menubar=no,toolbar=no');
    printRestService
      .printDocument(new PrintContextRequest(store.Server, store.SessionId, 'activity', String(selectedItem?.id), 'pdf', fullName, signBase64))
      .then(async (response: any) => {
        //console.log('response', response.documentId);

        let strURL = `${baseUrl}/asp-cgi/doc-download.cgi?instance=${loginInfo.instanceName}&session=${store.SessionId}&documentId=${response.documentId}&disposition=inline`;

        windowReference?.location.replace(strURL);

        const { ACTIVITY } = await projectService.getActivity(new GetActivityRequest(store.Server, store.SessionId, selectedItem!.id));
        if (ACTIVITY.customState !== signedStatusId) {
          const { result } = await projectService.updateActivity(new UpdateActivityRequest(store.Server, store.SessionId, { ...ACTIVITY, customState: signedStatusId }));
          if (result === 'OK') {
            await featureService.checkWorkFlowQueue(new CheckWorkFlowQueueRequest(store.Server, store.SessionId));
          } else ToastQueue.negative('Error updating activity status');
        }

        setFullName('');
        setSignBase64('');
        setShowLoader(false);
      })
      .catch(error => {
        console.log('error', error);
        setShowLoader(false);
        windowReference?.close();
      });
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
    setSignBase64('');
  };

  if (showLoader) {
    return (
      <Flex width="100%" minHeight={'50px'} justifyContent={'center'} marginTop={10}>
        <ProgressCircle aria-label="Loading…" isIndeterminate />
      </Flex>
    );
  } else {
    return (
      <Flex width={'100%'} direction={'column'} alignItems={'start'} justifyContent={'start'} gap={'16px'} position={'relative'} marginBottom={'20px'} UNSAFE_className={styles.signature_parent}>
        <Flex direction={'column'} alignItems={'start'} justifyContent={'start'} gap={'12px'}>
          <View alignSelf={'stretch'} position={'relative'} UNSAFE_className={styles.label_heading}>
            {t('signature', { ns: 'layout_components' })}
          </View>
          <View alignSelf={'stretch'} position={'relative'} UNSAFE_className={styles.label_text}>
            {t('before_signing_the_document_fill_in_your_name_and_surname_below', { ns: 'layout_components' })}
          </View>
        </Flex>
        <Flex alignSelf={'stretch'} position={'relative'} UNSAFE_className={isMobile ? styles.signature_pad_parent_mobile : styles.signature_pad_parent}>
          <Flex direction={'column'} position={'absolute'} top={'18px'} left={'16px'} width={'97%'}>
            <Flex direction={'column'} alignItems={'start'} justifyContent={'start'} position={'relative'} gap={'16px'}>
              <Flex direction={'column'} alignItems={'start'} justifyContent={'start'} zIndex={0}>
                <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} height={'24px'}>
                  <Flex direction={'row'} alignItems={'center'} justifyContent={'start'} height={'24px'}>
                    <View position={'relative'} UNSAFE_className={styles.input_label_text}>
                      {t('full_name', { ns: 'layout_components' })}
                    </View>
                  </Flex>
                </Flex>
                <View>
                  <TextField aria-label="none" width={'256px'} height={'32px'} onChange={val => setFullName(val)}></TextField>
                </View>
              </Flex>
              <View UNSAFE_className={isMobile ? styles.rectangle_parent_mobile : styles.rectangle_parent}>
                <Flex UNSAFE_className={styles.signature_control}>
                  <SignatureCanvas
                    id={signatureKey}
                    penColor={statusColor(signatureColor?.toString() ?? '#000')}
                    canvasProps={{
                      className: isMobile ? styles.signature_pad_mobile : styles.signature_pad,
                    }}
                    dotSize={signatureSize ?? 3}
                    minWidth={1}
                    maxWidth={signatureSize ?? 3}
                    ref={sigCanvas}
                    onEnd={onChange}
                  />
                </Flex>
                <Flex alignItems={'center'} justifyContent={'center'}>
                  <View position={'relative'} width={'50%'} UNSAFE_className={styles.line_div}></View>
                </Flex>
                <Flex UNSAFE_className={isMobile ? styles.label_fullname_text_mobile : styles.label_fullname_text} direction={'row'} justifyContent={'center'} alignContent={'center'}>
                  {fullName}
                </Flex>
              </View>
              <Flex width={'100%'} justifyContent={'end'} alignContent={'end'}>
                {fullName && signBase64 && (
                  <Link UNSAFE_className={styles.btn_confirm} isQuiet onPress={handleConfirm}>
                    {t('confirm_and_open', { ns: 'layout_components' })}
                  </Link>
                )}
              </Flex>

              <Flex direction={'row'} position={'absolute'} alignItems={'center'} justifyContent={'center'} zIndex={2} UNSAFE_className={styles.delete_button}>
                <div className={styles.icon_trash} onClick={clearSignature}>
                  <TrashIcon size={32} />
                </div>
                {!isMobile && (
                  <Flex UNSAFE_className={styles.lable_delete} alignSelf={'stretch'} alignItems={'center'} justifyContent={'center'}>
                    <div onClick={clearSignature}>{t('delete', { ns: 'layout_components' })}</div>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  }
}

export const SignComponent = LayoutComponent(Signature);
