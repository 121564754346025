import { cacheDataDB } from "../../data/LocalCache";

export class OneTimeStorage {
    private static instance: OneTimeStorage;
    
    static getInstance() {
        if (!OneTimeStorage.instance) {
            OneTimeStorage.instance = new OneTimeStorage();
        }
        return OneTimeStorage.instance;
    }
    async get(key: string): Promise<string | null> {
        try{
            if(await cacheDataDB.cacheData.where({type: key}).count() > 0) {
                let last_item = (await cacheDataDB.cacheData.where({type: key}).last());

                if(last_item) return last_item.data;
            }
        }catch(e){
            console.log(`Error in reading onetime storage key (${key}) : `,e);
        }
        return null;
    }
    async set(key: string, value: string) {
        if(await cacheDataDB.cacheData.where({type: key}).count() > 0)
            await cacheDataDB.cacheData.where({type: key}).delete();
        await cacheDataDB.cacheData.add({type: key, data: value, timestamp: new Date()});
    }   
    async clear() {
        await cacheDataDB.cacheData.clear();
    }
}