import { useCallback, useEffect, useRef, useState } from 'react';
import { DialogContainer, Grid, View } from '@adobe/react-spectrum';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SessionInfo } from '../../data/SessionInfo';
import { ListApplicationsRequest } from '../../services/soap/features/requests/ListApplicationsRequest';
import { Application } from '../../services/soap/features/responses/ListApplicationsResponse';
import UserHeader from './Header/UserHeader';
import LoginDialog from '../../components/protected/Dialogs/LoginDialog';
import { ServiceBase } from '../../services/soap/ServiceBase';
import { useDependency } from '../../contexts/DependencyProvider';
import { doLogin } from '../../utils/LoginUtils';
import { ToastQueue } from '@react-spectrum/toast';
import { useTranslation } from 'react-i18next';
import styles from './UserWebLayout.module.css';
import { AppMenuMobile } from '../../components/protected/AppMenuMobile/AppMenuMobile';
import { UserHeaderMobile } from './Header/UserHeaderMobile';
import { getPrimaryPath } from '../../config/app-routes';
import { MobileGlobalSearch } from '../../components/protected/MobileSearch/MobileGlobalSearch';
import { MobileMenu } from '../../components/protected/Menu/MobileMenu';
import { AppMenuItemType } from '../../components/protected/AppMenu/AppMenu.types';
import { AppTabContainerItemType } from '../../components/protected/AppTabs/AppTabContainerItem';
import { usePreloadAssets } from '../../hooks/UsePreloadAssets';
import { addKey, useKeyboardShortcuts } from '../../hooks/UseKeyboardShortcuts';
import { HelpDialog } from '../../components/protected/Dialogs/HelpDialog';
import { AtollonEvent, unsubscribe, subscribe } from '../../infra/events/Events';
import { invokeFinderApp } from '../../utils/NavigationUtils';
import { ListLanguagesRequest } from '../../services/soap/translate/reqeusts/ListLanguagesRequest';
import { useReefConfig } from '../../hooks/UseReefConfig';
import { useDispatch } from 'react-redux';
import { addAppInfo, removeAppInfo, selectApp } from '../../state/userWebLayout/userWebLayoutSlice';
import { UpdateFavouriteContextRequest } from '../../services/soap/project/requests/UpdateFavouriteContextRequest';
import { Profile } from '../../pages/protected/profile/Profile';
import { OneTimeStorage } from '../../services/LocalStorage/OneTimeStorage';
import { LogoutRequest } from '../../services/soap/main/requests/LogoutRequest';
import { useApps } from '../../contexts/AppsProvider';

function _MakeGroupsForAppMenu(apiResponse: Application[]): any[] {
  var icons: Map<string, string> = new Map<string, string>([
    ['general', '../assets/icons/group-402.svg'],
    ['marketing', '../assets/icons/group-4021.svg'],
    ['sales', '../assets/icons/group-4022.svg'],
    ['project', '../assets/icons/group-4023.svg'],
    ['services', '../assets/icons/group-4024.svg'],
    ['finance', '../assets/icons/group-4025.svg'],
    ['reports', '../assets/icons/group-403.svg'],
    ['recruitment', '../assets/icons/group-4026.svg'],
  ]);
  var defaultIcon: string = '../assets/icons/group-397.svg';

  var keyMapping: Map<string, string> = new Map<string, string>([
    ['general', 'general'],
    ['marketing', 'marketing'],
    ['trading', 'sales'],
    ['project', 'project'],
    ['services', 'services'],
    ['finance', 'finance'],
    ['reports', 'reports'],
    ['recruitment', 'recruitment'],
  ]);

  var labelMapping: Map<string, string> = new Map<string, string>([
    ['general', 'General'],
    ['marketing', 'Marketing'],
    ['trading', 'Sales'],
    ['project', 'Project'],
    ['services', 'Services'],
    ['finance', 'Finance'],
    ['reports', 'Reports'],
    ['recruitment', 'Recruitment'],
  ]);

  var displayOrder: Map<string, number> = new Map<string, number>([
    ['general', 10],
    ['marketing', 20],
    ['sales', 30],
    ['project', 40],
    ['services', 50],
    ['finance', 60],
    ['reports', 70],
    ['recruitment', 80],
    ['other', 90],
  ]);

  const items: AppMenuItemType[] = apiResponse.map((app, index) => ({
    sectionTitle: app.subCategory.trim(),
    itemTitle: app.name ?? '',
    itemIcon: keyMapping.has(app.subCategory.trim()) && icons.has(keyMapping.get(app.subCategory.trim())!) ? icons.get(keyMapping.get(app.subCategory.trim())!)! : defaultIcon,
    itemLink: getPrimaryPath(app),
    itemAppIdent: app.appIdent,
    appId: app.id,
  }));

  const grouped_items = items.reduce((groups: any, item) => {
    const group = item.sectionTitle!;
    if (!groups[group]) {
      groups[group] = [];
    }
    groups[group].push(item);
    return groups;
  }, {});

  const groupArrays = Object.keys(grouped_items).map(group => {
    return {
      sectionKey: group,
      sectionTitle: labelMapping.get(group),
      items: grouped_items[group],
    };
  });

  groupArrays.sort((a: any, b: any) => displayOrder.get(keyMapping.get(a.sectionKey)!)! - displayOrder.get(keyMapping.get(b.sectionKey)!)!);
  return groupArrays;
}
function UserWebLayout() {
  const LOGIN_DIALOG = 'login-dialog';
  const HELP_DIALOG = 'help-dialog';

  const { 
    mainService, 
    featureService, 
    translateService, 
    projectService, 
    store
  } = useDependency();

  const { setApps, redirectUrl, setRedirectUrl } = useApps();

  const dispatch = useDispatch();

  //const { instanceName } = useLagoonConfig();
  const { loginInfo } = useReefConfig();
  const { state, pathname, search } = useLocation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showDialog, setShowDialog] = useState('');
  const [isShowSearch, setIsShowSearch] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [groups, setGroups] = useState<any[]>([]); // TODO: [any
  const [openedApps, setOpenedApps] = useState<AppTabContainerItemType[]>([]);
  const [selectedAppKey, setSelectedAppKey] = useState('');
  const { folderStatusMapping, projectStatusMapping, activityStatusMapping } = usePreloadAssets();
  const session: SessionInfo = state.session;

  const is_login_in_progress = useRef(false);

  const showTabIfMissing = (_groups: any[], dataForLink: string) => {
    if (openedApps.length === 0) {
      var item = _groups.find(x => x.items.find((y: AppMenuItemType) => y.itemLink === pathname));

      if (item) {
        handleAppClicked(item.items.find((y: AppMenuItemType) => y.itemLink === pathname)!, dataForLink);
      }
    }
  };
  const loadApplications = useCallback(async () => {
    try {
      const result = await featureService.listApplications(new ListApplicationsRequest(store.Server, store.SessionId));
      store.customApps = result.APPLICATION.filter(x => x.accessType === 'custom').map(x => x.appIdent);
      var groupArrays = _MakeGroupsForAppMenu(result.APPLICATION);

      setApps(result.APPLICATION);
      setGroups(groupArrays);
      showTabIfMissing(groupArrays, search.substring(1));
      setTimeout(() => {
        if(redirectUrl){
          console.log('redirecting to', redirectUrl);
          navigate(redirectUrl, { state: { session }, replace: true });
          setRedirectUrl('');
        }  
      }, 100);
    } catch (error) {
      console.log('error in loadApplications', error);
    }
  }, [featureService, store]);

  const { i18n } = useTranslation();

  useEffect(() => {
    //console.log('i18n.language', i18n.language);
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  useEffect(() => {
    loadApplications();
    (async () => {
      try{
        var language_response = await translateService.listLanguages(new ListLanguagesRequest(store.Server, store.SessionId));
        if (!language_response || +language_response.count === 0) return;

        const record = language_response.LANGUAGE.find((x: any) => +x.id === +store.session.userPreferences.language);
        
        if(!record) return;

        i18n.changeLanguage(record.isoShort);
      }catch(error){
        console.log('error in loadLanguages', error);
      }
    })();
  }, [loadApplications]);

  const findAppByAppIdent = useCallback(
    (appIdent: string, data: string = '') => {
      var item = groups.find(x => x.items.find((y: AppMenuItemType) => y.itemAppIdent === appIdent));

      if (item) {
        handleAppClicked(
          item.items.find((y: AppMenuItemType) => y.itemAppIdent === appIdent),
          data
        );
      }
    },
    [groups]
  );

  const handleFinderDetailLoaded = useCallback(
    (event: AtollonEvent) => {
      findAppByAppIdent('com.atollon.core.finder', `id=${event.detail.contextId}`);
    },
    [findAppByAppIdent]
  );

  useEffect(() => {
    subscribe(AtollonEvent.FINDER_DETAIL_LOADED, handleFinderDetailLoaded);
    return () => {
      unsubscribe(AtollonEvent.FINDER_DETAIL_LOADED, handleFinderDetailLoaded);
    };
  }, [handleFinderDetailLoaded]);

  const handleSessionExpired = async () => {
    if (is_login_in_progress.current) return;
    is_login_in_progress.current = true;

    if (store.Username && store.Password && store.Server) {
      ToastQueue.info('Session expired. Logging in again...', { timeout: 3000 });
      var p_username: string = store.Username;
      if (!store.Username.includes('@')) {
        p_username += '@' + loginInfo.instanceName;
      }

      await doLogin(p_username, store.Password, store.Server, mainService, store);
      navigate('/user/dashboard', { state: { session }, replace: true });
    } else {
      if (showDialog !== LOGIN_DIALOG) {
        setShowDialog(LOGIN_DIALOG);
      }
    }
    is_login_in_progress.current = false;
  };

  ServiceBase.SessionExpiredHandler = handleSessionExpired;

  const handleClick = (e: any) => {
    setIsMenuOpen(false);
  };

  const closeCurrentApp = () => {
    var index = openedApps.findIndex(x => x.itemKey === selectedAppKey);
    if (index !== -1) {
      dispatch(removeAppInfo(selectedAppKey));
      openedApps.splice(index, 1);
      setOpenedApps([...openedApps]);
      if (openedApps.length > 0) handleSelectedAppChanged(openedApps[0]);

      if (openedApps.length === 0) navigate('/user/dashboard', { state: state });
    }
  };

  const handleInfoChange = useCallback(
    (e: AtollonEvent) => {
      var item = openedApps.find(x => x.itemKey === selectedAppKey);
      if (item && item.ref === '/finder') {
        item.altName = e.detail;
        setOpenedApps([...openedApps]);
      }
    },
    [openedApps, selectedAppKey]
  );

  const handleHomeClicked = () => {
    navigate('/user/dashboard', { state: { session }, replace: true });
  };
  useEffect(() => {
    subscribe(AtollonEvent.INFO_CHANGED, handleInfoChange);
    return () => {
      unsubscribe(AtollonEvent.INFO_CHANGED, handleInfoChange);
    };
  }, [handleInfoChange]);

  useKeyboardShortcuts([
    ...addKey('g', { ctrl: true }, () => setIsShowSearch(true)),

    ...addKey('a', { ctrl: true, shift: true }, () => setIsMenuOpen(true)),
    ...addKey('z', { ctrl: true, shift: true }, () => closeCurrentApp()),
    ...addKey('c', { ctrl: true, shift: true }, () => findAppByAppIdent('com.atollon.messaging.messages')),
    ...addKey('o', { ctrl: true, shift: true }, () => findAppByAppIdent('com.atollon.core.contact')),
    ...addKey('s', { ctrl: true, shift: true }, () => findAppByAppIdent('com.atollon.scheduler.events')),
    ...addKey('u', { ctrl: true, shift: true }, () => findAppByAppIdent('com.atollon.scheduler.tasks')),
    ...addKey('f', { ctrl: true, shift: true }, () => findAppByAppIdent('com.atollon.core.finder')),
    ...addKey('d', { ctrl: true, shift: true }, () => findAppByAppIdent('com.atollon.core.document')),
    ...addKey('x', { ctrl: true, shift: true }, () => handleLogout()),
    ...addKey('h', { ctrl: true }, () => handleHomeClicked()),
    ...addKey('/', { ctrl: true }, () => setShowDialog(HELP_DIALOG)),
  ]);

  const moveToApp = (item: AppTabContainerItemType, data: string = '') => {
    var url = item.ref;
    if (data) {
      url = url + '?' + data;
    }
    navigate(url, { state: { ...state, appKey: item.itemKey } });
  };

  const handleSelectedAppChanged = (item: AppTabContainerItemType, data: string = '') => {
    dispatch(selectApp(item.appId));
    setSelectedAppKey(item.itemKey);
    var _item = openedApps.find(x => x.itemKey === item.itemKey);
    if (_item) {
      moveToApp(_item, data);
    }
  };

  const handleAppClicked = (item: AppMenuItemType, data: string = '') => {
    var ts = new Date().getTime();
    var new_item = { itemKey: '' + ts, label: item.itemTitle!, ref: item.itemLink!, icon: item.itemIcon!, appId: item.appId! };
    dispatch(addAppInfo('' + ts));
    setOpenedApps([...openedApps, new_item]);
    setIsMenuOpen(false);
    handleSelectedAppChanged(new_item);
    moveToApp(new_item, data);
  };

  const handleAppClosed = (item: string) => {
    var index = openedApps.findIndex(x => x.itemKey === item);
    if (index !== -1) {
      dispatch(removeAppInfo(item));
      openedApps.splice(index, 1);
      setOpenedApps([...openedApps]);
      if (item === selectedAppKey && openedApps.length > 0) handleSelectedAppChanged(openedApps[0]);

      if (item === selectedAppKey && openedApps.length === 0) navigate('/user/dashboard', { state: state });
    }
  };

  const handleHelpClicked = () => {
    setShowDialog(HELP_DIALOG);
  };
  const updateFavouriteContextCallback = useCallback(
    async (contextId: number, contextType: string) => {
      try {
        var folderId = contextType === 'FOLDER' ? contextId : undefined;
        var projectId = contextType === 'PROJECT' ? contextId : undefined;
        var activityId = contextType === 'ACTIVITY' ? contextId : undefined;

        await projectService.updateFavouriteContext(new UpdateFavouriteContextRequest(store.Server, store.SessionId, folderId, projectId, activityId));
      } catch (error) {
        console.log(error);
      }
    },
    [projectService, store.Server, store.SessionId]
  );

  const handleSearchSelected = async (item: any) => {
    setIsShowSearch(false);
    await updateFavouriteContextCallback(item.contextId, item.contextType);
    invokeFinderApp(item.contextId);
  };

  const handleLogout = async () => {
    ToastQueue.info('Are you sure to want to logout?', {
      actionLabel: 'Yes',
      onAction: async () => {
        try {
          await mainService.logout(new LogoutRequest(store.SessionId, store.Server));
          await OneTimeStorage.getInstance().clear();
          store.clear();
        } catch (error) {
          console.log('error in handleMenuAction', error);
        }
        navigate('/');
      },
      shouldCloseOnAction: true,
    });
  };

  return (
    <>
      <div className={styles.mainContainer} onClick={handleClick}>
        <DialogContainer
          onDismiss={() => {
            setShowDialog('');
          }}
        >
          {showDialog === LOGIN_DIALOG && <LoginDialog />}
          {showDialog === HELP_DIALOG && <HelpDialog close={() => setShowDialog('')} />}
        </DialogContainer>

        <Grid
          areas={{
            base: ['header', 'main'],
            M: ['header', 'main'],
          }}
          columns={['1fr']}
          rows={{
            base: ['auto', '1fr'],
            M: ['auto', '1fr'],
          }}
          height={{
            base: '91vh',
            M: '100vh',
          }}
        >
          <View gridArea="header" backgroundColor={'static-white'}>
            <View isHidden={{ base: true, M: false }}>
              <UserHeader
                groups={groups}
                isMenuOpen={isMenuOpen}
                setIsMenuOpen={setIsMenuOpen}
                isSearchOpen={isShowSearch}
                setIsSearchOpen={setIsShowSearch}
                onAppClicked={handleAppClicked}
                closeApp={handleAppClosed}
                folderStatusMap={folderStatusMapping}
                projectStatusMap={projectStatusMapping}
                activityStatusMap={activityStatusMapping}
                openedApps={openedApps}
                selectedApp={selectedAppKey}
                onSelectedAppChanged={handleSelectedAppChanged}
                onHelpClicked={handleHelpClicked}
                onLogout={handleLogout}
              />
            </View>
            <View isHidden={{ base: true, M: true }}>
              <UserHeaderMobile />
            </View>
          </View>
          <View gridArea="main" UNSAFE_style={{ overflow: 'auto' }}>
            <Outlet />
          </View>
        </Grid>
        <View isHidden={{ base: false, M: true }}>
          {isMenuOpen && (
            <div className={styles.mobileAppScreen}>
              <AppMenuMobile openedApps={openedApps} groups={groups} onItemClicked={handleAppClicked} selectedApp={selectedAppKey} onSelectedAppChanged={handleSelectedAppChanged} />
            </div>
          )}
          {isProfile && <Profile onLogout={handleLogout} />}
          {isShowSearch && (
            <div className={styles.mobileAppScreen}>
              <MobileGlobalSearch
                close={() => setIsShowSearch(false)}
                onSearchSelected={handleSearchSelected}
                folderStatusMap={folderStatusMapping}
                projectStatusMap={projectStatusMapping}
                activityStatusMap={activityStatusMapping}
              />
            </div>
          )}
        </View>
        <div className={styles.mobileMenu} onClick={(e: any) => e.stopPropagation()}>
          <MobileMenu setIsMenuOpen={setIsMenuOpen} setIsShowSearch={setIsShowSearch} setIsProfileOpen={setIsProfile} />
        </div>
      </div>
    </>
  );
}

export default UserWebLayout;
