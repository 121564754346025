import { Finder } from "../pages/protected/finder/Finder";
import { ProjectActivities } from "../pages/protected/project-activities/ProjectActivities";
import { RecruitmentDashboard } from "../pages/protected/recruitment/RecruitmentDashboard";
import { ServiceActivities } from "../pages/protected/service-activities/ServiceActivities";
import { Application } from "../services/soap/features/responses/ListApplicationsResponse";

export const routesMap: Map<string, any> = new Map([
  ['com.atollon.recruitment.dashboard',  RecruitmentDashboard ],
  ['com.atollon.core.finder', Finder ],
  ['module-project=>serviceActivity', ServiceActivities ],
  ['module-project=>sales', ProjectActivities ]
]);

export function getPrimaryPath(app:Application): string {
  switch(app.accessType.toLowerCase()){
    case 'system':
      var system_app = routesMap.get(app.appIdent);
      if(system_app && app.primaryPath)
        return app.primaryPath;
      break;
    case 'custom':
      var data = JSON.parse(app.data);
      if(data.handler){
        var custom_app = routesMap.get(data.handler);
        if(custom_app && app.primaryPath) return app.primaryPath;
      } 
      break;
    default:
      break;
  }
  
  return '/user/NotFound?service=' + app.appIdent;
}