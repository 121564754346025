import { View } from '@adobe/react-spectrum';
import styles from './FinderDetails.module.css';
import { FPAData, FPADataTypes } from '../../../../infra/protected/FPA/FPAData';
import { ComponentFactory } from '../../../../components/protected/layout-components/ComponentFactory';
import { useCallback, useEffect, useState } from 'react';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { GetFormPanelRequest } from '../../../../services/soap/features/requests/GetFormPanelRequest';
import { SoapUtils } from '../../../../utils/SoapUtils';
import { LayoutDefinition } from '../../../../infra/protected/Layout/LayoutDefinitions';
import { useTranslation } from 'react-i18next';

export interface IFinderDetailsProps {
    selectedItem: FPAData | null;
    onClicked?: () => void;
}

export function FinderDetails ({
    selectedItem,
    onClicked
}: IFinderDetailsProps) {
    const { store, featureService } = useDependency();
    const [definition, setDefinition] = useState<LayoutDefinition | null>(null);
    const { t } = useTranslation();

    const _t = (key: string) => t(key, { ns: 'finder' });

    const getLayout = useCallback(async () => {
        try{
            if(selectedItem === null || selectedItem === undefined) return;
            if(selectedItem.type === FPADataTypes.FOLDER_TYPE || selectedItem.type === FPADataTypes.ITEM_TYPE) return;

            const layout = await featureService.getFormPanel(new GetFormPanelRequest(store.Server, store.SessionId, ""+selectedItem!.item_type_id));

            if(layout.count > 0) {
                var scheme = SoapUtils.parseXmlString2(layout.ROW.SCHEME);
                var layoutObject = JSON.parse(scheme.ActivityPanelSettings.layoutObject);
                setDefinition(layoutObject);    
            } else {
                setDefinition(null);
            }
        } catch (err){
            console.error('getlayout error: ',err);
        }
    }, [featureService, selectedItem, store.Server, store.SessionId]);

    useEffect(() => {
        getLayout();
    }, [getLayout]);

    if(selectedItem === null || selectedItem === undefined) return (<>{_t("nothing is selected")}</>);
    if(selectedItem.type === FPADataTypes.FOLDER_TYPE || selectedItem.type === FPADataTypes.ITEM_TYPE) return (<>{_t("folder or item type selected")}</>);
    const styleWithVar: React.CSSProperties & { "--status-color"?: string } = {"--status-color" : selectedItem.statusColor};

    return (
    <>
        <div className={styles.container} style={ styleWithVar }  onClick={() => { if(onClicked) onClicked(); }} onFocus={() => { if(onClicked) onClicked(); }}>
            <View padding={20}>
                <ComponentFactory definition={definition}
                    selectedItem={selectedItem}
                 systemAttributes={{
                    onPrint: (item) => console.log('onPrint', item),
                    onEdit: (item) => console.log('onEdit', item),
                    onDelete: (item) => console.log('onDelete', item),
                    onChat: (item) => console.log('onChat', item),
                    onConfig: (item, type) => console.log('onConfig', item, type)
                }} />
            </View>
        </div>
    </>);
}
