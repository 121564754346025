export const truncateText = (text: string, maxLength: number) => {
  return text && text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

export function statusColor(color: string) {
  if (!color || color === '0') return '';
  try {
    let finalColor = (+color).toString(16).split('').reverse();
    let padding = Array(6 - finalColor?.length).fill('0');
    let finalColorString = '#' + [...finalColor, ...padding].join('');
    return finalColorString === '#ffffff' ? '#FAFAFA' : finalColorString;
  } catch (error) {
    return '';
  }
}

export const formatDecimalToString = (amount: any) => {
  // Check if the amount ends with '.00'
  if (amount.endsWith('.00')) {
    // If yes, remove the decimal part
    return amount.substring(0, amount.length - 3);
  }
  // If the decimal part is not '.00', return the original amount
  return amount;
};
