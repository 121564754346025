import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IDeleteActivityUserRequest extends IBaseRequest {
    roleName?:     string;
    role?:         string;
    note?:         string;
    id?:           string;
    userId?:       string;
    activityName?: string;
    acl?:          string;
    activity?:     string;
    isPrimary?:    string;
    contact?:      string;
    userName?:     string;
}

export class DeleteActivityUserRequest extends BaseRequest implements IDeleteActivityUserRequest {

    public roleName?: string;
    public role?: string;
    public note?: string;
    public id?: string;
    public userId?: string;
    public activityName?: string;
    public acl?: string;
    public activity?: string;
    public isPrimary?: string;
    public contact?: string;
    public userName?: string;

  constructor(server: string, session: string, otherOptions:any) {
    super(server, session);
    this.roleName = otherOptions.roleName;
    this.role = otherOptions.role;
    this.note = otherOptions.note;
    this.id = otherOptions.id;
    this.userId = otherOptions.userId;
    this.activityName = otherOptions.activityName;
    this.acl = otherOptions.acl;
    this.activity = otherOptions.activity;
    this.isPrimary = otherOptions.isPrimary;
    this.contact = otherOptions.contact;
    this.userName = otherOptions.userName;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    if (this.roleName) parameters += `<roleName>${this.roleName}</roleName>`;
    if (this.role) parameters += `<role>${this.role}</role>`;
    if (this.note) parameters += `<note>${this.note}</note>`;
    if (this.id) parameters += `<id>${this.id}</id>`;
    if (this.userId) parameters += `<userId>${this.userId}</userId>`;
    if (this.activityName) parameters += `<activityName>${this.activityName}</activityName>`;
    if (this.acl) parameters += `<acl>${this.acl}</acl>`;
    if (this.activity) parameters += `<activity>${this.activity}</activity>`;
    if (this.isPrimary) parameters += `<isPrimary>${this.isPrimary}</isPrimary>`;
    if (this.contact) parameters += `<contact>${this.contact}</contact>`;
    if (this.userName) parameters += `<userName>${this.userName}</userName>`;

    return super.wrapperXml('DeleteActivityUser', parameters);
  }
}
