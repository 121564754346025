import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListItemValueRequest extends IBaseRequest {
  formInfo?: string;
  formItem?: string;
  formItemName?: string;
  context?: string;
  formId?: string;
  remoteId?: string;
  typ?: string;
}

export class ListItemValueRequest extends BaseRequest implements IListItemValueRequest {
  public formInfo?: string;
  public formItem?: string;
  public formItemName?: string;
  public context?: string;
  public formId?: string;
  public remoteId?: string;
  public typ?: string;

  constructor(server: string, session: string, formInfo?: string, formItem?: string, formItemName?: string, context?: string, formId?: string, remoteId?: string, typ?: string) {
    super(server, session);
    this.formInfo = formInfo;
    this.formItem = formItem;
    this.formItemName = formItemName;
    this.context = context;
    this.formId = formId;
    this.remoteId = remoteId;
    this.typ = typ;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    if (this.formInfo) parameters += `<formInfo>${this.formInfo}</formInfo>`;
    if (this.formItem) parameters += `<formItem>${this.formItem}</formItem>`;
    if (this.formItemName) parameters += `<formItemName>${this.formItemName}</formItemName>`;
    if (this.context) parameters += `<context>${this.context}</context>`;
    if (this.formId) parameters += `<formId>${this.formId}</formId>`;
    if (this.remoteId) parameters += `<remoteId>${this.remoteId}</remoteId>`;
    if (this.typ) parameters += `<typ>${this.typ}</typ>`;

    return super.wrapperXml('ListItemValue', parameters);
  }
}
