import { useCallback, useEffect, useState } from 'react';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { ListStatusRequest, ListStatusType } from '../../../../services/soap/project/requests/ListStatusRequest';
import { Flex, View } from '@adobe/react-spectrum';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { AtollonIcon, PrintIcon, EditIcon, ToolIcon, TrashIcon, MoreIcon, ProjectIconBlue } from '../../Icons/IconsLib';
import ButtonsComponent from './ButtonsComponent';
import { GetContextPathRequest } from '../../../../services/soap/project/requests/GetContextPathRequest';
import { ToastQueue } from '@react-spectrum/toast';
import { useTranslation } from 'react-i18next';
import { GetProjectRequest } from '../../../../services/soap/project/requests/GetProjectRequest';
import { UpdateProjectRequest } from '../../../../services/soap/project/requests/UpdateProjectRequest';
import { CheckWorkFlowQueueRequest } from '../../../../services/soap/features/requests/CheckWorkFlowQueueRequest';
import { FPAData } from '../../../../infra/protected/FPA/FPAData';
import styles from './HeaderToolbarComponent.module.css';

interface IProjectComponentProps {
  headerData: any;
  statusColor: (color: string) => string;
  onPrintClicked?: () => void;
  onEditClicked?: () => void;
  onDeleteClicked?: () => void;
  onToolClicked?: () => void;
  onAIClicked?: () => void;
  selectedItem?: FPAData;
}

const ProjectComponent = ({ headerData, statusColor, onPrintClicked, onEditClicked, onDeleteClicked, onToolClicked, onAIClicked, selectedItem }: IProjectComponentProps) => {
  const { store, projectService, featureService } = useDependency();
  const [projectStatusList, setProjectStatusList] = useState<any>([]);
  const [fullPath, setFullPath] = useState<string>('');
  const [projectTypeName, setProjectTypeName] = useState<string>('');
  const [projectData, setProjectData] = useState<any>([]);
  const { t } = useTranslation();
  useEffect(() => {
    (async () => {
      headerData && (await getFullPath());
      await getProjectData();
      await getProjectStatusArray();
    })();
  }, []);

  const getFullPath = async () => {
    const response: any = await projectService.getContextPath(new GetContextPathRequest(store.Server, store.SessionId, headerData?.id || 0));
    //console.log('response.LIST[0].typeName', response.LIST[0].CONTEXT.typeName);
    setProjectTypeName(response.LIST[0].CONTEXT?.typeName);
    const modifiedList = response.LIST.slice(1).reverse();
    //console.log('getFullPath response', response);
    setFullPath(modifiedList.map((item: any) => item.CONTEXT.name).join(' > '));
  };

  const getProjectStatusArray = useCallback(async () => {
    const statusArray: any = [];
    let res: any = await projectService.listProjectStatus2(new ListStatusRequest(store.Server, store.SessionId, ListStatusType.Project));

    if (res.result === 'OK' && res.STATUS.length > 0) {
      res?.STATUS.forEach((status: any) => {
        // Extract id and name from each STATUS object
        const { id, name, color, TYPE } = status;
        // Push an object containing id and name to the statusArray
        statusArray.push({ id, name, color, types: TYPE });
      });

      var filtered_status = statusArray.filter((status: any) => status.types.find((type: any) => type.id === headerData?.projectType));

      setProjectStatusList(filtered_status);
    }
  }, []);

  function itemTemplate(data: any): JSX.Element {
    return (
      <Flex UNSAFE_className={styles.dot_text_container}>
        <View UNSAFE_className={styles.dot} UNSAFE_style={{ backgroundColor: `${statusColor(data.color)}` }}></View>
        <View marginTop={5}>{data.name}</View>
      </Flex>
    );
  }

  function valueTemplate(data: any): JSX.Element {
    return (
      <Flex UNSAFE_className={styles.dot_text_container}>
        <View UNSAFE_className={styles.dot} UNSAFE_style={{ backgroundColor: `${statusColor(data.color)}` }}></View>
        <View marginTop={5} UNSAFE_style={{ marginLeft: '10px' }}>
          <span title={data?.name}>{data?.name && data?.name.length > 16 ? data?.name.substring(0, 16) + '...' : data?.name}</span>
        </View>
      </Flex>
    );
  }

  async function getProjectData() {
    if (selectedItem) {
      let { PROJECT } = await projectService.getProject(new GetProjectRequest(store.Server, store.SessionId, selectedItem.id));
      setProjectData(PROJECT);
    }
  }
  async function updateProjectStatus(e: any) {
    let resProject = await projectService.updateProject(new UpdateProjectRequest(store.Server, store.SessionId, { ...projectData, customState: e.value }));
    if (resProject.result == 'OK') {
      await featureService.checkWorkFlowQueue(new CheckWorkFlowQueueRequest(store.Server, store.SessionId));
      ToastQueue.positive(t('status_updated_successfully', { ns: 'layout_components' }), { timeout: 1000 });
    } else {
      ToastQueue.negative(resProject.EXCEPTION?.message, { timeout: 1000 });
    }
  }

  return (
    <Flex width="100%">
      <View UNSAFE_className={styles.header_main}>
        <View UNSAFE_className={styles.header_child}>
          <View UNSAFE_className={styles.frame_parent}>
            <View UNSAFE_className={styles.icon_activity_parent}>
              <ProjectIconBlue size={32} />
              <b title={headerData?.name} className={styles.activity_name}>
                {headerData?.name.length > 55 ? headerData?.name.substring(0, 55) + '...' : headerData?.name}
              </b>
            </View>

            <View UNSAFE_className={styles.folder_name_parent}>
              <View UNSAFE_className={styles.folder_name}>{fullPath}</View>
            </View>
          </View>
          <View UNSAFE_className={styles.tag_parent} marginTop={20}>
            <View UNSAFE_className={styles.tag}>
              <View UNSAFE_className={styles.activity_type}>
                <View UNSAFE_className={styles.activity_type_text}>{projectTypeName}</View>

                {headerData?.refId ? (
                  <>
                    <View UNSAFE_className={styles.divider}></View> <View UNSAFE_className={styles.ref_id}>{headerData?.refId}</View>
                  </>
                ) : (
                  <View UNSAFE_className={styles.divider_transparent}></View>
                )}
              </View>
            </View>
          </View>
        </View>
        <View UNSAFE_className={styles.icon_list_parent}>
          <DropDownListComponent
            width={'200px'}
            dataSource={projectStatusList}
            fields={{ text: 'name', value: 'id' }}
            showClearButton={true}
            itemTemplate={itemTemplate}
            valueTemplate={valueTemplate}
            value={headerData?.customState}
            onChange={(e: any) => {
              updateProjectStatus(e);
            }}
          />

          <ButtonsComponent
            styles={styles}
            AtollonIcon={AtollonIcon}
            PrintIcon={PrintIcon}
            EditIcon={EditIcon}
            ToolIcon={ToolIcon}
            TrashIcon={TrashIcon}
            onPrintClicked={onPrintClicked}
            onEditClicked={onEditClicked}
            onDeleteClicked={onDeleteClicked}
            onToolClicked={onToolClicked}
            onAIClicked={onAIClicked}
          />
        </View>
      </View>
    </Flex>
  );
};

export default ProjectComponent;
