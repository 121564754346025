import { BaseRequest, IBaseRequest } from "../../BaseRequest";
import { ProjectItem, toProjectItemXML } from "./ProjectItem";


export interface ICreateProjectItemRequest extends IBaseRequest {
    ITEM: ProjectItem;
}

export class CreateProjectItemRequest
  extends BaseRequest
  implements ICreateProjectItemRequest
{
    public ITEM: ProjectItem;
    constructor(server: string, session: string, item: ProjectItem) {
        super(server, session);
        this.ITEM = item;
    }

  public toXml(): string {
    var parameters: string = super.toXml();
    parameters += "<ITEM>";
    parameters += toProjectItemXML(this.ITEM);
    parameters += "</ITEM>";
    return super.wrapperXml("CreateProjectItem", parameters);
  }
}
