import { useEffect } from "react";

export interface KeyboardShortcut {
    key: string;
    ctrl?: boolean;
    shift?: boolean;
    alt?: boolean;
    meta?: boolean;
    callback: (key?:string) => void;
}
export interface SpecialKeys{
    ctrl?: boolean;
    shift?: boolean;
    alt?: boolean;
}
export function addKey(key: string, {ctrl, shift, alt} : SpecialKeys, callback: (key?:string) => void){
    var isAlpha = key.length === 1 && key.match(/^[a-zA-Z]$/);

    var result: KeyboardShortcut[] = [];

    if(isAlpha){
        result.push({ key: key.toLowerCase(), ctrl: ctrl, shift: shift, alt: alt, callback: callback });
        result.push({ key: key.toUpperCase(), ctrl: ctrl, shift: shift, alt: alt, callback: callback });
        if(ctrl){
            result.push({ key: key.toLowerCase(), meta: true, shift: shift, alt: alt, callback: callback });
            result.push({ key: key.toUpperCase(), meta: true, shift: shift, alt: alt, callback: callback });
        }
    } else {
        result.push({ key: key, ctrl: ctrl, shift: shift, alt: alt, callback: callback });
        if(ctrl){
            result.push({ key: key, meta: true, shift: shift, alt: alt, callback: callback });
        }
    }

    return result;
}
export function useKeyboardShortcuts(shortcuts: KeyboardShortcut[], hasFocus:boolean = true, debug: boolean = false){
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if(debug) console.log(`key: ${e.key}`, `ctrlKey: ${e.ctrlKey}`, `shiftKey: ${e.shiftKey}`, `altKey: ${e.altKey}`, `metaKey: ${e.metaKey}`);
            if(!hasFocus) {
                if(debug) console.log('keyboard hook lost focus'); 
                return;
            }
            const exceptionKeys = ['F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F10', 'F11', 'F12', 'F13', 'F14', 'F15', 'F16'];
            if(exceptionKeys.includes(e.key)) return;
            shortcuts.forEach(shortcut => {
                if(shortcut.key === 'any'){
                        e.preventDefault();
                        e.stopPropagation();
                        shortcut.callback(e.key);
                    return;
                }
                if(shortcut.key === e.key 
                    && (!shortcut.ctrl || shortcut.ctrl === e.ctrlKey) 
                    && (!shortcut.shift || shortcut.shift === e.shiftKey) 
                    && (!shortcut.alt || shortcut.alt === e.altKey) 
                    && (!shortcut.meta || shortcut.meta === e.metaKey)){
                    if(debug) console.log('found shortcut', shortcut);
                    e.preventDefault();
                    e.stopPropagation();
                    shortcut.callback();
                }
            })
        }
        window.addEventListener('keydown', handleKeyDown)
        return () => {
          window.removeEventListener('keydown', handleKeyDown)
        }
      }, [debug, shortcuts]);
}

