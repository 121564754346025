import { Flex } from '@react-spectrum/layout';
import { View } from '@react-spectrum/view';
import styles from './EmptyHolder.module.css';
import { EmptyIcon2 } from '../Icons/IconsLib';
import { useReefConfig } from '../../../hooks/UseReefConfig';
import { useTranslation } from 'react-i18next';


export interface IEmptyHolderProps {
    url?: string;
    title?: string;
}

export function EmptyHolder ({
    url,
    title
}: IEmptyHolderProps) {
    const { install_path } = useReefConfig();
    const { t } = useTranslation();

    const _t = (key: string) => t(`emptyComponent.${key}`, { ns: 'layout-components' });

    if(!title) title = 'Empty Component';
    const handleOpenInDesktop = () => {
        window.open(url, '_blank');
    };
    const handleInstallDesktop = () => {
        window.open(install_path, '_blank');
    };
    return (
        <>
            <Flex direction="column" gap="size-100" justifyContent={'center'} alignItems={'center'}>
                <View>
                    <EmptyIcon2 size={40} />
                </View>
                <View UNSAFE_className={styles.mainTitle}>
                    {title}
                </View>
                <View UNSAFE_className={styles.subTitle}>
                    {_t('message')}
                </View>
                <View UNSAFE_className={styles.subTitle} height={'size-100'} />
                <Flex direction={{M:"row",base:"column"}} gap={{base:'size-300'}} justifyContent={'space-evenly'} maxWidth={'400px'}>
                    {url && (<View>
                        <span className={styles.openButton} onClick={handleOpenInDesktop}>
                            {_t('button.openDesktop')}
                        </span>
                    </View>)}
                    <View>
                        <span className={styles.installButton} onClick={handleInstallDesktop}>
                            {_t('button.openDesktop')}
                        </span>
                    </View>
                </Flex>
            </Flex>
        </>
    );
}
