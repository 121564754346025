import { ActionButton, DialogTrigger, Flex, Grid, View } from '@adobe/react-spectrum';
import { encode } from 'html-entities';
import { FPATree } from '../../../components/protected/FPATree/FPATree';
import styles from './Finder.module.css';
import { CACHE_KEYS, saveToCache, usePreloadAssets } from '../../../hooks/UsePreloadAssets';
import { ListRegisterUtils } from '../../../utils/ListRegisterUtils';
import { SoapUtils } from '../../../utils/SoapUtils';
import { useEffect, useRef } from 'react';
import { useDependency } from '../../../contexts/DependencyProvider';
import { FPAData, FPADataFactory, FPADataTypes } from '../../../infra/protected/FPA/FPAData';
import { FinderDetails } from './components/FinderDetails';
import { SearchIcon } from '../../../components/protected/Icons/IconsLib';
import { useLocation, useSearchParams } from 'react-router-dom';
import { FinderDetailsMobile } from './components/FinderDetailsMobile';
import { useViewInfo } from '../../../hooks/UseViewInfo';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../state/store';
import FilterIcon from '@spectrum-icons/workflow/Filter';
import { 
  changePageOffsetOfItems,
  closeContextMode,
  closeDetails,
  doSearch,
  getContextPath,
  hideSearch,
  loadFinder,
  loadFolderTypes, 
  loadTreeData, 
  moveToParent, 
  nextSelection, 
  openContextMode, 
  openDetails, 
  previousSelection, 
  resetFinder, 
  resetSearch, 
  setMappings, 
  setSearchFilter, 
  setSelectedIndexAsSelectedItem, 
  setSelectedItem, 
  setSelectedItemAsSelectedIndex, 
  setTreeFocus, 
  showGlobalSearch, 
  showSearch, 
  showSearchedSelected, 
  unloadTreeData, 
  updateFilters
} from '../../../state/Finder/finderSlice';
import { InPlaceSearch } from '../../../components/protected/FPATree/components/InPlaceSearch/InPlaceSearch';
import { FPAFiltersDialog } from '../../../components/protected/Dialogs/FPAFiltersDialog';
import { AtollonEvent, publish } from '../../../infra/events/Events';
import { updateAppInfo } from '../../../state/userWebLayout/userWebLayoutSlice';
import FolderAddToIcon from '@spectrum-icons/workflow/FolderAddTo';
import { addKey, useKeyboardShortcuts } from '../../../hooks/UseKeyboardShortcuts';
import { GetContextPathResponse } from '../../../services/soap/project/responses/GetContextPathResponse';
import ConstantUtils from '../../../utils/ConstantUtils';
import { useTranslation } from 'react-i18next';
import { ContextNewDialog } from '../../../components/protected/Context/ContextNewDialog';
import { DataProvider } from '../../../components/protected/data-components/Contexts/DataContext';

export function Finder () {  
  const treeData = useSelector((state: RootState) => state.finder.treeData);
  const treeFocus = useSelector((state: RootState) => state.finder.treeFocus);
  const selectedItem = useSelector((state: RootState) => state.finder.selectedItem);
  const showOverlay = useSelector((state: RootState) => state.finder.showOverlay);
  const showGlobalFinder = useSelector((state: RootState) => state.finder.showGlobalFinder);
  const searchResults = useSelector((state: RootState) => state.finder.searchData);
  const searchFilter = useSelector((state: RootState) => state.finder.searchFilter);
  const inContextMode = useSelector((state: RootState) => state.finder.inContextMode);
  const lastOpenedItem = useSelector((state: RootState) => state.finder.lastOpenedItem);
  const dataStore = useSelector((state: RootState) => state.userWebLayout.appInfos);
  const search_result_offset = useSelector((state: RootState) => state.finder.search_result_offset);
  const search_result_total = useSelector((state: RootState) => state.finder.search_result_total);
  const showDetails = useSelector((state: RootState) => state.finder.showDetails);
  const backupDataLength = useSelector((state: RootState) => state.finder.backupData.length);

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const dataRef = useRef<any>({});
  const searchCallRef = useRef<any>();
  const { registers, folderTypes, folderStatusMapping, projectStatusMapping, activityStatusMapping } = usePreloadAssets();
  const { isMobile } = useViewInfo();
  const { featureService, projectService, store } = useDependency();
  const [params, setSearchParams] = useSearchParams();
  const { state } = useLocation();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  
  const _t = (key: string) => t(key, { ns: 'finder' });
  const showSelectedItem = () => {
    if(timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }

    timerRef.current = setTimeout(() => {
      dispatch(setSelectedItemAsSelectedIndex());
      timerRef.current = null;
    }, 300);
  }

  useKeyboardShortcuts([
    ...addKey('f', {ctrl: true}, () =>{ 
      if(selectedItem && selectedItem.type !== FPADataTypes.ITEM_TYPE){
        if(selectedItem.type === FPADataTypes.FOLDER_TYPE || selectedItem.count > 0)
          handleItemSearchOpened(selectedItem);
      }else
        handleOpenGlobalFinderSearch()
    }),
    ...addKey('ArrowUp', {}, () =>{ 
      dispatch(previousSelection());
      showSelectedItem();
    }),
    ...addKey('ArrowUp', {ctrl: true}, () => {
      dispatch(moveToParent());
    }),
    ...addKey('ArrowDown', {ctrl: true}, () => {
      if(selectedItem)
        handleItemSelected(selectedItem, true);
    }),
    ...addKey('ArrowDown', {}, () => {
      dispatch(nextSelection());
      showSelectedItem();
    }),
    ...addKey('ArrowLeft', {}, () => {
      if(selectedItem 
        && selectedItem.type !== FPADataTypes.ITEM_TYPE
      ){
        if(selectedItem.type === FPADataTypes.FOLDER_TYPE || selectedItem.count > 0)
          handleItemClosed(selectedItem);
      }
    }),
    ...addKey('ArrowRight', {}, () => {
      if(selectedItem 
        && selectedItem.type !== FPADataTypes.ITEM_TYPE
      ){
        if(selectedItem.type === FPADataTypes.FOLDER_TYPE || selectedItem.count > 0)
          handleItemOpened(selectedItem);
      }
    }),
    ...addKey('Enter', {}, () =>{ 
      if(selectedItem)
        handleItemSelected(selectedItem, true);
    }),
    ...addKey('Escape', {}, () => {
      if(showOverlay){ 
        handleItemSearchClosed(true);
        return;
      }
      handleShowAll();
    }),
  ],treeFocus);
  
  useEffect(() => {
    dataRef.current = { treeData, selectedItem, showDetails, inContextMode, lastOpenedItem};
  },[treeData, selectedItem, showDetails, inContextMode, lastOpenedItem]);

  useEffect(() => {
    if(Object.keys(folderTypes).length === 0) return;

    if(dataStore[state.appKey]?.treeData && dataStore[state.appKey]?.treeData.length > 0){
      dispatch(loadFinder({
        treeData: JSON.parse(dataStore[state.appKey].treeData) as FPAData[],
        selectedItem: dataStore[state.appKey].selectedItem ? JSON.parse(dataStore[state.appKey].selectedItem) : null,
        showDetails: dataStore[state.appKey].showDetails,
        inContextMode: dataStore[state.appKey].inContextMode,
        lastOpenedItem: dataStore[state.appKey].lastOpenedItem
      }));
    } else if(params.has('id')){
      dispatch(getContextPath({
        api: projectService,
        contextId: +params.get('id')!,
        sessionId: store.SessionId,
        server: store.Server,
        isForSearch: false
      })).then((tmp) => {
        var item = tmp.payload as GetContextPathResponse;
        if(item.count === 0) return;
        var t_item = FPADataFactory.createFromContextItem(item.LIST[0].CONTEXT);
        dispatch(loadTreeData({
          api: projectService, 
          itemType: t_item.type, 
          itemId: t_item.id, 
          sessionId: store.SessionId, 
          server: store.Server,
          offset: 0,
          limit: ConstantUtils.LIST_FPA_PAGE_SIZE
        })).then((tmp1) => {
          dispatch(setSelectedIndexAsSelectedItem());
          dispatch(openDetails());
        });
      });
    }      
    else
      dispatch(loadFolderTypes({folderTypes}));
    
    // dispatch(setSelectedIndex(0));

    return () => {
      if(searchCallRef.current) searchCallRef.current.abort();
      if(timerRef.current){ clearTimeout(timerRef.current); timerRef.current = null; }

      dispatch(updateAppInfo({ 
        key: state.appKey, 
        data: {
          treeData: JSON.stringify(dataRef.current.treeData),
          selectedItem: JSON.stringify(dataRef.current.selectedItem),
          showDetails: dataRef.current.showDetails,
          inContextMode: dataRef.current.inContextMode,
          lastOpenedItem: dataRef.current.lastOpenedItem
        }
      }));
      dispatch(resetFinder());
    }
  },
  [dispatch, folderTypes, params, projectService, store.Server, store.SessionId]);

  useEffect(() => {
    if(registers === undefined) return;
    var registry_key = `${ListRegisterUtils.Modules.Finder}_${ListRegisterUtils.Keys.Finder.FinderSettings}`;
    if(registers[registry_key] === undefined) return;
    const search_settings = (registers[registry_key]).value ? SoapUtils.parseXmlString((registers[registry_key]).value) : {};
    dispatch(setSearchFilter({...search_settings}));
  },[dispatch, registers]);
  
  useEffect(() => {
    if(folderStatusMapping === undefined || projectStatusMapping === undefined || activityStatusMapping === undefined) return;
    dispatch(setMappings({folderStatusMapping, projectStatusMapping, activityStatusMapping}));
  },[folderStatusMapping, projectStatusMapping, activityStatusMapping, dispatch]);

  const handleItemOpened = (item:FPAData) => {    
    dispatch(setSelectedItemAsSelectedIndex());
    dispatch(loadTreeData({
      api: projectService, 
      itemType: item.type, 
      itemId: item.id, 
      sessionId: store.SessionId, 
      server: store.Server,
      offset: item.page_offset,
      limit: ConstantUtils.LIST_FPA_PAGE_SIZE
    })).then((tmp) => {
      dispatch(changePageOffsetOfItems({itemId: item.id, offset: item.page_offset + ConstantUtils.LIST_FPA_PAGE_SIZE}));
      if(item.parent_id && item.count === 0){
        
      }
    });
  }

  const handleItemClosed = (item:FPAData) => {
    dispatch(unloadTreeData({itemId: item.id}));
  }

  const changeQueryStringId = (id:number) => {
    const url = new URL(window.location.href);
    url.searchParams.set('id', id+"");
    window.history.pushState(null, '', url.toString());
  }
  const handleItemSelected = (item:FPAData, show_context:boolean = true) => {
    dispatch(setSelectedItemAsSelectedIndex());
    dispatch(setSelectedItem({item, show_context}));
    dispatch(openDetails());
    if(show_context){
      changeQueryStringId(item.id);
        if(item.type === FPADataTypes.FOLDER_TYPE || item.count > 0)
          dispatch(loadTreeData({
            api: projectService, 
            itemType: item.type, 
            itemId: item.id, 
            sessionId: store.SessionId, 
            server: store.Server,
            offset: 0,
            limit: ConstantUtils.LIST_FPA_PAGE_SIZE
          })).then((tmp) => {
            dispatch(setSelectedIndexAsSelectedItem());
            dispatch(changePageOffsetOfItems({itemId: item.id, offset: ConstantUtils.LIST_FPA_PAGE_SIZE}));
          });
        else{
          dispatch(setSelectedIndexAsSelectedItem());
        }
    }
    publish(AtollonEvent.INFO_CHANGED, item.title);
  }

  const handleOverlayClicked = () => {
    dispatch(hideSearch(true));
  }

  const handleItemSearching = (search:string, parent_id?:number, parent_type?:FPADataTypes, is_page:boolean = false) => {
    if(is_page && search_result_offset > 0 && search_result_offset >= search_result_total) return;    

    if(searchCallRef.current) searchCallRef.current.abort();

    searchCallRef.current = dispatch(doSearch({
      api: projectService, 
      itemType: parent_type, 
      itemId: parent_id, 
      searchText: search,
      sessionId: store.SessionId, 
      server: store.Server,
      offset: is_page ? search_result_offset : 0,
      limit: ConstantUtils.LIST_FPA_PAGE_SIZE
    }));
  }

  const handleItemSearchOpened = (item:FPAData) => {
    dispatch(showSearch(item));
  }

  const handleItemSearchClosed = (isCancel: boolean) => {
    dispatch(hideSearch(isCancel));
  }

  const handleRootSearchedItemSelected = (item:any) => {
    dispatch(hideSearch(false));
    dispatch(showSearchedSelected(item.item));
    dispatch(getContextPath({ 
      api: projectService, 
      contextId: item.contextId, 
      sessionId: store.SessionId, 
      server: store.Server,
      isForSearch: true
    })).then((tmp) => {
      dispatch(loadTreeData({
        api: projectService, 
        itemType: item.item.type, 
        itemId: item.item.id, 
        sessionId: store.SessionId, 
        server: store.Server,
        offset: 0,
        limit: ConstantUtils.LIST_FPA_PAGE_SIZE
      })).then((tmp1) => {
        dispatch(setSelectedIndexAsSelectedItem());
      });
    });
    publish(AtollonEvent.INFO_CHANGED, item.item.title);
  }
  const handleSearchedItemSelected = (item:FPAData) => {
    dispatch(showSearchedSelected(item));
    dispatch(openContextMode(item));
    dispatch(loadTreeData({
      api: projectService, 
      itemType: item.type, 
      itemId: item.id, 
      sessionId: store.SessionId, 
      server: store.Server,
      offset: 0,
      limit: ConstantUtils.LIST_FPA_PAGE_SIZE
    }));
    publish(AtollonEvent.INFO_CHANGED, item.title);
  }

  const handleSearchReset = (item:FPAData) => {
    dispatch(resetSearch(item));
  }

  const handleRootSearch = (search:string, is_page:boolean = false) => {
    if(searchCallRef.current) searchCallRef.current.abort();
    if(is_page && search_result_offset > 0 && search_result_offset >= search_result_total) return;    
    searchCallRef.current = dispatch(doSearch({
      api: projectService, 
      searchText: search,
      sessionId: store.SessionId, 
      server: store.Server,
      offset: is_page ? search_result_offset : 0,
      limit: ConstantUtils.LIST_FPA_PAGE_SIZE
    }));
  }

  const handleOpenGlobalFinderSearch = () => {
    dispatch(showGlobalSearch());
  }

  const handleShowAll = () => {
    dispatch(closeContextMode());
    dispatch(setSelectedIndexAsSelectedItem());
    
    if(backupDataLength === 0)
      dispatch(loadFolderTypes({folderTypes}));

    publish(AtollonEvent.INFO_CHANGED, '');
  }

  const handleGoBack = () => {
    dispatch(closeDetails());
  }

  const handleLoadMore = (item:FPAData) => {
    if(item.page_offset >= item.count) return;
    dispatch(loadTreeData({
      api: projectService, 
      itemType: item.type, 
      itemId: item.id, 
      sessionId: store.SessionId, 
      server: store.Server,
      offset: item.page_offset,
      limit: ConstantUtils.LIST_FPA_PAGE_SIZE
    })).then((tmp) => {
      if(tmp.payload)
        dispatch(changePageOffsetOfItems({itemId: item.id, offset: item.page_offset + ConstantUtils.LIST_FPA_PAGE_SIZE}));
    });
  }

  const onApply = (filter:any) => {
    var registry_key = `${ListRegisterUtils.Modules.Finder}_${ListRegisterUtils.Keys.Finder.FinderSettings}`;
    registers[registry_key] = { ...registers[registry_key] , value: SoapUtils.JStoXML(filter)};

    dispatch(updateFilters({
      api: featureService, 
      sessionId: store.SessionId, 
      server: store.Server,
      id: registers[registry_key].id,
      moduleId: ListRegisterUtils.Modules.Finder,
      key: ListRegisterUtils.Keys.Finder.FinderSettings,
      level: registers[registry_key].level,
      value: encode(SoapUtils.JStoXML(filter)),
      assignTo: registers[registry_key].assignTo
    }));
    
    saveToCache(CACHE_KEYS.registers, registers);
  }

  const handleClickedDetails = () => {
    dispatch(setTreeFocus(false));
  }

  const handleClickedTree = () => {
    dispatch(setTreeFocus(true));
  }
  return (
    <>
      {showOverlay && (<span className={styles.overlay} onClick={handleOverlayClicked}>
      </span>)}
        <DataProvider>
          <Grid areas={ { base:['sidebar'], M:['sidebar content'] } } 
                columns={ { base:['1fr'], M: ['1fr', '3fr'] } } 
                UNSAFE_className={styles.appContainer} rows={['auto']} height="calc(100vh - 60px)">
              <View gridArea="sidebar" backgroundColor={'static-white'} paddingTop={'size-100'}>
                <Flex direction="column" gap="size-100" height="100%" UNSAFE_className={styles.treeHolder}>
                  {showGlobalFinder && (<Flex direction="row" UNSAFE_className={styles.searchHeader}>
                      <View flex>
                        <InPlaceSearch closeSearch={handleItemSearchClosed}
                          doSearch={handleRootSearch}
                          onSearchSelected={handleRootSearchedItemSelected}
                          items={searchResults}
                          />
                      </View>
                    </Flex>)}
                    {(!isMobile || !showDetails) && !showGlobalFinder && (<Flex direction="row" gap="size-10" justifyContent={'space-between'} UNSAFE_className={styles.contextHeader}>
                        <View UNSAFE_className={styles.contextText}>
                          {_t('context')}
                        </View>
                        {inContextMode && (<View>
                          <DialogTrigger>
                            <ActionButton UNSAFE_className={styles.addNewButton}>
                              {_t('add new')}
                            </ActionButton>
                            {(close) => (<ContextNewDialog onClose={close} parentItem={selectedItem} />)}
                          </DialogTrigger>
                          <ActionButton isQuiet 
                            UNSAFE_className={styles.actionButton} 
                            onPress={handleShowAll}
                            >
                                {_t('show all')}
                            </ActionButton>
                        </View>)}
                        {!inContextMode && (<View>
                          <DialogTrigger>
                            <ActionButton UNSAFE_className={styles.addNewButton}>
                              {_t('add new')}
                            </ActionButton>
                            {(close) => (<ContextNewDialog onClose={close} parentItem={null} />)}
                          </DialogTrigger>
                          <ActionButton isQuiet 
                            UNSAFE_className={styles.actionButton} 
                            onPress={handleOpenGlobalFinderSearch}
                            >
                              <span>
                                <SearchIcon size={20} />
                              </span>
                            </ActionButton>
                          <DialogTrigger>
                            <ActionButton isQuiet>
                              <span className={styles.filterIcon}>
                                <FilterIcon size={'M'} />
                              </span>
                            </ActionButton>
                            {(close) => (<FPAFiltersDialog 
                                            filter={searchFilter} 
                                            onClose={close} 
                                            onApply={onApply}
                                            />)}
                          </DialogTrigger>
                        </View>)}
                    </Flex>)}                  
                    {(isMobile && showDetails) && (<Flex direction="row" gap="size-10" justifyContent={'end'}>
                        <View>
                          <ActionButton isQuiet onPress={handleGoBack} >
                            <FolderAddToIcon />
                          </ActionButton>
                        </View>
                    </Flex>)}                  
                    {(!isMobile || !showDetails) && (
                      <View height={'100%'}>
                        <div onClick={handleClickedTree} onFocus={handleClickedTree}>
                          <FPATree 
                            treeData={treeData}
                            onItemOpened={handleItemOpened}
                            onItemClosed={handleItemClosed}
                            onItemSelected={handleItemSelected}
                            onItemSearching={handleItemSearching}
                            onItemSearchOpened={handleItemSearchOpened}
                            onItemSearchClosed={handleItemSearchClosed}
                            onSearchedItemSelected={handleSearchedItemSelected}
                            onItemSearchReset={handleSearchReset}
                            onItemLoadMore={handleLoadMore}
                            />
                        </div>
                      </View>)}
                    {showDetails && (<View height={'100%'} isHidden={ { base: false, M: true } }>
                      <FinderDetailsMobile selectedItem={selectedItem} onClicked={handleClickedDetails} />
                    </View>)}
                </Flex>
              </View>
              <View gridArea="content" height={'100%'} isHidden={ { base: true, M: false } }>
                <Flex direction="column" 
                      gap="size-100" 
                      height="100%" 
                      alignItems={'center'} 
                      justifyContent={'center'}
                      UNSAFE_className={styles.detailsHolder}
                      >
                          <FinderDetails onClicked={handleClickedDetails} selectedItem={selectedItem} />
                </Flex>
              </View>
          </Grid>
        </DataProvider>
    </>
  );
}
