import React from 'react';
import { View } from '@adobe/react-spectrum';

interface IButtonsComponentProps {
  styles: any;
  AtollonIcon: any;
  PrintIcon: any;
  EditIcon: any;
  ToolIcon: any;
  TrashIcon: any;
  onPrintClicked?: () => void;
  onEditClicked?: () => void;
  onDeleteClicked?: () => void;
  onToolClicked?: () => void;
  onAIClicked?: () => void;
}

const ButtonsComponent: React.FC<IButtonsComponentProps> = props => {
  const { styles, AtollonIcon, PrintIcon, EditIcon, ToolIcon, TrashIcon, onPrintClicked, onEditClicked, onDeleteClicked, onToolClicked, onAIClicked } = props;

  const handlePrintClicked = () => {
    console.log('handlePrintClicked');
    if (onPrintClicked) {
      onPrintClicked();
    }
  };

  const handleEditClicked = () => {
    console.log('handleEditClicked');
    if (onEditClicked) {
      onEditClicked();
    }
  };

  const handleDeleteClicked = () => {
    console.log('handleDeleteClicked');
    if (onDeleteClicked) {
      onDeleteClicked();
    }
  };

  const handleToolClicked = () => {
    console.log('handleToolClicked');
    if (onToolClicked) {
      onToolClicked();
    }
  };

  const handleAIClicked = () => {
    console.log('handleAIClicked');
    if (onAIClicked) {
      onAIClicked();
    }
  };

  return (
    <>
      <View UNSAFE_className={styles.icon_list_child}>
        <div className={styles.atollon_icon} onClick={handleAIClicked}>
          <AtollonIcon size={32} />
        </div>
        <div className={styles.print_icon} onClick={handlePrintClicked}>
          <PrintIcon size={32} />
        </div>
        <div className={styles.edit_icon} onClick={handleEditClicked}>
          <EditIcon size={32} />
        </div>
        <div className={styles.tool_icon} onClick={handleToolClicked}>
          <ToolIcon size={32} />
        </div>
        <div className={styles.delete_icon} onClick={handleDeleteClicked}>
          <TrashIcon size={32} />
        </div>
      </View>
    </>
  );
};

export default ButtonsComponent;
