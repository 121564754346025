import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IGetItemRequest extends IBaseRequest {
    id: string;
}

export class GetItemRequest extends BaseRequest implements IGetItemRequest {
    public id: string;
    constructor(server: string, session: string, id: string) {
        super(server, session);
        this.id = id;
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        parameters += '<id>' + this.id + '</id>';
        
        return super.wrapperXml('GetItem', parameters);
    }
}
