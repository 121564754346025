import { Button, Form, TextField, Checkbox, Flex, View, Image } from '@adobe/react-spectrum';
import { ToastQueue } from '@react-spectrum/toast';
import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDependency } from '../../contexts/DependencyProvider';
import { doLogin } from '../../utils/LoginUtils';
import { useViewInfo } from '../../hooks/UseViewInfo';
import { useReefConfig } from '../../hooks/UseReefConfig';
import styles from './Login.module.css';
import { useApps } from '../../contexts/AppsProvider';

const md5 = require('md5');

const Login = () => {
  const navigate = useNavigate();
  const { redirectUrl, setRedirectUrl } = useApps();
  const { loginInfo } = useReefConfig();

  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [server, setServer] = useState<string>(loginInfo.serverName);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { isDesktop } = useViewInfo();
  const { mainService, store } = useDependency();
  const is_login_in_progress = useRef(false);

  const onHandleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  useEffect(() => {
    setServer(loginInfo.serverName);
  }, [loginInfo]);

  const processLogin = async (uname: string, pass: string, serv: string, plainPassword: boolean = true) => {
    try {
      if (is_login_in_progress.current) return;
      is_login_in_progress.current = true;
      setIsDisabled(true);

      var p_username: string = uname;
      if (uname !== 'root' && !uname.includes('@')) {
        if (loginInfo.instanceName !== '' && loginInfo.instanceName !== null) p_username += '@' + loginInfo.instanceName;
        else if (serv !== '' && serv !== null) p_username += '@' + serv;
      }
      const { status, message, session } = await doLogin(p_username, plainPassword ? md5(pass) : pass, serv, mainService, store);
      is_login_in_progress.current = false;
      if (status) {
        if (rememberMe) {
          store.Password = plainPassword ? md5(pass) : pass;
        }
        store.Username = uname;
        store.Server = serv;
        store.UserId = +session!.userId;
        store.session = session!;

        if (isDesktop) ToastQueue.positive('Login is done!', { timeout: 3000 });

        navigate('/user/dashboard', { state: { session } });
      } else {
        ToastQueue.negative(message, { timeout: 3000 });
      }
    } catch (error) {
      console.log('error in handleLogin', error);
      is_login_in_progress.current = false;
    } finally {
      setIsDisabled(false);
    }
  };
  const handleLogin = async () => {
    if (username === '' || password === '' || server === '') {
      ToastQueue.negative('Please fill in all fields!', { timeout: 3000 });
      return;
    }

    await processLogin(username, password, server);
  };

  useEffect(() => {
    if (store.Password) {
      setUsername(store.Username);
      setPassword(store.Password);
      setServer(store.Server);
      setRememberMe(true);
      processLogin(store.Username, store.Password, store.Server, false);
    }
  }, [store]);

  return (
    <>
        <Flex direction="column" gap="size-100" alignItems="start"
          UNSAFE_className={styles.loginContainer}
          >
          <View>
            <Image src="/assets/images/logo-web.svg" alt="Logo" width={112} />
          </View>
          <View>
            <span className={styles.welcomeText}>Welcome back!</span>
          </View>
          <View>
            <Form maxWidth="size-3600">
              <TextField label="Username" 
                          isRequired value={username} 
                          onChange={setUsername} 
                          isDisabled={isDisabled} 
                          onKeyUp={onHandleKeyUp} 
                          width={315}
                          />

              <TextField label="Password" 
                          type="password" 
                          isRequired 
                          value={password} 
                          onChange={setPassword} 
                          isDisabled={isDisabled} 
                          onKeyUp={onHandleKeyUp} 
                          width={315}
                          />

              <TextField label="Server" 
                          isRequired 
                          value={server} 
                          onChange={setServer} 
                          isDisabled={isDisabled} 
                          onKeyUp={onHandleKeyUp} 
                          isHidden={!loginInfo.showServer} 
                          width={315}
                          />

              <Checkbox isDisabled={isDisabled} isSelected={rememberMe} onChange={setRememberMe}>
                Remember me
              </Checkbox>

              <View width={315} paddingBottom={'50px'}>
                Forgot password? Please ask your administrator.
              </View>

              <Button variant="cta" 
                      onPress={handleLogin} 
                      isDisabled={isDisabled}
                      width={315}
                      >
                Login
              </Button>
            </Form>
          </View>
        </Flex>
    </>
  );
};

export default Login;
