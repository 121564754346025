import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export enum ListStatusType {
  Folder,
  Project,
  Activity,
}

export interface IListProjectStatusRequest extends IBaseRequest {
  fpaTypeCategory?: string;
}

export class ListStatusRequest extends BaseRequest implements IListProjectStatusRequest {
  public type: ListStatusType;
  public fpaTypeCategory?: string;
  constructor(server: string, session: string, type: ListStatusType, _fpaTypeCategory?: string) {
    super(server, session);
    this.type = type;
    this.fpaTypeCategory = _fpaTypeCategory;
  }

  public toXml(): string {
    var tag = '';
    switch (this.type) {
      case ListStatusType.Folder:
        tag = 'ListFolderStatus';
        break;
      case ListStatusType.Project:
        tag = 'ListProjectStatus';
        break;
      case ListStatusType.Activity:
        tag = 'ListActivityStatus';
        break;
    }
    var parameters: string = super.toXml();
    if (this.fpaTypeCategory) parameters += `<fpaTypeCategory>${this.fpaTypeCategory}</fpaTypeCategory>\n`;
    return super.wrapperXml(tag, parameters);
  }
}
