import {parseAbsoluteToLocal} from '@internationalized/date';
import { Component } from './FormDefinitions';
import { Checkbox, ComboBox, DatePicker, Flex, Item, ListView, NumberField, TextArea, TextField, View } from '@adobe/react-spectrum';
import { SoapUtils } from '../../../../../utils/SoapUtils';

export interface IFormControlProps {
    component: Component;
    updateData: (key: string, value: any) => void;
    getData: (key: string, default_value:any) => any;
}

const warning_styles = {
    color: 'red',
    fontSize: '12px',
    fontWeight: 'bold'
}

const CONTROLS = {
    mx:{
        controls:{
            Label: 'mx.controls::Label',
            List: 'mx.controls::List',
        }
    },
    com:{
        atollon:{
            formDesigner:{
                components:{
                    MainContentGroup: "com.atollon.formDesigner.components::MainContentGroup",
                    VBoxExtended: "com.atollon.formDesigner.components::VBoxExtended",
                    HBoxExtended: "com.atollon.formDesigner.components::HBoxExtended",
                    inputs: {
                        FormTextInput: "com.atollon.formDesigner.components.inputs::FormTextInput",
                        FormDateField: "com.atollon.formDesigner.components.inputs::FormDateField",
                        FormNumberInput: "com.atollon.formDesigner.components.inputs::FormNumberInput",
                        FormComboBox: "com.atollon.formDesigner.components.inputs::FormComboBox",
                        FormTextArea: "com.atollon.formDesigner.components.inputs::FormTextArea",
                        FormCheckbox: "com.atollon.formDesigner.components.inputs::FormCheckbox"
                    }
                }
            }
        }
    }
};
export function FormControl ({ component, updateData, getData }: IFormControlProps) {
    // console.log('FormControl', component);
    switch(component.CLASS) {
        case CONTROLS.com.atollon.formDesigner.components.MainContentGroup:
            return (<MainContentGroup component={component} updateData={updateData} getData={getData} />);
        case CONTROLS.com.atollon.formDesigner.components.VBoxExtended:
            return (<VBoxExtended component={component} updateData={updateData} getData={getData} />);
        case CONTROLS.com.atollon.formDesigner.components.HBoxExtended:
            return (<HBoxExtended component={component} updateData={updateData} getData={getData} />);
        case CONTROLS.com.atollon.formDesigner.components.inputs.FormTextInput:
            return (<FormTextInput component={component} updateData={updateData} getData={getData} />);
        case CONTROLS.com.atollon.formDesigner.components.inputs.FormDateField:
            return (<FormDateField component={component} updateData={updateData} getData={getData} />);
        case CONTROLS.com.atollon.formDesigner.components.inputs.FormNumberInput:
            return (<FormNumberInput component={component} updateData={updateData} getData={getData} />);
        case CONTROLS.com.atollon.formDesigner.components.inputs.FormComboBox:
            return (<FormComboBox component={component} updateData={updateData} getData={getData} />);
        case CONTROLS.com.atollon.formDesigner.components.inputs.FormTextArea:
            return (<FormTextArea component={component} updateData={updateData} getData={getData} />);
        case CONTROLS.com.atollon.formDesigner.components.inputs.FormCheckbox:
            return (<FormCheckbox component={component} updateData={updateData} getData={getData} />);
        case CONTROLS.mx.controls.Label:
            return (<FormLabel component={component} updateData={updateData} getData={getData} />);
        case CONTROLS.mx.controls.List:
            return (<FormList component={component} updateData={updateData} getData={getData} />);
        default:
            return (<UnSupportedComponent component={component} updateData={updateData} getData={getData} />);
    }
}

function MainContentGroup( { component, updateData, getData }: IFormControlProps) {

    return (
        <View>
            {component.CHILDREN && component.CHILDREN.COMPONENT.map((child, index) => (<FormControl key={index} component={child} updateData={updateData} getData={getData} />))}
        </View>
    );
}

function VBoxExtended( { component, updateData, getData }: IFormControlProps) {

    return (
        <Flex direction="column" gap={10}>
            {component.CHILDREN && component.CHILDREN.COMPONENT.map((child, index) => (<FormControl key={index} component={child} updateData={updateData} getData={getData} />))}
        </Flex>
    );
}

function HBoxExtended( { component, updateData, getData }: IFormControlProps) {

    return (
        <Flex direction={{ "base": 'column', "M": "row"}} gap={10}>
            {component.CHILDREN && component.CHILDREN.COMPONENT.map((child, index) => (<FormControl key={index} component={child} updateData={updateData} getData={getData} />))}
        </Flex>
    );
}

function UnSupportedComponent( { component, updateData, getData }: IFormControlProps) {
    return (
        <View>
            <div style={warning_styles}>UnSupportedComponent: {component.CLASS}</div>
            {component.CHILDREN && component.CHILDREN.COMPONENT.map((child, index) => (<FormControl key={index} component={child} updateData={updateData} getData={getData} />))}
        </View>
    );
}

function FormTextInput( { component, updateData, getData }: IFormControlProps) {
    return (
        <View>
            <TextField label={component.PROPERTIES.name} 
                        width={'100%'}
                        value={getData(component.FORMITEM, '')} 
                        defaultValue={component.PROPERTIES.value}
                        onChange={(value) => updateData(component.FORMITEM, value)}
                        aria-label='none'
                        />
        </View>
    );
}

function FormDateField( { component, updateData, getData }: IFormControlProps) {
    var data = getData(component.FORMITEM, null);
    if(data != null){
        if(data.charAt(data.length-1) != 'Z')
            data = data+'Z';
        if(data.charAt(10) != 'T')
            data = data.substring(0, 10)+'T'+data.substring(11);
        data = parseAbsoluteToLocal(data);
    }

    const handleChanged = (value: any) => {
        var selected_date = new Date(value.year, value.month - 1, value.day);
        updateData(component.FORMITEM, value === null ? null : SoapUtils.toDateForXml(selected_date, true));
    }
    return (
        <View>
            <DatePicker label={component.PROPERTIES.name} 
                        width={'100%'}
                        granularity="day" 
                        defaultValue={parseAbsoluteToLocal(component.PROPERTIES.value ? component.PROPERTIES.value+'Z': new Date().toISOString())}
                        onChange={handleChanged}
                        value={data}
                        aria-label='none'
                        />
        </View>
    );
}

function FormNumberInput( { component, updateData, getData }: IFormControlProps) {
    return (
        <View>
            <NumberField label={component.PROPERTIES.name} 
                        width={'100%'}
                        value={+(getData(component.FORMITEM, 0))} 
                        defaultValue={+component.PROPERTIES.value!} 
                        onChange={(value) => updateData(component.FORMITEM, value)}
                        aria-label='none'
                        />
        </View>
    );
}

function FormComboBox( { component, updateData, getData }: IFormControlProps) {

    if(component.PROPERTIES.dataProvider === undefined
        || component.PROPERTIES.dataProvider!.items === undefined
        || component.PROPERTIES.dataProvider!.items!.item === undefined){
        return (<View UNSAFE_style={warning_styles}>FormComboBox: No dataProvider.items.item</View>);
    }
    return (
        <View>
            <ComboBox label={component.PROPERTIES.name} 
                        width={'100%'}
                        defaultSelectedKey={component.PROPERTIES.value}
                        selectedKey={getData(component.FORMITEM, '')} 
                        onSelectionChange={(key) => updateData(component.FORMITEM, key)}
                        aria-label='none'
                        >
                {(component.PROPERTIES.dataProvider!.items!.item!.map((v, i) => (<Item key={v}>{v}</Item>)))}
            </ComboBox>
        </View>
    );
}

function FormTextArea( { component, updateData, getData }: IFormControlProps) {
    return (
        <View>
            <TextArea label={component.PROPERTIES.name} 
                        width={'100%'}
                        value={getData(component.FORMITEM, '')} 
                        defaultValue={component.PROPERTIES.value} 
                        onChange={(value) => updateData(component.FORMITEM, value)}
                        aria-label='none'
                        />
        </View>
    );
}

function FormLabel({ component, updateData, getData }: IFormControlProps){
    var labelStyles = {
        color: component.STYLES.color,
        fontSize: `${component.STYLES.fontSize}px`,
        fontWeight: component.STYLES.fontWeight
    }
    return (
        <View>
            <div style={labelStyles}>{component.PROPERTIES.text}</div> 
        </View>
    )
}

function FormCheckbox( { component, updateData, getData }: IFormControlProps) {
    var val = getData(component.FORMITEM, false);
    return (
        <View>
            <Checkbox isSelected={!!(val == 'true' || val == true)}
                        onChange={(value) => updateData(component.FORMITEM, value)}
                        aria-label='none'
                >
                {component.PROPERTIES.label}
            </Checkbox>
        </View>
    );
}

function FormList( { component, updateData, getData }: IFormControlProps) {
    var list_item = component.PROPERTIES.dataProvider!.items.item as string[];
    var selected_item = new Set<string>(getData(component.FORMITEM, '').split(','));
    return (
        <View>
            <div>{component.PROPERTIES.name}</div>
            <ListView selectionMode='single'
                        selectionStyle='highlight'
                        aria-label='none'
                        defaultSelectedKeys={component.PROPERTIES.value}
                        selectedKeys={selected_item}
                        onSelectionChange={(key:any) => updateData(component.FORMITEM, key.currentKey)}
                >
                {list_item.map((v, i) => (<Item key={v}>{v}</Item>))}
            </ListView>
        </View>
    );
}