import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListDepartmentRequest extends IBaseRequest {
    limit?: number;
    offset?: number;
}

export class ListDepartmentRequest extends BaseRequest implements IListDepartmentRequest {
    public limit?: number;
    public offset?: number;
  constructor(server: string, session: string, offset?: number, limit?: number) {
    super(server, session);
    this.offset = offset;
    this.limit = limit;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    if (this.offset) parameters += `<offset>${this.offset}</offset>`;
    if (this.limit) parameters += `<limit>${this.limit}</limit>`;

    return super.wrapperXml('ListDepartment', parameters);
  }
}
