import { BaseRequest, IBaseRequest } from "../../BaseRequest";

export interface IGetActivityRequest extends IBaseRequest {
  id: number;
}

export class GetActivityRequest
  extends BaseRequest
  implements IGetActivityRequest
{
  public id: number;
  constructor(server: string, session: string, id: number) {
    super(server, session);
    this.id = id;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    parameters += "<id>" + this.id + "</id>";
    return super.wrapperXml("GetActivity", parameters);
  }
}
