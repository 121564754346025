import { SearchField } from '@adobe/react-spectrum';
import styles from './MobileGlobalSearch.module.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ListContextRequest } from '../../../services/soap/project/requests/ListContextRequest';
import { useDependency } from '../../../contexts/DependencyProvider';
import { GroupedSearchResultItemData, mergeGroupedSearchResults, processHistoryData, processSearchData } from '../../../utils/SearchUtils';
import { MobileSearchResults } from './SearchResults/MobileSearchResults';
import { ListFPARequest } from '../../../services/soap/project/requests/ListFPARequest';
import ConstantUtils from '../../../utils/ConstantUtils';
// import { useRef } from 'react';
// import { useDependency } from '../../../contexts/DependencyProvider';

export interface IMobileGlobalSearchProps {
  close: () => void;
  onSearchSelected: (selectedItem: any) => void;
  folderStatusMap: any;
  projectStatusMap: any;
  activityStatusMap: any;
}

export function MobileGlobalSearch ({
    close, 
    onSearchSelected, 
    folderStatusMap, 
    projectStatusMap, 
    activityStatusMap   
}: IMobileGlobalSearchProps) {
    const { projectService, store } = useDependency();
    const [searchTitle, setSearchTitle] = useState("History");
    const [searchResults, setSearchResults] = useState<GroupedSearchResultItemData[]>([]);

    const resultOffsetRef = useRef<number>(0);
    const resultRef = useRef<GroupedSearchResultItemData[]>([]);
    const searchRef = useRef<string>('');
    const resultEndRef = useRef<boolean>(false);
    const cancelSource = useRef<any>(null);
    const totalCountRef = useRef<number>(0);

    var timeout:number | null = null;

    const historyCallBack = useCallback(async () => {
      try {
        if(cancelSource.current){
          cancelSource.current.cancel();
          cancelSource.current = null;
        }
  
        if(resultEndRef.current) return;
        var request = new ListContextRequest(store.Server, store.SessionId, resultOffsetRef.current, ConstantUtils.LIST_CONTEXT_PAGE_SIZE);
        cancelSource.current = request.cancelSource;
        var result = await projectService.listContext(request);
        var processed_result = processHistoryData(result.CONTEXTS, folderStatusMap, projectStatusMap, activityStatusMap);
        var total_items = mergeGroupedSearchResults(resultRef.current, processed_result);

        totalCountRef.current += +result.count;
        setSearchTitle(`History (${totalCountRef.current})`);
        setSearchResults(total_items);
        resultRef.current = total_items;
        resultOffsetRef.current += ConstantUtils.LIST_CONTEXT_PAGE_SIZE;
        resultEndRef.current = result.CONTEXTS.length < ConstantUtils.LIST_CONTEXT_PAGE_SIZE;

      } catch (error) {
        console.log(error);
      }
    }, [projectService, store.Server, store.SessionId, folderStatusMap, projectStatusMap, activityStatusMap]);    

    const searchCallBack = async (text:string) => {
      try {
        if(text !== searchRef.current) {
          resultOffsetRef.current = 0;
          resultRef.current = [];
          resultEndRef.current = false;
          totalCountRef.current = 0;
        }

        if(resultEndRef.current) return;

        searchRef.current = text;

        if(cancelSource.current){
          cancelSource.current.cancel();
          cancelSource.current = null;
        }
  
        if(text.length < 1) { historyCallBack(); return ; }
        var request = new ListFPARequest(store.Server, store.SessionId, text+"*", true, ConstantUtils.LIST_FPA_PAGE_SIZE, resultOffsetRef.current);
        cancelSource.current = request.cancelSource;
        var result = await projectService.listFPA(request);
  
        var tmpSearchData = processSearchData(result.ITEMS, folderStatusMap, projectStatusMap, activityStatusMap);
        var processed_result = mergeGroupedSearchResults(resultRef.current, tmpSearchData);
        
        totalCountRef.current += +result.count;
        setSearchTitle(`Results (${totalCountRef.current})`);
        setSearchResults(processed_result);
        resultRef.current = processed_result;
        resultOffsetRef.current += ConstantUtils.LIST_FPA_PAGE_SIZE;
        resultEndRef.current = result.ITEMS.length < ConstantUtils.LIST_FPA_PAGE_SIZE;
      } catch (error) {
        console.log(error);
      }
    };

    const handleSearchChange = async (text:string) => {
      if(timeout !== null)
        window.clearTimeout(timeout);
  
      timeout = window.setTimeout(() => {
        searchCallBack(text);
      }, 500) as number;
    }
    
    useEffect(() => {
      historyCallBack();
    }, [historyCallBack]);

    const handleLoadMore = async () => {
      if(searchTitle.startsWith("History")) {
        historyCallBack();
      }else {
        console.log('calling searchCallBack: ', searchRef.current, resultOffsetRef.current);
        searchCallBack(searchRef.current);
      }
    }
  
    return (
        <>
            <div className={styles.searchContainer}>
                <SearchField aria-label="Search" width={'100%'} onChange={handleSearchChange} autoFocus />
                <div className={styles.separator}></div>
                <MobileSearchResults 
                  mainTitle={searchTitle} 
                  groups={searchResults} 
                  onSearchSelected={onSearchSelected} 
                  onLoadMore={handleLoadMore}
                />

            </div>
        </>
    );
}
