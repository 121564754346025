import { Button, ButtonGroup, Content, Dialog, Divider, Flex, Footer, Header, Heading, ProgressBar, TextField, View } from '@adobe/react-spectrum';
import { FPAData, FPADataTypes } from '../../../infra/protected/FPA/FPAData';
import { useContextNewDialog } from './ContextNewDialog.hooks';
import { FinderContextTree } from '../../../pages/protected/finder/components/FinderContextTree';
import styles from './ContextNewDialog.module.css';
import { FolderContextFields } from './FolderContextFields';
import { ProjectContextFields } from './ProjectContextFields';
import { ActivityContextFields } from './ActivityContextField';

export interface IContextNewDialogProps {
    parentItem: FPAData | null;
    onClose: () => void;
}

export function ContextNewDialog ({
    onClose,
    parentItem,
}: IContextNewDialogProps) {
    const { 
        treeData, 
        onSelection, 
        loading, 
        currentStep,
        nextStep ,
        selectedItem,
        settings,
        setFormData
    } = useContextNewDialog({ parentItem, onClose });

    const showFields = () => {
        if(selectedItem){
            switch (selectedItem.type) {
                case FPADataTypes.FOLDER:
                    return <FolderContextFields settings={settings} selectedItem={selectedItem} setFormData={setFormData} />;
                case FPADataTypes.PROJECT:
                    return <ProjectContextFields settings={settings} selectedItem={selectedItem} />;
                case FPADataTypes.ACTIVITY:
                    return <ActivityContextFields settings={settings} selectedItem={selectedItem} />;
                default:
                    return (<>Unknown item type selected</>)
            }
        }
    }
    return (
        <Dialog>
            <Heading>
                Add New Context
            </Heading>
            <Header>
                {currentStep === 1 ? 'Step 1' : 'Step 2'}
            </Header>
            <Divider />
            <Content>
                {currentStep === 1 && (
                    <Flex direction="column">
                        <View>
                            <TextField label="Choose context" autoFocus width={'100%'} />
                        </View>
                        <View>
                            <FinderContextTree items={treeData} onSelection={onSelection} />
                        </View>
                    </Flex>
                )}
                {currentStep === 2 && (
                    <Flex direction="column">
                        <View>
                            Fill in the details
                        </View>
                        {showFields()}
                    </Flex>
                )}
            </Content>
            <Footer>
                <Flex direction={'column'}>
                    {currentStep == 2 && selectedItem && (
                        <View>
                            {`${selectedItem.type}: ${selectedItem.title}`}
                        </View>
                    )}
                    <View>
                        {loading && (<ProgressBar label="Loading..." isIndeterminate />)}
                    </View>
                </Flex>
            </Footer>
            <ButtonGroup>
                <Button variant="secondary" onPress={onClose}>Cancel</Button>
                <Button variant="accent" onPress={nextStep}>{currentStep == 1 ? "Next" : "Save" }</Button>
            </ButtonGroup>
        </Dialog>
    );
}
