class AtollonEvent extends Event {
    public static readonly INFO_CHANGED:string = 'infoChanged';
    public static readonly FINDER_DETAIL_LOADED:string = 'finderDetailLoaded';
    public detail?: any;
    
    constructor(eventName: string, detail?: any) {
        super(eventName);
        this.detail = detail;
    }
}

function subscribe(eventName: string, callback: (event: AtollonEvent) => void) {
    document.addEventListener(eventName, callback);
}

function unsubscribe(eventName: string, callback: (event: AtollonEvent) => void) {
    document.removeEventListener(eventName, callback);
}

function publish(eventName: string, detail?: any) {
    document.dispatchEvent(new AtollonEvent(eventName, detail));
}

export { subscribe, unsubscribe, publish, AtollonEvent };

