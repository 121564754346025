import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import { useEffect } from 'react';

function Loader() {
  useEffect(() => {
    createSpinner({
      // Specify the target for the spinner to show
      target: document.getElementById('container') as HTMLElement,
    });
    showSpinner(document.getElementById('container') as HTMLElement);
  });
  return (
    <div className="control-pane">
      <div id="container" className="control-section col-lg-12 spinner-target"></div>
    </div>
  );
}
export default Loader;
