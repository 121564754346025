import { apiDataDB } from "../../data/db";
import { IRestClient, RestClient } from "./RestClient";

export class RestServiceBase {
    public static SessionExpiredHandler:()=>void = ()=>{};

    restClient:IRestClient;
    REST_URL: string;

    // public _checkErrors(response:BaseResponse):boolean{
    //     const SESSION_EXPIRED = '65540';

    //     if(response.EXCEPTION){
    //         switch (response.EXCEPTION.code) {
    //             case SESSION_EXPIRED:
    //                 ServiceBase.SessionExpiredHandler();
    //                 throw new Error('SESSION_EXPIRED');
    //             default:
    //                 // throw new Error(response.EXCEPTION.message);
    //                 break;
    //         }
    //         return false;
    //     }
    //     return true;
    // }

    constructor(_url:string){
        this.restClient = new RestClient();
        this.REST_URL = _url;
    }
    sendResponse(response:string):any{
        return JSON.parse(response);
    }
    
    makeCall(requestBody:any, action:string, cancelSource:any, responseType: any = 'json'):Promise<any>{
        return this.restClient.makeRestCall(requestBody, this.REST_URL, action, cancelSource, responseType);
    }

    async updateCache(type: string,  request: string, response: string) {
        var cache_data = {type, url: this.REST_URL, request , response};
        await apiDataDB.apiData.add(cache_data);
    }

    async getFromCache(type: string,  request: string) {
        let apiData = await apiDataDB.apiData.where({type, url: this.REST_URL, request}).last();
        return apiData?.response? apiData.response : '';
    }
}