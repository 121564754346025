import { Checkbox, Text, Flex } from '@adobe/react-spectrum';
import { useEffect, useState } from 'react';

export interface IMultipleOptionFilterProps {
    title: string;
    typeKey:string;
    onChange?: (key:string, value: any) => void;
    defaultOptions?: boolean[];
  }
  
  export function MultipleOptionFilter ({
    title,
    typeKey,
    onChange,
    defaultOptions
  }: IMultipleOptionFilterProps) {

    const [active, setActive] = useState(false);
    const [initial, setInitial] = useState(false);
    const [closed, setClosed] = useState(false);

    useEffect(() => {
      if(onChange === undefined) return;
      onChange(typeKey, [active, initial, closed]);
    }, [active, initial, closed, onChange, typeKey]);

    useEffect(() => { 
      // console.log(typeKey+'.defaultOptions: ', defaultOptions);
      if(defaultOptions === undefined) return;
      setActive(defaultOptions[0] ? defaultOptions[0] : false);
      setInitial(defaultOptions[1] ? defaultOptions[1] : false);
      setClosed(defaultOptions[2] ? defaultOptions[2] : false);
    }, [defaultOptions]);
    return (
      <Flex direction='column' gap={'size-10'}>
        <Text>{title}</Text>
        <Flex direction='row' gap={'size-10'}>
          <Checkbox isSelected={active} onChange={setActive} defaultSelected>Active</Checkbox>
          <Checkbox isSelected={initial} onChange={setInitial} defaultSelected>Initial</Checkbox>
          <Checkbox isSelected={closed} onChange={setClosed} defaultSelected>Closed</Checkbox>
        </Flex>
      </Flex>
    );
  }
  