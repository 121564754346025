import { FunctionComponent } from "react";
import AppMenuGroupItem from "./AppMenuGroupItem";
import styles from "./AppMenuGroup.module.css";
import { AppMenuItemType } from "./AppMenu.types";

type AppMenuGroupType = {
  sectionTitle?: string;
  items?: any[];
  onAppClicked: (item:AppMenuItemType) => void;
  filter: string;
  isGroupSelected: boolean;
  selectedItem: number;
  /** Style props */
};

const AppMenuGroup: FunctionComponent<AppMenuGroupType> = ({
  sectionTitle,
  items,
  onAppClicked,
  filter,
  isGroupSelected,
  selectedItem
}) => {
  return (
    <div className={styles.generalParent}>
      <b className={styles.general}>
        {sectionTitle}
      </b>
      <div className={styles.frameParent}>
        {items && items.filter(x => x.itemTitle.toLowerCase().includes(filter.toLowerCase())).map((item, index) => (
          <AppMenuGroupItem key={index} 
            onAppClicked={onAppClicked} 
            item={item} 
            isSelectedItem={isGroupSelected && selectedItem === index}
            />
        ))}
      </div>
    </div>
  );
};

export default AppMenuGroup;
