import * as React from 'react';
import styles from './AppMenuGroupItem.module.css';
import { AppMenuItemType } from '../AppMenu/AppMenu.types';

export interface IAppMenuGroupItemProps {
    item: AppMenuItemType;
    onItemClicked: (item:AppMenuItemType) => void;
}

export function AppMenuGroupItem ({
    item,
    onItemClicked
  }: IAppMenuGroupItemProps) {
  return (
    <div className={[styles.MainItem, item.itemLink?.startsWith('/user/NotFound') ? styles.broken: ''].join(' ')} onClick={() => {onItemClicked(item)}}>
      <img className={styles.Icon} alt="" src={item.itemIcon} />
      <div className={styles.Title}>{item.itemTitle}</div>
    </div>
  );
}
