import { BaseRequest, IBaseRequest } from "../../BaseRequest";

export interface IUpdateRegisterRequest extends IBaseRequest {
    id?: number;
    module?: number;
    moduleId?:string;
    application?: number;
    key?: string;
    level?: number;
    value?: string;
    assignTo?: number;
}

export class UpdateRegisterRequest extends BaseRequest implements IUpdateRegisterRequest {
    id?: number;
    module?: number;
    moduleId?:string;
    application?: number;
    key?: string;
    level?: number;
    value?: string;
    assignTo?: number;

    constructor(server: string, 
        session: string,
        id?: number,
        module?: number,
        moduleId?:string,
        application?: number,
        key?: string,
        level?: number,
        value?: string,
        assignTo?: number) {
            super(server, session);
            this.id = id;
            this.module = module;
            this.moduleId = moduleId;
            this.application = application;
            this.key = key;
            this.level = level;
            this.value = value;
            this.assignTo = assignTo;
    }

    toXml(): string {
        var parameters:string = super.toXml();
        if(this.id){
            parameters += `<id>${this.id}</id>`;
        }
        if(this.module){
            parameters += `<module>${this.module}</module>`;
        }
        if(this.moduleId){
            parameters += `<moduleId>${this.moduleId}</moduleId>`;
        }
        if(this.application){
            parameters += `<application>${this.application}</application>`;
        }
        if(this.key){
            parameters += `<key>${this.key}</key>`;
        }
        if(this.level){
            parameters += `<level>${this.level}</level>`;
        }
        if(this.value){
            parameters += `<value>${this.value}</value>`;
        }
        if(this.assignTo){
            parameters += `<assignTo>${this.assignTo}</assignTo>`;
        }

        return super.wrapperXml('UpdateRegister', parameters);
    }
}