import { BaseRequest, IBaseRequest } from "../../BaseRequest";

export interface IListProjectTypeRequest extends IBaseRequest {
    fixedType?: number;
    canBeRoot?: boolean;
    systemType?: number;
    categoryId?: number;
    fpaTypeCategory?:string;
}

export class ListProjectTypeRequest extends BaseRequest implements IListProjectTypeRequest{
    public fixedType?: number;
    public canBeRoot?: boolean;
    public systemType?: number;
    public categoryId?: number;
    public fpaTypeCategory?:string;

    constructor(_server:string, _sessionId:string, 
        _fpaTypeCategory?:string,
        _fixedType?: number, 
        _canBeRoot?: boolean, 
        _systemType?: number, 
        _categoryId?: number, 
        ) {

        super(_server, _sessionId);
        this.fixedType = _fixedType;
        this.canBeRoot = _canBeRoot;
        this.systemType = _systemType;
        this.categoryId = _categoryId;
        this.fpaTypeCategory = _fpaTypeCategory;
    }

    toXml(): string {
        var parameters:string = super.toXml();
        if(this.fixedType) parameters += `<fixedType>${this.fixedType}</fixedType>\n`;
        if(this.canBeRoot !== undefined) parameters += `<canBeRoot>${this.canBeRoot}</canBeRoot>\n`;
        if(this.systemType) parameters += `<systemType>${this.systemType}</systemType>\n`;
        if(this.categoryId) parameters += `<categoryId>${this.categoryId}</categoryId>\n`;
        if(this.fpaTypeCategory) parameters += `<fpaTypeCategory>${this.fpaTypeCategory}</fpaTypeCategory>\n`;

        return super.wrapperXml("ListProjectType", parameters);
    }
}