import { FINANCE } from '../../../utils/ServiceUtils';
import {ServiceBase} from '../ServiceBase';
import { IGetFinanceSettingsRequest } from './requests/GetFinanceSettingsRequest';
import { IListCurrencyRateRequest } from './requests/ListCurrencyRateRequest';
import { GetFinanceSettingsResponse } from './responses/GetFinanceSettingsResponse';
import { ListCurrencyRateResponse } from './responses/ListCurrencyRateResponse';

export interface IFinanceService {
    getFinanceSettings(request:IGetFinanceSettingsRequest):Promise<GetFinanceSettingsResponse>;
    listCurrencyRate(request: IListCurrencyRateRequest): Promise<ListCurrencyRateResponse>;
}

export class FinanceService extends ServiceBase implements IFinanceService { 
    public async getFinanceSettings(request: IGetFinanceSettingsRequest): Promise<GetFinanceSettingsResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), FINANCE.getFinanceSettings, request.cancelSource)).data ;
            await this.updateCache(FINANCE.getFinanceSettings, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(FINANCE.getFinanceSettings, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).GetFinanceSettingsResponse as GetFinanceSettingsResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async listCurrencyRate(request: IListCurrencyRateRequest): Promise<ListCurrencyRateResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), FINANCE.listCurrencyRate, request.cancelSource)).data ;
            await this.updateCache(FINANCE.listCurrencyRate, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(FINANCE.listCurrencyRate, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListCurrencyRateResponse as ListCurrencyRateResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }
}