import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IGetTypeOfWorkForContextRequest extends IBaseRequest {
    onlyCurrent?: boolean;
}

export class GetTypeOfWorkForContextRequest extends BaseRequest implements IGetTypeOfWorkForContextRequest {
    public onlyCurrent?: boolean;
    constructor(server: string, session: string, onlyCurrent?: boolean) {
        super(server, session);
        this.onlyCurrent = onlyCurrent
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        if (this.onlyCurrent) {
            parameters += '<onlyCurrent>' + (this.onlyCurrent ? '1' : '0') + '</onlyCurrent>';
        }
        
        return super.wrapperXml('GetTypeOfWorkForContext', parameters);
    }
}
