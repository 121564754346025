import { RECRUITMENT } from "../../../utils/ServiceUtils";
import { ServiceBase } from "../ServiceBase";
import { GetSelectionProcedureStatsRequest } from "./requests/GetSelectionProcedureStatsRequest";
import { ListCandidateVacancyRequest } from "./requests/ListCandidateVacancyRequest";
import { IListClientProjectPackageRequest } from "./requests/ListClientProjectPackageRequest";
import { IListClientProjectRequest } from "./requests/ListClientProjectRequest";
import { IListPlacementConfigurationRequest } from "./requests/ListPlacementConfigurationRequest";
import { UpdateCandidateVacancyRequest } from "./requests/UpdateCandidateVacancyRequest";
import { GetSelectionProcedureStatsResponse } from "./responses/GetSelectionProcedureStatsResponse";
import { ListCandidateVacancyResponse } from "./responses/ListCandidateVacancyResponse";
import { ListClientProjectPackageResponse } from "./responses/ListClientProjectPackageResponse";
import { ListClientProjectResponse } from "./responses/ListClientProjectResponse";
import { ListPlacementConfigurationResponse } from "./responses/ListPlacementConfigurationResponse";
import { UpdateCandidateVacancyResponse } from "./responses/UpdateCandidateVacancyResponse";

export interface IRecruitmentService {
    listCandidateVacancy(request: ListCandidateVacancyRequest): Promise<ListCandidateVacancyResponse>;
    updateCandidateVacancy(request: UpdateCandidateVacancyRequest): Promise<UpdateCandidateVacancyResponse>;
    listClientProject(request: IListClientProjectRequest): Promise<ListClientProjectResponse>;
    listClientProjectPackage(request: IListClientProjectPackageRequest): Promise<ListClientProjectPackageResponse>;
    listPlacementConfiguration(request: IListPlacementConfigurationRequest): Promise<ListPlacementConfigurationResponse>;
    getSelectionProcedureStats(request: GetSelectionProcedureStatsRequest): Promise<GetSelectionProcedureStatsResponse>;
}

export class RecruitmentService extends ServiceBase implements IRecruitmentService {
    private _addRootElementStatsList(xml: string): string {
        xml = xml.replaceAll('</count><STAT>', '</count><STAT_LIST><STAT>');
        xml = xml.replaceAll('</STAT></ns1:GetSelectionProcedureStatsResponse>', '</STAT></STAT_LIST></ns1:GetSelectionProcedureStatsResponse>');
        return xml;
    }
    public async listCandidateVacancy(request: ListCandidateVacancyRequest): Promise<ListCandidateVacancyResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), RECRUITMENT.listCandidateVacancy, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(RECRUITMENT.listCandidateVacancy, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(RECRUITMENT.listCandidateVacancy, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListCandidateVacancyResponse as ListCandidateVacancyResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async updateCandidateVacancy(request: UpdateCandidateVacancyRequest): Promise<UpdateCandidateVacancyResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), RECRUITMENT.updateCandidateVacancy, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(RECRUITMENT.updateCandidateVacancy, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(RECRUITMENT.updateCandidateVacancy, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).UpdateCandidateVacancyResponse as UpdateCandidateVacancyResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listClientProject(request: IListClientProjectRequest): Promise<ListClientProjectResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), RECRUITMENT.listClientProject, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(RECRUITMENT.listClientProject, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(RECRUITMENT.listClientProject, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListClientProjectResponse as ListClientProjectResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listClientProjectPackage(request: IListClientProjectPackageRequest): Promise<ListClientProjectPackageResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), RECRUITMENT.listClientProjectPackage, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(RECRUITMENT.listClientProjectPackage, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(RECRUITMENT.listClientProjectPackage, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListClientProjectPackageResponse as ListClientProjectPackageResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async listPlacementConfiguration(request: IListPlacementConfigurationRequest): Promise<ListPlacementConfigurationResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), RECRUITMENT.listPlacementConfiguration, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(RECRUITMENT.listPlacementConfiguration, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(RECRUITMENT.listPlacementConfiguration, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).ListPlacementConfigurationResponse as ListPlacementConfigurationResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }

    public async getSelectionProcedureStats(request: GetSelectionProcedureStatsRequest): Promise<GetSelectionProcedureStatsResponse> {
        let response: any = null;
        try {
            let tmp_response = (await this.makeCall(request.toXml(), RECRUITMENT.getSelectionProcedureStats, request.cancelSource));
            response = this._addRootElementStatsList(tmp_response.data);
            await this.updateCache(RECRUITMENT.getSelectionProcedureStats, request.toXml(), response);
        } catch (error: any) {
            response = await this.getFromCache(RECRUITMENT.getSelectionProcedureStats, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).GetSelectionProcedureStatsResponse as GetSelectionProcedureStatsResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;        
    }
}