import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListProjectItemRequest extends IBaseRequest {
    limit:              number;
    offset:             number;
    orderBy?:            string;
    countOnly?:          boolean;
    onlyMaster?:         boolean;
    onlySlave?:          boolean;
    addInvoice?:         string;
    folder?:             string;
    project?:            string;
    customer?:           string;
    provider?:           string;
    id?:                 string;
    activity?:           string;
    item?:               string;
    billingStatus?:      string;
    projectState?:       string;
    projectCustomState?: string;
    invoiceId?:          string;
    orderId?:            string;
    budgetId?:           string;
    groupBy?:            string;
    billingDate?:        string;
    billingDateFrom?:    string;
    billingDateTo?:      string;
    activityType?:       string;
    projectType?:        string;
    itemFilter?:         string;
    supplier?:           string;
    type?:               string;
    IDFILTER?:           Idfilter[];
    MASSCHANGE?:         Masschange;
    FILTER?:             Filter;
}
export interface Filter {
  COL?: Col[];
}
export interface Idfilter {
  id: string;
}
export interface Masschange {
  projectChange?:           string;
  project?:                 string;
  itemChange?:              string;
  item?:                    string;
  amountChange?:            string;
  amount?:                  string;
  currencyChange?:          string;
  currency?:                string;
  quantityChange?:          string;
  quantity?:                number;
  freeQuantityChange?:      string;
  freeQuantity?:            string;
  freeQuantityChangeType?:  string;
  totalQuantityChange?:     string;
  totalQuantity?:           string;
  totalQuantityChangeType?: string;
  totalAmountChange?:       string;
  totalAmount?:             string;
  fixAmountChange?:         string;
  fixAmount?:               string;
  vatChange?:               string;
  vat?:                     string;
  vatValue?:                string;
  billingStatusChange?:     string;
  billingStatus?:           string;
  billingDateChange?:       string;
  billingDate?:             string;
  taxationDateChange?:      string;
  taxationDate?:            string;
  noteChange?:              string;
  note?:                    string;
  fixTextChange?:           string;
  fixText?:                 string;
  splitInInvoiceChange?:    string;
  splitInInvoice?:          string;
}

export interface Col {
  name:        string;
  type:        string;
  value?:       string;
  negation?:    string;
  includeNull?: string;
}

export class ListProjectItemRequest extends BaseRequest implements IListProjectItemRequest {
  public limit:              number;
  public offset:             number;
  public orderBy?:            string;
  public countOnly?:          boolean;
  public onlyMaster?:         boolean;
  public onlySlave?:          boolean;
  public addInvoice?:         string;
  public folder?:             string;
  public project?:            string;
  public customer?:           string;
  public provider?:           string;
  public id?:                 string;
  public activity?:           string;
  public item?:               string;
  public billingStatus?:      string;
  public projectState?:       string;
  public projectCustomState?: string;
  public invoiceId?:          string;
  public orderId?:            string;
  public budgetId?:           string;
  public groupBy?:            string;
  public billingDate?:        string;
  public billingDateFrom?:    string;
  public billingDateTo?:      string;
  public activityType?:       string;
  public projectType?:        string;
  public itemFilter?:         string;
  public supplier?:           string;
  public type?:               string;
  public IDFILTER?:           Idfilter[];
  public MASSCHANGE?:         Masschange;
  public FILTER?:             Filter;

  constructor(server: string, session: string, limit: number, offset: number, otherOptions?: any) {
    super(server, session);
    this.limit = limit;
    this.offset = offset;
    this.orderBy = otherOptions.orderBy;
    this.countOnly = otherOptions.countOnly;
    this.onlyMaster = otherOptions.onlyMaster;
    this.onlySlave = otherOptions.onlySlave;
    this.addInvoice = otherOptions.addInvoice;
    this.folder = otherOptions.folder;
    this.project = otherOptions.project;
    this.customer = otherOptions.customer;
    this.provider = otherOptions.provider;
    this.id = otherOptions.id;
    this.activity = otherOptions.activity;
    this.item = otherOptions.item;
    this.billingStatus = otherOptions.billingStatus;
    this.projectState = otherOptions.projectState;
    this.projectCustomState = otherOptions.projectCustomState;
    this.invoiceId = otherOptions.invoiceId;
    this.orderId = otherOptions.orderId;
    this.budgetId = otherOptions.budgetId;
    this.groupBy = otherOptions.groupBy;
    this.billingDate = otherOptions.billingDate;
    this.billingDateFrom = otherOptions.billingDateFrom;
    this.billingDateTo = otherOptions.billingDateTo;
    this.activityType = otherOptions.activityType;
    this.projectType = otherOptions.projectType;
    this.itemFilter = otherOptions.itemFilter;
    this.supplier = otherOptions.supplier;
    this.type = otherOptions.type;
    this.IDFILTER = otherOptions.IDFILTER;
    this.MASSCHANGE = otherOptions.MASSCHANGE;
    this.FILTER = otherOptions.FILTER;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    parameters += '<limit>' + this.limit + '</limit>';
    parameters += '<offset>' + this.offset + '</offset>';
    if(this.orderBy) parameters += '<orderBy>' + this.orderBy + '</orderBy>';
    if(this.countOnly) parameters += '<countOnly>' + this.countOnly + '</countOnly>';
    if(this.onlyMaster) parameters += '<onlyMaster>' + this.onlyMaster + '</onlyMaster>';
    if(this.onlySlave) parameters += '<onlySlave>' + this.onlySlave + '</onlySlave>';
    if(this.addInvoice) parameters += '<addInvoice>' + this.addInvoice + '</addInvoice>';
    if(this.folder) parameters += '<folder>' + this.folder + '</folder>';
    if(this.project) parameters += '<project>' + this.project + '</project>';
    if(this.customer) parameters += '<customer>' + this.customer + '</customer>';
    if(this.provider) parameters += '<provider>' + this.provider + '</provider>';
    if(this.id) parameters += '<id>' + this.id + '</id>';
    if(this.activity) parameters += '<activity>' + this.activity + '</activity>';
    if(this.item) parameters += '<item>' + this.item + '</item>';
    if(this.billingStatus) parameters += '<billingStatus>' + this.billingStatus + '</billingStatus>';
    if(this.projectState) parameters += '<projectState>' + this.projectState + '</projectState>';
    if(this.projectCustomState) parameters += '<projectCustomState>' + this.projectCustomState + '</projectCustomState>';
    if(this.invoiceId) parameters += '<invoiceId>' + this.invoiceId + '</invoiceId>';
    if(this.orderId) parameters += '<orderId>' + this.orderId + '</orderId>';
    if(this.budgetId) parameters += '<budgetId>' + this.budgetId + '</budgetId>';
    if(this.groupBy) parameters += '<groupBy>' + this.groupBy + '</groupBy>';
    if(this.billingDate) parameters += '<billingDate>' + this.billingDate + '</billingDate>';
    if(this.billingDateFrom) parameters += '<billingDateFrom>' + this.billingDateFrom + '</billingDateFrom>';
    if(this.billingDateTo) parameters += '<billingDateTo>' + this.billingDateTo + '</billingDateTo>';
    if(this.activityType) parameters += '<activityType>' + this.activityType + '</activityType>';
    if(this.projectType) parameters += '<projectType>' + this.projectType + '</projectType>';
    if(this.itemFilter) parameters += '<itemFilter>' + this.itemFilter + '</itemFilter>';
    if(this.supplier) parameters += '<supplier>' + this.supplier + '</supplier>';
    if(this.type) parameters += '<type>' + this.type + '</type>';
    
    if(this.IDFILTER) {
      parameters += '<IDFILTER>';
      this.IDFILTER.forEach((idFilter) => {
        parameters += '<id>' + idFilter.id + '</id>';
      });
      parameters += '</IDFILTER>';
    }

    if(this.MASSCHANGE) {
      parameters += '<MASSCHANGE>';
      if(this.MASSCHANGE.projectChange) parameters += '<projectChange>' + this.MASSCHANGE.projectChange + '</projectChange>';
      if(this.MASSCHANGE.project) parameters += '<project>' + this.MASSCHANGE.project + '</project>';
      if(this.MASSCHANGE.itemChange) parameters += '<itemChange>' + this.MASSCHANGE.itemChange + '</itemChange>';
      if(this.MASSCHANGE.item) parameters += '<item>' + this.MASSCHANGE.item + '</item>';
      if(this.MASSCHANGE.amountChange) parameters += '<amountChange>' + this.MASSCHANGE.amountChange + '</amountChange>';
      if(this.MASSCHANGE.amount) parameters += '<amount>' + this.MASSCHANGE.amount + '</amount>';
      if(this.MASSCHANGE.currencyChange) parameters += '<currencyChange>' + this.MASSCHANGE.currencyChange + '</currencyChange>';
      if(this.MASSCHANGE.currency) parameters += '<currency>' + this.MASSCHANGE.currency + '</currency>';
      if(this.MASSCHANGE.quantityChange) parameters += '<quantityChange>' + this.MASSCHANGE.quantityChange + '</quantityChange>';
      if(this.MASSCHANGE.quantity) parameters += '<quantity>' + this.MASSCHANGE.quantity + '</quantity>';
      if(this.MASSCHANGE.freeQuantityChange) parameters += '<freeQuantityChange>' + this.MASSCHANGE.freeQuantityChange + '</freeQuantityChange>';
      if(this.MASSCHANGE.freeQuantity) parameters += '<freeQuantity>' + this.MASSCHANGE.freeQuantity + '</freeQuantity>';
      if(this.MASSCHANGE.freeQuantityChangeType) parameters += '<freeQuantityChangeType>' + this.MASSCHANGE.freeQuantityChangeType + '</freeQuantityChangeType>';
      if(this.MASSCHANGE.totalQuantityChange) parameters += '<totalQuantityChange>' + this.MASSCHANGE.totalQuantityChange + '</totalQuantityChange>';
      if(this.MASSCHANGE.totalQuantity) parameters += '<totalQuantity>' + this.MASSCHANGE.totalQuantity + '</totalQuantity>';
      if(this.MASSCHANGE.totalQuantityChangeType) parameters += '<totalQuantityChangeType>' + this.MASSCHANGE.totalQuantityChangeType + '</totalQuantityChangeType>';
      if(this.MASSCHANGE.totalAmountChange) parameters += '<totalAmountChange>' + this.MASSCHANGE.totalAmountChange + '</totalAmountChange>';
      if(this.MASSCHANGE.totalAmount) parameters += '<totalAmount>' + this.MASSCHANGE.totalAmount + '</totalAmount>';
      if(this.MASSCHANGE.fixAmountChange) parameters += '<fixAmountChange>' + this.MASSCHANGE.fixAmountChange + '</fixAmountChange>';
      if(this.MASSCHANGE.fixAmount) parameters += '<fixAmount>' + this.MASSCHANGE.fixAmount + '</fixAmount>';
      if(this.MASSCHANGE.vatChange) parameters += '<vatChange>' + this.MASSCHANGE.vatChange + '</vatChange>';
      if(this.MASSCHANGE.vat) parameters += '<vat>' + this.MASSCHANGE.vat + '</vat>';
      if(this.MASSCHANGE.vatValue) parameters += '<vatValue>' + this.MASSCHANGE.vatValue + '</vatValue>';
      if(this.MASSCHANGE.billingStatusChange) parameters += '<billingStatusChange>' + this.MASSCHANGE.billingStatusChange + '</billingStatusChange>';
      if(this.MASSCHANGE.billingStatus) parameters += '<billingStatus>' + this.MASSCHANGE.billingStatus + '</billingStatus>';
      if(this.MASSCHANGE.billingDateChange) parameters += '<billingDateChange>' + this.MASSCHANGE.billingDateChange + '</billingDateChange>';
      if(this.MASSCHANGE.billingDate) parameters += '<billingDate>' + this.MASSCHANGE.billingDate + '</billingDate>';
      if(this.MASSCHANGE.taxationDateChange) parameters += '<taxationDateChange>' + this.MASSCHANGE.taxationDateChange + '</taxationDateChange>';
      if(this.MASSCHANGE.taxationDate) parameters += '<taxationDate>' + this.MASSCHANGE.taxationDate + '</taxationDate>';
      if(this.MASSCHANGE.noteChange) parameters += '<noteChange>' + this.MASSCHANGE.noteChange + '</noteChange>';
      if(this.MASSCHANGE.note) parameters += '<note>' + this.MASSCHANGE.note + '</note>';
      if(this.MASSCHANGE.fixTextChange) parameters += '<fixTextChange>' + this.MASSCHANGE.fixTextChange + '</fixTextChange>';
      if(this.MASSCHANGE.fixText) parameters += '<fixText>' + this.MASSCHANGE.fixText + '</fixText>';
      if(this.MASSCHANGE.splitInInvoiceChange) parameters += '<splitInInvoiceChange>' + this.MASSCHANGE.splitInInvoiceChange + '</splitInInvoiceChange>';
      if(this.MASSCHANGE.splitInInvoice) parameters += '<splitInInvoice>' + this.MASSCHANGE.splitInInvoice + '</splitInInvoice>';
      parameters += '</MASSCHANGE>';
    }

    if(this.FILTER) {
      parameters += '<FILTER>';
      if(this.FILTER.COL) {
        this.FILTER.COL.forEach((col) => {
          parameters += '<COL>';
          parameters += '<name>' + col.name + '</name>';
          parameters += '<type>' + col.type + '</type>';
          if(col.value) parameters += '<value>' + col.value + '</value>';
          if(col.negation) parameters += '<negation>' + col.negation + '</negation>';
          if(col.includeNull) parameters += '<includeNull>' + col.includeNull + '</includeNull>';
          parameters += '</COL>';
        });
      }
      parameters += '</FILTER>';
    }

    return super.wrapperXml('ListProjectItem', parameters);
  }
}
