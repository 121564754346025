import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IGetContextPathRequest extends IBaseRequest {
    contextId: number;
}

export class GetContextPathRequest extends BaseRequest implements IGetContextPathRequest {
    public contextId: number;

  constructor(server: string, session: string, contextId: number) {
    super(server, session);
    this.contextId = contextId;
  }

  public toXml(): string {
    var parameters: string = super.toXml();
    parameters += '<contextId>' + this.contextId + '</contextId>';
    return super.wrapperXml('GetContextPath', parameters);
  }
}
