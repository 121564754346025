import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { ListStatusRequest, ListStatusType } from '../../../../services/soap/project/requests/ListStatusRequest';
import { Flex, View } from '@adobe/react-spectrum';
import { enableRipple } from '@syncfusion/ej2-base';
import { ContextMenuComponent, MenuItemModel } from '@syncfusion/ej2-react-navigations';
import { AtollonIcon, MoreIcon, ActivityIconBlue } from '../../Icons/IconsLib';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ToastQueue } from '@react-spectrum/toast';
import { GetContextPathRequest } from '../../../../services/soap/project/requests/GetContextPathRequest';
import { GetActivityRequest } from '../../../../services/soap/project/requests/GetActivityRequest';
import { UpdateActivityRequest } from '../../../../services/soap/project/requests/UpdateActivityRequest';
import { CheckWorkFlowQueueRequest } from '../../../../services/soap/features/requests/CheckWorkFlowQueueRequest';
import { FPAData } from '../../../../infra/protected/FPA/FPAData';
import { useTranslation } from 'react-i18next';
import styles from './HeaderToolbarComponent.module.css';

interface IActivityComponentMobileProps {
  headerData: any;
  statusColor: (color: string) => string;
  menuItems: MenuItemModel[];
  handleMenuItemClick?: (args: any) => void;
  onAIClicked?: () => void;
  selectedItem?: FPAData;
}
enableRipple(true);
const ActivityComponentMobile = ({ headerData, statusColor, menuItems, handleMenuItemClick, onAIClicked, selectedItem }: IActivityComponentMobileProps) => {
  const { store, projectService, featureService } = useDependency();
  const [activityStatusList, setActivityStatusList] = useState<any>([]);
  let [activityStatus, setActivityStatus] = useState(headerData?.customState);
  const [fullPath, setFullPath] = useState<string>('');
  const [activityData, setActivityData] = useState<any>([]);
  const { t } = useTranslation();
  const contextMenuRef = useRef<ContextMenuComponent | null>(null);

  useEffect(() => {
    (async () => {
      headerData && (await getFullPath());
      await getActivityData();
      await getActivityStatusArray();
    })();
  }, []);

  React.useEffect(() => {
    // Add the click event listener to the target element
    const targetElement = document.querySelector('.context-target');
    if (targetElement) {
      targetElement.addEventListener('click', handleContextMenu);
    }

    // Cleanup
    return () => {
      if (targetElement) {
        targetElement.removeEventListener('click', handleContextMenu);
      }
    };
  }, []);

  const getActivityStatusArray = useCallback(async () => {
    const statusArray: any = [];
    let res: any = await projectService.listActivityStatus2(new ListStatusRequest(store.Server, store.SessionId, ListStatusType.Activity));

    if (res.result === 'OK' && res.STATUS.length > 0) {
      res?.STATUS.forEach((status: any) => {
        // Extract id and name from each STATUS object
        const { id, name, color, TYPE } = status;
        // Push an object containing id and name to the statusArray
        statusArray.push({ id, name, color, types: TYPE });
      });
      //console.log('statusArray', statusArray);
      var filtered_status = statusArray.filter((status: any) => status.types.find((type: any) => type.id === headerData?.activityType));
      setActivityStatusList(filtered_status);
    }
    return statusArray;
  }, []);

  const getFullPath = async () => {
    const response = await projectService.getContextPath(new GetContextPathRequest(store.Server, store.SessionId, headerData?.id || 0));
    const modifiedList = response.LIST.slice(1).reverse();
    setFullPath(modifiedList.map(item => item.CONTEXT.name).join(' > '));
  };

  const getAddress = () => {
    return headerData?.CONTACT?.COMPANY?.street !== undefined && headerData?.CONTACT?.COMPANY?.street !== null
      ? headerData?.CONTACT?.COMPANY?.street + ' ' + headerData?.CONTACT?.COMPANY?.city + ' ' + headerData?.CONTACT?.COMPANY?.zip + ' ' + headerData?.CONTACT?.COMPANY?.country
      : '';
  };

  function itemTemplate(data: any): JSX.Element {
    return (
      <Flex UNSAFE_className={styles.dot_text_container}>
        <View UNSAFE_className={styles.dot} UNSAFE_style={{ backgroundColor: `${statusColor(data.color)}` }}></View>
        <View marginTop={5}>{data.name}</View>
      </Flex>
    );
  }
  function valueTemplate(data: any): JSX.Element {
    return (
      <Flex UNSAFE_className={styles.dot_text_container}>
        <View UNSAFE_className={styles.dot} UNSAFE_style={{ backgroundColor: `${statusColor(data.color)}` }}></View>
        <View marginTop={5} UNSAFE_style={{ marginLeft: '10px' }}>
          <span title={data?.name}>{data?.name && data?.name.length > 23 ? data?.name.substring(0, 23) + '...' : data?.name}</span>
        </View>
      </Flex>
    );
  }

  const handleContextMenu = (event: any) => {
    // Prevent the default context menu
    event.preventDefault();

    // Open the custom context menu at the click position
    if (contextMenuRef.current) {
      contextMenuRef.current.open(event.pageY, event.pageX);
    }
  };

  const handleAIClicked = () => {
    console.log('handleAIClicked');
    if (onAIClicked) {
      onAIClicked();
    }
  };

  async function getActivityData() {
    if (selectedItem) {
      let { ACTIVITY } = await projectService.getActivity(new GetActivityRequest(store.Server, store.SessionId, selectedItem.id));
      setActivityData(ACTIVITY);
    }
  }
  async function updateActivityStatus(e: any) {
    let resActivity = await projectService.updateActivity(new UpdateActivityRequest(store.Server, store.SessionId, { ...activityData, customState: e.value }));
    if (resActivity.result == 'OK') {
      await featureService.checkWorkFlowQueue(new CheckWorkFlowQueueRequest(store.Server, store.SessionId));
      ToastQueue.positive(t('status_updated_successfully', { ns: 'layout_components' }), { timeout: 1000 });
    } else {
      ToastQueue.negative(resActivity.EXCEPTION?.message, { timeout: 1000 });
    }
  }

  return (
    <Flex UNSAFE_className={styles.header}>
      <View UNSAFE_className={styles.header_main}>
        <Flex direction="row" width={'100%'} justifyContent={'space-between'}>
          <View>
            <DropDownListComponent
              width={'250px'}
              dataSource={activityStatusList}
              fields={{ text: 'name', value: 'id' }}
              showClearButton={true}
              itemTemplate={itemTemplate}
              valueTemplate={valueTemplate}
              value={headerData?.customState}
              onChange={(e: any) => {
                updateActivityStatus(e);
              }}
            />
          </View>
          <Flex direction={'row'}>
            <View>
              <AtollonIcon size={32} />
            </View>
            <View>
              <ContextMenuComponent target=".context-target" id="contextmenu" ref={contextMenuRef} items={menuItems} select={handleMenuItemClick} />
              <span className="context-target" role="button" tabIndex={0}>
                <MoreIcon size={32} />
              </span>
            </View>
          </Flex>
        </Flex>
        <View UNSAFE_className={styles.header_child} marginTop="15px">
          <View UNSAFE_className={styles.frame_parent}>
            <Flex direction={'row'} justifyContent={'space-between'} width={'100%'}>
              <View UNSAFE_style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <View>
                  <ActivityIconBlue size={32} />
                </View>
                <View>
                  <b title={headerData?.name} className={styles.activity_name}>
                    {headerData?.name.length > 30 ? headerData?.name.substring(0, 30) + '...' : headerData?.name}
                  </b>
                </View>
              </View>
            </Flex>
            <View UNSAFE_className={styles.folder_name_parent}>
              <View UNSAFE_className={styles.folder_name}>{fullPath}</View>
            </View>
            <View UNSAFE_className={styles.tag_parent} marginTop={'5px'}>
              <Flex direction={'row'} justifyContent={'space-between'} width={'100%'}>
                <View UNSAFE_className={styles.tag}>
                  <View UNSAFE_className={styles.activity_type}>
                    <View UNSAFE_className={styles.activity_type_text}>{headerData?.activityTypeName}</View>
                    {headerData?.refId ? (
                      <>
                        <View UNSAFE_className={styles.divider}></View> <View UNSAFE_className={styles.ref_id}>{headerData?.refId}</View>
                      </>
                    ) : (
                      <View UNSAFE_className={styles.divider_transparent}></View>
                    )}
                  </View>
                </View>
              </Flex>
            </View>
          </View>
        </View>
        {/* <Flex direction={'row'} justifyContent={'space-between'} width={'100%'} marginTop={10}>
      <View width={230}>
            {getAddress() !== '' ? (
              <>
                <View UNSAFE_className={styles.address_child}>Address</View>
                <View UNSAFE_className={styles.address}>
                  {getAddress()}
                </View>
              </>
            ) : (
              ''
            )}
          </View> 
        </Flex>*/}
      </View>
    </Flex>
  );
};

export default ActivityComponentMobile;
