import { useCallback, useEffect, useRef, useState } from 'react';
import { FPAData, FPADataTypes } from '../../../infra/protected/FPA/FPAData';
import { useDependency } from '../../../contexts/DependencyProvider';
import { ListFolderTemplateRequest } from '../../../services/soap/project/requests/ListFolderTemplateRequest';
import { FPATemplateItem } from '../../../infra/protected/FPA/FPATemplateItem';
import { GetFolderProjectTypeRequest } from '../../../services/soap/project/requests/GetFolderProjectTypeRequest';
import { GetFolderTemplateRequest } from '../../../services/soap/project/requests/GetFolderTemplateRequest';
import { ToastQueue } from '@react-spectrum/toast';
import { GetFormSettingsRequest } from '../../../services/soap/features/requests/GetFormSettingsRequest';
import { SettingItem } from '../../../services/soap/features/responses/GetFormSettingsResponse';
import { usePreloadAssets } from '../../../hooks/UsePreloadAssets';
import { GetProjectTemplateRequest } from '../../../services/soap/project/requests/GetProjectTemplateRequest';
import { GetActivityTemplateRequest } from '../../../services/soap/project/requests/GetActivityTemplateRequest';

export interface IUseContextNewDialogProps {
    parentItem: FPAData | null;
    onClose: () => void;
}

export function useContextNewDialog ({
    onClose,
    parentItem,
}: IUseContextNewDialogProps) {
    const [currentStep, setCurrentStep] = useState(1);
    const [treeData, setTreeData] = useState<FPATemplateItem[]>([]);
    const [settings, setSettings] = useState<SettingItem[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState<FPATemplateItem | null>(null);
    const { store, projectService, featureService } = useDependency();
    const { formSettings } = usePreloadAssets();
    const formDataRef = useRef<{[key:string]: any}>({});

    const setFormData = (key: string, value: any) => {
        formDataRef.current[key] = value;
    }
    const onSelection = async (long_key: string) => {
        var item = findItemById(treeData, long_key);
        if (!item) {
            setSelectedItem(null);
            return;
        }
        var [items, systemType] = await loadChildTemplates(item);

        addChildrenUnderParent(treeData, long_key, items);
        setTreeData(JSON.parse(JSON.stringify(treeData)));
        setSelectedItem({...item, systemType});
    };

    const loadFolderTemplate = async () => {
        var template = await projectService.getFolderTemplate(new GetFolderTemplateRequest(store.Server, store.SessionId, selectedItem!.id));
        if(template.FOLDERTEMPLATE) {
            var setting_id = template.FOLDERTEMPLATE.formSetting;
            if(!setting_id) {
                setting_id = formSettings['TEditNewFolder'].id;
            }
            var response = await featureService.getFormSettings(new GetFormSettingsRequest(store.Server, store.SessionId, setting_id));
            setSettings(response.SETTING[0].ITEM);
        }
    }

    const loadProjectTemplate = async () => {
        var template = await projectService.getProjectTemplate(new GetProjectTemplateRequest(store.Server, store.SessionId, selectedItem!.id));
        if(template.PROJECTTEMPLATE) {
            var setting_id = template.PROJECTTEMPLATE.formSetting;
            if(!setting_id) {
                setting_id = formSettings['TEditNewProject'].id;
            }
            var response = await featureService.getFormSettings(new GetFormSettingsRequest(store.Server, store.SessionId, setting_id));
            setSettings(response.SETTING[0].ITEM);
        }
    }

    const loadActivityTemplate = async () => {
        // ToastQueue.negative('Activity template not implemented yet: '+selectedItem?.id, { timeout: 1000 });
        var template = await projectService.getActivityTemplate(new GetActivityTemplateRequest(store.Server, store.SessionId, selectedItem!.id));
        if(template.TEMPLATE) {
            var setting_id = template.TEMPLATE.formSetting;
            if(!setting_id) {
                setting_id = formSettings['TEditNewActivity'].id;
            }
            var response = await featureService.getFormSettings(new GetFormSettingsRequest(store.Server, store.SessionId, setting_id));
            setSettings(response.SETTING[0].ITEM);
        }
    }

    const nextStep = async () => {
        switch(currentStep) {
            case 1:
                if (!selectedItem) {
                    ToastQueue.negative('Please select an item', { timeout: 1000 });
                    return;
                }
                switch (selectedItem.type) {
                    case FPADataTypes.FOLDER:
                        await loadFolderTemplate();
                        break;
                    case FPADataTypes.PROJECT:
                        await loadProjectTemplate();
                        break;
                    case FPADataTypes.ACTIVITY:
                        await loadActivityTemplate();
                        break;
                }
                setCurrentStep(2);
                break;
            case 2:
                console.log('formData', formDataRef.current);
                break;
        }
    }

    const toTemplateItem = (item: FPAData): FPATemplateItem => {
        return {
            key: item.id.toString(),
            id: +item.id,
            title: item.title,
            type: item.type,
            itemType: item.item_type_id,
            childItems: [] as FPATemplateItem[],
        };
    }
    const findItemById = (items:FPATemplateItem[], id:string):FPATemplateItem | null => {
        for (let i = 0; i < items.length; i++) {
            if (items[i].key == id) {
                return items[i];
            }
            if (items[i].childItems.length > 0) {
                var item = findItemById(items[i].childItems, id);
                if (item) {
                    return item;
                }
            }
        }
        return null;
    }
    const addChildrenUnderParent = (items:FPATemplateItem[], parent:string, children:FPATemplateItem[]) => {
        items.forEach((item:FPATemplateItem) => {
            if(item.key == parent) {
                item.childItems = children.map(v => ({...v, key: `${parent}_${v.id}`}));
            }
            else if(item.childItems.length > 0) {
                addChildrenUnderParent(item.childItems, parent, children);
            }
        });
    }
    var loadRootTemplates = useCallback(async () => {
        setLoading(true);
        var items: FPATemplateItem[] = [];
        try {
            var res = await projectService.listFolderTemplate(new ListFolderTemplateRequest(store.Server, store.SessionId, { canBeRoot: true }));
            if (res.count > 0) {
                items = res.FOLDERTEMPLATE.map((item) => {
                    return ({
                        key: item.id.toString(),
                        id: item.id,
                        title: item.name,
                        type: FPADataTypes.FOLDER,
                        itemType: item.folderType,
                        childItems: [] as FPATemplateItem[],
                    });
                });
            }
        } catch (error) {
            console.log('error', error);
        }
        setTreeData(items);
        setLoading(false);
    }, [projectService, store.Server, store.SessionId]);

    var loadChildTemplates = useCallback(async (item: FPATemplateItem): Promise<[FPATemplateItem[], number]> => {
        setLoading(true);
        var items: FPATemplateItem[] = [];
        var system_type = 0;
        try {
            var res = await projectService.getFolderProjectType(new GetFolderProjectTypeRequest(store.Server, store.SessionId, item.itemType));
            if (res.count > 0) {
                var folder_templates = [] as FPATemplateItem[];
                var project_templates = [] as FPATemplateItem[];
                var activity_templates = [] as FPATemplateItem[];
                
                system_type = +res.TYPE[0].systemType;
                if (res.TYPE[0].FOLDERTEMPLATES) {
                    folder_templates = res.TYPE[0].FOLDERTEMPLATES.TEMPLATE.map((item) => ({
                        key: item.folderTemplate.toString(),
                        id: item.folderTemplate,
                        title: item.folderTemplateName,
                        type: FPADataTypes.FOLDER,
                        itemType: item.folderType,
                        childItems: [] as FPATemplateItem[],
                    }));
                }
                
                if(res.TYPE[0].PROJECTTEMPLATES){                    
                    project_templates = res.TYPE[0].PROJECTTEMPLATES.TEMPLATE.map((item) => ({
                        key: item.projectTemplate.toString(),
                        id: item.projectTemplate,
                        title: item.projectTemplateName,
                        type: FPADataTypes.PROJECT,
                        itemType: item.projectType,
                        childItems: [] as FPATemplateItem[],
                    }));
                }

                if(res.TYPE[0].ACTIVITYTEMPLATES){
                    activity_templates = res.TYPE[0].ACTIVITYTEMPLATES.TEMPLATE.map((item) => ({
                        key: item.activityTemplate.toString(),
                        id: item.activityTemplate,
                        title: item.activityTemplateName,
                        type: FPADataTypes.ACTIVITY,
                        itemType: item.activityType,
                        childItems: [] as FPATemplateItem[],
                    }));
                }
                items = [...folder_templates, ...project_templates, ...activity_templates];
            }
        } catch (error) {
            console.log('error', error);
        }
        
        setLoading(false);
        return [items, system_type];
    }, [projectService, store.Server, store.SessionId]);

    useEffect(() => {
        if (!parentItem) {
            loadRootTemplates();
        }
    }, [loadRootTemplates, parentItem]);

    useEffect(() => {
        if (parentItem) {
            var item = toTemplateItem(parentItem);
            loadChildTemplates(item).then(([items]) => {
                setTreeData(items);
                
            });
        }
    }, []);

    return { 
        treeData, 
        onSelection, 
        loading, 
        currentStep,
        nextStep,
        selectedItem,
        settings,
        setFormData
    };
}
