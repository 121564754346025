import { Flex } from '@adobe/react-spectrum';
import styles from './InPlaceSearchResultItem.module.css';
import { FPAData } from '../../../../../infra/protected/FPA/FPAData';

export interface IInPlaceSearchResultItemProps {
    image: string;
    title: string;
    subtitle: string;
    description: string;
    onSearchSelected: (item:any) => void;
    contextType: string;
    contextId?: number;
    stateName?: string;
    stateColor?: string;    
    isSelected?: boolean;
    item: FPAData;
}

export function InPlaceSearchResultItem ({
    image,
    title,
    subtitle,
    description,
    onSearchSelected,
    contextType,
    contextId,
    stateName,
    stateColor,
    isSelected,
    item
}: IInPlaceSearchResultItemProps) {
    const handleClicked = () => { 
        onSearchSelected({ contextType, contextId, item });
    }
    return (
    <>
        <span onClick={handleClicked}>
            <Flex direction="row" gap="size-100" alignItems="center" UNSAFE_className={styles.itemContainer + ' ' +(isSelected? styles.itemContainer_active : '')}>
                <img src={image} alt="search result" className={styles.searchResultImage} />
                <Flex direction="column" gap="size-50" flex>
                    <div className={styles.searchResultTitle}>{title}</div>
                    <div className={styles.searchResultSubtitle}>{subtitle}</div>
                    <div className={styles.searchResultDescription}>{description}</div>
                </Flex>
                {stateName && (
                    <div className={styles.rightContainer}>
                        <div className={styles.searchResultIndicator} style={{backgroundColor: stateColor}}></div>
                        <div className={styles.searchResultStatus}>{stateName}</div>
                    </div>
                )}
            </Flex>
        </span>
    </>
  );
}
