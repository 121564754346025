/* eslint-disable react-hooks/rules-of-hooks */
import { useDependency } from "../contexts/DependencyProvider";
import { FPADataTypes } from "../infra/protected/FPA/FPAData";
import { Activity } from "../services/soap/dataObjects/Activity";
import { GetActivityRequest } from "../services/soap/project/requests/GetActivityRequest";
import { GetFolderRequest } from "../services/soap/project/requests/GetFolderRequest";
import { GetProjectRequest } from "../services/soap/project/requests/GetProjectRequest";
import { UpdateActivityRequest } from "../services/soap/project/requests/UpdateActivityRequest";
import { UpdateFolderRequest } from "../services/soap/project/requests/UpdateFolderRequest";
import { UpdateProjectRequest } from "../services/soap/project/requests/UpdateProjectRequest";
import { Folder } from "../services/soap/project/responses/GetFolderResponse";
import { Project } from "../services/soap/project/responses/GetProjectResponse";

interface IFPA {
    get: (id: number) => Promise<any>;
    update: (updatedObject: any) => Promise<string>;
}

export function useFolder(): IFPA {
    const { store, projectService } = useDependency();

    const get = async (folderId: number):Promise<Folder> => {
        let { FOLDER } = await projectService.getFolder(new GetFolderRequest(store.Server, store.SessionId, folderId));
        return FOLDER;
    };
    
    const update = async (updatedObject: any):Promise<string> => {
        let folder_save = await projectService.updateFolder(new UpdateFolderRequest(store.Server, store.SessionId, updatedObject));
        return folder_save.result;
    }

    return { get, update };
}

export function useProject(): IFPA {
    const { store, projectService } = useDependency();

    const get = async (projectId: number):Promise<Project> => {
        let { PROJECT } = await projectService.getProject(new GetProjectRequest(store.Server, store.SessionId, projectId));
        return PROJECT;
    };
    
    const update = async (updatedObject: any):Promise<string> => {
        let project_save = await projectService.updateProject(new UpdateProjectRequest(store.Server, store.SessionId, updatedObject));
        return project_save.result;
    }

    return { get, update };
}

export function useActivity(): IFPA {
    const { store, projectService } = useDependency();

    const get = async (activityId: number):Promise<Activity> => {
        let { ACTIVITY } = await projectService.getActivity(new GetActivityRequest(store.Server, store.SessionId, activityId));
        return ACTIVITY;
    };
    
    const update = async (updatedObject: any):Promise<string> => {
        let activity_save = await projectService.updateActivity(new UpdateActivityRequest(store.Server, store.SessionId, updatedObject));
        return activity_save.result;
    }

    return { get, update };
}

export function useFPA(type: FPADataTypes): IFPA {
    switch (type) {
        case FPADataTypes.FOLDER:
            return useFolder();
        case FPADataTypes.PROJECT:
            return useProject();
        case FPADataTypes.ACTIVITY:
            return useActivity();
        default:
            return { get: async () => { return null; }, update: async () => { return 'Error'; } };
    }
}