import * as React from 'react';
import { AppMenuGroupItem } from './AppMenuGroupItem';
import styles from './AppMenuGroup.module.css';
import { AppMenuItemType } from '../AppMenu/AppMenu.types';

interface IAppMenuGroupProps {
    sectionTitle: string;
    items: any[];
    onItemClicked: (item:AppMenuItemType) => void;
}

const AppMenuGroup: React.FunctionComponent<IAppMenuGroupProps> = ({
    sectionTitle,
    items,
    onItemClicked
}) => {
  return (<>
    <div className={styles.mobileGroup}>
        <span className={styles.Title}>{sectionTitle}</span>
        <div className={styles.mobileGroupItems}>
            {items.map((item, index) => (<AppMenuGroupItem key={index} item={item} onItemClicked={onItemClicked} />))}
        </div>
    </div>
  </>);
};

export default AppMenuGroup;
