import { GroupedSearchResultItemData } from '../../../../utils/SearchUtils';
import styles from './SearchResult.module.css';
import SearchResultGroup from './SearchResultGroup';

export interface ISearchResultProps {
    mainTitle: string;
    groups: GroupedSearchResultItemData[];
    selectedItem:number;
    selectedGroup:number;
    onSearchSelected: (item:any) => void;
}

export default function SearchResult ({
    mainTitle,
    groups,
    selectedItem,
    selectedGroup,
    onSearchSelected,
}: ISearchResultProps) {

  return (
    <>
      <div className={styles.mainTitle}>{mainTitle}</div>
      {groups.map((group, index) => <SearchResultGroup key={index} 
                                                      groupTitle={group.title} 
                                                      items={group.items} 
                                                      onSearchSelected={onSearchSelected} 
                                                      selectedItemIndex={selectedItem}
                                                      isSelected={selectedGroup === index}
                                                      />)}
    </>
  );
}
