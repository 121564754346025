import styles from './IconsLib.module.css';
import { ReactComponent as OfflineImage } from '../../../assets/icons/offline.svg';
import { ReactComponent as SearchImage } from '../../../assets/icons/search.svg';
import { ReactComponent as MobileSearchImage } from '../../../assets/icons/mobile-search.svg';
import { ReactComponent as AppsImage } from '../../../assets/icons/mobile-app.svg';
import { ReactComponent as HomeImage } from '../../../assets/icons/mobile-home.svg';
import { ReactComponent as MessageImage } from '../../../assets/icons/mobile-message.svg';
import { ReactComponent as ProfileImage } from '../../../assets/icons/mobile-profile.svg';
import { ReactComponent as CloseImage } from '../../../assets/icons/close.svg';
import { ReactComponent as IconActivity } from '../../../assets/icons/icon-activity.svg';
import { ReactComponent as IconActivityBlue } from '../../../assets/icons/icon-activity-blue.svg';
import { ReactComponent as IconClient } from '../../../assets/icons/icon-client.svg';
import { ReactComponent as IconClientBlue } from '../../../assets/icons/icon_client_blue.svg';
import { ReactComponent as IconEmpty } from '../../../assets/icons/icon-empty.svg';
import { ReactComponent as IconFinder } from '../../../assets/icons/icon-finder.svg';
import { ReactComponent as IconProject } from '../../../assets/icons/icon-project.svg';
import { ReactComponent as IconProjectBlue } from '../../../assets/icons/icon_project_blue.svg';
import { ReactComponent as EmptyImage } from '../../../assets/icons/empty.svg';
import { ReactComponent as AllVacancies } from '../../../assets/icons/all_vacancies.svg';
import { ReactComponent as MyVacancies } from '../../../assets/icons/my_vacancies.svg';
import { ReactComponent as IconArrowDetail } from '../../../assets/icons/icon-arrrow-detail.svg';
import { ReactComponent as IconContact } from '../../../assets/icons/icon-contacts.svg';
import { ReactComponent as SearchUser } from '../../../assets/icons/search_user.svg';
import { ReactComponent as RightArrow } from '../../../assets/icons/icon-right-arrow.svg';
import { ReactComponent as IconAtollon } from '../../../assets/icons/icon_atollon.svg';
import { ReactComponent as IconPrint } from '../../../assets/icons/icon_print.svg';
import { ReactComponent as IconEdit } from '../../../assets/icons/icon_edit.svg';
import { ReactComponent as IconTool } from '../../../assets/icons/icon_tool.svg';
import { ReactComponent as IconTrash } from '../../../assets/icons/icon_trash.svg';
import { ReactComponent as IconMore } from '../../../assets/icons/icon_more.svg';
import { ReactComponent as IconLinkdin } from '../../../assets/icons/icon_linkdin.svg';
import { ReactComponent as IconFacecook } from '../../../assets/icons/icon_facebook.svg';
import { ReactComponent as IconTwitter } from '../../../assets/icons/icon_twitter.svg';
import { ReactComponent as IconMassmailGreen } from '../../../assets/icons/icon_massmail_green.svg';
import { ReactComponent as IconMassmailRed } from '../../../assets/icons/icon_massmail_red.svg';
import { ReactComponent as IconAlert } from '../../../assets/icons/icon_alert.svg';
import { ReactComponent as IconAlertFill } from '../../../assets/icons/icon_alert_fill.svg';
import { ReactComponent as IconTable } from '../../../assets/icons/icon_table.svg';
import { ReactComponent as IconCard } from '../../../assets/icons/icon_card.svg';
import { ReactComponent as IconFilter } from '../../../assets/icons/icon_filter.svg';

export enum IconStyle {
  none = '',
  filtered = 'filtered',
  normal = 'normal',
}
type IconType = {
  size: number;
  color?: string;
  stateClass?: IconStyle;
};

const _getIconStyle = (stateClass?: IconStyle) => {
  switch (stateClass) {
    case IconStyle.filtered:
      return styles.filtered;
    case IconStyle.normal:
      return styles.normal;
    default:
      return '';
  }
};
export const ActivityIcon = ({ size }: IconType) => {
  return (
    <div>
      <IconActivity width={size} height={size} />
    </div>
  );
};

export const ActivityIconBlue = ({ size }: IconType) => {
  return (
    <div>
      <IconActivityBlue width={size} />
    </div>
  );
};

export const ClientIcon = ({ size }: IconType) => {
  return (
    <div>
      <IconClient width={size} height={size} />
    </div>
  );
};

export const ClientIconBlue = ({ size }: IconType) => {
  return (
    <div>
      <IconClientBlue width={size} height={size} />
    </div>
  );
};

export const EmptyIcon = ({ size }: IconType) => {
  return (
    <div>
      <IconEmpty width={size} height={size} />
    </div>
  );
};

export const FinderIcon = ({ size }: IconType) => {
  return (
    <div>
      <IconFinder width={size} height={size} />
    </div>
  );
};

export const ProjectIcon = ({ size }: IconType) => {
  return (
    <div>
      <IconProject width={size} height={size} />
    </div>
  );
};

export const ProjectIconBlue = ({ size }: IconType) => {
  return (
    <div>
      <IconProjectBlue width={size} height={size} />
    </div>
  );
};

export const OfflineIcon = ({ size }: IconType) => {
  const iconStyle = {
    backgroundColor: '#FEE8E6',
    borderRadius: '50%',
    padding: '3px 3px 0px 3px',
  };
  return (
    <div style={iconStyle}>
      <OfflineImage width={size} height={size} />
    </div>
  );
};

export const SearchIcon = ({ size, stateClass }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
  };
  return (
    <div style={iconStyle}>
      <SearchImage width={size} height={size} className={_getIconStyle(stateClass)} />
    </div>
  );
};

export const MobileSearchIcon = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
  };
  return (
    <div style={iconStyle}>
      <MobileSearchImage width={size} height={size} />
    </div>
  );
};

export const AppsIcon = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
  };
  return (
    <div style={iconStyle}>
      <AppsImage width={size} height={size} />
    </div>
  );
};

export const HomeIcon = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
  };
  return (
    <div style={iconStyle}>
      <HomeImage width={size} height={size} />
    </div>
  );
};

export const MessageIcon = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
  };
  return (
    <div style={iconStyle}>
      <MessageImage width={size} height={size} />
    </div>
  );
};

export const ProfileIcon = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
  };
  return (
    <div style={iconStyle}>
      <ProfileImage width={size} height={size} />
    </div>
  );
};

export const CloseIcon = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
  };
  return (
    <div style={iconStyle}>
      <CloseImage width={size} height={size} />
    </div>
  );
};

export const EmptyIcon2 = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
  };
  return (
    <div style={iconStyle}>
      <EmptyImage width={size} height={size} />
    </div>
  );
};

export const AllVacanciesIcon = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
  };
  return (
    <div style={iconStyle}>
      <AllVacancies width={size} height={size} />
    </div>
  );
};

export const MyVacanciesIcon = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
  };
  return (
    <div style={iconStyle}>
      <MyVacancies width={size} height={size} />
    </div>
  );
};

export const ArrowDetailIcon = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
    display: 'contents',
  };
  return (
    <div style={iconStyle}>
      <IconArrowDetail width={size} height={size} />
    </div>
  );
};

export const ContactIcon = ({ size, color }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
    display: 'inherit',
  };
  return (
    <div style={iconStyle}>
      <IconContact color={color} width={size} height={size} />
    </div>
  );
};

export const SearchUserIcon = ({ size, color }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
    display: 'inherit',
  };
  return (
    <div style={iconStyle}>
      <SearchUser color={color} width={size} height={size} />
    </div>
  );
};

export const RightArrowIcon = ({ size }: IconType) => {
  return (
    <div>
      <RightArrow width={size} />
    </div>
  );
};

export const AtollonIcon = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
    display: 'inherit',
  };
  return (
    <div style={iconStyle}>
      <IconAtollon width={size} />
    </div>
  );
};

export const PrintIcon = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
    display: 'inherit',
  };
  return (
    <div style={iconStyle}>
      <IconPrint width={size} />
    </div>
  );
};

export const EditIcon = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
    display: 'inherit',
  };
  return (
    <div style={iconStyle}>
      <IconEdit width={size} />
    </div>
  );
};

export const ToolIcon = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
    display: 'inherit',
  };
  return (
    <div style={iconStyle}>
      <IconTool width={size} />
    </div>
  );
};

export const TrashIcon = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
    display: 'inherit',
  };
  return (
    <div style={iconStyle}>
      <IconTrash width={size} />
    </div>
  );
};

export const MoreIcon = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
    display: 'inherit',
  };
  return (
    <div style={iconStyle}>
      <IconMore width={size} />
    </div>
  );
};

export const LinkdinIcon = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
    display: 'inherit',
  };
  return (
    <div style={iconStyle}>
      <IconLinkdin width={size} />
    </div>
  );
};
export const FacebookIcon = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
    display: 'inherit',
  };
  return (
    <div style={iconStyle}>
      <IconFacecook width={size} />
    </div>
  );
};
export const TwitterIcon = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
    display: 'inherit',
  };
  return (
    <div style={iconStyle}>
      <IconTwitter width={size} />
    </div>
  );
};

export const MassmailGreen = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
    display: 'inherit',
  };
  return (
    <div style={iconStyle}>
      <IconMassmailGreen width={size} />
    </div>
  );
};

export const MassmailRed = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
    display: 'inherit',
  };
  return (
    <div style={iconStyle}>
      <IconMassmailRed width={size} />
    </div>
  );
};

export const AlertIcon = ({ size }: IconType) => {
  const iconStyle = {
    cursor: 'pointer',
    display: 'inherit',
  };
  return (
    <div style={iconStyle}>
      <IconAlert width={size} />
    </div>
  );
};

export const AlertIconFill = ({ size }: IconType) => {
  return (
    <div>
      <IconAlertFill width={size} />
    </div>
  );
};

export const TableIcon = ({ size }: IconType) => {
  return (
    <div>
      <IconTable width={size} />
    </div>
  );
};

export const CardIcon = ({ size }: IconType) => {
  return (
    <div>
      <IconCard width={size} />
    </div>
  );
};

export const FilterIcon = ({ size }: IconType) => {
  return (
    <div>
      <IconFilter width={size} />
    </div>
  );
};
