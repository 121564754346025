export interface SearchTimeSheet{
    recordId?:                string;
    currency?:                string;
    etypeFilter?:             string;
    statusFilter?:            string;
    chargeFilter?:            string;
    statusGroup?:             string;
    typeOfWorkGroup?:         string;
    activityGroup?:           string;
    projectGroup?:            string;
    folderGroup?:             string;
    masterProjectGroup?:      string;
    masterFolderGroup?:       string;
    userGroup?:               string;
    dayGroup?:                string;
    itmVatGroup?:             string;
    wageTypeGroup?:           string;
    wageFixTypeGroup?:        string;
    priceGroup?:              string;
    priceFilter?:             string;
    userDimension1Group?:     string;
    userDimension2Group?:     string;
    userDimension3Group?:     string;
    contextDimension1Group?:  string;
    contextDimension2Group?:  string;
    contextDimension3Group?:  string;
    limit?:                   number;
    offset?:                  number;
    countOnly?:               string;
    toTime?:                  string;
    fromTime?:                string;
    subFunction?:             string;
    oldStatus?:               string;
    newStatus?:               string;
    typeOfWorkFilter?:        string;
    viewId?:                  string;
    allRecords?:              string;
    treeHandle?:              string;
    customer?:                string;
    provider?:                string;
    wageId?:                  string;
    wageType?:                string;
    wageFixType?:             string;
    reevaluate?:              string;
    orderBy?:                 string;
    workReport?:              string;
    dimension1?:              string;
    dimension2?:              string;
    dimension3?:              string;
    invoiceId?:               string;
    itmVatValue?:             string;
    comments?:                string;
    description?:             string;
    plannedTaskCurrent?:      string;
    searchText?:              string;
    userDimension1?:          string;
    userDimension2?:          string;
    userDimension3?:          string;
    contextDimension1?:       string;
    contextDimension2?:       string;
    contextDimension3?:       string;
    userDimension1Simple?:    string;
    userDimension2Simple?:    string;
    userDimension3Simple?:    string;
    contextDimension1Simple?: string;
    contextDimension2Simple?: string;
    contextDimension3Simple?: string;
    FOLDERS?:                 Folders;
    MASTERFOLDERS?:           Folders;
    USERS?:                   Users;
    SELECTED_ITEMS?:          AdvancedTimesheets;
    TYPESOFWORK?:             Typesofwork;
    ADVANCED_TIMESHEETS?:     AdvancedTimesheets;
    MASSCHANGE?:              Masschange;

}

export interface AdvancedTimesheets {
    ITEM?: Item;
}

export interface Item {
    id?: string;
}

export interface Folders {
    count?:  string;
    FOLDER?: Folder;
}

export interface Folder {
    folderId?:   string;
    projectId?:  string;
    activityId?: string;
}

export interface Masschange {
    changeChargeability?:       string;
    chargeability?:             string;
    changeTypeOfWork?:          string;
    typeOfWorkId?:              string;
    changeContext?:             string;
    folderId?:                  string;
    projectId?:                 string;
    activityId?:                string;
    copyCostToPrice?:           string;
    userDimension1Change?:      string;
    userDimension1ToChange?:    string;
    userDimension2Change?:      string;
    userDimension2ToChange?:    string;
    userDimension3Change?:      string;
    userDimension3ToChange?:    string;
    contextDimension1Change?:   string;
    contextDimension1ToChange?: string;
    contextDimension2Change?:   string;
    contextDimension2ToChange?: string;
    contextDimension3Change?:   string;
    contextDimension3ToChange?: string;
}

export interface Typesofwork {
    TYPEOFWORK?: Item;
}

export interface Users {
    count?: string;
    USER?:  Item;
}

export function toSearchTimeSheetXml(obj: SearchTimeSheet): string {
    var parameters: string = '';

    if(obj.recordId) parameters += '<recordId>' + obj.recordId + '</recordId>';
    if(obj.currency) parameters += '<currency>' + obj.currency + '</currency>';
    if(obj.etypeFilter) parameters += '<etypeFilter>' + obj.etypeFilter + '</etypeFilter>';
    if(obj.statusFilter) parameters += '<statusFilter>' + obj.statusFilter + '</statusFilter>';
    if(obj.chargeFilter) parameters += '<chargeFilter>' + obj.chargeFilter + '</chargeFilter>';
    if(obj.statusGroup) parameters += '<statusGroup>' + obj.statusGroup + '</statusGroup>';
    if(obj.typeOfWorkGroup) parameters += '<typeOfWorkGroup>' + obj.typeOfWorkGroup + '</typeOfWorkGroup>';
    if(obj.activityGroup) parameters += '<activityGroup>' + obj.activityGroup + '</activityGroup>';
    if(obj.projectGroup) parameters += '<projectGroup>' + obj.projectGroup + '</projectGroup>';
    if(obj.folderGroup) parameters += '<folderGroup>' + obj.folderGroup + '</folderGroup>';
    if(obj.masterProjectGroup) parameters += '<masterProjectGroup>' + obj.masterProjectGroup + '</masterProjectGroup>';
    if(obj.masterFolderGroup) parameters += '<masterFolderGroup>' + obj.masterFolderGroup + '</masterFolderGroup>';
    if(obj.userGroup) parameters += '<userGroup>' + obj.userGroup + '</userGroup>';
    if(obj.dayGroup) parameters += '<dayGroup>' + obj.dayGroup + '</dayGroup>';
    if(obj.itmVatGroup) parameters += '<itmVatGroup>' + obj.itmVatGroup + '</itmVatGroup>';
    if(obj.wageTypeGroup) parameters += '<wageTypeGroup>' + obj.wageTypeGroup + '</wageTypeGroup>';
    if(obj.wageFixTypeGroup) parameters += '<wageFixTypeGroup>' + obj.wageFixTypeGroup + '</wageFixTypeGroup>';
    if(obj.priceGroup) parameters += '<priceGroup>' + obj.priceGroup + '</priceGroup>';
    if(obj.priceFilter) parameters += '<priceFilter>' + obj.priceFilter + '</priceFilter>';
    if(obj.userDimension1Group) parameters += '<userDimension1Group>' + obj.userDimension1Group + '</userDimension1Group>';
    if(obj.userDimension2Group) parameters += '<userDimension2Group>' + obj.userDimension2Group + '</userDimension2Group>';
    if(obj.userDimension3Group) parameters += '<userDimension3Group>' + obj.userDimension3Group + '</userDimension3Group>';
    if(obj.contextDimension1Group) parameters += '<contextDimension1Group>' + obj.contextDimension1Group + '</contextDimension1Group>';
    if(obj.contextDimension2Group) parameters += '<contextDimension2Group>' + obj.contextDimension2Group + '</contextDimension2Group>';
    if(obj.contextDimension3Group) parameters += '<contextDimension3Group>' + obj.contextDimension3Group + '</contextDimension3Group>';
    if(obj.limit) parameters += '<limit>' + obj.limit + '</limit>';
    if(obj.offset) parameters += '<offset>' + obj.offset + '</offset>';
    if(obj.countOnly) parameters += '<countOnly>' + obj.countOnly + '</countOnly>';
    if(obj.toTime) parameters += '<toTime>' + obj.toTime + '</toTime>';
    if(obj.fromTime) parameters += '<fromTime>' + obj.fromTime + '</fromTime>';
    if(obj.subFunction) parameters += '<subFunction>' + obj.subFunction + '</subFunction>';
    if(obj.oldStatus) parameters += '<oldStatus>' + obj.oldStatus + '</oldStatus>';
    if(obj.newStatus) parameters += '<newStatus>' + obj.newStatus + '</newStatus>';
    if(obj.typeOfWorkFilter) parameters += '<typeOfWorkFilter>' + obj.typeOfWorkFilter + '</typeOfWorkFilter>';
    if(obj.viewId) parameters += '<viewId>' + obj.viewId + '</viewId>';
    if(obj.allRecords) parameters += '<allRecords>' + obj.allRecords + '</allRecords>';
    if(obj.treeHandle) parameters += '<treeHandle>' + obj.treeHandle + '</treeHandle>';
    if(obj.customer) parameters += '<customer>' + obj.customer + '</customer>';
    if(obj.provider) parameters += '<provider>' + obj.provider + '</provider>';
    if(obj.wageId) parameters += '<wageId>' + obj.wageId + '</wageId>';
    if(obj.wageType) parameters += '<wageType>' + obj.wageType + '</wageType>';
    if(obj.wageFixType) parameters += '<wageFixType>' + obj.wageFixType + '</wageFixType>';
    if(obj.reevaluate) parameters += '<reevaluate>' + obj.reevaluate + '</reevaluate>';
    if(obj.orderBy) parameters += '<orderBy>' + obj.orderBy + '</orderBy>';
    if(obj.workReport) parameters += '<workReport>' + obj.workReport + '</workReport>';
    if(obj.dimension1) parameters += '<dimension1>' + obj.dimension1 + '</dimension1>';
    if(obj.dimension2) parameters += '<dimension2>' + obj.dimension2 + '</dimension2>';
    if(obj.dimension3) parameters += '<dimension3>' + obj.dimension3 + '</dimension3>';
    if(obj.invoiceId) parameters += '<invoiceId>' + obj.invoiceId + '</invoiceId>';
    if(obj.itmVatValue) parameters += '<itmVatValue>' + obj.itmVatValue + '</itmVatValue>';
    if(obj.comments) parameters += '<comments>' + obj.comments + '</comments>';
    if(obj.description) parameters += '<description>' + obj.description + '</description>';
    if(obj.plannedTaskCurrent) parameters += '<plannedTaskCurrent>' + obj.plannedTaskCurrent + '</plannedTaskCurrent>';
    if(obj.searchText) parameters += '<searchText>' + obj.searchText + '</searchText>';
    if(obj.userDimension1) parameters += '<userDimension1>' + obj.userDimension1 + '</userDimension1>';
    if(obj.userDimension2) parameters += '<userDimension2>' + obj.userDimension2 + '</userDimension2>';
    if(obj.userDimension3) parameters += '<userDimension3>' + obj.userDimension3 + '</userDimension3>';
    if(obj.contextDimension1) parameters += '<contextDimension1>' + obj.contextDimension1 + '</contextDimension1>';
    if(obj.contextDimension2) parameters += '<contextDimension2>' + obj.contextDimension2 + '</contextDimension2>';
    if(obj.contextDimension3) parameters += '<contextDimension3>' + obj.contextDimension3 + '</contextDimension3>';
    if(obj.userDimension1Simple) parameters += '<userDimension1Simple>' + obj.userDimension1Simple + '</userDimension1Simple>';
    if(obj.userDimension2Simple) parameters += '<userDimension2Simple>' + obj.userDimension2Simple + '</userDimension2Simple>';
    if(obj.userDimension3Simple) parameters += '<userDimension3Simple>' + obj.userDimension3Simple + '</userDimension3Simple>';
    if(obj.contextDimension1Simple) parameters += '<contextDimension1Simple>' + obj.contextDimension1Simple + '</contextDimension1Simple>';
    if(obj.contextDimension2Simple) parameters += '<contextDimension2Simple>' + obj.contextDimension2Simple + '</contextDimension2Simple>';
    if(obj.contextDimension3Simple) parameters += '<contextDimension3Simple>' + obj.contextDimension3Simple + '</contextDimension3Simple>';
    if(obj.FOLDERS){ 
        parameters += '<FOLDERS>'; 
        if(obj.FOLDERS.count) parameters += '<count>' + obj.FOLDERS.count + '</count>';
        if(obj.FOLDERS.FOLDER){
            var folder = obj.FOLDERS.FOLDER;
            if(folder){
                parameters += '<FOLDER>';
                if(folder.folderId) parameters += '<folderId>' + folder.folderId + '</folderId>';
                if(folder.projectId) parameters += '<projectId>' + folder.projectId + '</projectId>';
                if(folder.activityId) parameters += '<activityId>' + folder.activityId + '</activityId>';
                parameters += '</FOLDER>';
            }
        }
        parameters += '</FOLDERS>';
    }
    if(obj.MASTERFOLDERS){ 
        parameters += '<MASTERFOLDERS>'; 
        if(obj.MASTERFOLDERS.count) parameters += '<count>' + obj.MASTERFOLDERS.count + '</count>';
        if(obj.MASTERFOLDERS.FOLDER){
            var master_folder = obj.MASTERFOLDERS.FOLDER;
            if(master_folder){
                parameters += '<FOLDER>';
                if(master_folder.folderId) parameters += '<folderId>' + master_folder.folderId + '</folderId>';
                if(master_folder.projectId) parameters += '<projectId>' + master_folder.projectId + '</projectId>';
                if(master_folder.activityId) parameters += '<activityId>' + master_folder.activityId + '</activityId>';
                parameters += '</FOLDER>';
            }
        }
        parameters += '</MASTERFOLDERS>';
    }
    if(obj.USERS){
        parameters += '<USERS>';
        if(obj.USERS.count) parameters += '<count>' + obj.USERS.count + '</count>';
        if(obj.USERS.USER){
            var user = obj.USERS.USER;
            if(user){
                parameters += '<USER>';
                if(user.id) parameters += '<id>' + user.id + '</id>';
                parameters += '</USER>';
            }
        }
        parameters += '</USERS>';
    }
    if(obj.SELECTED_ITEMS){
        parameters += '<SELECTED_ITEMS>';
        if(obj.SELECTED_ITEMS.ITEM){
            var item = obj.SELECTED_ITEMS.ITEM;
            if(item){
                parameters += '<ITEM>';
                if(item.id) parameters += '<id>' + item.id + '</id>';
                parameters += '</ITEM>';
            }
        }
        parameters += '</SELECTED_ITEMS>';
    }
    if(obj.TYPESOFWORK){
        parameters += '<TYPESOFWORK>';
        if(obj.TYPESOFWORK.TYPEOFWORK){
            var type_of_work = obj.TYPESOFWORK.TYPEOFWORK;
            if(type_of_work){
                parameters += '<TYPEOFWORK>';
                if(type_of_work.id) parameters += '<id>' + type_of_work.id + '</id>';
                parameters += '</TYPEOFWORK>';
            }
        }
        parameters += '</TYPESOFWORK>';
    }
    if(obj.ADVANCED_TIMESHEETS){
        parameters += '<ADVANCED_TIMESHEETS>';
        if(obj.ADVANCED_TIMESHEETS.ITEM){
            var adv_item = obj.ADVANCED_TIMESHEETS.ITEM;
            if(adv_item){
                parameters += '<ITEM>';
                if(adv_item.id) parameters += '<id>' + adv_item.id + '</id>';
                parameters += '</ITEM>';
            }
        }
        parameters += '</ADVANCED_TIMESHEETS>';
    }
    if(obj.MASSCHANGE){
        parameters += '<MASSCHANGE>';
        if(obj.MASSCHANGE.changeChargeability) parameters += '<changeChargeability>' + obj.MASSCHANGE.changeChargeability + '</changeChargeability>';
        if(obj.MASSCHANGE.chargeability) parameters += '<chargeability>' + obj.MASSCHANGE.chargeability + '</chargeability>';
        if(obj.MASSCHANGE.changeTypeOfWork) parameters += '<changeTypeOfWork>' + obj.MASSCHANGE.changeTypeOfWork + '</changeTypeOfWork>';
        if(obj.MASSCHANGE.typeOfWorkId) parameters += '<typeOfWorkId>' + obj.MASSCHANGE.typeOfWorkId + '</typeOfWorkId>';
        if(obj.MASSCHANGE.changeContext) parameters += '<changeContext>' + obj.MASSCHANGE.changeContext + '</changeContext>';
        if(obj.MASSCHANGE.folderId) parameters += '<folderId>' + obj.MASSCHANGE.folderId + '</folderId>';
        if(obj.MASSCHANGE.projectId) parameters += '<projectId>' + obj.MASSCHANGE.projectId + '</projectId>';
        if(obj.MASSCHANGE.activityId) parameters += '<activityId>' + obj.MASSCHANGE.activityId + '</activityId>';
        if(obj.MASSCHANGE.copyCostToPrice) parameters += '<copyCostToPrice>' + obj.MASSCHANGE.copyCostToPrice + '</copyCostToPrice>';
        if(obj.MASSCHANGE.userDimension1Change) parameters += '<userDimension1Change>' + obj.MASSCHANGE.userDimension1Change + '</userDimension1Change>';
        if(obj.MASSCHANGE.userDimension1ToChange) parameters += '<userDimension1ToChange>' + obj.MASSCHANGE.userDimension1ToChange + '</userDimension1ToChange>';
        if(obj.MASSCHANGE.userDimension2Change) parameters += '<userDimension2Change>' + obj.MASSCHANGE.userDimension2Change + '</userDimension2Change>';
        if(obj.MASSCHANGE.userDimension2ToChange) parameters += '<userDimension2ToChange>' + obj.MASSCHANGE.userDimension2ToChange + '</userDimension2ToChange>';
        if(obj.MASSCHANGE.userDimension3Change) parameters += '<userDimension3Change>' + obj.MASSCHANGE.userDimension3Change + '</userDimension3Change>';
        if(obj.MASSCHANGE.userDimension3ToChange) parameters += '<userDimension3ToChange>' + obj.MASSCHANGE.userDimension3ToChange + '</userDimension3ToChange>';
        if(obj.MASSCHANGE.contextDimension1Change) parameters += '<contextDimension1Change>' + obj.MASSCHANGE.contextDimension1Change + '</contextDimension1Change>';
        if(obj.MASSCHANGE.contextDimension1ToChange) parameters += '<contextDimension1ToChange>' + obj.MASSCHANGE.contextDimension1ToChange + '</contextDimension1ToChange>';
        if(obj.MASSCHANGE.contextDimension2Change) parameters += '<contextDimension2Change>' + obj.MASSCHANGE.contextDimension2Change + '</contextDimension2Change>';
        if(obj.MASSCHANGE.contextDimension2ToChange) parameters += '<contextDimension2ToChange>' + obj.MASSCHANGE.contextDimension2ToChange + '</contextDimension2ToChange>';
        if(obj.MASSCHANGE.contextDimension3Change) parameters += '<contextDimension3Change>' + obj.MASSCHANGE.contextDimension3Change + '</contextDimension3Change>';
        if(obj.MASSCHANGE.contextDimension3ToChange) parameters += '<contextDimension3ToChange>' + obj.MASSCHANGE.contextDimension3ToChange + '</contextDimension3ToChange>';
        parameters += '</MASSCHANGE>';

    }
    return parameters;

}