import { ComboBox, Flex, Item } from '@adobe/react-spectrum';
import * as React from 'react';
import { useEffect, useState } from 'react';

export interface IResponsibleUserFilterProps {
    title: string;
    options: any[];
    onChange?: (key:string, value: any) => void;
    selectedOption?: any;
}

export function ResponsibleUserFilter ({
    title,
    options,
    onChange,
    selectedOption
}: IResponsibleUserFilterProps) {
    const [selectedUser, setSelectedUser] = useState(selectedOption);

    const handleOnChange = (value:any) => {
        setSelectedUser(value);
        if(onChange === undefined) return;
        onChange('responsibleUser', value);
    }

    useEffect(() => { 
        setSelectedUser(selectedOption);
    } , [selectedOption]);
  return (
    <Flex direction='column' gap={'size-10'}>
        <ComboBox label={title} selectedKey={selectedUser} onSelectionChange={handleOnChange} width={'90%'} >
            {options.map((item) => (
                <Item textValue={item.name} key={item.id}>
                {item.name}
                </Item>
            ))}
        </ComboBox>
    </Flex>
  );
}
