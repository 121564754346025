import axios from "axios";
export interface ISoapClient {
  makeSoapCall(request: any, url: string, soapAction: string, cancelSource: any): Promise<any>;
}

export class SoapClient implements ISoapClient {
  public async makeSoapCall(
    request: any,
    url: string,
    soapAction: string,
    cancelSource: any
  ): Promise<any> {
    return axios.post(url, request, {
      cancelToken: cancelSource.token,
      headers: {
        "Content-Type": "text/xml",
        SOAPAction: soapAction,
        "Access-Control-Allow-Origin": "*",
      },
    });
  }
}
