import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IUpdateProjectUserRequest extends IBaseRequest {
  roleName?: string;
  role?: string;
  note?: string;
  id?: string;
  userId?: string;
  projectName?: string;
  acl?: string;
  project?: string;
  isPrimary?: string;
  contact?: string;
  userName?: string;
}

export class UpdateProjectUserRequest extends BaseRequest implements IUpdateProjectUserRequest {
  public roleName?: string;
  public role?: string;
  public note?: string;
  public id?: string;
  public userId?: string;
  public projectName?: string;
  public acl?: string;
  public project?: string;
  public isPrimary?: string;
  public contact?: string;
  public userName?: string;

  constructor(server: string, session: string, otherOptions: any) {
    super(server, session);
    this.roleName = otherOptions.roleName;
    this.role = otherOptions.role;
    this.note = otherOptions.note;
    this.id = otherOptions.id;
    this.userId = otherOptions.userId;
    this.projectName = otherOptions.projectName;
    this.acl = otherOptions.acl;
    this.project = otherOptions.project;
    this.isPrimary = otherOptions.isPrimary;
    this.contact = otherOptions.contact;
    this.userName = otherOptions.userName;
  }

  public toXml(): string {
    var parameters: string = super.toXml();

    if (this.roleName) parameters += `<roleName>${this.roleName}</roleName>`;
    if (this.role) parameters += `<role>${this.role}</role>`;
    if (this.note) parameters += `<note>${this.note}</note>`;
    if (this.id) parameters += `<id>${this.id}</id>`;
    if (this.userId) parameters += `<userId>${this.userId}</userId>`;
    if (this.projectName) parameters += `<projectName>${this.projectName}</projectName>`;
    if (this.acl) parameters += `<acl>${this.acl}</acl>`;
    if (this.project) parameters += `<project>${this.project}</project>`;
    if (this.isPrimary) parameters += `<isPrimary>${this.isPrimary}</isPrimary>`;
    if (this.contact) parameters += `<contact>${this.contact}</contact>`;
    if (this.userName) parameters += `<userName>${this.userName}</userName>`;

    return super.wrapperXml('UpdateProjectUser', parameters);
  }
}
