import { ServiceBase } from "../ServiceBase";
import { IContactDataTransferRequest } from "./requests/ContactDataTransferRequest";
import { ILoginRequest } from "./requests/LoginRequest";
import { ContactDataTransferResponse } from "./responses/ContactDataTransferResponse";
import { LoginResponse } from "./responses/LoginResponse";
import { MAIN } from "../../../utils/ServiceUtils";
import { ILogoutRequest } from "./requests/LogoutRequest";
import { LogoutResponse } from "./responses/LogoutResponse";
import { IGetContactRequest } from "./requests/GetContactRequest";
import { GetContactResponse } from "./responses/GetContactResponse";
import { IListDistributionGroupRequest } from "./requests/ListDistributionGroupRequest";
import { ListDistributionGroupResponse } from "./responses/ListDistributionGroupResponse";
import { IGetContactListRequest } from "./requests/GetContactListRequest";
import { GetContactListResponse } from "./responses/GetContactListResponse";
import { IListReferenceRequest } from "./requests/ListReferenceRequest";
import { ListReferenceResponse } from "./responses/ListReferenceResponse";
import { IListContactListRequest } from "./requests/ListContactListRequest";
import { ListContactListResponse } from "./responses/ListContactListResponse";
import { IGetFromContactListRequest } from "./requests/GetFromContactListRequest";
import { GetFromContactListResponse } from "./responses/GetFromContactListResponse";
import { ICreateContactRequest } from "./requests/CreateContactRequest";
import { CreateContactResponse } from "./responses/CreateContactResponse";
import { IListIndustryRequest } from "./requests/ListIndustryRequest";
import { ListIndustryResponse } from "./responses/ListIndustryResponse";
import { ICountIndustryRequest } from "./requests/CountIndustryRequest";
import { CountIndustryResponse } from "./responses/CountIndustryResponse";
import { ICountPositionRequest } from "./requests/CountPositionRequest";
import { CountPositionResponse } from "./responses/CountPositionResponse";
import { IListPositionRequest } from "./requests/ListPositionRequest";
import { ListPositionResponse } from "./responses/ListPositionResponse";
import { ICountDepartmentRequest } from "./requests/CountDepartmentRequest";
import { CountDepartmentResponse } from "./responses/CountDepartmentResponse";
import { IListDepartmentRequest } from "./requests/ListDepartmentRequest";
import { ListDepartmentResponse } from "./responses/ListDepartmentResponse";
import { ICreateCompanyPersonRelationRequest } from "./requests/CreateCompanyPersonRelationRequest";
import { CreateCompanyPersonRelationResponse } from "./responses/CreateCompanyPersonRelationResponse";

export interface IMainService{
    login(request:ILoginRequest):Promise<LoginResponse>;
    logout(request:ILogoutRequest):Promise<LogoutResponse>;
    contactDataTransfer(request: IContactDataTransferRequest): Promise<ContactDataTransferResponse>;
    getContact(request: IGetContactRequest): Promise<GetContactResponse>;
    listDistributionGroup(request: IListDistributionGroupRequest): Promise<ListDistributionGroupResponse>;
    getContactList(request: IGetContactListRequest): Promise<GetContactListResponse>;
    listReference(request: IListReferenceRequest): Promise<ListReferenceResponse>;
    listContactList(request: IListContactListRequest): Promise<ListContactListResponse>;
    createContact(request: ICreateContactRequest): Promise<CreateContactResponse>;
    getFromContactList(request: IGetFromContactListRequest): Promise<GetFromContactListResponse>;
    countIndustry(request: ICountIndustryRequest): Promise<CountIndustryResponse>;
    listIndustry(request: IListIndustryRequest): Promise<ListIndustryResponse>;
    countPosition(request: ICountPositionRequest): Promise<CountPositionResponse>;
    listPosition(request: IListPositionRequest): Promise<ListPositionResponse>;
    countDepartment(request: ICountDepartmentRequest): Promise<CountDepartmentResponse>;
    listDepartment(request: IListDepartmentRequest): Promise<ListDepartmentResponse>;
    createCompanyPersonRelation(request: ICreateCompanyPersonRelationRequest): Promise<CreateCompanyPersonRelationResponse>;
}

export class MainService extends ServiceBase implements IMainService {
    // constructor(_url:string){
    //     super(_url);
    // }
    _addRootElementForDGroupList(xml:string):string{
        xml = xml.replace('</count><DGROUP>', '</count><DGROUPS><DGROUP>');
        xml = xml.replace('</DGROUP></ns1:ListDistributionGroupResponse>', '</DGROUP></DGROUPS></ns1:ListDistributionGroupResponse>');
        return xml;
    }

    _addRootElementForContactList(xml:string):string{
        xml = xml.replace('</count><CONTACT>', '</count><CONTACTS><CONTACT>');
        xml = xml.replace('</CONTACT></ns1:GetContactListResponse>', '</CONTACT></CONTACTS></ns1:GetContactListResponse>');
        return xml;
    }

    _addRootElementForReferenceList(xml:string):string{
        xml = xml.replace('</count><REFERENCE>', '</count><LIST><REFERENCE>');
        xml = xml.replace('</REFERENCE></ns1:ListReferenceResponse>', '</REFERENCE></LIST></ns1:ListReferenceResponse>');
        return xml;
    }
    public async login(request:ILoginRequest):Promise<LoginResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), MAIN.login, request.cancelSource)).data ;
            await this.updateCache(MAIN.login, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(MAIN.login, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).LoginResponse as LoginResponse;
        super._checkErrors(parsedResponse);
        return parsedResponse;
    }

    public async logout(request:ILogoutRequest):Promise<LogoutResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), MAIN.logout, request.cancelSource)).data ;
            await this.updateCache(MAIN.logout, request.toXml(), response);
        } catch (error:any) {
            response = await this.getFromCache(MAIN.logout, request.toXml());
        }

        var parsedResponse = super.sendResponse(response).LogoutResponse as LogoutResponse;
        super._checkErrors(parsedResponse);
        return parsedResponse;
    }

    public async contactDataTransfer(request:IContactDataTransferRequest):Promise<ContactDataTransferResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), MAIN.contactDataTransfer, request.cancelSource)).data ;
            await this.updateCache(MAIN.contactDataTransfer, request.toXml(), response);    
        } catch (error:any) {
            response = await this.getFromCache(MAIN.contactDataTransfer, request.toXml());
        }
        
        var parsedResponse = super.sendResponse(response).ContactDataTransferResponse as ContactDataTransferResponse;
        try{
            super._checkErrors(parsedResponse);
        }catch(e){
            console.error(e);
        }
        return parsedResponse;
    }

    public async getContact(request:IGetContactRequest):Promise<GetContactResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), MAIN.getContact, request.cancelSource)).data ;
            await this.updateCache(MAIN.getContact, request.toXml(), response);    
        } catch (error:any) {
            response = await this.getFromCache(MAIN.getContact, request.toXml());
        }
        
        var parsedResponse = super.sendResponse(response).GetContactResponse as GetContactResponse;
        try{
            super._checkErrors(parsedResponse);
        }catch(e){
            console.error(e);
        }
        return parsedResponse;
    }

    public async listDistributionGroup(request:IListDistributionGroupRequest):Promise<ListDistributionGroupResponse>{
        let response:any = null;
        try {
            var tmp_response = (await this.makeCall(request.toXml(), MAIN.listDistributionGroup, request.cancelSource)).data ;
            response = this._addRootElementForDGroupList(tmp_response.data);
            await this.updateCache(MAIN.listDistributionGroup, request.toXml(), response);    
        } catch (error:any) {
            response = await this.getFromCache(MAIN.listDistributionGroup, request.toXml());
        }
        
        var parsedResponse = super.sendResponse(response).ListDistributionGroupResponse as ListDistributionGroupResponse;
        try{
            super._checkErrors(parsedResponse);
        }catch(e){
            console.error(e);
        }
        return parsedResponse;
    }

    public async getContactList(request:IGetContactListRequest):Promise<GetContactListResponse>{
        let response:any = null;
        try {
            var tmp_response = (await this.makeCall(request.toXml(), MAIN.getContactList, request.cancelSource)).data ;
            // response = this._addRootElementForContactList(tmp_response);
            response = tmp_response;
            await this.updateCache(MAIN.getContactList, request.toXml(), response);    
        } catch (error:any) {
            response = await this.getFromCache(MAIN.getContactList, request.toXml());
        }
        
        var parsedResponse = super.sendResponse2(response,['CONTACT']).GetContactListResponse as GetContactListResponse;
        if(!super._checkErrors(parsedResponse)){

        };
        return parsedResponse;
    }

    public async listReference(request:IListReferenceRequest):Promise<ListReferenceResponse>{
        let response:any = null;
        try {
            var tmp_response = (await this.makeCall(request.toXml(), MAIN.listReference, request.cancelSource)).data ;
            response = this._addRootElementForReferenceList(tmp_response);
            await this.updateCache(MAIN.listReference, request.toXml(), response);    
        } catch (error:any) {
            response = await this.getFromCache(MAIN.listReference, request.toXml());
        }
        
        var parsedResponse = super.sendResponse(response).ListReferenceResponse as ListReferenceResponse;
        try{
            super._checkErrors(parsedResponse);
        }catch(e){
            console.error(e);
        }
        return parsedResponse;
    }

    public async listContactList(request:IListContactListRequest):Promise<ListContactListResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), MAIN.listContactList, request.cancelSource)).data ;
            await this.updateCache(MAIN.listContactList, request.toXml(), response);    
        } catch (error:any) {
            response = await this.getFromCache(MAIN.listContactList, request.toXml());
        }
        
        var parsedResponse = super.sendResponse2(response, ['ITEM']).ListContactListResponse as ListContactListResponse;
        try{
            super._checkErrors(parsedResponse);
        }catch(e){
            console.error(e);
        }
        return parsedResponse;
    }

    public async createContact(request:ICreateContactRequest):Promise<CreateContactResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), MAIN.createContact, request.cancelSource)).data ;
            await this.updateCache(MAIN.createContact, request.toXml(), response);    
        } catch (error:any) {
            response = await this.getFromCache(MAIN.createContact, request.toXml());
        }
        
        var parsedResponse = super.sendResponse2(response).CreateContactResponse as CreateContactResponse;
        try{
            super._checkErrors(parsedResponse);
        }catch(e){
            console.error(e);
        }
        return parsedResponse;
    }

    public async getFromContactList(request:IGetFromContactListRequest):Promise<GetFromContactListResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), MAIN.getFromContactList, request.cancelSource)).data ;
            await this.updateCache(MAIN.getFromContactList, request.toXml(), response);    
        } catch (error:any) {
            response = await this.getFromCache(MAIN.getFromContactList, request.toXml());
        }
        
        var parsedResponse = super.sendResponse2(response, ['ITEM']).GetFromContactListResponse as GetFromContactListResponse;
        try{
            super._checkErrors(parsedResponse);
        }catch(e){
            console.error(e);
        }
        return parsedResponse;
    }

    public async countIndustry(request:ICountIndustryRequest):Promise<CountIndustryResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), MAIN.countIndustry, request.cancelSource)).data ;
            await this.updateCache(MAIN.countIndustry, request.toXml(), response);    
        } catch (error:any) {
            response = await this.getFromCache(MAIN.countIndustry, request.toXml());
        }
        
        var parsedResponse = super.sendResponse2(response).CountIndustryResponse as CountIndustryResponse;
        try{
            super._checkErrors(parsedResponse);
        }catch(e){
            console.error(e);
        }
        return parsedResponse;
    }
    public async listIndustry(request:IListIndustryRequest):Promise<ListIndustryResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), MAIN.listIndustry, request.cancelSource)).data ;
            await this.updateCache(MAIN.listIndustry, request.toXml(), response);    
        } catch (error:any) {
            response = await this.getFromCache(MAIN.listIndustry, request.toXml());
        }
        
        var parsedResponse = super.sendResponse2(response, ['INDUSTRY']).ListIndustryResponse as ListIndustryResponse;
        try{
            super._checkErrors(parsedResponse);
        }catch(e){
            console.error(e);
        }
        return parsedResponse;
    }

    public async countPosition(request:ICountPositionRequest):Promise<CountPositionResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), MAIN.countPosition, request.cancelSource)).data ;
            await this.updateCache(MAIN.countPosition, request.toXml(), response);    
        } catch (error:any) {
            response = await this.getFromCache(MAIN.countPosition, request.toXml());
        }
        
        var parsedResponse = super.sendResponse2(response).CountPositionResponse as CountPositionResponse;
        try{
            super._checkErrors(parsedResponse);
        }catch(e){
            console.error(e);
        }
        return parsedResponse;
    }

    public async listPosition(request:IListPositionRequest):Promise<ListPositionResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), MAIN.listPosition, request.cancelSource)).data ;
            await this.updateCache(MAIN.listPosition, request.toXml(), response);    
        } catch (error:any) {
            response = await this.getFromCache(MAIN.listPosition, request.toXml());
        }
        
        var parsedResponse = super.sendResponse2(response, ['POSITION']).ListPositionResponse as ListPositionResponse;
        try{
            super._checkErrors(parsedResponse);
        }catch(e){
            console.error(e);
        }
        return parsedResponse;
    }

    public async countDepartment(request:ICountDepartmentRequest):Promise<CountDepartmentResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), MAIN.countDepartment, request.cancelSource)).data ;
            await this.updateCache(MAIN.countDepartment, request.toXml(), response);    
        } catch (error:any) {
            response = await this.getFromCache(MAIN.countDepartment, request.toXml());
        }
        
        var parsedResponse = super.sendResponse2(response).CountDepartmentResponse as CountDepartmentResponse;
        try{
            super._checkErrors(parsedResponse);
        }catch(e){
            console.error(e);
        }
        return parsedResponse;
    }

    public async listDepartment(request:IListDepartmentRequest):Promise<ListDepartmentResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), MAIN.listDepartment, request.cancelSource)).data ;
            await this.updateCache(MAIN.listDepartment, request.toXml(), response);    
        } catch (error:any) {
            response = await this.getFromCache(MAIN.listDepartment, request.toXml());
        }
        
        var parsedResponse = super.sendResponse2(response, ['DEPARTMENT']).ListDepartmentResponse as ListDepartmentResponse;
        try{
            super._checkErrors(parsedResponse);
        }catch(e){
            console.error(e);
        }
        return parsedResponse;
    }
    
    public async createCompanyPersonRelation(request:ICreateCompanyPersonRelationRequest):Promise<CreateCompanyPersonRelationResponse>{
        let response:any = null;
        try {
            response = (await this.makeCall(request.toXml(), MAIN.createCompanyPersonRelation, request.cancelSource)).data ;
            await this.updateCache(MAIN.createCompanyPersonRelation, request.toXml(), response);    
        } catch (error:any) {
            response = await this.getFromCache(MAIN.createCompanyPersonRelation, request.toXml());
        }
        
        var parsedResponse = super.sendResponse2(response).CreateCompanyPersonRelationResponse as CreateCompanyPersonRelationResponse;
        try{
            super._checkErrors(parsedResponse);
        }catch(e){
            console.error(e);
        }
        return parsedResponse;
    }
}