import { BaseRequest, IBaseRequest } from "../../BaseRequest";

export interface IListContextRequest extends IBaseRequest{
    offset?: number;
    limit?: number;
}

export class ListContextRequest extends BaseRequest implements IListContextRequest{
    offset?: number;
    limit?: number;
    constructor(server: string, session: string, offset?: number, limit?: number) {
        super(server, session);
        this.offset = offset;
        this.limit = limit;
    }
    toXml(): string {
        var parameters:string = super.toXml();
        if(this.offset) parameters += `<offset>${this.offset}</offset>`;
        if(this.limit) parameters += `<limit>${this.limit}</limit>`;
        return super.wrapperXml("ListContext", parameters);
    }
}