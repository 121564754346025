import { FunctionComponent, useState } from "react";
import AppMenuGroup from "./AppMenuGroup";
import styles from "./AppMenu.module.css";
import { AppMenuItemType } from "./AppMenu.types";
import { SearchField } from "@react-spectrum/searchfield";
import { useKeyboardShortcuts } from "../../../hooks/UseKeyboardShortcuts";

type AppMenuType = {
  groups: any[];
  onAppClicked: (item:AppMenuItemType) => void;
  onClosed: () => void;
};
const AppMenu: FunctionComponent<AppMenuType> = ({ 
  groups, 
  onAppClicked,
  onClosed
}) => {
  const [searchText, setSearchText] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [selectedItem, setSelectedItem] = useState(0);

  const handleSearchChange = (value: string) => {
    setSearchText(value);
    setSelectedGroup(0);
    setSelectedItem(0);
  }

  const handleSearchKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
      case 'ArrowRight':
      case 'ArrowLeft':
      case 'ArrowDown':
      case 'ArrowUp':
        keyboardCallback(e.key);
        break;
      case 'Escape':
        onClosed();
        break;
    }
  }

  const keyboardCallback = (key?:string) => {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz';

    if(key && alphabet.includes(key.toLowerCase())){
      setShowSearch(true);
      setSearchText(searchText + key);
    }
    else if(key === 'Enter'){
      const firstItem = groups.filter(x => x.items.filter( (y:any) => y.itemTitle.toLowerCase().includes(searchText.toLowerCase())).length > 0 )[selectedGroup].items.filter((y:any) => y.itemTitle.toLowerCase().includes(searchText.toLowerCase()))[selectedItem];
      if (firstItem) {
        onAppClicked(firstItem);
      }
    }
    else if(key === 'Escape'){
      onClosed();
    }
    else if(key === 'ArrowRight'){
      if(selectedGroup < groups.length - 1){
        setSelectedGroup(selectedGroup + 1);
        if(selectedItem > groups[selectedGroup + 1].items.length - 1){
          setSelectedItem(groups[selectedGroup + 1].items.length - 1);
        }
      }
    }
    else if(key === 'ArrowLeft'){
      if(selectedGroup > 0){
        setSelectedGroup(selectedGroup - 1);
        if(selectedItem > groups[selectedGroup - 1].items.length - 1){
          setSelectedItem(groups[selectedGroup - 1].items.length - 1);
        }
      }
    }
    else if(key === 'ArrowDown'){
      if(selectedItem < groups[selectedGroup].items.length - 1){
        setSelectedItem(selectedItem + 1);
      }
    }
    else if(key === 'ArrowUp'){
      if(selectedItem > 0){
        setSelectedItem(selectedItem - 1);
      }
    }
  }
  useKeyboardShortcuts([
    { key: 'any', callback: keyboardCallback }
  ]);

  return (
    <div className={styles.appMenu}>
      {showSearch && (<div className={styles.searchContainer} onClick={(e) => e.stopPropagation()}>
        <SearchField 
          autoFocus={true}
          onChange={handleSearchChange}
          defaultValue={searchText}
          onKeyUp={handleSearchKeyUp}
        />
      </div>)}
      <div className={showSearch ? styles.frameParentInSearch : styles.frameParent}>
        {groups.filter(x => x.items.filter( (y:any) => y.itemTitle.toLowerCase().includes(searchText.toLowerCase())).length > 0 ).map((group, index) => (
          <AppMenuGroup key={index} 
            sectionTitle={group.sectionTitle} 
            items={group.items} 
            onAppClicked={onAppClicked}
            filter={searchText}
            isGroupSelected={selectedGroup === index}
            selectedItem={selectedItem}
            />))}
      </div>
    </div>
  );
};

export default AppMenu;
