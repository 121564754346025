import { BaseRequest, IBaseRequest } from "../../BaseRequest";

export interface IListApplicationsRequest extends IBaseRequest {
}

export class ListApplicationsRequest extends BaseRequest implements IListApplicationsRequest {

  toXml(): string {
    return super.wrapperXml('ListApplications',super.toXml());
  }
}
