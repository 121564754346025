import { RestServiceBase } from "../RestServiceBase";
import { RctDashboardRequest } from "./requests/RctDashboardRequest";
import { DashboardItem, RctDashboardResponse } from "./responses/RctDashboardResponse";

export interface IRecruitmentRestService {
    getRctDashboard(request: RctDashboardRequest): Promise<RctDashboardResponse>;
}

export class RecruitmentRestService extends RestServiceBase implements IRecruitmentRestService {
    public async getRctDashboard(request: RctDashboardRequest): Promise<RctDashboardResponse> {
        const CALL_TAG = `rct_dashboard?session=${request.session}&instance=${request.server}`
        let response: any = null;
        try {
            var request_data = request.dashboardData;
            let tmp_response = (await this.makeCall(request_data, CALL_TAG, request.cancelSource));
            response = tmp_response.data;
            await this.updateCache(CALL_TAG, JSON.stringify(request_data), response);
        } catch (error: any) {
            response = await this.getFromCache(CALL_TAG, JSON.stringify(request_data));
        }
        var parsedResponse = response as DashboardItem[];
        // if(!super._checkErrors(parsedResponse)){

        // };
        return { items: parsedResponse };        
    }
}