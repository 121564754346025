import { BaseRequest, IBaseRequest } from '../../BaseRequest';
import { SearchTimeSheet, toSearchTimeSheetXml } from './SearchTimesheet';

export interface IEvaluateTimesheetRequest extends IBaseRequest {
    options: SearchTimeSheet;
}

export class EvaluateTimesheetRequest extends BaseRequest implements IEvaluateTimesheetRequest {
    public options: SearchTimeSheet;
    constructor(server: string, session: string, options: SearchTimeSheet) {
        super(server, session);
        this.options = options;
    }

    public toXml(): string {
        var parameters: string = super.toXml();
        parameters += toSearchTimeSheetXml(this.options);
        return super.wrapperXml('EvaluateTimesheet', parameters);
    }
}
