import { BaseRequest, IBaseRequest } from '../../BaseRequest';

export interface IListActivityTypeRequest extends IBaseRequest {
    fixedType?:       string;
    canBeRoot?:       string;
    systemType?:      string;
    categoryId?:      string;
    fpaTypeCategory?: string;
}

export class ListActivityTypeRequest extends BaseRequest implements IListActivityTypeRequest {
    fixedType?:       string;
    canBeRoot?:       string;
    systemType?:      string;
    categoryId?:      string;
    fpaTypeCategory?: string;

    constructor(server: string, session: string, otherOptions: any) {
        super(server, session);
        this.fixedType = otherOptions.fixedType;
        this.canBeRoot = otherOptions.canBeRoot;
        this.systemType = otherOptions.systemType;
        this.categoryId = otherOptions.categoryId;
        this.fpaTypeCategory = otherOptions.fpaTypeCategory
    }

    public toXml(): string {
        var parameters: string = super.toXml();

        if (this.fixedType) parameters += `<fixedType>${this.fixedType}</fixedType>\n`;
        if (this.canBeRoot) parameters += `<canBeRoot>${this.canBeRoot}</canBeRoot>\n`;
        if (this.systemType) parameters += `<systemType>${this.systemType}</systemType>\n`;
        if (this.categoryId) parameters += `<categoryId>${this.categoryId}</categoryId>\n`;
        if (this.fpaTypeCategory) parameters += `<fpaTypeCategory>${this.fpaTypeCategory}</fpaTypeCategory>\n`;
        
        return super.wrapperXml('ListActivityType', parameters);
    }
}
