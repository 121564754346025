import React from "react";
import { Application } from "../services/soap/features/responses/ListApplicationsResponse";

type AppsContextProps = {
    apps: Application[];
    setApps: (apps: Application[]) => void;
    redirectUrl: string;
    setRedirectUrl: (url: string) => void;
}
const AppsContext = React.createContext<AppsContextProps | undefined>(undefined);

export default AppsContext;