import { useEffect, useState } from 'react';
import { useDependency } from '../../../../contexts/DependencyProvider';
import { Form, Flex, View, TextField } from '@adobe/react-spectrum';
import { AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { useTranslation } from 'react-i18next';
import CustomDialogComponent from '../../../../components/ui/CustomDialogComponent';
import { useDebounce, usePrevious } from '@uidotdev/usehooks';
import { FilterCol, FilterFolder, FilterProject, ListFPARequest } from '../../../../services/soap/project/requests/ListFPARequest';
import styles from './RecrutimentDashboard.module.css';

type SearchDialogTemplateProps = {
  updateSearchDialogState: (newState: boolean, reloadKanban: boolean, reqParams: any) => void;
  dialogVisibility: boolean;
};

const SearchDialogTemplate = ({ updateSearchDialogState, dialogVisibility = false }: SearchDialogTemplateProps): JSX.Element => {
  const { projectService, store } = useDependency();
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const previousSearchResults = usePrevious(searchResults);
  const [visibility, setVisibility] = useState<boolean>(dialogVisibility);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState<any>('');
  const [searchedValue, _] = useState<any>(JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('rec_search_value')) ?? {})));
  const debouncedSearchTerm = useDebounce(searchTerm, 1000);
  let autoCompleteRef: AutoCompleteComponent | null;
  const { t } = useTranslation();
  useEffect(() => {
    (async () => {
      showSpinner();
      let newResultArray: { [key: string]: Object }[] = [];
      //console.log('debouncedSearchTerm', debouncedSearchTerm);
      if (debouncedSearchTerm) {
        const reqParams = {
          FILTER_FOLDER: new FilterFolder([new FilterCol('fpaTypeCategories', 'any', 'com.atollon.project.fpatypecategory.recruitment.client')]),
          FILTER_PROJECT: new FilterProject([new FilterCol('fpaTypeCategories', 'any', 'com.atollon.project.fpatypecategory.recruitment.vacancy')]),
          ORDER: {
            COL: [
              {
                name: 'name',
              },
              {
                name: 'created',
                desc: true,
              },
            ],
          },
        };
        let request = new ListFPARequest(store.Server, store.SessionId, searchTerm + '*', true, 50, 0, '', false, false, undefined, reqParams.FILTER_FOLDER, reqParams.FILTER_PROJECT);
        let result = await projectService.listFPA(request);
        //console.log(result?.count);
        if (result?.count > 0) {
          //console.log(result.ITEMS.some((item: any) => 'FOLDER' in item?.ITEM));
          if (result.ITEMS.some((item: any) => 'FOLDER' in item.ITEM)) {
            const searchResultFolder = result.ITEMS.map((item: any) => item?.ITEM?.FOLDER);
            //console.log(searchResult);
            searchResultFolder.forEach((item: any) =>
              newResultArray.push({
                id: item?.id,
                name: `${item?.name} (${item?.folderTypeName})`,
                recordType: 'Client',
              })
            );
          }
          if (result.ITEMS.some((item: any) => 'PROJECT' in item.ITEM)) {
            const searchResultProject = result.ITEMS.map((item: any) => item.ITEM?.PROJECT);
            //console.log(searchResult);
            searchResultProject.forEach((item: any) =>
              newResultArray.push({
                id: item?.id,
                name: `${item?.name} > ${item?.contextParentName}`,
                recordType: 'Vacancy',
              })
            );
          }
        } else {
          newResultArray = previousSearchResults || [];
        }

        //console.log(newResultArray);
      }

      hideSpinner();
      setSearchResults(newResultArray);
    })();
  }, [debouncedSearchTerm]);

  const buttons: any = [
    {
      buttonModel: {
        content: t('cancel', { ns: 'recruitment' }),
        cssClass: 'e-flat-cancel',
      },
      click: () => {
        updateSearchDialogState(false, false, {});
        setVisibility(false);
      },
    },
    {
      buttonModel: {
        content: t('apply', { ns: 'recruitment' }),
        cssClass: 'e-flat-apply',
        isPrimary: true,
        disabled: !selectedValue,
      },
      click: () => {
        handleSubmit();
      },
    },
  ];

  const handleChange = (value: any) => {
    //console.log(value?.itemData);
    setSelectedValue(value?.itemData);
    localStorage.setItem('rec_search_value', JSON.stringify(value?.itemData));
  };

  const onFiltering = async (e: any) => {
    try {
      if (e.text === '') {
        return;
      }
      setSearchTerm(e.text);
    } catch (error) {
      console.log(error);
    } finally {
      hideSpinner();
    }
  };

  const handleSubmit = async () => {
    let filterParam = localStorage.getItem('rec_filter_param');
    let objParam;
    if (filterParam) {
      let objFilterParam = JSON.parse(JSON.parse(JSON.stringify(filterParam)));
      console.log('objFilterParam', objFilterParam.listMasterFilter.FILTER_MASTER_PROJECT);
      if (objFilterParam.listMasterFilter.FILTER_MASTER_PROJECT) {
        objFilterParam.listMasterFilter.FILTER_MASTER_PROJECT.COL.push({
          name: selectedValue?.recordType === 'Vacancy' ? 'id' : 'folderId',
          type: 'equals',
          value: selectedValue?.id,
        });
      } else {
        objFilterParam.listMasterFilter.FILTER_MASTER_PROJECT = {
          COL: [
            {
              name: selectedValue?.recordType === 'Vacancy' ? 'id' : 'folderId',
              type: 'equals',
              value: selectedValue?.id,
            },
          ],
        };
      }
      objParam = objFilterParam;
      console.log('objFilterParamUpdated', objFilterParam);
    } else {
      objParam = {
        listMasterFilter: {
          limit: 20,
          offset: 0,
          masterProjectCategory: 'com.atollon.project.fpatypecategory.recruitment.vacancy',
          // masterFolderCategory: 'com.atollon.project.fpatypecategory.recruitment.client',
          onlyPrimaryMasterFolders: 1,
          FILTER_MASTER_PROJECT: {
            COL: [
              {
                name: selectedValue?.recordType === 'Vacancy' ? 'id' : 'folderId',
                type: 'equals',
                value: selectedValue?.id,
              },
            ],
          },
        },
      };
    }
    delete objParam.listMasterFilter.masterFolderCategory;

    //console.log(objParam);

    updateSearchDialogState(false, true, objParam);
    setVisibility(false);
  };

  const handleClose = async (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      updateSearchDialogState(false, false, {});
      setVisibility(false);
      return;
    }
  };

  function searchDalogheader(): JSX.Element {
    return <b>{t('search_filter', { ns: 'recruitment' })}</b>;
  }

  function searchDalogContent(): any {
    //console.log(respUsers);
    const searchFields = { value: 'name', groupBy: 'recordType' };
    return (
      <Form onSubmit={handleSubmit}>
        <>
          {searchedValue && Object.keys(searchedValue).length ? (
            <Flex direction={{ base: 'column', L: 'row' }}>
              <View width="size-6000">
                <b>{t('searched_text', { ns: 'recruitment' })} :</b> {searchedValue?.name}
              </View>
            </Flex>
          ) : (
            ''
          )}
        </>
        <Flex direction={{ base: 'column', L: 'row' }}>
          <View width="size-1000">
            <b>{t('search', { ns: 'recruitment' })}</b>
          </View>

          <View width="size-6000">
            <AutoCompleteComponent
              id="autocomplete"
              ref={autocomplete => {
                autoCompleteRef = autocomplete;
              }}
              highlight={true}
              dataSource={searchResults}
              //sortOrder={sortOrder}
              fields={searchFields}
              placeholder={t('type_to_search', { ns: 'recruitment' })}
              allowCustom={true}
              autofill={true}
              filtering={onFiltering}
              change={handleChange}
              showPopupButton={true}
              noRecordsTemplate={t('type_to_search', { ns: 'recruitment' })}
            />
          </View>
        </Flex>
      </Form>
    );
  }

  const showSpinner = () => {
    if (autoCompleteRef) {
      autoCompleteRef.showSpinner();
    }
  };

  const hideSpinner = () => {
    if (autoCompleteRef) {
      autoCompleteRef.hideSpinner();
    }
  };

  const closeDialog = () => {
    updateSearchDialogState(false, false, {});
    setVisibility(false);
  };

  return (
    <div className={styles.overlay} onKeyUp={handleClose}>
      <CustomDialogComponent
        width="750px"
        isModal={true}
        buttons={buttons}
        visible={visibility}
        header={searchDalogheader}
        dialogContent={searchDalogContent}
        closeDialog={closeDialog}
      ></CustomDialogComponent>
    </div>
  );
};

export default SearchDialogTemplate;
