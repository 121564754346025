import { BaseRequest, IBaseRequest } from "../../BaseRequest";

export interface IListUserRequest extends IBaseRequest {
    limit?: number;
    offset?: number;
    orderBy?: string;
    countOnly?: boolean;
    accessFilter?: string;
    groupId?: number;
    notPublic?: boolean;
    onlyActive?: boolean;
    public?: boolean;
    roleId?: number;
    treeHandle?: number;
    userName?: string;
}

export class ListUserRequest extends BaseRequest implements IListUserRequest {
    public limit?: number;
    public offset?: number;
    public orderBy?: string;
    public countOnly?: boolean;
    public accessFilter?: string;
    public groupId?: number;
    public notPublic?: boolean;
    public onlyActive?: boolean;
    public public?: boolean;
    public roleId?: number;
    public treeHandle?: number;
    public userName?: string;

    constructor( _server: string, 
        _session: string, 
        _onlyActive?: boolean, 
        _limit?: number, 
        _offset?: number, 
        _orderBy?: string, 
        _countOnly?: boolean, 
        _accessFilter?: string, 
        _groupId?: number, 
        _notPublic?: boolean, 
        _public?: boolean, 
        _roleId?: number, 
        _treeHandle?: number, 
        _userName?: string
        ) {
        super(_server, _session);
        this.limit = _limit;
        this.offset = _offset;
        this.orderBy = _orderBy;
        this.countOnly = _countOnly;
        this.accessFilter = _accessFilter;
        this.groupId = _groupId;
        this.notPublic = _notPublic;
        this.onlyActive = _onlyActive;
        this.public = _public;
        this.roleId = _roleId;
        this.treeHandle = _treeHandle;
        this.userName = _userName;
    }

    toXml(): string {
        var parameters:string = super.toXml();
        if(this.limit) parameters += "<limit>" + this.limit + "</limit>";
        if(this.offset) parameters += "<offset>" + this.offset + "</offset>";
        if(this.orderBy) parameters += "<orderBy>" + this.orderBy + "</orderBy>";
        if(this.countOnly) parameters += "<countOnly>" + this.countOnly + "</countOnly>";
        if(this.accessFilter) parameters += "<accessFilter>" + this.accessFilter + "</accessFilter>";
        if(this.groupId) parameters += "<groupId>" + this.groupId + "</groupId>";
        if(this.notPublic) parameters += "<notPublic>" + this.notPublic + "</notPublic>";
        if(this.onlyActive) parameters += "<onlyActive>" + this.onlyActive + "</onlyActive>";
        if(this.public) parameters += "<public>" + this.public + "</public>";
        if(this.roleId) parameters += "<roleId>" + this.roleId + "</roleId>";
        if(this.treeHandle) parameters += "<treeHandle>" + this.treeHandle + "</treeHandle>";
        if(this.userName) parameters += "<userName>" + this.userName + "</userName>";

        return super.wrapperXml("ListUser", parameters);
    }
}