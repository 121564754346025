import { BaseRequest } from "../../BaseRequest";

export class ListCandidateVacancyRequest extends BaseRequest {
  public id?: number;
  public candidate?: string;
  public client?: string;
  public jobField?: string;
  public position?: string;
  public grade?: string;
  public folder?: string;
  public orderBy?: string;

  constructor(
    server: string,
    sessionId: string,
    id?: number,
    candidate?: string,
    client?: string,
    jobField?: string,
    position?: string,
    grade?: string,
    folder?: string,
    orderBy?: string
  ) {
    super(server, sessionId);
    this.id = id;
    this.candidate = candidate;
    this.client = client;
    this.jobField = jobField;
    this.position = position;
    this.grade = grade;
    this.folder = folder;
    this.orderBy = orderBy;
  }

  public override toXml(): string {
    var parameters: string = super.toXml();
    if (this.id) {
      parameters += `<id>${this.id}</id>`;
    }
    if (this.candidate) {
      parameters += `<candidate>${this.candidate}</candidate>`;
    }
    if (this.client) {
      parameters += `<client>${this.client}</client>`;
    }
    if (this.jobField) {
      parameters += `<jobField>${this.jobField}</jobField>`;
    }
    if (this.position) {
      parameters += `<position>${this.position}</position>`;
    }
    if (this.grade) {
      parameters += `<grade>${this.grade}</grade>`;
    }
    if (this.folder) {
      parameters += `<folder>${this.folder}</folder>`;
    }
    if (this.orderBy) {
      parameters += `<orderBy>${this.orderBy}</orderBy>`;
    }
    return super.wrapperXml("ListCandidateVacancy", parameters);
  }
}
