import { GroupedSearchResultItemData } from '../../../utils/SearchUtils';
import styles from './GlobalSearchResults.module.css';
import SearchResult from './SearchResults/SearchResult';

export interface IGlobalSearchResultsProps {
  title: string;
  groups: GroupedSearchResultItemData[];
  selectedGroup: number;
  selectedItem: number;
  onSearchSelected: (item:any) => void;
  onLoadMore?: () => void;
}

export default function GlobalSearchResults ({ 
  title, 
  groups, 
  selectedGroup,
  selectedItem,
  onSearchSelected,
  onLoadMore
}: IGlobalSearchResultsProps) {
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const element = event.target as HTMLDivElement;
    if(element.scrollTop + element.clientHeight >= element.scrollHeight) {
      onLoadMore && onLoadMore();
    }
  }
  return (
    <>
        <div className={styles.searchResultsContainer} 
              onScroll={handleScroll} 
              >
            <SearchResult  
                mainTitle={title} 
                groups={groups} 
                onSearchSelected={onSearchSelected} 
                selectedGroup={selectedGroup}
                selectedItem={selectedItem}
                />
        </div>
    </>
  );
}
