import { TEAM } from '../../../utils/ServiceUtils';
import { ServiceBase } from '../ServiceBase';
import { IDeleteActivityUserRequest } from './requests/DeleteActivityUserRequest';
import { IDeleteFolderUserRequest } from './requests/DeleteFolderUserRequest';
import { IDeleteProjectUserRequest } from './requests/DeleteProjectUserRequest';
import { IListActivityUserRequest } from './requests/ListActivityUserRequest';
import { IListFolderUserRequest } from './requests/ListFolderUserRequest';
import { IListProjectUserRequest } from './requests/ListProjectUserRequest';
import { INewActivityUserRequest } from './requests/NewActivityUserRequest';
import { INewFolderUserRequest } from './requests/NewFolderUserRequest';
import { INewProjectUserRequest } from './requests/NewProjectUserRequest';
import { IUpdateActivityUserRequest } from './requests/UpdateActivityUserRequest';
import { IUpdateFolderUserRequest } from './requests/UpdateFolderUserRequest';
import { IUpdateProjectUserRequest } from './requests/UpdateProjectUserRequest';
import { DeleteActivityUserResponse } from './responses/DeleteActivityUserResponse';
import { DeleteFolderUserResponse } from './responses/DeleteFolderUserResponse';
import { DeleteProjectUserResponse } from './responses/DeleteProjectUserResponse';
import { ListActivityUserResponse } from './responses/ListActivityUserResponse';
import { ListFolderUserResponse } from './responses/ListFolderUserResponse';
import { ListProjectUserResponse } from './responses/ListProjectUserResponse';
import { NewActivityUserResponse } from './responses/NewActivityUserResponse';
import { NewFolderUserResponse } from './responses/NewFolderUserResponse';
import { NewProjectUserResponse } from './responses/NewProjectUserResponse';
import { UpdateActivityUserResponse } from './responses/UpdateActivityUserResponse';
import { UpdateFolderUserResponse } from './responses/UpdateFolderUserResponse';
import { UpdateProjectUserResponse } from './responses/UpdateProjectUserResponse';

export interface ITeamService {
  listActivityUser(request: IListActivityUserRequest): Promise<ListActivityUserResponse>;
  newActivityUser(request: INewActivityUserRequest): Promise<NewActivityUserResponse>;
  updateActivityUser(request: IUpdateActivityUserRequest): Promise<UpdateActivityUserResponse>;
  deleteActivityUser(request: IDeleteActivityUserRequest): Promise<DeleteActivityUserResponse>;

  listProjectUser(request: IListProjectUserRequest): Promise<ListProjectUserResponse>;
  updateProjectUser(request: IUpdateProjectUserRequest): Promise<UpdateProjectUserResponse>;
  deleteProjectUser(request: IDeleteProjectUserRequest): Promise<DeleteProjectUserResponse>;
  newProjectUser(request: INewProjectUserRequest): Promise<NewProjectUserResponse>;

  listFolderUser(request: IListFolderUserRequest): Promise<ListFolderUserResponse>;
  updateFolderUser(request: IUpdateFolderUserRequest): Promise<UpdateFolderUserResponse>;
  deleteFolderUser(request: IDeleteFolderUserRequest): Promise<DeleteFolderUserResponse>;
  newFolderUser(request: INewFolderUserRequest): Promise<NewFolderUserResponse>;
}

export class TeamService extends ServiceBase implements ITeamService {
  public async listActivityUser(request: IListActivityUserRequest): Promise<ListActivityUserResponse> {
    let response: any = null;
    try {
      let tmp_response = await this.makeCall(request.toXml(), TEAM.listActivityUser, request.cancelSource);
      response = tmp_response.data;
      await this.updateCache(TEAM.listActivityUser, request.toXml(), response);
    } catch (error: any) {
      response = await this.getFromCache(TEAM.listActivityUser, request.toXml());
    }

    var parsedResponse = super.sendResponse2(response, ['ROW']).ListActivityUserResponse as ListActivityUserResponse;
    if (!super._checkErrors(parsedResponse)) {
    }
    return parsedResponse;
  }

  public async newActivityUser(request: INewActivityUserRequest): Promise<NewActivityUserResponse> {
    let response: any = null;
    try {
      let tmp_response = await this.makeCall(request.toXml(), TEAM.newActivityUser, request.cancelSource);
      response = tmp_response.data;
      await this.updateCache(TEAM.newActivityUser, request.toXml(), response);
    } catch (error: any) {
      response = await this.getFromCache(TEAM.newActivityUser, request.toXml());
    }

    var parsedResponse = super.sendResponse2(response).NewActivityUserResponse as NewActivityUserResponse;
    if (!super._checkErrors(parsedResponse)) {
    }
    return parsedResponse;
  }

  public async listProjectUser(request: IListProjectUserRequest): Promise<ListProjectUserResponse> {
    let response: any = null;
    try {
      let tmp_response = await this.makeCall(request.toXml(), TEAM.listProjectUser, request.cancelSource);
      response = tmp_response.data;
      await this.updateCache(TEAM.listProjectUser, request.toXml(), response);
    } catch (error: any) {
      response = await this.getFromCache(TEAM.listProjectUser, request.toXml());
    }

    var parsedResponse = super.sendResponse2(response, ['ROW']).ListProjectUserResponse as ListProjectUserResponse;
    if (!super._checkErrors(parsedResponse)) {
    }
    return parsedResponse;
  }

  public async listFolderUser(request: IListFolderUserRequest): Promise<ListFolderUserResponse> {
    let response: any = null;
    try {
      let tmp_response = await this.makeCall(request.toXml(), TEAM.listFolderUser, request.cancelSource);
      response = tmp_response.data;
      await this.updateCache(TEAM.listFolderUser, request.toXml(), response);
    } catch (error: any) {
      response = await this.getFromCache(TEAM.listFolderUser, request.toXml());
    }

    var parsedResponse = super.sendResponse2(response, ['ROW']).ListFolderUserResponse as ListFolderUserResponse;
    if (!super._checkErrors(parsedResponse)) {
    }
    return parsedResponse;
  }

  public async updateActivityUser(request: IUpdateActivityUserRequest): Promise<UpdateActivityUserResponse> {
    let response: any = null;
    try {
      let tmp_response = await this.makeCall(request.toXml(), TEAM.updateActivityUser, request.cancelSource);
      response = tmp_response.data;
      await this.updateCache(TEAM.updateActivityUser, request.toXml(), response);
    } catch (error: any) {
      response = await this.getFromCache(TEAM.updateActivityUser, request.toXml());
    }

    var parsedResponse = super.sendResponse2(response).UpdateActivityUserResponse as UpdateActivityUserResponse;
    if (!super._checkErrors(parsedResponse)) {
    }
    return parsedResponse;
  }

  public async deleteActivityUser(request: IDeleteActivityUserRequest): Promise<DeleteActivityUserResponse> {
    let response: any = null;
    try {
      let tmp_response = await this.makeCall(request.toXml(), TEAM.deleteActivityUser, request.cancelSource);
      response = tmp_response.data;
      await this.updateCache(TEAM.deleteActivityUser, request.toXml(), response);
    } catch (error: any) {
      response = await this.getFromCache(TEAM.deleteActivityUser, request.toXml());
    }

    var parsedResponse = super.sendResponse2(response).DeleteActivityUserResponse as DeleteActivityUserResponse;
    if (!super._checkErrors(parsedResponse)) {
    }
    return parsedResponse;
  }

  public async updateProjectUser(request: IUpdateProjectUserRequest): Promise<UpdateProjectUserResponse> {
    let response: any = null;
    try {
      let tmp_response = await this.makeCall(request.toXml(), TEAM.updateProjectUser, request.cancelSource);
      response = tmp_response.data;
      await this.updateCache(TEAM.updateProjectUser, request.toXml(), response);
    } catch (error: any) {
      response = await this.getFromCache(TEAM.updateProjectUser, request.toXml());
    }

    var parsedResponse = super.sendResponse2(response).UpdateProjectUserResponse as UpdateProjectUserResponse;
    if (!super._checkErrors(parsedResponse)) {
    }
    return parsedResponse;
  }

  public async deleteProjectUser(request: IDeleteProjectUserRequest): Promise<DeleteProjectUserResponse> {
    let response: any = null;
    try {
      let tmp_response = await this.makeCall(request.toXml(), TEAM.deleteProjectUser, request.cancelSource);
      response = tmp_response.data;
      await this.updateCache(TEAM.deleteProjectUser, request.toXml(), response);
    } catch (error: any) {
      response = await this.getFromCache(TEAM.deleteProjectUser, request.toXml());
    }

    var parsedResponse = super.sendResponse2(response).DeleteProjectUserResponse as DeleteProjectUserResponse;
    if (!super._checkErrors(parsedResponse)) {
    }
    return parsedResponse;
  }

  public async updateFolderUser(request: IUpdateFolderUserRequest): Promise<UpdateFolderUserResponse> {
    let response: any = null;
    try {
      let tmp_response = await this.makeCall(request.toXml(), TEAM.updateFolderUser, request.cancelSource);
      response = tmp_response.data;
      await this.updateCache(TEAM.updateFolderUser, request.toXml(), response);
    } catch (error: any) {
      response = await this.getFromCache(TEAM.updateFolderUser, request.toXml());
    }

    var parsedResponse = super.sendResponse2(response).UpdateFolderUserResponse as UpdateFolderUserResponse;
    if (!super._checkErrors(parsedResponse)) {
    }
    return parsedResponse;
  }

  public async deleteFolderUser(request: IDeleteFolderUserRequest): Promise<DeleteFolderUserResponse> {
    let response: any = null;
    try {
      let tmp_response = await this.makeCall(request.toXml(), TEAM.deleteFolderUser, request.cancelSource);
      response = tmp_response.data;
      await this.updateCache(TEAM.deleteFolderUser, request.toXml(), response);
    } catch (error: any) {
      response = await this.getFromCache(TEAM.deleteFolderUser, request.toXml());
    }

    var parsedResponse = super.sendResponse2(response).DeleteFolderUserResponse as DeleteFolderUserResponse;
    if (!super._checkErrors(parsedResponse)) {
    }
    return parsedResponse;
  }

  public async newFolderUser(request: INewFolderUserRequest): Promise<NewFolderUserResponse> {
    let response: any = null;
    try {
      let tmp_response = await this.makeCall(request.toXml(), TEAM.newFolderUser, request.cancelSource);
      response = tmp_response.data;
      await this.updateCache(TEAM.newFolderUser, request.toXml(), response);
    } catch (error: any) {
      response = await this.getFromCache(TEAM.newFolderUser, request.toXml());
    }

    var parsedResponse = super.sendResponse2(response).NewFolderUserResponse as NewFolderUserResponse;
    if (!super._checkErrors(parsedResponse)) {
    }
    return parsedResponse;
  }

  public async newProjectUser(request: INewProjectUserRequest): Promise<NewProjectUserResponse> {
    let response: any = null;
    try {
      let tmp_response = await this.makeCall(request.toXml(), TEAM.newProjectUser, request.cancelSource);
      response = tmp_response.data;
      await this.updateCache(TEAM.newProjectUser, request.toXml(), response);
    } catch (error: any) {
      response = await this.getFromCache(TEAM.newProjectUser, request.toXml());
    }

    var parsedResponse = super.sendResponse2(response).NewProjectUserResponse as NewProjectUserResponse;
    if (!super._checkErrors(parsedResponse)) {
    }
    return parsedResponse;
  }
}
